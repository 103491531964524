const isDevelopment = window.location.hostname.includes('development');
const isStaging = window.location.hostname.includes('staging');
const isBeta = window.location.hostname.includes('beta.app.engequest');
const isProd = window.location.hostname.includes('app.engequest');
const getBaseUrl = (): string => {
    if (isBeta) {
        return 'https://beta.api.engequest.com.br';
    }
    if (isProd) {
        return 'https://api.engequest.com.br';
    }
    if (isStaging) {
        return 'https://staging.engequestapi.dotcoding.com.br';
    }
    return 'https://api.engequest.com.br';
};
const baseUrl = getBaseUrl();

const getIDNotification = (): string => {
    if (isBeta) {
        return '444db60e-b05e-438a-875f-8627606f9d9f';
    }
    if (isProd) {
        return '6b159f84-3caa-4f1d-b206-70246cc06a00';
    }
    if (isStaging) {
        return '14bc9948-3ea3-4a48-a82a-d528c4ee3753';
    }
    if (isDevelopment) {
        return 'eb79685b-7f73-4a72-b59a-4e9252124d14';
    }
    return '';
};

export default {
    baseUrl,
    getIDNotification,
};
