// libraries
// import { getTime } from 'date-fns';
import axios from 'axios';

// js
import constants from '../configs/constants';
// import functions from '../utils/functions';

// constants
const { baseUrl } = constants;

async function GetInfoUser(
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/users?idUser=${idUser}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function EditProfile(
    idUser: string,
    token: string,
    name?: string,
    email?: string,
    password?: string,
    telephone?: string,
    birthDate?: string,
    identifier?: string,
    idEngineering?: string,
    idExamination?: string,
    photo?: string,
): Promise<any> {
    try {
        const body = new FormData();
        if (name?.length) {
            body.append('name', name);
        }
        if (email?.length) {
            body.append('email', email);
        }
        if (password?.length) {
            body.append('password', password);
        }
        if (photo) {
            body.append('photoRemote', photo);
        }
        // if (telephone?.length) {
        //     body.append('telephone', functions.OnlyNumbersMask(telephone));
        // }
        // if (birthDate?.length) {
        //     const [day, month, year] = birthDate.split('/');
        //     const timeBirthDate =
        //      getTime(new Date(Number(year), Number(month) - 1, Number(day)));
        //     body.append('birthDate', String(timeBirthDate));
        // }
        // if (identifier?.length) {
        //     body.append('cpf', functions.OnlyNumbersMask(identifier));
        // }
        if (idEngineering) {
            body.append('idEngineering', idEngineering);
        }
        if (idExamination) {
            body.append('idExamination', idExamination);
        }
        // if (actionNewBookSuggested) {
        //     body.append('actionNewBookSuggested', actionNewBookSuggested);
        // }
        // if (photo !== null) {
        //     body.append('photo', photo);
        // }
        const headers = {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token,
        };
        const response = await axios.put(`${baseUrl}/users?idUser=${idUser}`, body, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    EditProfile,
    GetInfoUser,
};
