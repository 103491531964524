// libraries
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

// js
import colors from '../../styles/colors';
import {
    Container,
    Home,
    ContainerBoxFilters,
    ContainerBody,
    ContainerAdjustInfo,
    InfoCard,
    ContainerFilters,
    ContainerSearch,
    ImgSearch,
    InputSearch,
    ImgFilter,
    ContainerAllFilters,
    // ContainerCalendar,
    // TitleCalendar,
    ButtonAddFilter,
    AddFilter,
    ContainerOptionsDrawer,
} from './filtersStyles';
import { addBook, filterGray, searchGray } from '../../assets';
import { RootState } from '../../store/index';

// components
import DrawerComponent from '../../components/Drawer/DrawerComponent';
import HeaderComponent from '../../components/Header/HeaderComponent';
// import OneDateSimulateComponent from '../Timeline/components/OneDateSimulateComponent';
import filters from '../../services/filters';
import categories from '../../services/categories';
import PopUpComponent from '../../components/PopUp/PopUpComponent';
import FiltersComponent from './components/FiltersComponent';
import constants from '../../utils/consts';
import PopUpQuestions from '../../components/PopUpQuestions/PopUpQuestions';

// main
const FiltersPage: React.FC = () => {
    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // states
    // const [search, setSearch] = useState('');
    const [searchFilters, setSearchFilters] = useState('');
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const [show, setShow] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [existFilters, setExistFilters] = useState<boolean | null>(null);
    const [allFiltersSaved, setAllFiltersSaved] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [idOrder, setIdOrder] = useState('id');
    const [flowOrderBy, setFlowOrderBy] = useState('asc');
    const [popUp, setPopUp] = useState(false);
    const [idPopUp, setIdPopUp] = useState(-1);
    const [newNameFilter, setNewNameFilter] = useState('');
    const [selectFilterSaved, setSelectFilterSaved] = useState<any>(null);
    const [allSubjectsSelect, setAllSubjectsSelect] = useState<Array<any>>([]);
    const [selectFilter, setSelectFilter] = useState<Array<number>>([]);
    const [nameCreateFilter, setNameCreateFilter] = useState('');
    const [allQuestions, setAllQuestions] = useState([]);
    const [flowQuestions, setFlowQuestions] = useState(false);
    const [selectDropdawn, setSelectDropdawn] = useState<Array<number>>([]);

    // methods
    const onDrawer = () => {
        setIsOpen(true);
    };

    const onChangeSearchFilters = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilters(txt.target.value);
    };

    const ActionInitialFilters = async () => {
        const GetAllResultBooks = await filters.GetAllFiltersSaved(
            searchFilters,
            idOrder,
            flowOrderBy,
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        setAllFiltersSaved(GetAllResultBooks?.filterSaved);
        setExistFilters(GetAllResultBooks?.filterSaved?.length > 0);
        setLoading(false);
        const GetAllCategories = await categories.GetAllCategories(
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        setAllCategories(GetAllCategories?.result);
    };

    function ValidationMarginTimeline() {
        if (show) {
            return '310px';
        }
        if (!show) {
            return '160px';
        }
    }

    function ValidationMarginTimelineLowLarge() {
        if (show) {
            return '215px';
        }
        if (!show) {
            return '105px';
        }
    }

    const RefreshInitial = () => {
        ActionInitialFilters();
    };

    const OpenOrderList = () => {
        setIdPopUp(1);
    };

    const GoToCreateFilterSaved = () => {
        setIdPopUp(2);
    };

    // useEffect
    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        setLoading(true);
        const timeOutId = setTimeout(() => {
            ActionInitialFilters();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchFilters]);

    useEffect(() => {
        if (idPopUp !== -1) {
            setPopUp(true);
        }
    }, [idPopUp]);

    useEffect(() => {
        if (popUp === false) {
            setIdPopUp(-1);
        }
    }, [popUp]);

    useEffect(() => {
        if (allQuestions?.length) {
            setFlowQuestions(true);
        }
    }, [allQuestions]);

    // renders
    // const RenderAllDates = () => (
    //     constants.allDates.map((v) => (
    //         <OneDateSimulateComponent item={v} />
    //     ))
    // );

    const RenderTitle = () => {
        if (idPopUp === 1) {
            return 'Ordenação';
        }
        if (idPopUp === 2) {
            return 'Criar filtro salvo';
        }
        if (idPopUp === 3) {
            return 'Editar filtro salvo';
        }
        if (idPopUp === 4) {
            return 'Opções';
        }
        return 'Selecione um filtro';
    };

    const ActionApplyOrder = async () => {
        setPopUp(false);
        const GetAllResultBooks = await filters.GetAllFiltersSaved(
            searchFilters,
            idOrder,
            flowOrderBy,
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        setAllFiltersSaved(GetAllResultBooks?.filterSaved);
        setExistFilters(GetAllResultBooks?.filterSaved?.length > 0);
    };

    const CleanFilters = async () => {
        setIdOrder('id');
        setFlowOrderBy('asc');
        setPopUp(false);
        const GetAllResultBooks = await filters.GetAllFiltersSaved(
            searchFilters,
            'id',
            'asc',
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        setAllFiltersSaved(GetAllResultBooks?.filterSaved);
        setExistFilters(GetAllResultBooks?.filterSaved?.length > 0);
    };

    function AllFilters() {
        if (existFilters === false) {
            return (
                <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>
                    SEM FILTROS SALVOS PARA SEREM EXIBIDOS
                </InfoCard>
            );
        }
        return allFiltersSaved.map((v, i) => (
            <FiltersComponent
                item={v}
                index={i}
                setSelectFilterSaved={setSelectFilterSaved}
                setIdPopUp={setIdPopUp}
                setSelectFilter={setSelectFilter}
                setAllSubjectsSelect={setAllSubjectsSelect}
                setNameCreateFilter={setNameCreateFilter}
            />
        ));
    }

    const CloseModal = () => {
        setIsOpen(false);
    };

    return (
        <Container>
            <Home>
                <HeaderComponent
                    // search={search}
                    // setSearch={setSearch}
                    // labelSearch="Concursos"
                    widthActual={widthActual}
                    onDrawer={onDrawer}
                />
                <ContainerBody>
                    {widthActual >= 800 ? (
                        <DrawerComponent
                            show={show}
                            setShow={setShow}
                        />
                    ) : null}
                    <ContainerFilters
                        existStatistics
                        marginLeft={ValidationMarginTimeline()}
                        marginLeftLowLarge={ValidationMarginTimelineLowLarge()}
                    >
                        <ContainerBoxFilters>
                            <ContainerAdjustInfo>
                                <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>—</InfoCard>
                                <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>
                                    CONFIRA SEUS FILTROS SALVOS
                                </InfoCard>
                            </ContainerAdjustInfo>
                            <ContainerAdjustInfo flexDirection="row" alignItems="center" marginTop="40px">
                                <ContainerSearch>
                                    <ImgSearch src={searchGray} />
                                    <InputSearch value={searchFilters} placeholder="Buscar" onChange={onChangeSearchFilters} />
                                </ContainerSearch>
                                <ImgFilter src={filterGray} onClick={OpenOrderList} />
                            </ContainerAdjustInfo>
                            <ContainerAllFilters justifyContent={existFilters === null ? 'center' : 'space-between'}>
                                <Loader
                                    visible={loading}
                                    type="TailSpin"
                                    color={colors.theme(window.localStorage.getItem('theme')
                                    || 'light').purpleDark}
                                    height={80}
                                    width={80}
                                />
                                {existFilters === null ? null : AllFilters()}
                            </ContainerAllFilters>
                        </ContainerBoxFilters>
                    </ContainerFilters>
                    {/* <ContainerCalendar>
                        <TitleCalendar>Lista de Simulados</TitleCalendar>
                        {RenderAllDates()}
                    </ContainerCalendar> */}
                </ContainerBody>
                <ButtonAddFilter onClick={GoToCreateFilterSaved}>
                    <AddFilter src={addBook} />
                </ButtonAddFilter>
                <Modal
                    isOpen={isOpen}
                    style={constants.modalDrawerStyles}
                    onRequestClose={CloseModal}
                >
                    <ContainerOptionsDrawer>
                        <DrawerComponent
                            isModal
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            show
                        />
                    </ContainerOptionsDrawer>
                </Modal>
                <PopUpComponent
                    popUp={popUp}
                    setPopUp={setPopUp}
                    title={RenderTitle()}
                    idOrder={idOrder}
                    selectDropdawn={selectDropdawn}
                    setSelectDropdawn={setSelectDropdawn}
                    setIdOrder={setIdOrder}
                    flowOrderBy={flowOrderBy}
                    setFlowOrderBy={setFlowOrderBy}
                    setAllBooks={setAllFiltersSaved}
                    nameBook={newNameFilter}
                    setNameBook={setNewNameFilter}
                    RefreshInitial={RefreshInitial}
                    setIdPopUp={setIdPopUp}
                    selectFilterSaved={selectFilterSaved}
                    idScreen="Filters"
                    allCategories={allCategories}
                    selectFilter={selectFilter}
                    setSelectFilter={setSelectFilter}
                    allSubjectsSelect={allSubjectsSelect}
                    setAllSubjectsSelect={setAllSubjectsSelect}
                    nameCreateFilter={nameCreateFilter}
                    setNameCreateFilter={setNameCreateFilter}
                    setAllQuestions={setAllQuestions}
                    ActionApplyOrder={ActionApplyOrder}
                    CleanFilters={CleanFilters}
                />
                <PopUpQuestions
                    popUp={flowQuestions}
                    setPopUp={setFlowQuestions}
                    allQuestions={allQuestions}
                    setAllQuestions={setAllQuestions}
                    idType="Filtros"
                    idSelected={selectFilterSaved?.id}
                />
            </Home>
        </Container>
    );
};

export default FiltersPage;
