// libraries
import React from 'react';

// js
import {
    Cover,
    ContainerBox,
    ContainerInfo,
    Title,
    Hours,
    Description,
    ButtonMore,
    ContainerInfoOptions,
} from '../questionsStyles';

// interface
interface Props {
    item: any,
    widthActual: number,
    setIdPopUp: (v: number) => void,
}

const BoxOptionsComponent: React.FC<Props> = (props: Props) => {
    // constants
    const {
        item,
        widthActual,
        setIdPopUp,
    } = props;

    // methods
    const ValidationQtdLine = () => {
        if (widthActual < 2000) {
            return 3;
        }

        return 2;
    };

    const OpenModal = () => {
        setIdPopUp(item?.id === 1 ? 5 : item?.id);
    };

    // main
    return (
        <ContainerBox key={item.id}>
            {item?.file ? (
                <Cover src={item.file} />
            ) : null}
            <ContainerInfo fileExist={item?.file?.length > 0}>
                <ContainerInfoOptions>
                    <Title>—</Title>
                    <Title>{item.title}</Title>
                    <Description qtdLine={ValidationQtdLine()}>{item.description}</Description>
                </ContainerInfoOptions>
                <ButtonMore onClick={OpenModal}>
                    <Hours>CLIQUE PARA ACESSAR</Hours>
                </ButtonMore>
            </ContainerInfo>
        </ContainerBox>
    );
};

export default BoxOptionsComponent;
