/* eslint-disable react/require-default-props */
// libraries
import React from 'react';

// js
import { Button, Label } from './buttonStyles';

// interfaces
interface Props {
    label: string,
    onClick: any,
    colorButton?: string,
    colorLabel?: string,
    disabled?: boolean,
    cursor?: string,
    opacity?: number,
}

const ButtonComponent: React.FC<Props> = (props: Props) => {
    // constants
    const {
        label,
        onClick,
        colorButton,
        colorLabel,
        disabled,
        cursor,
        opacity,
    } = props;

    return (
        <Button cursor={cursor} opacity={opacity} disabled={disabled} onClick={onClick} activeColor={colorButton || ''}>
            <Label activeColor={colorLabel || ''}>{label}</Label>
        </Button>
    );
};

export default ButtonComponent;
