// libraries
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';

// js
import colors from '../../styles/colors';

// constants
const fadeInAnimation = keyframes`${fadeIn}`;

// interfaces
interface TitleInputInterfaces {
    activeColor?: string,
    activeCursor?: string,
    fontWeight?: string,
    width?: string,
}

interface ContainerBoxInterfaces {
    opacity?: number,
}

interface ButtonOptionInterfaces {
    backgroundColor?: string,
    border?: string,
}

interface TextActionNavigationInterfaces {
    color?: string,
    fontSize?: string,
}

interface HomeInterfaces {
    background: string,
    zoomHeight?: boolean,
    height?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    min-height: 100vh;
    min-width: 100vw;
`;

export const ContainerBody = styled.div<HomeInterfaces>`
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: ${(props) => (props.zoomHeight ? '100% 100%' : '100% 150%')};
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* height: ${isMobile ? '20vh' : '50vh'}; */
    height: ${(props) => (props.zoomHeight ? '100vh' : props.height)};
    transition-duration: 1000ms;
    min-width: 100vw;
`;

export const LogoMain = styled.img`
    object-fit: contain;
    height: 500px;
    transition-duration: 400ms;
    animation: 1s ${fadeInAnimation};
    width: 500px;
`;

export const ContainerBox = styled.div<ContainerBoxInterfaces>`
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 10px;
    position: absolute;
    top: 100px;
    min-height: 60vh;
    width: 40%;
    opacity: ${(props) => (props.opacity || 1)};
    transition-duration: 1000ms;
    @media (max-width: 900px) {
        width: 90%;
    }
`;

export const TitleInput = styled.text<TitleInputInterfaces>`
    color: ${(props) => (props.activeColor || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    cursor: ${(props) => (props.activeCursor || 'default')};
    font-size: 16px;
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    width: ${(props) => (props.width || '80%')};
    text-decoration: ${(props) => (props.textDecoration || 'default')};
    @media (max-width: 900px) {
        font-size: 14px;
    }
    @media (max-width: 650px), (max-height: 650px) {
        font-size: 12px;
    }
`;

export const ContainerOptions = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 10px;
    width: 80%;
`;

export const ContainerCheckBox = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const ButtonOption = styled.div<ButtonOptionInterfaces>`
    cursor: pointer;
    align-items: center;
    display: flex;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    border: ${(props) => (props.border || 'none')};
    border-radius: 30px;
    margin-bottom: 20px;
    justify-content: center;
    min-height: 50px;
    transition-duration: 400ms;
    padding: 5px;
    width: 45%;
    @media (max-width: 512px), (max-height: 650px) {
        min-height: 40px;
        margin-bottom: 10px;
    }
`;

export const IconOption = styled.img`
    height: auto;
    width: auto;
    max-width: 30px;
    max-height: 30px;
`;

export const TextOption = styled.text<TextActionNavigationInterfaces>`
    color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').placeHolderAuthInputs)};
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    @media (max-width: 1200px) {
        font-size: 14px;
    }
    @media (max-width: 512px), (max-height: 650px) {
        font-size: 12px;
    }
`;

export const LogoHeader = styled.img`
    object-fit: contain;
    height: 80px;
    width: 200px;
`;

export const Title = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    /* margin-top: 40px; */
    font-weight: bold;
    font-size: 26px;
    @media (max-height: 900px) {
        /* margin-top: 20px; */
    }
    @media (max-height: 650px) {
        font-size: 18px;
    }
`;

export const Subtitle = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    margin-bottom: 40px;
    font-size: 20px;
    width: 80%;
    text-align: center;
    @media (max-height: 900px) {
        margin-bottom: 20px;
    }
    @media (max-height: 650px) {
        font-size: 14px;
    }
`;

export const ContainerOr = styled.div`
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    @media (max-height: 900px) {
        margin-top: 10px;
        margin-bottom: 5px;
    }
`;

export const Dashed = styled.div`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight};
    height: 1px;
    width: 45%;
`;

export const ImgBackgroundLinearGradient = styled.div<HomeInterfaces>`
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: ${(props) => (props.zoomHeight ? '100% 100%' : '100% 150%')};
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    opacity: 0.7;
`;
