// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllTests(
    idEngineering: number,
    idExamination: number,
    titleForSearching: string,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/tests?searchTxt=${titleForSearching || ''}&idEngineering=${idEngineering || ''}&idExamination=${idExamination || ''}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CheckOneTests(
    idTest: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.put(`${baseUrl}/tests?idTest=${idTest}`, null, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllTests,
    CheckOneTests,
};
