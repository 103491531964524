// libraries
import React from 'react';

// js
import colors from '../../../../styles/colors';
import {
    ContainerUser,
    InfoUser,
    ContainerPosition,
    InfoCard,
} from '../../statisticsStyles';
import { ContainerHeader, TextInfoUser, YouIdentifier } from './headerRankingStyles';

// interfaces
interface Props {
    infoUser: any,
    type: string,
    widthActual: number,
    allStatisticsRanking: Array<any>
}

const RankingComponent: React.FC<Props> = ({
    infoUser,
    type,
    widthActual,
    allStatisticsRanking,
}) => {
    // constants
    const formatTopTen = type === 'fristFive' ? allStatisticsRanking.slice(0, 5) : allStatisticsRanking.slice(5, 10);

    // main
    return (
        <>
            {(widthActual > 950 || type === 'fristFive') && allStatisticsRanking?.length ? (
                <ContainerHeader>
                    <InfoUser>
                        <TextInfoUser>#</TextInfoUser>
                        <TextInfoUser>NOME</TextInfoUser>
                    </InfoUser>
                    <TextInfoUser>PONTOS</TextInfoUser>
                    <TextInfoUser>APROVEITAMENTO</TextInfoUser>
                </ContainerHeader>
            ) : null}
            {formatTopTen.map((v: any) => {
                const ValidationBackground = () => {
                    if (v.ranking <= '3') {
                        return colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen;
                    }

                    return 'transparent';
                };
                const ValidationBackgroundPosition = () => {
                    if (v.ranking === '1') {
                        return colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTopOne;
                    }
                    if (v.ranking === '2') {
                        return colors.basic(window.localStorage.getItem('theme') || 'light').graySmall;
                    }
                    if (v.ranking === '3') {
                        return colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTopThree;
                    }
                    if (v.idUser === infoUser?.id) {
                        return colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark;
                    }
                    return colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTopTen;
                };
                return (
                    <ContainerUser backgroundColor={ValidationBackground()} border={v.idUser === infoUser?.id ? `1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').graySmall}` : 'none'}>
                        <InfoUser>
                            <ContainerPosition backgroundColor={ValidationBackgroundPosition()}>
                                <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').white} marginTop="0px">{`${v.ranking}º`}</InfoCard>
                            </ContainerPosition>
                            <InfoCard fontWeight={v.idUser === infoUser?.id || [1, 2, 3].includes(v.ranking) ? 'bold' : 'normal'}>
                                {v.name}
                                {v.idUser === infoUser?.id ? (
                                    <YouIdentifier>(você)</YouIdentifier>
                                ) : null}
                            </InfoCard>
                        </InfoUser>
                        <InfoCard width="25%">{v.points}</InfoCard>
                        <InfoCard width="25%">{v.performance}</InfoCard>
                    </ContainerUser>
                );
            })}
        </>
    );
};

export default RankingComponent;
