/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/destructuring-assignment */
// libraries
import React from 'react';
import { intervalToDuration } from 'date-fns';

// js
import {
    AdjustAlternative,
    ButtonAlternative,
    PercentageAlternative,
} from './flowQuestionsStyles';
import simulate from '../../services/simulate';
import tests from '../../services/tests';

// functions
const RenderLevel = (level: number): string => {
    if (level >= 1 && level <= 3) {
        return '***';
    }
    if (level >= 4 && level <= 7) {
        return '**';
    }
    if (level >= 8 && level <= 10) {
        return '*';
    }

    return '';
};

const RenderAlternatives = (
    options: Array<string>,
    feedback: string,
    chosenOption: string,
    setChosenOption: (v: string) => void,
    infoUser: any,
    itemActual: any,
): React.ReactElement => (
    <>
        {options.map((v: any) => {
            const TradeChosenOption = () => {
                if (v?.value === chosenOption) {
                    setChosenOption('');
                } else {
                    setChosenOption(v?.value);
                }
            };
            return (
                <AdjustAlternative>
                    <ButtonAlternative
                        onClick={TradeChosenOption}
                        select={chosenOption === v?.value}
                    >
                        {v?.value}
                    </ButtonAlternative>
                    {infoUser?.hit_questions?.includes(itemActual?.id)
                        || infoUser?.miss_questions?.includes(itemActual?.id) ? (
                            <PercentageAlternative>{v?.percentage}</PercentageAlternative>
                        ) : null}
                </AdjustAlternative>
            );
        })}
    </>
);

const ValidationTimeOutQuestion = (timeActual: number | Date): number => {
    const FullTime = new Date();
    const diff = intervalToDuration({ start: timeActual, end: FullTime });
    const {
        seconds,
        minutes,
        hours,
        days,
    } = diff;
    const validationMinutes = minutes ? minutes * 60 : 0;
    const validationHours = hours ? hours * 3600 : 0;
    const validationDays = days ? days * 86400 : 0;
    const result = validationDays + validationHours + validationMinutes + (seconds || 0);
    return result;
};

const handleTimerFinish = async (
    ClosedModal: any,
    idSelected: number,
    infoUser: any,
    idType: string,
): Promise<any> => {
    if (idSelected && idType === 'Simulados') {
        simulate.CheckOneSimulates(idSelected, infoUser.id, infoUser.token);
    }
    if (idSelected && idType === 'Provas') {
        tests.CheckOneTests(idSelected, infoUser.token);
    }
    ClosedModal();
};

const RenderTimer = (timeOutActual: number) => {
    const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: timeOutActual * 1000 });
    return `${hours}:${minutes}:${seconds}`;
};

export default {
    RenderLevel,
    RenderAlternatives,
    ValidationTimeOutQuestion,
    handleTimerFinish,
    RenderTimer,
};
