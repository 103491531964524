// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllAlarms(
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/alarms?idUser=${idUser}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateOneAlarm(
    title: string,
    subtitle: string,
    fromDates: Array<number>,
    hours: string,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            title,
            subtitle,
            fromDates,
            hours,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/alarms?idUser=${idUser}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function EditOneAlarm(
    title: string,
    subtitle: string,
    fromDates: Array<number>,
    hours: string,
    enabled: boolean,
    idAlarm: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            title,
            subtitle,
            fromDates,
            hours,
            enabled,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.put(`${baseUrl}/alarms?idUser=${idUser}&idAlarm=${idAlarm}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DeleteOneAlarm(
    idAlarm: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.delete(`${baseUrl}/alarms?idAlarm=${idAlarm}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllAlarms,
    CreateOneAlarm,
    EditOneAlarm,
    DeleteOneAlarm,
};
