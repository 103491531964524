// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerStatisticsInterfaces {
    existStatistics: boolean,
    marginLeft?: string,
    marginLeftLowLarge?: string,
}
interface InterfacesButtonPeriod {
    enabledFocus: boolean,
}
interface ButtonTabInterfaces {
    isFocus?: boolean,
}

interface CardInterfaces {
    isPair?: boolean,
    backgroundColor?: string,
}

interface ContainerImgStatisticsInterfaces {
    backgroundColor: string,
    width?: string,
    alignItems?: string,
    flexDirection?: string,
    marginTop?: string,
    borderRadius?: string,
    border?: string,
}

interface AdjustInfoInterfaces {
    justifyContent?: string,
    isFlexDirection?: boolean,
    alignItems?: string,
    width?: string,
    backgroundColor?: string,
    margin?: string,
    isQuestions?: boolean,
}

interface InfoCardInterfaces {
    marginTop?: string,
    padding?: string,
    marginLeft?: string,
}

interface SelectPeriodInterfaces {
    width?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
`;

export const TextLabelPath = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
`;

export const ContainerBody = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-top: 100px;
    width: 100%;
`;

export const ContainerOptionsDrawer = styled.div`
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px;
    width: auto;
`;

export const ContainerCalendar = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    min-height: 80vh;
    width: 300px;
    position: absolute;
    right: 15px;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        width: 200px;
    }
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const ContainerStatistics = styled.div<ContainerStatisticsInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.existStatistics ? 'center' : 'flex-start')};
    flex-direction: column;
    display: flex;
    width: 100%;
    margin-left: ${(props) => (props.marginLeft || '0px')};
    /* margin-right: 310px; */
    transition-duration: 400ms;
    overflow: hidden;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        /* margin-right: 215px; */
        margin-left: ${(props) => (props.marginLeftLowLarge || '0px')};
    }
    @media (max-width: 1200px) {
        /* margin-right: 5px; */
    }
    @media (max-width: 799px) {
        margin-left: 0px;
    }
`;

export const TitleCalendar = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    margin-bottom: 20px;
`;

export const ContainerFilters = styled.div`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 30px;
    padding: 20px;
    width: 97%;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

export const ContainerCards = styled.div<ContainerImgStatisticsInterfaces>`
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    border-radius: ${(props) => (props.borderRadius || '0px')};
    flex-wrap: wrap;
    margin-top: ${(props) => (props.marginTop || '0px')};
    justify-content: space-between;
    width: 97%;
    @media (max-width: 950px) {
        flex-wrap: nowrap;
        flex-direction: column;
    }
    @media (max-width: 600px) {
        width: 90%;
    }
`;

export const ContainerAdjustCard = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 950px) {
        flex-wrap: nowrap;
        flex-direction: column;
    }
`;

export const ContainerTabs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const ButtonTab = styled.div<ButtonTabInterfaces>`
    background-color: ${(props) => (props.isFocus ? colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics : colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    border-radius: 35px;
    border: ${(props) => (props.isFocus ? 'none' : `1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight}`)};
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    height: 70px;
    transition-duration: 400ms;
    padding: 15px;
    width: 24%;
    font-weight: ${(props) => (props.isFocus ? 'bold' : 'normal')};
    @media (max-width: 1600px) {
        height: 50px;
    }
    @media (max-width: 1200px) {
        border-radius: 30px;
        height: 50px;
    }
    @media (max-width: 600px) {
        height: 40px;
        padding: 10px;
        width: 100%;
        margin-bottom: 5px;
    }
    :hover {
        background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics};
        opacity: .6;

        > div:first-child {
            color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
        }
    }
`;

export const SelectPeriod = styled.div<SelectPeriodInterfaces>`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundSelectPeriod};
    border-radius: 35px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    justify-content: space-between;
    height: 70px;
    padding: 10px;
    min-width: ${(props) => (props.width || '24%')};
    @media (max-width: 1600px) {
        width: 60%;
        margin-right: 5px;
        height: 50px;
    }
    @media (max-width: 1200px) {
        border-radius: 30px;
        height: 50px;
        margin-right: 5px;
        width: 60%;
    }
    @media (max-width: 1000px) {
        justify-content: center;
        width: 60%;
    }
    @media (max-width: 600px) {
        height: 40px;
        width: 100%;
    }
    :hover {
        opacity: .7;
    }
`;

export const TitlePeriod = styled.div`
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').colorSelectPeriod};
    @media (max-width: 1600px) {
        font-size: 18px;
    }
    @media (max-width: 1350px) {
        font-size: 16px;
    }
    @media (max-width: 1270px) {
        font-size: 14px;
    }
    @media (max-width: 1000px) {
        font-size: 12px;
    }
    @media (max-width: 600px) {
        font-size: 12px;
    }
`;

export const TitleTab = styled.div<ButtonTabInterfaces>`
    color: ${(props) => (props.isFocus ? colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault : colors.basic(window.localStorage.getItem('theme') || 'light').grayLight)};
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    @media (max-width: 1600px) {
        font-size: 18px;
    }
    @media (max-width: 1200px) {
        font-size: 16px;
    }
    @media (max-width: 900px) {
        font-size: 14px;
    }
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

export const TitleSelect = styled.div`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').colorSelectPeriod};
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    width: 100%;
    @media (max-width: 1600px) {
        font-size: 18px;
    }
    @media (max-width: 1350px) {
        font-size: 16px;
    }
    @media (max-width: 1270px) {
        font-size: 14px;
    }
    @media (max-width: 600px) {
        font-size: 12px;
        /* flex-direction: column; */
    }
`;

export const IconDropDawn = styled.img`
    object-fit: contain;
    height: 10.5px;
    margin-left: 10px;
    width: 21px;
    @media (max-width: 1000px) {
        height: 7px;
        width: 14px;
    }
`;

export const IconDropDawnCategory = styled.img`
    object-fit: contain;
    height: 21px;
    width: 10.5px;
`;

export const Card = styled.div<CardInterfaces>`
    background-color: ${(props) => (props.backgroundColor || colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    padding: 10px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    border-radius: 30px;
    height: 200px;
    flex: 1;
    max-width: 49%;
    margin-left: ${(props) => (props.isPair ? '15px' : '0px')};
    @media (max-width: 1400px) {
        height: 180px;
    }
    @media (max-width: 1100px) {
        height: 160px;
    }
    @media (max-width: 950px) {
        margin-left: 0px;
        width: 100%;
        max-width: 100%;
    }
`;

export const ContainerImgStatistics = styled.div<ContainerImgStatisticsInterfaces>`
    background-color: ${(props) => (props.backgroundColor)};
    display: flex;
    align-items: ${(props) => (props.alignItems || 'center')};
    justify-content: center;
    flex-direction: ${(props) => (props.flexDirection || 'row')};
    border-radius: 20px;
    height: 160px;
    margin-left: 10px;
    transition-duration: 400ms;
    width: ${(props) => (props.width || '195px')};
    @media (max-width: 1400px) {
        height: 144px;
    }
    @media (max-width: 1100px) {
        height: 128px;
    }
`;

export const ImgStatistics = styled.img`
    object-fit: contain;
    height: 64px;
    width: 64px;
`;

export const InfoCard = styled.text<InfoCardInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    display: flex;
    margin-top: ${(props) => (props.marginTop || '5px')};
    flex-direction: column;
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    font-size: 20px;
    width: ${(props) => (props.width || 'auto')};
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;

export const InfoValueCard = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    font-weight: bold;
    font-size: 50px;
    @media (max-width: 1700px) {
        font-size: 45px;
    }
    @media (max-width: 1400px) {
        font-size: 35px;
    }
    @media (max-width: 1100px) {
        font-size: 30px;
    }
    @media (max-width: 1000px) {
        font-size: 25px;
    }
    @media (max-width: 950px) {
        font-size: 35px;
    }
`;

export const ContainerInfoSpecifics = styled.div<InfoCardInterfaces>`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    padding: ${(props) => (props.padding || '20px')};
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => (props.marginTop || '20px')};
    margin-left: ${(props) => (props.marginLeft || '0px')};
    border-radius: 30px;
    flex: 1;
    @media (max-width: 950px) {
        margin-left: 0px;
    }
`;

export const ContainerCategory = styled.button<AdjustInfoInterfaces>`
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    text-align: left;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    margin: ${(props) => (props.margin || '5px 0px 5px 0px')};
    width: ${(props) => (props.width || '100%')};
    :hover {
        background-color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen)};
    }
    
    @media (max-width: 600px) {
        padding: 10px 5px 10px 5px;
    }
    :disabled {
        opacity: ${(props) => (props.isQuestions ? '1' : '.5')};
        cursor: not-allowed;
    }
`;

export const ContainerCategorySolo = styled.div<AdjustInfoInterfaces>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 7px 10px 7px 10px;
    border-radius: 10px;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    margin: ${(props) => (props.margin || '5px 0px 5px 0px')};
    width: ${(props) => (props.width || '100%')};
    :hover {
        background-color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen)};
    }

    @media (max-width: 600px) {
        padding: 10px 5px 10px 5px;
    }
    @media (min-width: 600px) {
        &:last-child {
        margin-bottom: 40px;
    }
    }
    
`;

export const SingleContainerCategory = styled.button<AdjustInfoInterfaces>`
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 40px;
    padding: 10px;
    border-radius: 10px;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    margin: ${(props) => (props.margin || '5px 0px 5px 0px')};
    width: ${(props) => (props.width || '100%')};
    :hover {
        background-color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen)};
    }

    @media (max-width: 600px) {
        padding: 10px 0px 10px 0px;
    }
    :disabled {
        opacity: ${(props) => (props.isQuestions ? '1' : '.5')};
        cursor: not-allowed;
    }
`;

export const NameCategory = styled.text`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 20px;
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 16px;
    }
`;

export const ContainerQtd = styled.div`
    cursor: pointer;
    border-radius: 35px;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundQtdCategory};
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
    height: 50px;
    width: 100px;
    margin-top: 3px;
    margin-bottom: 3px;
    @media (max-width: 1700px) {
        height: 40px;
        width: 80px;
    }
    @media (max-width: 1400px) {
        height: 30px;
        width: 60px;
    }
`;

export const AdjustInfo = styled.div<AdjustInfoInterfaces>`
    align-items: ${(props) => (props.alignItems || 'center')};
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    width: ${(props) => (props.width || 'auto')};
    @media (max-width: 600px) {
        flex-direction: ${(props) => (props.isFlexDirection ? 'column' : 'row')};
    }
`;

export const ContainerSubCategory = styled.div<AdjustInfoInterfaces>`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px 40px 5px 40px;
    border-radius: 10px;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    margin: ${(props) => (props.margin || '5px 0px 5px 0px')};
    width: ${(props) => (props.width || '100%')};
    :hover {
        background-color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen)};
    }

    @media (max-width: 600px) {
        padding: 10px 40px 10px 40px;
    }

`;

export const AdjustGraph = styled.div<AdjustInfoInterfaces>`
    display: flex;
    justify-content: center;
    width: ${(props) => (props.width || 'auto')};
`;

export const CheckBoxRadius = styled.div<ButtonTabInterfaces>`
    border: ${(props) => (props.isFocus ? `1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics}` : `1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayDefault}`)};
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    cursor: pointer;
    border-radius: 12px;
    margin-right: 5px;
    @media (max-width: 1700px) {
        min-height: 22px;
        min-width: 22px;
        border-radius: 11px;
    }
    @media (max-width: 1400px) {
        min-height: 18px;
        min-width: 18px;
        border-radius: 9px;
    }
`;

export const Checked = styled.div<ButtonTabInterfaces>`
    background-color: ${(props) => (props.isFocus ? colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics : 'transparent')};
    height: 14px;
    width: 14px;
    border-radius: 7px;
    @media (max-width: 1700px) {
        height: 12px;
        width: 12px;
        border-radius: 6px;
    }
    @media (max-width: 1400px) {
        height: 10px;
        width: 10px;
        border-radius: 5px;
    }
`;

export const ContainerBar = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    height: 10px;
    border-radius: 35px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
`;

export const ProgressBar = styled.div<ContainerImgStatisticsInterfaces>`
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen)};
    height: 10px;
    border-radius: 35px;
    width: ${(props) => (props.width || '100%')};
`;

export const ContainerUser = styled.div<ContainerImgStatisticsInterfaces>`
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: ${(props) => (props.border || 'none')};
    align-items: center;
    height: 70px;
    padding: 0px 10px 0px 10px;
    border-radius: 35px;
    width: 100%;
`;

export const InfoUser = styled.div`
    align-items: center;
    display: flex;
    width: 45%;
`;

export const ContainerPosition = styled.div<ContainerImgStatisticsInterfaces>`
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTopTen)};
    min-height: 50px;
    min-width: 50px;
`;

export const DivNull = styled.div`
    height: 64px;
    width: 64px;
`;

export const ButtonPeriod = styled.div<InterfacesButtonPeriod>`
    background-color: ${(props) => (props.enabledFocus ? colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark : 'transparent')};
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 400px;
    border-radius: 35px;
    padding-left: 30px;
    margin-bottom: 10px;
    min-height: 40px;
    transition-duration: 400ms;
    @media (max-width: 1600px) {
        height: 50px;
        width: 250px;
        padding-left: 20px;
    }
    @media (max-width: 1200px) {
        width: 154px;
        border-radius: 30px;
        height: 50px;
    }
    :hover {
        background-color: rgba(117, 80, 241, 0.6);
    }
`;

export const ContainerPeriod = styled.div`
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px 10px 10px 35px;
    justify-content: space-between;
    margin-left: 10px;
    transition-duration: 400ms;
    min-width: 500px;
    overflow-y: auto;
    max-height: 50vh;
    margin-left: 5px;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
    @media (max-width: 1600px) {
        padding: 15px 0px 15px 0px;
        border-radius: 12px;
        min-width: 320px;
    }
    @media (max-width: 600px) {
        padding: 15px 0px 15px 0px;
        border-radius: 12px;
        margin-left: 5px;
        min-width: 300px;
    }
`;

export const TextPeriod = styled.div<InterfacesButtonPeriod>`
    color: ${(props) => (props.enabledFocus ? colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault : colors.basic(window.localStorage.getItem('theme') || 'light').grayLight)};
    color: ${(props) => (props.enabledFocus || window.localStorage.getItem('theme') === 'dark' ? colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight : colors.basic(window.localStorage.getItem('theme') || 'light').grayDefault)};
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
    @media (max-width: 1600px) {
        font-size: 18px;
    }
    @media (max-width: 1200px) {
        font-size: 16px;
    }
`;

export const InputCustom = styled.input`
    border-radius: 20px;
    width: 200px;
    padding-left: 10px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    min-height: 30px;
    margin-top: 10px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    font-weight: bold;
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight};
    }
`;
