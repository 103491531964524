// libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

// js
import {
    Container,
    Home,
    ContainerOptionsDrawer,
    ContainerBody,
    ContainerStatistics,
    // ContainerCalendar,
    // TitleCalendar,
    ContainerFilters,
    ContainerTabs,
    ButtonTab,
    TitleTab,
    ContainerCards,
    AdjustInfo,
} from './statisticsStyles';
import StatisticsComponents from './StatisticsComponents';
import colors from '../../styles/colors';
import { RootState } from '../../store';
import auth from '../../services/auth';
import constants from '../../utils/consts';

// components
import DrawerComponent from '../../components/Drawer/DrawerComponent';
import HeaderComponent from '../../components/Header/HeaderComponent';
import statisticsConstants from './statisticsConstants';
// import OneDateSimulateComponent from '../Timeline/components/OneDateSimulateComponent';
import PickerComponent from './components/Picker/PickerComponent';
import ModalPickerComponent from './components/ModalPickerComponent';
import statisticsFunctions from './statisticsFunctions';
import PopUpComponent from '../../components/PopUp/PopUpComponent';

// main
const StatisticsPage: React.FC = () => {
    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // useState
    // const [search, setSearch] = useState('');
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const [openModalPeriod, setOpenModalPeriod] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [show, setShow] = useState(true);
    const [idFilter, setIdFilter] = useState(1);
    const [typeGraphSpecifics, setTypeGraphSpecifics] = useState<'numberResolution' | 'performanceRelative'>('numberResolution');
    const [resizeAxis, setResizeAxis] = useState(statisticsConstants.stylesAxis);
    const [allStatisticsGeneral, setAllStatisticsGeneral] = useState(null);
    const [allStatisticsSpecifics, setAllStatisticsSpecifics] = useState<any>(null);
    const [allStatisticsStrongAndWeak, setAllStatisticsStrongAndWeak] = useState(null);
    const [allStatisticsRanking, setAllStatisticsRanking] = useState([]);
    const [typeOrderId, setTypeOrderId] = useState(7);
    const [idBook, setIdBook] = useState(0);
    const [allData, setAllData] = useState<Array<any>>([]);
    const [allFilters, setAllFilters] = useState<Array<any>>([]);
    const [allCategories, setAllCategories] = useState<Array<any>>([]);
    const [allSubjectsSelect, setAllSubjectsSelect] = useState<Array<any>>([]);
    const [selectFilter, setSelectFilter] = useState<Array<number>>([]);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [nameBook, setNameBook] = useState('');
    const [allBooks, setAllBooks] = useState([]);
    const [allExamination, setAllExamination] = useState([]);
    const [allEngineering, setAllEngineering] = useState([]);
    const [examination, setExamination] = useState({ id: 0, name: 'Selecione um concurso' });
    const [engineering, setEngineering] = useState({ id: 0, name: 'Selecione uma Formação' });
    const [typeBankOrEngineering, setTypeBankOrEngineering] = useState('');
    const [selectDropdawn, setSelectDropdawn] = useState<Array<number>>([]);

    // methods
    const onDrawer = () => {
        setIsOpen(true);
    };

    function ValidationMarginTimeline() {
        if (show) {
            return '310px';
        }
        if (!show) {
            return '160px';
        }
    }

    function ValidationMarginTimelineLowLarge() {
        if (show) {
            return '215px';
        }
        if (!show) {
            return '105px';
        }
    }

    const ApplyFilter = async () => {
        setPopUp(false);
        await statisticsFunctions.GetSpecifics(
            allData,
            typeOrderId,
            setAllStatisticsSpecifics,
            selectFilter,
            allSubjectsSelect,
            infoUser,
        );
    };

    const GetInfoScreen = async () => {
        statisticsFunctions.GetAllBooks(setAllBooks, infoUser);
        await statisticsFunctions.GetGeneral(
            setAllStatisticsGeneral,
            infoUser,
            allData,
            typeOrderId,
            start,
            end,
        );
        await ApplyFilter();
        await statisticsFunctions.GetRanking(
            allData,
            typeOrderId,
            setAllStatisticsRanking,
            examination?.id,
            engineering.id,
            infoUser,
        );
        await statisticsFunctions.GetAllCategories(setAllCategories, infoUser);
        const dataExamination = await auth.GetExamination();
        const dataEngineering = await auth.GetEngineering();
        setAllExamination(dataExamination?.examination);
        setAllEngineering(dataEngineering?.engineering);
    };

    const ActionResizeAxisGraph = () => {
        statisticsFunctions.ResizeAxisGraph(
            resizeAxis,
            setResizeAxis,
        );
    };

    // useEffect
    useEffect(() => {
        ActionResizeAxisGraph();
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
            ActionResizeAxisGraph();
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        if (idFilter === 1) {
            statisticsFunctions.GetGeneral(
                setAllStatisticsGeneral,
                infoUser,
                allData,
                typeOrderId,
                start,
                end,
            );
        }
        if (idFilter === 2 && allData?.length === 6) {
            statisticsFunctions.GetSpecifics(
                allData,
                typeOrderId,
                setAllStatisticsSpecifics,
                selectFilter,
                allSubjectsSelect,
                infoUser,
            );
        }
        if (idFilter === 4) {
            statisticsFunctions.GetRanking(
                allData,
                typeOrderId,
                setAllStatisticsRanking,
                examination?.id,
                engineering.id,
                infoUser,
            );
        }
    }, [typeOrderId, start, end]);

    useEffect(() => {
        if (idBook) {
            statisticsFunctions.GetStrongAndWeak(
                allData,
                idBook,
                typeOrderId,
                start,
                end,
                setAllStatisticsStrongAndWeak,
                infoUser,
            );
        }
    }, [idBook, typeOrderId, start, end]);

    useEffect(() => {
        if (examination.id !== 0 || engineering.id !== 0) {
            statisticsFunctions.GetRanking(
                allData,
                typeOrderId,
                setAllStatisticsRanking,
                examination?.id,
                engineering.id,
                infoUser,
            );
        }
    }, [examination, engineering]);

    useEffect(() => {
        GetInfoScreen();
    }, []);

    useEffect(() => {
        if (allData?.length) {
            setOpenModalPeriod(true);
        }
    }, [allData]);

    // renders
    // const RenderAllDates = () => (
    //     constants.allDates.map((v) => (
    //         <OneDateSimulateComponent item={v} />
    //     ))
    // );

    const allTabs = () => (
        statisticsConstants.allTabs.map((v) => {
            const ActionFilter = () => {
                setIdFilter(v.id);
                setIdBook(0);
                if ([1, 3, 4].includes(v.id)) {
                    setTypeOrderId(7);
                } else {
                    setTypeOrderId(5);
                }
            };
            return (
                <ButtonTab isFocus={idFilter === v.id} onClick={ActionFilter}>
                    <TitleTab isFocus={idFilter === v.id}>{v.title}</TitleTab>
                </ButtonTab>
            );
        })
    );

    const allStatistics = () => {
        if (idFilter === 1) {
            return (
                <ContainerCards backgroundColor="">
                    <StatisticsComponents.AllCards
                        allStatisticsGeneral={allStatisticsGeneral}
                    />
                </ContainerCards>
            );
        }
        if (idFilter === 2) {
            return (
                <ContainerCards backgroundColor="">
                    <StatisticsComponents.AllCardsSpecifics
                        widthActual={widthActual}
                        allCategories={allCategories}
                        typeGraphSpecifics={typeGraphSpecifics}
                        setTypeGraphSpecifics={setTypeGraphSpecifics}
                        resizeAxis={resizeAxis}
                        allStatisticsSpecifics={allStatisticsSpecifics}
                        setPopUp={setPopUp}
                        setAllFilters={setAllFilters}
                        infoUser={infoUser}
                        selectFilter={selectFilter}
                    />
                </ContainerCards>
            );
        }
        if (idFilter === 3 && allStatisticsStrongAndWeak) {
            return (
                <ContainerCards backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').white} marginTop="20px" borderRadius="30px">
                    <StatisticsComponents.AllCardsStrongAndWeak
                        allStatisticsStrongAndWeak={allStatisticsStrongAndWeak}
                    />
                </ContainerCards>
            );
        }
        if (idFilter === 4) {
            return (
                <ContainerCards backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').white} marginTop="20px" borderRadius="30px">
                    <StatisticsComponents.AllCardsRanking
                        infoUser={infoUser}
                        widthActual={widthActual}
                        allStatisticsRanking={allStatisticsRanking}
                    />
                </ContainerCards>
            );
        }

        return null;
    };

    const RenderPickerComponent = () => {
        if (idFilter === 3) {
            return <PickerComponent setAllData={setAllData} label="Cadernos:" value={nameBook || 'Selecione um Caderno'} idFilter={idFilter} setPopUp={setPopUp} />;
        }
        if (idFilter === 4) {
            return (
                <>
                    <PickerComponent setPopUp={setPopUp} setAllData={setAllData} label="Bancas" value={examination.name} idFilter={1} setTypeBankOrEngineering={setTypeBankOrEngineering} />
                    <PickerComponent setPopUp={setPopUp} setAllData={setAllData} label="Formações" value={engineering.name} idFilter={1} setTypeBankOrEngineering={setTypeBankOrEngineering} />
                </>
            );
        }

        return null;
    };

    const RenderTitle = () => {
        if (idFilter === 3) {
            return 'Cadernos';
        }
        if (idFilter === 4) {
            return typeBankOrEngineering;
        }

        return 'Filtros';
    };

    const RenderData = () => {
        if (idFilter === 3) {
            return allBooks;
        }
        if (idFilter === 4 && typeBankOrEngineering === 'Bancas') {
            return allExamination;
        }
        if (idFilter === 4 && typeBankOrEngineering === 'Formações') {
            return allEngineering;
        }

        return allFilters;
    };

    const CloseModal = () => {
        setIsOpen(false);
    };

    return (
        <Container>
            <Home>
                <HeaderComponent
                    // search={search}
                    // setSearch={setSearch}
                    // labelSearch="Concursos"
                    widthActual={widthActual}
                    onDrawer={onDrawer}
                    idScreen="statistics"
                />
                <ContainerBody>
                    {widthActual >= 800 ? (
                        <DrawerComponent
                            show={show}
                            setShow={setShow}
                        />
                    ) : null}
                    <ContainerStatistics
                        existStatistics
                        marginLeft={ValidationMarginTimeline()}
                        marginLeftLowLarge={ValidationMarginTimelineLowLarge()}
                    >
                        <ContainerFilters>
                            <ContainerTabs>{allTabs()}</ContainerTabs>
                            <AdjustInfo justifyContent="space-between" isFlexDirection>
                                <PickerComponent label="Período:" value="" idFilter={idFilter} typeOrderId={typeOrderId} setOpenModalPeriod={setOpenModalPeriod} setAllData={setAllData} />
                                {RenderPickerComponent()}
                            </AdjustInfo>
                        </ContainerFilters>
                        {allStatistics()}
                    </ContainerStatistics>
                    {/* <ContainerCalendar>
                        <TitleCalendar>Lista de Simulados</TitleCalendar>
                        {RenderAllDates()}
                    </ContainerCalendar> */}
                </ContainerBody>
                <Modal
                    isOpen={openModalPeriod}
                    style={statisticsConstants.modalPickerStyles}
                    onRequestClose={() => setOpenModalPeriod(false)}
                >
                    <ModalPickerComponent
                        allData={allData}
                        typeOrderId={typeOrderId}
                        setTypeOrderId={setTypeOrderId}
                        setOpenModalPeriod={setOpenModalPeriod}
                        start={start}
                        end={end}
                        setStart={setStart}
                        setEnd={setEnd}
                    />
                </Modal>
                <Modal
                    isOpen={isOpen}
                    style={constants.modalDrawerStyles}
                    onRequestClose={CloseModal}
                >
                    <ContainerOptionsDrawer>
                        <DrawerComponent
                            isModal
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            show
                        />
                    </ContainerOptionsDrawer>
                </Modal>
                <PopUpComponent
                    popUp={popUp}
                    setPopUp={setPopUp}
                    title={RenderTitle()}
                    allData={RenderData()}
                    selectDropdawn={selectDropdawn}
                    setSelectDropdawn={setSelectDropdawn}
                    selectFilter={selectFilter}
                    setSelectFilter={setSelectFilter}
                    allSubjectsSelect={allSubjectsSelect}
                    setAllSubjectsSelect={setAllSubjectsSelect}
                    ApplyFilter={ApplyFilter}
                    idScreen="statistics"
                    allBooks={allBooks}
                    idBook={idBook}
                    setIdBook={setIdBook}
                    setNameBook={setNameBook}
                    setExamination={setExamination}
                    setEngineering={setEngineering}
                    allExamination={allExamination}
                    allEngineering={allEngineering}
                    examination={examination}
                    engineering={engineering}
                    setAllBooks={setAllBooks}
                    setAllExamination={setAllExamination}
                    setAllEngineering={setAllEngineering}
                    setAllFilters={setAllFilters}
                />
            </Home>
        </Container>
    );
};

export default StatisticsPage;
