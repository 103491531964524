// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllQuestions(
    idFilters: Array<any>,
    idSubjects: Array<any>,
    typeQuestion: Array<string>,
    idUser: string,
    token: string,
    idBook: string,
    idDailyChallenges?: number,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        let basePathUrl = `${baseUrl}/questions?idUser=${idUser}`;
        if (idFilters?.length) {
            basePathUrl += `&idFilters=${idFilters}`;
        }
        if (idSubjects?.length) {
            basePathUrl += `&idSubjects=${idSubjects}`;
        }
        if (idBook) {
            basePathUrl += `&idBook=${idBook}`;
        }
        if (typeQuestion?.length > 0) {
            basePathUrl += `&typeQuestion=${typeQuestion}`;
        }
        if (idDailyChallenges) {
            basePathUrl += `&idDailyChallenges=${idDailyChallenges}`;
        }
        const response = await axios.get(basePathUrl, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CheckedQuestion(
    hitQuestions: Array<number>,
    missQuestions: Array<number>,
    averageTime: number,
    idUser: number,
    token: string,
    idDailyChallenges?: number,
    idBook?: number,
): Promise<any> {
    try {
        const body = {
            hitQuestions,
            missQuestions,
            averageTime,
            idBook,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        let basePathUrl = `${baseUrl}/questions/finish?idUser=${idUser}`;
        if (idDailyChallenges) {
            basePathUrl += `&idDailyChallenges=${idDailyChallenges}`;
        }
        const response = await axios.post(basePathUrl, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function EditQuestionsCompleted(
    completedQuestions: Array<number>,
    idBook: number,
    suggested: boolean,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            completedQuestions,
            idBook,
            idUser,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const validationPath = suggested ? `${baseUrl}/books?suggested=${suggested}` : `${baseUrl}/books`;
        const response = await axios.put(validationPath, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetOnlyArchiveQuestion(
    idQuestion: string,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/questions/uniqueQuestion?idQuestion=${idQuestion}&color=${(window.localStorage.getItem('theme') || 'light') === 'dark' ? 'ffffff' : '000000'}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllQuestions,
    CheckedQuestion,
    EditQuestionsCompleted,
    GetOnlyArchiveQuestion,
};
