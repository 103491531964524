// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerInfoInterfaces {
    fileExist: boolean,
}
interface ContainerOptionsInterfaces {
    existOptions: boolean,
    marginLeft?: string,
    marginLeftLowLarge?: string,
}
interface DescriptionInterfaces {
    qtdLine: number | null,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
`;

export const ContainerBody = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-top: 100px;
    width: 100%;
`;

export const ContainerOptions = styled.div<ContainerOptionsInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.existOptions ? 'center' : 'flex-start')};
    flex-direction: column;
    display: flex;
    width: 100%;
    margin-left: ${(props) => (props.marginLeft || '0px')};
    /* margin-right: 310px; */
    transition-duration: 400ms;
    overflow: hidden;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        /* margin-right: 215px; */
        margin-left: ${(props) => (props.marginLeftLowLarge || '0px')};
    }
    @media (max-width: 1200px) {
        /* margin-right: 5px; */
    }
    @media (max-width: 799px) {
        margin-left: 0px;
    }
`;

export const Cover = styled.img`
    border-radius: 10px;
    margin-right: 15px;
    height: 100%;
    width: 300px;
    @media (max-width: 1350px) {
        width: 250px;
    }
    @media (max-width: 1200px) {
        width: 200px;
    }
    @media (max-width: 600px) {
        height: 100px;
        margin-bottom: 10px;
        margin-right: 0px;
        width: 80%;
    }
`;

export const ContainerBox = styled.div`
    align-items: center;
    justify-content: center;
    padding: 10px;
    display: flex;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    margin-top: 10px;
    border-radius: 30px;
    min-height: 150px;
    max-height: 150px;
    width: 97%;
    @media (max-width: 600px) {
        flex-direction: column;
        min-height: 350px;
        width: 70%;
    }
    @media (max-width: 480px) {
        flex-direction: column;
        min-height: 350px;
        width: 80%;
    }
`;

export const ContainerInfoOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ContainerInfo = styled.div<ContainerInfoInterfaces>`
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${(props) => (props.fileExist ? '70%' : '90%')};
    @media (max-width: 800px) {
        width: 90%;
        min-height: 136px;
    }
    @media (max-width: 600px) {
        width: 80%;
        flex-direction: column;
        min-height: 136px;
    }
`;

export const Title = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    font-size: 30px;
    @media (max-width: 2000px) {
        font-size: 28px;
    }
    @media (max-width: 1600px) {
        font-size: 26px;
    }
    @media (max-width: 1350px) {
        font-size: 24px;
    }
`;

export const Description = styled.text<DescriptionInterfaces>`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    font-size: 16px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.qtdLine)};
    -webkit-box-orient: vertical;
    @media (max-width: 2000px) {
        font-size: 14.4px;
    }
    @media (max-width: 1600px) {
        font-size: 12.8px;
    }
    @media (max-width: 1350px) {
        font-size: 11.2px;
    }
`;

export const Hours = styled.text`
    color: inherit;
    font-weight: bold;
    font-size: 16px;
    @media (max-width: 2000px) {
        font-size: 14.4px;
    }
    @media (max-width: 1600px) {
        font-size: 12.8px;
    }
    @media (max-width: 1350px) {
        font-size: 11.2px;
    }


`;

export const ButtonMore = styled.button`
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 35px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    height: 50px;
    width: 217px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    transition: background-color 0.3s, color 0.3s;
    margin-top: 8px;

    &:hover {
        background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayMedium};
        color: black; 
    }
`;

export const ContainerCalendar = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    min-height: 80vh;
    width: 300px;
    position: absolute;
    right: 15px;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        width: 200px;
    }
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const ContainerSimulate = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
`;

export const TitleSimulate = styled.text`
    color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark)};
    font-weight: 600;
    font-size: 14px;
`;

export const DescriptionSimulate = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-size: 11px;
`;

export const TitleCalendar = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    margin-bottom: 20px;
`;

export const ContainerOptionsDrawer = styled.div`
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px;
    width: auto;
`;
