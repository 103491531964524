// libraries
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';

// js
import colors from '../../styles/colors';

// constants
const fadeInAnimation = keyframes`${fadeIn}`;

// interface
interface InterfacesButtonDrawer {
    enabledFocus: boolean,
    show?: boolean,
}

interface ContainerDrawerInterfaces {
    show?: boolean,
}

// styles
export const ContainerDrawer = styled.div<ContainerDrawerInterfaces>`
    padding: 20px 0px 20px 0px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    flex-direction: column;
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    margin-left: 6px;
    transition-duration: 400ms;
    height: 65vh;
    @media (min-width: 1600px) {
        width: ${(props) => (props.show ? '260px' : '100px')};
    }
    @media (max-width: 1600px) {
        width: ${(props) => (props.show ? '200px' : '100px')};
    }
    @media (max-width: 1200px) {
        padding: 15px 0px 15px 0px;
        border-radius: 12px;
        margin-left: 5px;
        height: 50vh;
        width: ${(props) => (props.show ? '210px' : '105px')};
        height: 80vh;
    }
    @media (max-width: 800px) {
        height: 80vh;
        width: ${(props) => (props.show ? '215px' : '105px')};
    }

    @media (max-width: 799px) {
        width: 60vw;
        height: 60vh;
    }

    @media (max-width: 600px) {
        margin-bottom: 20px;
        width: 60vw;
        height: 54vh;
        padding-bottom: 20px;
    }
`;

export const ContainerTabs = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 600px) {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

`;

export const ContainerAdjustDrawer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
`;

export const ArrowDrawer = styled.img<ContainerDrawerInterfaces>`
    object-fit: contain;
    cursor: pointer;
    height: 20px;
    width: 10.5px;
`;

export const ButtonDrawer = styled.div<InterfacesButtonDrawer>`
    background-color: ${(props) => (props.enabledFocus ? colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark : 'transparent')};
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 2px;
    width: ${(props) => (props.show ? '220px' : '70px')};
    border-radius: 35px;
    padding-left: ${(props) => (props.show ? '30px' : '0px')};
    justify-content: ${(props) => (props.show ? 'flex-start' : 'center')};
    height: 70px;
    transition-duration: 400ms;
    animation: 1s ${fadeInAnimation};
    margin-top: auto;
    
    :hover {
        background-color: ${(props) => (props.enabledFocus ? colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark : 'rgba(117, 80, 241, 0.3)')};
        color: ${(props) => (colors.theme(window.localStorage.getItem('theme') || 'light').whiteReverse)};
    }

    @media (max-width: 1600px) {
        height: 50px;
        width: ${(props) => (props.show ? '160px' : '50px')};
        padding-left: ${(props) => (props.show ? '20px' : '0px')};
    }
    @media (max-width: 1200px) {
        width: ${(props) => (props.show ? '154px' : '50px')};
        border-radius: 30px;
        height: 50px;
    }
    @media (max-width: 800px) {
        width: ${(props) => (props.show ? '200px' : '50px')};
        border-radius: 30px;
        height: 50px;
        padding-left: 40px;
    }
`;

export const IconDrawer = styled.img<ContainerDrawerInterfaces>`
    object-fit: contain;
    height: 24px;
    width: 24px;
    margin-right: ${(props) => (props.show ? '5px' : '0px')};
    @media (max-width: 1600px) {
        height: 18px;
        width: 18px;
    }
    @media (max-width: 1200px) {
        height: 12px;
        width: 12px;
    }
`;

export const TextDrawer = styled.div<InterfacesButtonDrawer>`
    color: ${(props) => (props.enabledFocus ? colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault : colors.basic(window.localStorage.getItem('theme') || 'light').grayLight)};
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
    @media (max-width: 1600px) {
        font-size: 18px;
    }
    @media (max-width: 1200px) {
        font-size: 16px;
    }
`;

export const TextExit = styled.div<InterfacesButtonDrawer>`
    color: ${(props) => (props.enabledFocus ? colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault : colors.basic(window.localStorage.getItem('theme') || 'light').grayLight)};
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
    @media (min-width: 800px) {
        font-size: 18px;
        margin-left: 5px;
    }
`;

export const TextClosedModal = styled.text`
    align-self: flex-end;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0px;
    margin-right: 20px;
    cursor: pointer;
`;
