// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

// api
async function AllTimeLine(
    period: string,
    idExamination: Array<number>,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/notifications?period=${period}&idExamination=${idExamination}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function EditTimeLine(
    typeAvaliation: string,
    idNotification: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const dataBody = {
            typeAvaliation,
        };
        const body = JSON.stringify(dataBody);
        const headers = {
            'x-access-token': token,
            'Content-Type': 'application/json',
        };
        const response = await axios.put(`${baseUrl}/notifications?idNotification=${idNotification}&idUser=${idUser}`, body, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    AllTimeLine,
    EditTimeLine,
};
