// js
import colors from '../../styles/colors';

// constants
const stylesCircularProgress = {
    trail: { strokeWidth: 2, stroke: colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight },
    path: { stroke: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics },
    text: { fill: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics, fontSize: '20px' },
};

export default {
    stylesCircularProgress,
};
