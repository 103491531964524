// libraries
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

// js
import {
    ContainerExamination,
    NameExamination,
    // AdjustInfo,
    ContainerOneExamination,
    // IconDropDawnExamination,
} from '../filtersStyles';
// import {
//     optionBook,
// } from '../../../assets';
import colors from '../../../styles/colors';

// interfaces
interface Props {
    item: any,
    index: number,
    setSelectFilterSaved: any,
    setIdPopUp: (v: number) => void,
    setSelectFilter: (v: Array<number>) => void,
    setAllSubjectsSelect: (v: Array<number>) => void,
    setNameCreateFilter: (v: string) => void,
}

const FiltersComponent: React.FC<Props> = ({
    item,
    index,
    setSelectFilterSaved,
    setIdPopUp,
    setSelectFilter,
    setAllSubjectsSelect,
    setNameCreateFilter,
}) => {
    // constants
    const {
        id,
        title,
    } = item;

    // methods
    const openOptionsFilterSaved = () => {
        setSelectFilterSaved(item);
        setSelectFilter(item?.id_filters);
        setAllSubjectsSelect(item?.id_subjects);
        setNameCreateFilter(item?.title);
        setIdPopUp(4);
    };

    // main
    return (
        <ContainerOneExamination key={id}>
            <ContainerExamination onClick={openOptionsFilterSaved} padding="10px">
                <NameExamination color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>
                    {`${index + 1}. ${title}`}
                </NameExamination>
                {/* <AdjustInfo>
                    <IconDropDawnExamination src={optionBook} />
                </AdjustInfo> */}
            </ContainerExamination>
        </ContainerOneExamination>
    );
};

export default FiltersComponent;
