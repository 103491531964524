// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllSimulate(
    step: '1' | '2',
    idEngineering: number,
    idExamination: number,
    titleForSearching: string,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/simulates?searchTxt=${titleForSearching || ''}&idEngineering=${idEngineering || ''}&idExamination=${idExamination || ''}&step=${step}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CheckOneSimulates(
    idSimulator: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.put(`${baseUrl}/simulates?idUser=${idUser}&idSimulate=${idSimulator}`, null, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GenerateSimulates(
    typeQuestions: Array<string>,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    queries: any,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        let allData: any = { queries, idUser };
        if (typeQuestions) {
            allData = {
                ...allData,
                typeQuestions,
            };
        }
        const body = JSON.stringify(allData);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/simulates/generate`, body, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetAllSolicitation(
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/simulatesSolicit?idUser=${idUser}&page=1&limit=200`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllSimulate,
    CheckOneSimulates,
    GenerateSimulates,
    GetAllSolicitation,
};
