// js
import {
    iconDrawerGrayBooks,
    iconDrawerGrayFilters,
    iconDrawerGrayLogout,
    iconDrawerGrayQuestions,
    iconDrawerGrayStatistics,
    iconDrawerGrayTimeline,
    iconDrawerWhiteBooks,
    iconDrawerWhiteFilters,
    iconDrawerWhiteQuestions,
    iconDrawerWhiteStatistics,
    iconDrawerWhiteTimeline,
} from '../../assets';

// functions
const ValidationPathIcon = (path: string, enabledFocus: boolean): string => {
    if (path === '/timeline' && enabledFocus) {
        return iconDrawerWhiteTimeline;
    }
    if (path === '/timeline' && !enabledFocus) {
        return iconDrawerGrayTimeline;
    }
    if (path === '/statistics' && enabledFocus) {
        return iconDrawerWhiteStatistics;
    }
    if (path === '/statistics' && !enabledFocus) {
        return iconDrawerGrayStatistics;
    }
    if (path === '/questions' && enabledFocus) {
        return iconDrawerWhiteQuestions;
    }
    if (path === '/questions' && !enabledFocus) {
        return iconDrawerGrayQuestions;
    }
    if (path === '/filters' && enabledFocus) {
        return iconDrawerWhiteFilters;
    }
    if (path === '/filters' && !enabledFocus) {
        return iconDrawerGrayFilters;
    }
    if (path === '/books' && enabledFocus) {
        return iconDrawerWhiteBooks;
    }
    if (path === '/books' && !enabledFocus) {
        return iconDrawerGrayBooks;
    }
    return iconDrawerGrayLogout;
};

export default {
    ValidationPathIcon,
};
