// functions
function OnlyNumbersMask(initialValue: string): string {
    return (initialValue || '').replace(/\D/g, '');
}

const MaskPhone = (v: string): string => {
    let valueInitial = v?.replace(/\D/g, '').replace(/^0/, '') || '';
    if (valueInitial?.length > 11) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (valueInitial?.length > 7) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (valueInitial?.length > 2) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (v?.trim() !== '') {
        valueInitial = valueInitial.replace(/^(\d*)/, '($1');
    }
    return valueInitial;
};

const MaskIdentifier = (v: string): string => {
    const cpfFormatted = v?.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1-$2');
    return cpfFormatted;
};

const dateMask = (input: string, v: string): string => {
    const allFormat = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/', ''];
    let value = v;
    if (v?.length >= 0 && v?.length < 5) {
        if (input?.length === 3 && v?.length === 2) {
            value = v;
        } else if (v?.length === 2) {
            value = `${v}/`;
        } else if (v?.length === 3 && input?.length === 2) {
            value = `${input}/${v.slice(-1)}`;
        }
    } else if (v?.length >= 5) {
        if (input?.length === 6 && v?.length === 5) {
            value = v;
        } else if (v?.length === 5) {
            value = `${v}/`;
        } else if (v?.length === 6 && input?.length === 5) {
            value = `${input}/${v.slice(-1)}`;
        }
    }

    return allFormat.includes(v.slice(-1)) ? value.substring(0, 10) : input;
};

function Asc(String: string) {
    return String.charCodeAt(0);
}

function Chr(AsciiNum: any) {
    return String.fromCharCode(AsciiNum);
}

function Encrypt(dados: string): string {
    let mensx = '';
    let l;
    let i;
    let j = 0;
    let ch = '';
    ch = 'assbdFbdpdPdpfPdAAdpeoseslsQQEcDDldiVVkadiedkdkLLnm';
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < dados?.length; i++) {
        // eslint-disable-next-line no-plusplus
        j++;
        l = (Asc(dados.substr(i, 1)) + (Asc(ch.substr(j, 1))));
        if (j === 50) {
            j = 1;
        }
        if (l > 255) {
            l -= 256;
        }
        mensx += (Chr(l));
    }
    return mensx;
}

const Decrypt = (dados: string): string => {
    let mensx = '';
    let l;
    let i;
    let j = 0;
    let ch = '';
    ch = 'assbdFbdpdPdpfPdAAdpeoseslsQQEcDDldiVVkadiedkdkLLnm';
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < dados?.length; i++) {
        // eslint-disable-next-line no-plusplus
        j++;
        l = (Asc(dados.substr(i, 1)) - (Asc(ch.substr(j, 1))));
        if (j === 50) {
            j = 1;
        }
        if (l < 0) {
            l += 100;
        }
        mensx += (Chr(l));
    }
    return mensx;
};

const toPrice = (price: number, scale?: number | 2): string => (
    String(((price * 100) / 100).toFixed(scale)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
);

async function getDownloadRemoteFile(file: string): Promise<null> {
    // get file in Uint8Array
    const response = await fetch(file);
    const reader = response.body?.getReader();
    const result = await reader?.read();

    // Uint8Array ==> String
    const decoder = new TextDecoder('utf-8');
    const csv = decoder.decode(result?.value);

    // download file
    const fileBlob = new Blob([csv], { type: 'application/pdf' });
    const csvURL = window.URL.createObjectURL(fileBlob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.download = file;
    tempLink.click();

    return null;
}

export default {
    OnlyNumbersMask,
    MaskPhone,
    MaskIdentifier,
    dateMask,
    Encrypt,
    Decrypt,
    toPrice,
    getDownloadRemoteFile,
};
