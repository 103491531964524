// js
import {
    orderDate,
    orderProgress,
    orderName,
    // optionDownload,
    optionRename,
    optionEdit,
    optionDelete,
} from '../../assets';
import colors from '../../styles/colors';

// constants
const allOrder = [
    {
        id: 1,
        type: 'title',
        title: 'Ordem Alfabética',
        img: orderName,
    },
    {
        id: 2,
        type: 'created_at',
        title: 'Data Adicionado',
        img: orderDate,
    },
    {
        id: 3,
        type: 'progress',
        title: '% Concluído',
        img: orderProgress,
    },
];

const TypeQuestion = {
    name: 'Tipo da Questão',
    id: 'typeQuestion',
    allTypeQuestions: [
        {
            idGlobal: 'typeQuestion',
            id: 'discursive',
            name: 'Discursiva',
        },
        {
            idGlobal: 'typeQuestion',
            id: 'multiple_choices',
            name: 'Múltipla Escolha',
        },
        {
            idGlobal: 'typeQuestion',
            id: 'right_or_wrong',
            name: 'Certo ou Errado',
        },
    ],
};

const allOptions = [
    {
        id: 1,
        title: 'Abrir',
        type: '',
        img: orderName,
    },
    // {
    //     id: 2,
    //     title: 'Download',
    //     type: '',
    //     img: optionDownload,
    // },
    {
        id: 3,
        title: 'Renomear',
        type: '',
        img: optionRename,
    },
    {
        id: 4,
        title: 'Editar',
        type: '',
        img: optionEdit,
    },
    {
        id: 5,
        title: 'Deletar',
        type: '',
        img: optionDelete,
    },
];

const stylesAccordionItemButton = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.basic(window.localStorage.getItem('theme') || 'light').white,
    height: 50,
};

const stylesAccordionItem = (open: boolean): React.CSSProperties => ({
    color: colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark,
    backgroundColor: colors.basic(window.localStorage.getItem('theme') || 'light').white,
    cursor: 'pointer',
    height: open ? 'auto' : 50,
    marginBottom: 20,
});

export default {
    allOrder,
    allOptions,
    TypeQuestion,
    stylesAccordionItemButton,
    stylesAccordionItem,
};
