// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerInfoInterfaces {
    fileExist: boolean,
}

interface ContainerTimelineInterfaces {
    existTimeline: boolean,
    marginLeft?: string,
    marginLeftLowLarge?: string,
}

interface DescriptionInterfaces {
    qtdLine: number | null,
}

interface BannerInterfaces {
    existTimeline: boolean,
}

interface ContainerBoxInterfaces {
    moreShow?: boolean,
}

interface AdjustInfoInterfaces {
    justifyContent?: string,
    isFlexDirection?: boolean,
    alignItems?: string,
    width?: string,
    backgroundColor?: string,
    margin?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
`;

export const ContainerBody = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-top: 100px;
    width: 100%;
`;

export const ContainerTimeline = styled.div<ContainerTimelineInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.existTimeline ? 'center' : 'flex-start')};
    flex-direction: column;
    display: flex;
    width: 100%;
    margin-left: ${(props) => (props.marginLeft || '0px')};
    /* margin-right: 310px; */
    transition-duration: 400ms;
    overflow: hidden;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        /* margin-right: 215px; */
        margin-left: ${(props) => (props.marginLeftLowLarge || '0px')};
    }
    @media (max-width: 1200px) {
        /* margin-right: 5px; */
    }
    @media (max-width: 799px) {
        margin-left: 0px;
    }
`;

export const Banner = styled.img<BannerInterfaces>`
    object-fit: contain;
    margin-bottom: ${(props) => (props.existTimeline ? '0px' : '20px')};
    width: 97%;
    border-radius: 20px;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

export const Cover = styled.img`
    border-radius: 10px;
    margin-right: 15px;
    height: 100%;
    width: 300px;
    @media (max-width: 1350px) {
        width: 250px;
    }
    @media (max-width: 1200px) {
        width: 200px;
    }
    @media (max-width: 600px) {
        height: 100px;
        margin-bottom: 10px;
        margin-right: 0px;
        width: 90%;
    }
`;

export const ContainerBox = styled.div<ContainerBoxInterfaces>`
    align-items: ${(props) => (!props.moreShow ? 'center' : 'flex-start')};
    justify-content: ${(props) => (!props.moreShow ? 'center' : 'flex-start')};
    padding: 10px;
    display: flex;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    margin-top: 10px;
    border-radius: 30px;
    min-height: 200px;
    max-height: 200px;
    width: 97%;
    @media (max-width: 600px) {
        flex-direction: column;
        max-height: 300px;
        width: 90%;
    }
`;

export const ContainerAdjustFilters = styled.div<ContainerBoxInterfaces>`
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 20px 20px;
    display: flex;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    margin-top: 10px;
    border-radius: 30px;
    min-height: 100px;
    max-height: 100px;
    width: 97%;
    @media (max-width: 600px) {
        flex-direction: column;
        width: 90%;
    }
`;

export const EmptyList = styled.text`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blue};
`;

export const ContainerCategory = styled.div<AdjustInfoInterfaces>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    margin: ${(props) => (props.margin || '10px 10px 0px 10px')};
    width: ${(props) => (props.width || '50%')};
    :hover {
        opacity: .8;
    }
`;

export const NameCategory = styled.text`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 20px;
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;

export const ContainerInfo = styled.div<ContainerInfoInterfaces>`
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: ${(props) => (props.fileExist ? '70%' : '90%')};
    @media (max-width: 800px) {
        width: 90%;
        min-height: 136px;
    }
`;

export const Title = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 36px;
    @media (max-width: 2000px) {
        font-size: 34px;
    }
    @media (max-width: 1600px) {
        font-size: 32px;
    }
    @media (max-width: 1350px) {
        font-size: 30px;
    }
`;

export const Description = styled.text<DescriptionInterfaces>`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.qtdLine)};
    -webkit-box-orient: vertical;
    @media (max-width: 2000px) {
        font-size: 14.4px;
    }
    @media (max-width: 1600px) {
        font-size: 12.8px;
    }
    @media (max-width: 1350px) {
        font-size: 11.2px;
    }
`;

export const AdjustInfoTitle = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Hours = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    font-size: 16px;
    @media (max-width: 2000px) {
        font-size: 14.4px;
    }
    @media (max-width: 1600px) {
        font-size: 12.8px;
    }
    @media (max-width: 1350px) {
        font-size: 11.2px;
    }
`;

export const ButtonMore = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    align-self: center;
    border-radius: 35px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    height: 40px;
    width: 217px;
`;

export const ContainerCalendar = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    min-height: 80vh;
    width: 300px;
    position: absolute;
    right: 15px;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        width: 200px;
    }
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const ContainerSimulate = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
`;

export const TitleSimulate = styled.text`
    color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark)};
    font-weight: 600;
    font-size: 14px;
`;

export const DescriptionSimulate = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-size: 11px;
`;

export const TitleCalendar = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    margin-bottom: 20px;
`;

export const ContainerOptionsDrawer = styled.div`
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px;
    width: auto;
`;

export const ContainerAdjustMore = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    flex-direction: column;
`;

export const AdjustInfo = styled.div<AdjustInfoInterfaces>`
    align-items: ${(props) => (props.alignItems || 'center')};
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    width: ${(props) => (props.width || 'auto')};
    @media (max-width: 600px) {
        flex-direction: ${(props) => (props.isFlexDirection ? 'column' : 'row')};
    }
`;

export const ContainerQtd = styled.div`
    cursor: pointer;
    border-radius: 35px;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundQtdCategory};
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
    height: 50px;
    width: 100px;
    @media (max-width: 1700px) {
        height: 40px;
        width: 80px;
    }
    @media (max-width: 1400px) {
        height: 30px;
        width: 60px;
    }
`;

export const IconDropDawnCategory = styled.img`
    object-fit: contain;
    height: 21px;
    width: 10.5px;
`;
