// libraries
import React from 'react';
import Modal from 'react-modal';
import { checkCircleCorrect, checkCircleError, closeGray } from '../../../assets';

// js
import colors from '../../../styles/colors';
import {
    ContainerPopUp,
    ImageCorrectOrError,
    DescriptionResult,
    AdjustButtons,
    ButtonFinish,
    ContainerAdjustResult,
    DivNull,
    ImgClose,
    Close,
    AdjustCloseModal,
} from '../flowQuestionsStyles';

// interfaces
interface Props {
    popUp: boolean,
    setPopUp: (v: boolean) => void,
    NextQuestion: any,
    setIdScreen: (v: number) => void,
    isCorrect: boolean,
    setChosenOption: (v: string) => void,
}

const PopUpResultQuestion: React.FC<Props> = (props) => {
    // constants
    const {
        popUp,
        setPopUp,
        NextQuestion,
        setIdScreen,
        isCorrect,
        setChosenOption,
    } = props;
    const modalPopUp = {
        overlay: {
            backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
        },
        content: {
            alignItems: 'center',
            border: 'none',
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
        },
    };

    // methods
    const openResolution = () => {
        setPopUp(false);
        setIdScreen(2);
        setChosenOption('');
    };

    const nextQuestion = () => {
        setChosenOption('');
        setPopUp(false);
        NextQuestion();
    };

    const closeModal = () => {
        setPopUp(false);
        setChosenOption('');
    };

    return (
        <Modal isOpen={popUp} style={modalPopUp}>
            <ContainerPopUp>
                <ContainerAdjustResult>
                    <AdjustCloseModal>
                        <Close onClick={closeModal}>
                            <ImgClose src={closeGray} />
                        </Close>
                    </AdjustCloseModal>
                    <ImageCorrectOrError src={isCorrect ? checkCircleCorrect : checkCircleError} />
                    <DivNull alignItems="center">
                        <DescriptionResult fontWeight="bold">{isCorrect ? 'Parabéns!' : 'Ops!'}</DescriptionResult>
                        <DescriptionResult>{isCorrect ? 'Resposta correta!' : 'Resposta incorreta!'}</DescriptionResult>
                    </DivNull>
                    <AdjustButtons>
                        <ButtonFinish onClick={openResolution}>
                            Resolução
                        </ButtonFinish>
                        <ButtonFinish
                            color={colors.basic(window.localStorage.getItem('theme') || 'light').white}
                            backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong}
                            border={colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong}
                            onClick={nextQuestion}
                        >
                            Próxima
                        </ButtonFinish>
                    </AdjustButtons>
                </ContainerAdjustResult>
            </ContainerPopUp>
        </Modal>
    );
};

export default PopUpResultQuestion;
