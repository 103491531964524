// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllEssay(
    searchTxt: string,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/themesRedaction?searchTxt=${searchTxt}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetAllTextSupport(
    idThemeRedaction: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/supportingTextsRedaction?idThemeRedaction=${idThemeRedaction}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllEssay,
    GetAllTextSupport,
};
