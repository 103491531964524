/* eslint-disable max-len */
// js
import colors from '../styles/colors';

// constants
const fontFamily = '"Quicksand"';

const fontSize = {
    default: 22,
};

const allDates = [
    {
        id: 1,
        title: 'SIMULADO 087',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '10/11',
        type: 'simulate',
    },
    {
        id: 2,
        title: 'SIMULADO 088',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '11/11',
        type: 'simulate',
    },
    {
        id: 3,
        title: 'SIMULADO 089',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '12/11',
        type: 'revision',
    },
    {
        id: 4,
        title: 'SIMULADO 090',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '13/11',
        type: 'revision',
    },
];

const modalDrawerStyles = {
    overlay: {
        backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        border: 'none',
        backgroundColor: 'transparent',
        padding: '0px',
        minHeight: '80vh',
        top: '10vh',
        maxWidth: '60vw',
        left: '20vw',
    },
};

export default {
    fontSize,
    fontFamily,
    allDates,
    modalDrawerStyles,
};
