// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ButtonInterface {
    activeColor: string,
    cursor?: string,
    opacity?: number,
}

// styles
export const Button = styled.button<ButtonInterface>`
    align-items: center;
    background-color: ${(props) => (props.activeColor || colors.theme(window.localStorage.getItem('theme') || 'light').purple)};
    border-style: none;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? 'wait' : props.cursor || 'pointer')};
    display: flex;
    justify-content: center;
    border-radius: 30px;
    padding: 0px 10px 0px 10px;
    min-height: 50px;
    width: 80%;
    margin-bottom: 20px;

    :focus, :hover{
        opacity: ${(props) => (props.disabled ? 0.5 : 0.7)};
    }
    @media (max-width: 600px), (max-height: 650px) {
        min-height: 40px;
        margin-bottom: 10px;
    }
`;

export const Label = styled.text<ButtonInterface>`
    color: ${(props) => (props.activeColor || colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault)};
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    @media (max-width: 900px) {
        font-size: 17px;
    }
    @media (max-width: 600px), (max-height: 650px) {
        font-size: 15px;
    }
`;
