// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerStatisticsInterfaces {
    existStatistics: boolean,
    marginLeft?: string,
    marginLeftLowLarge?: string,
}

interface ContainerIndexInterfaces {
    width?: string,
    justifyContent?: string,
    margin?: string,
    flexWrap?: string,
    flexDirection?: string,
    overflowX?: string,
}

interface ContainerTopHeaderInterfaces {
    justifyContent?: string,
}

interface IndexCircleInterfaces {
    focus?: boolean,
    newColor?: string | null,
}

interface ButtonAlternativeInterfaces {
    select: boolean,
}

interface ButtonResolutionInterfaces {
    backgroundColor?: string,
}

interface ButtonFinishInterfaces {
    border?: string,
    color?: string,
    backgroundColor?: string,
}

interface ButtonNextQuestionInterfaces {
    backgroundColor?: string,
    border?: string,
    color?: string,
    fontWeight?: string,
}

interface ImgNextInterfaces {
    margin?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

// styles
export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    /* padding-bottom: 10px; */
    /* min-height: 100vh; */
    /* max-height: 100vh; */
    max-width: 100vw;
`;

export const ContainerBody = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-top: 100px;
    width: 100%;
`;

export const ContainerFilters = styled.div<ContainerStatisticsInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.existStatistics ? 'center' : 'flex-start')};
    flex-direction: column;
    display: flex;
    width: 100%;
    /* max-height: 80vh; */
    margin-left: ${(props) => (props.marginLeft || '0px')};
    /* margin-right: 310px; */
    transition-duration: 400ms;
    overflow: hidden;
    padding: 0px 10px 0px 10px;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        /* margin-right: 215px; */
        margin-left: ${(props) => (props.marginLeftLowLarge || '0px')};
    }
    @media (max-width: 1200px) {
        /* margin-right: 5px; */
    }
    @media (max-width: 799px) {
        margin-left: 0px;
    }
`;

export const ContainerCalendar = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    min-height: 80vh;
    width: 300px;
    position: absolute;
    right: 15px;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        width: 200px;
    }
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const TitleCalendar = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    margin-bottom: 20px;
`;

export const ContainerOptionsDrawer = styled.div`
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px;
    width: auto;
`;

export const ContainerAdjust = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerAdjustResult = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 40vh;
    max-height: 40vh;
    height: 100%;
    width: 100%;
`;

export const ContainerHeader = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    min-height: 15vh;
    max-height: 15vh;
    padding: 0px 4px 0px 4px;
    width: 100%;
    border-radius: 8px;
`;

export const ContainerTopHeader = styled.div<ContainerTopHeaderInterfaces>`
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'flex-end')};
    /* margin-top: 20px; */
    width: 100%;
`;

export const GoBackStep = styled.img`
    object-fit: contain;
    cursor: pointer;
    height: 20px;
    width: 10.5px;
`;

export const Close = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    /* background-color: pink; */
    display: flex;
    @media (max-width: 600px) {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

export const ImgClose = styled.img`
    height: 16px;
    width: 16px;
    @media (max-width: 600px) {
        height: 12px;
        width: 12px;
    }
    @media (max-width: 500px) {
        height: 8px;
        width: 8px;
    }
`;

export const ContainerAdjustOptions = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 100%;
`;

export const ContainerIndex = styled.div<ContainerIndexInterfaces>`
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: ${(props) => (props.flexDirection || 'flex')};
    overflow-x: ${(props) => (props.overflowX || 'visible')};
    margin: ${(props) => (props.margin || '0px')};
    margin-top: 20px;
    flex-wrap: ${(props) => (props.flexWrap || 'nowrap')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    min-width: ${(props) => (props.width || '80%')};
    max-width: ${(props) => (props.width || '80%')};
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const ContainerExercices = styled.div<ContainerIndexInterfaces>`
     display: flex;
        gap: 5px;
        align-items: center;
        flex-direction: ${(props) => (props.flexDirection || 'flex')};
        overflow-x: ${(props) => (props.overflowX || 'visible')};
        margin: ${(props) => (props.margin || '0px')};
        flex-wrap: ${(props) => (props.flexWrap || 'nowrap')};
        justify-content: ${(props) => (props.justifyContent || 'flex-start')};
        min-width: ${(props) => (props.width || '80%')};
        max-width: ${(props) => (props.width || '80%')};
`;

export const ContainerReportProblem = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber};
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
    @media (max-width: 600px) {
        min-width: 35px;
        min-height: 35px;
        max-width: 35px;
        max-height: 35px;
    }
    @media (max-width: 500px) {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }
    @media (max-width: 400px) {
        min-width: 25px;
        min-height: 25px;
        max-width: 25px;
        max-height: 25px;
    }
    :hover {
        opacity: .8;
    }
`;

export const ImgOption = styled.img`
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
    width: 40px;
`;

export const ImgAlert = styled.img`
    height: 32px;
    width: 6px;
`;

export const ContainerTimeOut = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blue};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    font-size: 14px;
    font-weight: bold;
    height: 25px;
    width: 100px;
    border-radius: 5px;
    margin-bottom: 10px;
    @media (max-width: 600px) {
        font-size: 13px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

export const ImageReport = styled.img`
    object-fit: contain;
    height: 54px;
    width: 54px;
`;

export const TitleReport = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    font-weight: 500;
    margin-top: 10px;
    font-size: 16px;
`;

export const InputReport = styled.textarea`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 10px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    padding: 10px;
    margin: 20px 0px 20px 0px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
    height: 100px;
    width: 70%;
    font-weight: normal;
    vertical-align: top;
    resize: none;
    font-family: inherit;
`;

interface StyleRenderPDFInterfaces {
    height?: any;
}

export const StyleRenderPDF = styled.embed<StyleRenderPDFInterfaces>`
    width: 100%;
    /* height: ${({ height }) => height || '500px'}; */
    min-height: 66.5vh;
    max-height: 66.5vh;
`;

export const ContainerClose = styled.div`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    height: 50px;
    width: 50px;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    align-self: flex-end;
`;

export const StyleArchiveFullSize = styled.div`
    width: 100%;
    max-width: 100%;
    text-align: justify;
    * {
        text-align: justify;
        font-size: 16px;
        color: ${colors.theme(window.localStorage.getItem('theme') || 'light').descriptionQuestion} !important;
        font-family: 'Nunito';
    }
    img {
        width: 40%;
    }
`;

export const StyleArchive = styled.div`
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    * {
        text-align: justify;
        font-size: 16px;
        color: ${colors.theme(window.localStorage.getItem('theme') || 'light').descriptionQuestion} !important;
        font-family: 'Nunito';
    }
    img {
        width: 40%;
    }
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

interface TitleQuestionInterfaces {
    width?: string;
}

export const TitleQuestion = styled.text<TitleQuestionInterfaces>`
    /* color: ${colors.theme(window.localStorage.getItem('theme') || 'light').descriptionQuestion}; */
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    margin-bottom: 10px;
    font-size: 14px;
    width: ${({ width }) => width || 'auto'};
`;

export const TitleTime = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;
    width: auto;
`;

export const TimeText = styled.text`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
`;

export const AdjustInfoTitle = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    justify-content: space-between;

    @media (max-width: 600px) {
        margin-bottom: 20px;
        gap: 10px;
    }
`;

export const AdjustInfoTime = styled.div`
    width: 100%;
    max-width: 100%;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
`;

export const RenderArchive = styled.p`
`;

export const AdjustAlternative = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const PercentageAlternative = styled.text`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blue};
    font-size: 12px;
    margin-top: 5px;
    font-weight: bold;
`;

export const VideoResolution = styled.video`
    margin: 20px 0px 10px 0px;
`;

export const ButtonAlternative = styled.div<ButtonAlternativeInterfaces>`
    align-items: center;
    display: flex;
    justify-content: center;
    color: ${(props) => (props.select ? colors.basic(window.localStorage.getItem('theme') || 'light').white : colors.theme(window.localStorage.getItem('theme') || 'light').colorAlternative)};
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
    background-color: ${(props) => (props.select ? colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong : colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: ${(props) => (props.select ? 'none' : `1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').blue}`)};
    :hover {
        opacity: .7;
    }
`;

export const ButtonResolution = styled.div<ButtonResolutionInterfaces>`
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark)};
    font-weight: bold;
    border: none;
    border-radius: 30px;
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault)};
    cursor: pointer;
    min-width: 30%;
    max-width: 50%;
    text-align: center;
    font-size: 14px;
    @media (max-height: 600px) {
        min-height: 45px;
        max-height: 45px;
    }
    @media (max-height: 500px) {
        min-height: 35px;
        max-height: 35px;
    }
    @media (max-width: 600px) {
        min-height: 55px;
        max-height: 55px;
        min-width: 50%;
        margin-bottom: 10px;
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 10px;
        min-height: 35px;
        max-height: 35px;
    }
    @media (max-width: 400px) {
        min-height: 35px;
        max-height: 35px;
    }
    :hover {
        opacity: .8;
    }
`;

export const ContainerAswerFullSize = styled.div`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    padding: 20px 10px 10px 10px;
    width: 90vw;
    height: 90vh;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

export const IndexCircle = styled.div<IndexCircleInterfaces>`
    min-height: 50px;
    border-radius: 25px;
    display: flex;
    margin-bottom: 4px;
    min-width: 50px;
    align-items: center;
    margin-right: 10px;
    border: ${(props) => (`3px solid ${props.newColor}`)};
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    color: ${(props) => (props.focus ? colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault : colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark)};
    background-color: ${(props) => (props.focus ? colors.theme(window.localStorage.getItem('theme') || 'light').backgroundIndexQuestion : colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    @media (max-width: 600px) {
        min-width: 40px;
        min-height: 40px;
    }
    @media (max-width: 500px) {
        min-width: 38px;
        min-height: 38px;
    }
    :hover {
        opacity: .85;
    }
`;

export const ContainerBodyTwo = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 10px 0px 10px;
    width: 100%;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;
    gap: 5px;
    padding-top: 10px;
    flex-direction: column;
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const ContainerButtonsResolution = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    width: 100%;
    gap: 15px;
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const ButtonNextQuestion = styled.div<ButtonNextQuestionInterfaces>`
    min-height: 45px;
    max-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics)};
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    border: ${(props) => (props.border || 'none')};
    border-radius: 30px;
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault)};
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    min-width: 48%;
    @media (max-height: 600px) {
        min-height: 45px;
        max-height: 45px;
    }
    @media (max-height: 500px) {
        min-height: 35px;
        max-height: 35px;
    }
    @media (max-width: 600px) {
        min-height: 45px;
        max-height: 45px;
        font-size: 12px;
        min-width: 90%;
    }
    @media (max-width: 500px) {
        font-size: 10px;
        min-height: 35px;
        max-height: 35px;
    }
    @media (max-width: 400px) {
        height: 50px;
    }
    :hover {
        opacity: .8;
    }
`;

export const ImgNext = styled.img<ImgNextInterfaces>`
    height: 14px;
    margin: ${(props) => (props.margin || '0px')};
    width: 14px;
`;

export const ContainerPopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    justify-content: space-between;
    margin-left: 10px;
    transition-duration: 400ms;
    min-width: 40vw;
    min-height: 40vh;
    max-height: 40vh;
    overflow: hidden;
    
    @media (max-width: 600px) {
        min-width: 60vw;
    }
`;

export const ImageCorrectOrError = styled.img`
    object-fit: contain;
    height: 50px;
    width: 50px;
`;

export const OpenOption = styled.div`
    border-radius: 25px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    height: 44px;
    margin-left: 6px;
    cursor: pointer;
    width: 44px;
    :hover {
        opacity: .8;
    }
`;

export const DescriptionResult = styled.text`
    font-size: 16px;
    font-weight: ${(props) => (props.fontWeight || '500')};
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').blueOrDarkMissOrHitQuestion};
`;

export const AdjustButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
`;

export const ButtonFinish = styled.div<ButtonFinishInterfaces>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 35px;
    min-height: 45px;
    border: ${(props) => (props.border || `1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').blueOrDarkMissOrHitQuestion}`)};
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').blueOrDarkMissOrHitQuestion)};
    background-color: ${(props) => (props.backgroundColor || colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    width: 45%;
    :hover{
        opacity: .7;
    }
`;

interface DivNullInterfaces {
    alignItems?: string
}

export const DivNull = styled.div<DivNullInterfaces>`
    display: flex;
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
`;

export const AdjustCloseModal = styled.div`
    justify-content: flex-end;
    width: 90%;
    display: flex;
`;

export const ImgUpperOrLower = styled.img`
    cursor: pointer;
    object-fit: contain;
    margin-left: 10px;
    height: 22px;
    width: 22px;
`;

export const AdjustFlex = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: flex;
`;
