// libraries
import { flipInX, slideInLeft } from 'react-animations';
import styled, { keyframes } from 'styled-components';

// js
import colors from '../../styles/colors';

// constants
const animationItemAccordion = keyframes`${flipInX}`;
const animationAllDays = keyframes`${slideInLeft}`;

// interfaces
interface TextFilterInterfaces {
    fontWeight?: string,
}

interface IconNotificationInterfaces {
    background?: string,
}

interface IconSearchInterfaces {
    cursor?: string,
}

interface LineInterfaces {
    margin?: string,
    justifyContent?: string,
}

interface ContainerDropDawnInterfaces {
    width?: string,
}

interface ContainerPopUpProfileInterfaces {
    openPopUp?: boolean,
    display?: string,
}

interface ButtonSaveInterfaces {
    backgroundColor?: string,
    color?: string,
    width?: string,
}

interface AdjustTimerInterfaces {
    justifyContent?: string,
    width?: string,
}

interface ContainerHeaderInterfaces {
    idScreen?: string,
}

// styles
export const ContainerHeader = styled.div<ContainerHeaderInterfaces>`
    align-items: center;
    justify-content: space-between;
    display: flex;
    top: 0px;
    right: 8px;
    height: 100px;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    position: absolute;
    padding: 0px 20px 0px 28px;
    z-index: ${(props) => (props.idScreen === 'statistics' ? 1 : 'auto')};
    min-width: 100vw;
    max-width: 100vw;
`;

export const ContainerSearchInvisible = styled.div`
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 0px 15px;
    margin: 0px 10px 0px 10px;
    height: 70px;
    width: 50%;
    @media (max-width: 1800px) {
        height: 50px;
    }
    @media (max-width: 1200px) {
        width: 400px;
    }
    @media (max-width: 1000px) {
        width: 300px;
        height: 40px;
    }
    @media (max-width: 800px) {
        width: 70%;
        height: 40px;
    }
    @media (max-width: 800px) {
        width: 60%;
        height: 40px;
    }
`;

export const ContainerSearch = styled.div`
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 0px 15px;
    margin: 0px 10px 0px 10px;
    height: 70px;
    width: 50%;
    @media (max-width: 1800px) {
        height: 50px;
    }
    @media (max-width: 1200px) {
        width: 400px;
    }
    @media (max-width: 1000px) {
        width: 300px;
        height: 40px;
    }
    @media (max-width: 800px) {
        width: 70%;
        height: 40px;
    }
    @media (max-width: 800px) {
        width: 60%;
        height: 40px;
    }
`;

export const Logo = styled.img`
    object-fit: contain;
    height: auto;
    width: 100%;
    max-width: 200px;
    @media (max-width: 1000px) {
        width: 100%;
        height: 140px;
        max-width: 140px;
    }
`;

export const IconSearch = styled.img<IconSearchInterfaces>`
    object-fit: contain;
    cursor: ${(props) => (props.cursor || 'default')};
    height: 30px;
    width: 30px;
    @media (max-width: 1000px) {
        height: 20px;
        width: 20px;
    }
    @media (max-width: 800px) {
        height: 24px;
        width: 24px;
    }
`;

export const ContainerAdjustIcons = styled.div`
    align-items: center;
    display: flex;
    justify-content: right;
    padding-right: 20px;
    width: 100%;
    @media (max-width: 800px) {
    }
`;

export const IconFaq = styled.img`
    object-fit: contain;
    height: 24px;
    width: 24px;
`;

export const InputSearch = styled.input`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-size: 18px;
    background-color: transparent;
    border: none;
    min-height: 60px;
    margin-left: 10px;
    width: 100%;
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
        @media (max-width: 1000px) {
            font-size: 13px;
        }
    }
    @media (max-width: 1800px) {
        font-size: 16px;
        min-height: 50px;
    }
    @media (max-width: 1000px) {
        min-height: 40px;
        font-size: 13px;
    }
`;

export const ButtonFaq = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    border-radius: 35px;
    margin: 0px 10px 0px 0px;
    min-height: 60px;
    width: 25%;
    @media (max-width: 1800px) {
        min-height: 50px;
        margin-right: 8px;
    }
    @media (max-width: 1600px) {
        align-items: right;
        margin: auto 0;
        margin-right: 8px;
        margin-left: auto;
        width: 204px;
        height: 40px;
    }
    @media (max-width: 1000px) {
        margin-left: auto;
        min-height: 40px;
        width: 130px;
    }
    :hover {
        opacity: .8;
    }
`;

export const ContainerDropDawn = styled.div<ContainerDropDawnInterfaces>`
    align-items: center;
    cursor: pointer;
    display: flex;
    width: ${(props) => (props.width || 'auto')};
    :hover {
        opacity: .85;
    }
`;

export const ArrowDropDawn = styled.img`
    height: 7.5px;
    margin-left: 5px;
    width: 18px;
`;

export const TextButtonFaq = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    margin-left: 5px;
    font-size: 18px;
    font-weight: bold;
    @media (max-width: 1000px) {
        font-size: 12px;
    }
`;

export const TextFilter = styled.div<TextFilterInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').grayLight)};
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        font-size: 14px;
    }
    @media (max-width: 1000px) {
        font-size: 12px;
    }
`;

export const IconNotification = styled.div<IconNotificationInterfaces>`
    background-image: ${(props) => (`url(${props.background})`)};
    display: flex;
    justify-content: flex-end;
    background-size: cover;
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    margin: 0px 10px 0px 0px;
    @media (max-width: 600px) {
        height: 24px;
        width: 24px;
    }
`;

export const NumberNotification = styled.div`
    align-items: center;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber};
    border-radius: 7px;
    height: 13px;
    display: flex;
    justify-content: center;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-size: 12px;
    width: 13px;
`;

export const IconProfile = styled.img`
    object-fit: cover;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin: 0px 10px 0px 0px;
    cursor: pointer;
    @media (max-width: 800px) {
        height: 40px;
        width: 40px;
    }
`;

export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 150px;
`;

interface WelcomeInterfaces {
    margin?: string,
}

export const TitleClose = styled.text<WelcomeInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '16px')};
    margin: ${(props) => (props.margin || '0px')};
    cursor: pointer;
    @media (max-width: 1000px) {
        font-size: 13px;
    }
`;

export const InputNullSelectedArchive = styled.input`
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    margin-bottom: 30px;
    background-color: black;
    cursor: pointer;
`;

export const Welcome = styled.text<WelcomeInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '16px')};
    margin: ${(props) => (props.margin || '0px')};
    @media (max-width: 1000px) {
        font-size: 13px;
    }
`;

export const DescriptionFaq = styled.text<WelcomeInterfaces>`
    color: ${(props) => (props.color || (window.localStorage.getItem('theme') === 'light' ? colors.basic(window.localStorage.getItem('theme') || 'light').gray : colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault))};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '16px')};
    margin: ${(props) => (props.margin || '0px')};
    @media (max-width: 1000px) {
        font-size: 13px;
    }
`;

export const TextTypeFilter = styled.div`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    font-size: 16px;
    font-weight: bold;
    padding-left: 5px;
    @media (max-width: 1200px) {
        font-size: 14px;
    }
    @media (max-width: 1000px) {
        font-size: 12px;
    }
`;

export const ContainerMenus = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
`;

export const ContainerPopUpProfile = styled.div<ContainerPopUpProfileInterfaces>`
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    display: ${(props) => (props.openPopUp ? 'flex' : 'none')};
    align-items: center;
    flex-direction: column;
    position: absolute;
    border-radius: 30px;
    z-index: 1;
    top: 15px;
    right: 20px;
    padding: 20px;
    overflow-y: auto;
    transition-duration: 500ms;
    max-height: 90vh;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const ContainerAdjustProfile = styled.div<LineInterfaces>`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    width: 100%;
    margin: ${(props) => (props.margin || '0px 0px 0 0px')};
    :hover {
        opacity: .7;
    }
`;

export const Line = styled.div<LineInterfaces>`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundLineTransparent};
    margin: ${(props) => (props.margin || '0 0px 0 0px')};
    min-height: 1px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
`;

export const IconTab = styled.img`
    margin-right: 10px;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
`;

export const ContainerPopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    justify-content: space-between;
    margin-left: 10px;
    transition-duration: 400ms;
    min-width: 75vw;
    max-width: 75vw;
    min-height: 70vh;
    max-height: 70vh;
    overflow: hidden;

    min-width: 60vw;
    max-width: 60vw;
    min-height: 80vh;
    max-height: 80vh;
    overflow: hidden;

`;

export const ContainerAdjust = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerHeaderPopUp = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 15px 20px 15px 20px;
    width: 100%;
`;

export const ContainerTopHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Close = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    font-size: 16px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 10px;
    /* background-color: pink; */
    display: flex;
    @media (max-width: 600px) {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

export const ImgClose = styled.img`
    height: 16px;
    width: 16px;
    @media (max-width: 600px) {
        height: 12px;
        width: 12px;
    }
    @media (max-width: 500px) {
        height: 8px;
        width: 8px;
    }
`;

export const Title = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-size: 30px;
    font-weight: bold;
    @media (max-width: 1200px) {
        font-size: 26px;
    }
    @media (max-width: 800px) {
        font-size: 24px;
    }
    @media (max-width: 600px) {
        font-size: 18px;
    }
    @media (max-height: 600px) {
        font-size: 18px;
    }
    @media (max-width: 400px) {
        font-size: 14px;
    }
`;

export const ContainerBody = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 20px 10px 20px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: 60vh;
    width: 100%;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const ContainerSelect = styled.div`
    align-items: center;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight};
    display: flex;
    justify-content: space-between;
    border-radius: 30px;
    margin: 10px 0px 20px 0px;
    padding: 0px 10px 0px 30px;
    min-height: 60px;
    width: 80%;
    @media (max-width: 900px), (max-height: 900px) {
        margin: 5px 0px 10px 0px;
        min-height: 50px;
    }
    @media (max-width: 600px), (max-height: 650px) {
        min-height: 40px;
    }
`;

export const Select = styled.select`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border: none;
    min-height: 100%;
    width: 95%;
`;

export const Option = styled.option``;

export const ButtonSave = styled.div<ButtonSaveInterfaces>`
    align-items: center;
    cursor: pointer;
    justify-content: center;
    align-self: center;
    width: ${(props) => (props.width || '70%')};
    border-radius: 35px;
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark)};
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault)};
    margin-top: 20px;
    display: flex;
    font-weight: bold;
    min-height: 50px;
`;

export const ContainerAlarm = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundLineTransparent};
    margin-top: 20px;
`;

export const TitleAlarm = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
`;

export const DescriptionAlarm = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-size: 14px;
`;

export const AdjustTimer = styled.div<AdjustTimerInterfaces>`
    align-items: center;
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-top: 10px;
    width: ${(props) => (props.width || 'auto')};
`;

export const IconAlarm = styled.img`
    object-fit: contain;
    min-height: 18px;
    margin-right: 10px;
    min-width: 18px;
`;

export const ContainerAdjustAbout = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ArrowForDropDawn = styled.img`
    height: 10.5px;
    width: 21px;
`;

export const AnimationDescription = styled.div`
    animation: 0.3s ${animationItemAccordion};
`;

export const GoBackStep = styled.img`
    object-fit: contain;
    cursor: pointer;
    height: 20px;
    width: 10.5px;
`;

export const AdjustContainerAlarm = styled.div`
    width: 100%;
`;

export const ContainerAdjustInputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const AllDays = styled.div`
    animation: 0.5s ${animationAllDays};
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContainerDay = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
`;

export const BarSelected = styled.div<ButtonSaveInterfaces>`
    height: 50px;
    margin-right: 5px;
    width: 3px;
    background-color: ${(props) => (props.backgroundColor)};
`;

export const UnChecked = styled.div`
    border: 1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').blue};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-right: 10px;
`;

export const Checked = styled.img`
    object-fit: contain;
    height: 20px;
    width: 20px;
    margin-right: 10px;
`;

export const ContainerInfoAlarm = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-bottom: 20px;
    width: 100%;
`;

export const EmptyList = styled.text`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blue};
`;

export const ContainerLikeAndDeslike = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
`;

export const IconLikeAndDeslike = styled.img`
    height: 25px;
    width: 25px;
    object-fit: contain;
`;

export const AdjustLikes = styled.div`
    display: flex;
    margin-top: 20px;
`;
