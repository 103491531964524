// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';

// js
import colors from '../../styles/colors';
import auth from '../../services/auth';
import {
    Container,
    Home,
    ContainerBody,
    ContainerTimeline,
    Banner,
    // ContainerCalendar,
    // TitleCalendar,
    ContainerOptionsDrawer,
    ContainerCategory,
    NameCategory,
    AdjustInfo,
    ContainerQtd,
    IconDropDawnCategory,
    ContainerAdjustFilters,
    EmptyList,
} from './timelineStyles';
import { RootState } from '../../store';
import { logoutAuth, updateUser } from '../../store/modules/user/actions';
import timelineApi from '../../services/timeline';
import { arrowDropDawnLight, bannerDefault } from '../../assets';
import constants from '../../utils/consts';
import statisticsConstants from '../Statistics/statisticsConstants';

// components
import HeaderComponent from '../../components/Header/HeaderComponent';
import DrawerComponent from '../../components/Drawer/DrawerComponent';
import BoxTimelineComponent from './components/BoxTimelineComponent';
// import OneDateSimulateComponent from './components/OneDateSimulateComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';
import profile from '../../services/profile';
import ModalOptionsProfile from '../../components/Header/components/ModalOptionsProfile';
import PickerComponent from '../Statistics/components/Picker/PickerComponent';
import ModalPickerComponent from '../Statistics/components/ModalPickerComponent';
import PopUpComponent from '../../components/PopUp/PopUpComponent';

const TimelinePage: React.FC = () => {
    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // dispatch
    const dispatch = useDispatch();

    // useState
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);
    // const [search, setSearch] = useState('');
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allTimeline, setAllTimeline] = useState([]);
    const [existTimeline, setExistTimeline] = useState<any>(null);
    const [show, setShow] = useState(true);
    const [openDescriptionTimeline, setOpenDescriptionTimeline] = useState<any>(null);
    const [typeOrderId, setTypeOrderId] = useState(7);
    const [openModalPeriod, setOpenModalPeriod] = useState(false);
    const [allData, setAllData] = useState<Array<any>>([]);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [allFilters, setAllFilters] = useState<Array<any>>([]);
    const [selectFilter, setSelectFilter] = useState<Array<number>>([]);
    const [allSubjectsSelect, setAllSubjectsSelect] = useState<Array<any>>([]);
    const [popUp, setPopUp] = useState(false);
    const [selectDropdawn, setSelectDropdawn] = useState<Array<number>>([]);

    // methods
    const GetDataTimeline = async (GetNewToken?: any) => {
        if (GetNewToken) {
            setLoading(true);
        }
        const GetAllTimeline = await timelineApi.AllTimeLine(
            allData[typeOrderId]?.id || 'ALL',
            selectFilter,
            GetNewToken?.token || infoUser?.token,
        );
        if (GetNewToken) {
            setLoading(false);
        }
        if (GetAllTimeline?.status === 200) {
            setAllTimeline(GetAllTimeline?.result);
            setExistTimeline(GetAllTimeline?.result?.length > 0);
        }
    };
    const ActionInitialScreen = async () => {
        const GetNewToken = await auth.RefreshToken(
            infoUser?.session,
            setPathRoute,
            setRedirect,
            dispatch,
            infoUser?.tokenRefresh,
        );
        if (GetNewToken?.status !== 200) {
            // eslint-disable-next-line no-alert
            alert(GetNewToken?.message);
            return dispatch(logoutAuth());
        }
        const GetAllInfoUser = await profile.GetInfoUser(infoUser.id, GetNewToken.token);
        if (GetAllInfoUser?.status === 200) {
            const addObject = {
                ...GetAllInfoUser.result[0],
                token: GetNewToken.token,
                tokenRefresh: infoUser?.tokenRefresh,
            };
            dispatch(updateUser(addObject));
        }
        await GetDataTimeline(GetNewToken);
        setLoading(false);
        return null;
    };

    const onDrawer = () => {
        setIsOpen(true);
    };

    const OpenModalFilter = async () => {
        const GetExamination = await auth.GetExamination();
        setAllFilters(GetExamination?.examination);
        setPopUp(true);
    };

    // useEffect
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            if (infoUser?.id) {
                ActionInitialScreen();
            }
        }, 500);
    }, []);

    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        if (allData?.length > 0) {
            GetDataTimeline();
        }
    }, [typeOrderId]);

    // renders
    const RenderAllTimeline = () => (
        allTimeline.map((v) => (
            <BoxTimelineComponent
                item={v}
                widthActual={widthActual}
                setOpenDescriptionTimeline={setOpenDescriptionTimeline}
            />
        ))
    );

    // const RenderAllDates = () => (
    //     constants.allDates.map((v) => (
    //         <OneDateSimulateComponent item={v} />
    //     ))
    // );

    function ValidationMarginTimeline() {
        if (show) {
            return '310px';
        }
        if (!show) {
            return '160px';
        }
    }

    function ValidationMarginTimelineLowLarge() {
        if (show) {
            return '215px';
        }
        if (!show) {
            return '105px';
        }
    }

    const CloseModal = () => {
        setIsOpen(false);
    };

    const ApplyFilter = async () => {
        setLoading(true);
        const GetAllTimeline = await timelineApi.AllTimeLine(
            allData[typeOrderId]?.id || 'ALL',
            selectFilter,
            infoUser?.token,
        );
        setAllTimeline(GetAllTimeline?.result);
        setExistTimeline(GetAllTimeline?.result?.length > 0);
        setLoading(false);
    };

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <HeaderComponent
                    // search={search}
                    // setSearch={setSearch}
                    // labelSearch="Concursos"
                    widthActual={widthActual}
                    onDrawer={onDrawer}
                />
                <ContainerBody>
                    {widthActual >= 800 ? (
                        <DrawerComponent
                            show={show}
                            setShow={setShow}
                        />
                    ) : null}
                    <ContainerTimeline
                        existTimeline={allTimeline?.length > 0}
                        marginLeft={ValidationMarginTimeline()}
                        marginLeftLowLarge={ValidationMarginTimelineLowLarge()}
                    >
                        <Banner src={bannerDefault} existTimeline={allTimeline?.length > 0} />
                        <ContainerAdjustFilters>
                            <ContainerCategory onClick={OpenModalFilter}>
                                <NameCategory>Concursos</NameCategory>
                                <AdjustInfo>
                                    <ContainerQtd>
                                        <NameCategory color={colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault} fontWeight="bold">{selectFilter?.length}</NameCategory>
                                    </ContainerQtd>
                                    <IconDropDawnCategory src={arrowDropDawnLight} />
                                </AdjustInfo>
                            </ContainerCategory>
                            <PickerComponent label="Período:" value="" idFilter={1} typeOrderId={typeOrderId} setOpenModalPeriod={setOpenModalPeriod} setAllData={setAllData} />
                        </ContainerAdjustFilters>
                        {existTimeline ? (
                            RenderAllTimeline()
                        ) : null}
                        {existTimeline === false ? (
                            <EmptyList>Sem dados para serem exibidos.</EmptyList>
                        ) : null}
                        {/* {existTimeline === null ? (
                            <Loader
                                visible={loading}
                                type="TailSpin"
                                color={colors.theme(window.localStorage.getItem('theme')
                                || 'light').purpleDark}
                                height={80}
                                width={80}
                            />
                        ) : null} */}
                    </ContainerTimeline>
                    {/* <ContainerCalendar>
                        <TitleCalendar>Lista de Simulados</TitleCalendar>
                        {RenderAllDates()}
                    </ContainerCalendar> */}
                </ContainerBody>
                <Modal
                    isOpen={isOpen}
                    style={constants.modalDrawerStyles}
                    onRequestClose={CloseModal}
                >
                    <ContainerOptionsDrawer>
                        <DrawerComponent
                            isModal
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            show
                        />
                    </ContainerOptionsDrawer>
                </Modal>
            </Home>
            <ModalOptionsProfile
                isOpen={openDescriptionTimeline?.description?.length > 0}
                setIsOpen={setOpenDescriptionTimeline}
                optionSelected={{
                    idTimeline: openDescriptionTimeline?.idTimeline,
                    name: 'Descrição',
                }}
                descriptionBody={openDescriptionTimeline?.description}
            />
            <Modal
                isOpen={openModalPeriod}
                style={statisticsConstants.modalPickerStyles}
                onRequestClose={() => setOpenModalPeriod(false)}
            >
                <ModalPickerComponent
                    allData={allData}
                    typeOrderId={typeOrderId}
                    setTypeOrderId={setTypeOrderId}
                    setOpenModalPeriod={setOpenModalPeriod}
                    start={start}
                    end={end}
                    setStart={setStart}
                    setEnd={setEnd}
                />
            </Modal>
            <PopUpComponent
                popUp={popUp}
                setPopUp={setPopUp}
                title="Selecione um filtro"
                allData={allFilters}
                idScreen="Timeline"
                selectDropdawn={selectDropdawn}
                setSelectDropdawn={setSelectDropdawn}
                selectFilter={selectFilter}
                setSelectFilter={setSelectFilter}
                allSubjectsSelect={allSubjectsSelect}
                setAllSubjectsSelect={setAllSubjectsSelect}
                ApplyFilter={ApplyFilter}
                setAllFilters={setAllFilters}
            />
        </Container>
    );
};

export default TimelinePage;
