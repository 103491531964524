/* eslint-disable react/require-default-props */
// libraries
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// js
import {
    ContainerDrawer,
    ButtonDrawer,
    IconDrawer,
    TextDrawer,
    TextClosedModal,
    ArrowDrawer,
    ContainerAdjustDrawer,
    ContainerTabs,
    TextExit,
} from './drawerStyles';
import drawerConstants from './drawerConstants';
import drawerFunctions from './drawerFunctions';
import { arrowLeftBlack, arrowRightBlack } from '../../assets';
import { logoutAuth } from '../../store/modules/user/actions';
import { RootState } from '../../store';
import auth from '../../services/auth';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// interfaces
interface Props {
    isModal?: boolean,
    setIsOpen?: (v: boolean) => void,
    isOpen?: boolean,
    show?: boolean,
    setShow?: (v: boolean) => void,
}

// main
const DrawerComponent: React.FC<Props> = (props: Props) => {
    // constants
    const pathActual = window.location.pathname;
    const {
        isModal,
        setIsOpen,
        isOpen,
        show,
        setShow,
    } = props;

    // dispatch
    const dispatch = useDispatch();

    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // states
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // methods
    const onClosedDrawer = () => {
        if (setIsOpen) {
            setIsOpen(!isOpen);
        }
    };

    const ShowOrHideDrawer = () => {
        if (setShow) {
            setShow(!show);
        }
    };

    const Signout = async () => {
        await auth.LogoutUser(infoUser?.id, infoUser?.token);
        dispatch(logoutAuth());
    };

    // use effect
    useEffect(() => {
        const pageClickEvent = (e: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
                onClosedDrawer();
            }
        };
        if (isOpen) {
            window.addEventListener('click', pageClickEvent);
        }
        return () => {
            window.removeEventListener('click', pageClickEvent);
        };
    }, [isOpen]);

    // renders
    const RenderAllTabs = () => (
        drawerConstants.allTabs.map((v) => {
            const GoToPathSelected = () => {
                setPathRoute(v.id);
                setRedirect(true);
            };
            return (
                <ButtonDrawer
                    key={v.id}
                    enabledFocus={v.id === pathActual}
                    onClick={GoToPathSelected}
                    show={show}
                >
                    <IconDrawer
                        src={drawerFunctions.ValidationPathIcon(v.id, v.id === pathActual)}
                        show={show}
                    />
                    {show ? (
                        <TextDrawer enabledFocus={v.id === pathActual}>{v.title}</TextDrawer>
                    ) : null}
                </ButtonDrawer>
            );
        })
    );

    // use effect
    useEffect(() => {
        if (setIsOpen && setShow && widthActual >= 800) {
            setIsOpen(false);
        }
    }, [isOpen]);

    // main
    return (
        <ContainerAdjustDrawer ref={dropdownRef}>
            {!isModal ? (
                <ArrowDrawer
                    src={show ? arrowLeftBlack : arrowRightBlack}
                    onClick={ShowOrHideDrawer}
                />
            ) : null}
            <ContainerDrawer show={show}>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                {isModal ? (
                    <TextClosedModal onClick={onClosedDrawer}>X</TextClosedModal>
                ) : null}
                <ContainerTabs>
                    {RenderAllTabs()}
                </ContainerTabs>
                <ButtonDrawer
                    key="/"
                    enabledFocus={false}
                    onClick={Signout}
                    show={show}
                >
                    <IconDrawer
                        src={drawerFunctions.ValidationPathIcon('/', false)}
                        show={show}
                    />
                    {show ? (
                        <TextExit enabledFocus={false}>
                            Sair
                        </TextExit>
                    ) : null}
                </ButtonDrawer>
            </ContainerDrawer>
        </ContainerAdjustDrawer>
    );
};

export default DrawerComponent;
