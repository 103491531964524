// js
import {
    closeSquareRed,
    graphLight,
    paperLight,
    tickSquareGreen,
    timeCircleYellow,
} from '../../assets';
import colors from '../../styles/colors';

const allDates = [
    {
        id: 1,
        title: 'SIMULADO 087',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '10/11',
        type: 'simulate',
    },
    {
        id: 2,
        title: 'SIMULADO 088',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '11/11',
        type: 'simulate',
    },
    {
        id: 3,
        title: 'SIMULADO 089',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '12/11',
        type: 'revision',
    },
    {
        id: 4,
        title: 'SIMULADO 090',
        description: 'Lorem ipsum dolor sit amet naus e, consectetur adipiscing elit',
        createdAt: '13/11',
        type: 'revision',
    },
];

const modalDrawerStyles = {
    overlay: {
        backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
    },
    content: {
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
    },
};

const allTabs = [
    {
        id: 1,
        title: 'Geral',
    },
    {
        id: 2,
        title: 'Específicas',
    },
    {
        id: 3,
        title: 'Pontos fracos e Pontos fortes',
    },
    {
        id: 4,
        title: 'Ranking',
    },
];

const allCards = [
    {
        id: 1,
        title: 'APROVEITAMENTO',
        icon: graphLight,
    },
    {
        id: 2,
        title: 'QUESTÕES NO PERÍODO',
        icon: paperLight,
    },
    {
        id: 3,
        title: 'ACERTOS',
        icon: tickSquareGreen,
    },
    {
        id: 4,
        title: 'ERROS',
        icon: closeSquareRed,
    },
    {
        id: 5,
        title: 'TEMPO MÉDIO POR QUESTÃO',
        icon: timeCircleYellow,
    },
];

const allCategories = [
    {
        id: 1,
        title: 'Bancas',
        qtd: 3,
    },
    {
        id: 2,
        title: 'Concursos',
        qtd: 14,
    },
    {
        id: 3,
        title: 'Dificuldade',
        qtd: 8,
    },
    {
        id: 4,
        title: 'Disciplinas - Ciclo Básico (Física)',
        qtd: 21,
    },
    {
        id: 5,
        title: 'Disciplinas - Ciclo Básico (Matemática)',
        qtd: 16,
    },
    {
        id: 6,
        title: 'Disciplinas - Ciclo Básico Específico',
        qtd: 15,
    },
];

const DataGraphStrongAndWeak = [
    {
        label: 'Pré-Cálculo (70%)',
        value: 7,
    },
    {
        label: 'Pré-Cálculo (90%)',
        value: 9,
    },
    {
        label: 'Pré-Cálculo (50%)',
        value: 5,
    },
];

const DomainGraphStrongAndWeak = DataGraphStrongAndWeak.map((v) => {
    if (v.value) {
        return {
            name: v.label,
            domain: [0, v.value + (10 - v.value)],
        };
    }
    return {
        name: '',
        domain: [0, 10],
    };
});

const topTenRaking = [
    {
        id: 1,
        idUser: 23,
        name: 'Maria da Graça',
        points: 124,
        performance: 98.34,
    },
    {
        id: 2,
        idUser: 12,
        name: 'Ana Vilela',
        points: 124,
        performance: 98.34,
    },
    {
        id: 3,
        idUser: 55,
        name: 'Paulo Henrique',
        points: 124,
        performance: 98.34,
    },
    {
        id: 4,
        idUser: 66,
        name: 'Carlos Alberto',
        points: 124,
        performance: 98.34,
    },
    {
        id: 5,
        idUser: 88,
        name: 'Angela Silva',
        points: 124,
        performance: 98.34,
    },
    {
        id: 6,
        idUser: 99,
        name: 'Sandro Almeida',
        points: 124,
        performance: 98.34,
    },
    {
        id: 7,
        idUser: 93,
        name: 'Rodrigo Santos',
        points: 124,
        performance: 98.34,
    },
    {
        id: 8,
        idUser: 75,
        name: 'Maria Augusta',
        points: 124,
        performance: 98.34,
    },
    {
        id: 9,
        idUser: 3,
        name: 'Samuel Ferreira',
        points: 124,
        performance: 98.34,
    },
    {
        id: 10,
        idUser: 7,
        name: 'Roberto Sales',
        points: 124,
        performance: 98.34,
    },
];

const DataGraphSpecifics = [
    [
        { x: 1, y: 0.45 },
        { x: 2, y: 0.75 },
        { x: 3, y: 0.45 },
    ],
    [
        { x: 1, y: 0.55 },
        { x: 2, y: 0.85 },
        { x: 3, y: 0.55 },
    ],
];

const stylesAxis = {
    axis: { stroke: colors.basic(window.localStorage.getItem('theme') || 'light').graySmall, strokeWidth: 1 },
    ticks: { stroke: 'transparent', size: 0 },
    tickLabels: { fontSize: 20, padding: 5, fill: colors.basic(window.localStorage.getItem('theme') || 'light').grayLight },
};

const allLabel = [
    {
        id: 'ANO',
        label: 'ANO',
    },
    {
        id: 'MÊS',
        label: 'MÊS',
    },
    {
        id: 'SEMANA',
        label: 'SEMANA',
    },
    {
        id: 'HOJE',
        label: 'HOJE',
    },
];

const optionsOrderTime = [
    {
        id: 'TODAY',
        label: 'Hoje',
    },
    {
        id: 'LAST_7_DAYS',
        label: 'Últimos 7 dias',
    },
    {
        id: 'LAST_15_DAYS',
        label: 'Últimos 15 dias',
    },
    {
        id: 'LAST_30_DAYS',
        label: 'Últimos 30 dias',
    },
    {
        id: 'LAST_3_MONTHS',
        label: 'Últimos 3 meses',
    },
    {
        id: 'LAST_6_MONTHS',
        label: 'Últimos 6 meses',
    },
    {
        id: 'YEAR',
        label: 'Último ano',
    },
    {
        id: 'ALL',
        label: 'Todo o Período',
    },
    {
        id: 'CUSTOM',
        label: 'Customizado',
    },
];

const modalPickerStyles = {
    overlay: {
        backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        border: 'none',
        backgroundColor: 'transparent',
        padding: '0px',
        height: '50vh',
        maxWidth: '350px',
        top: '25vh',
        // left: '35vw',
        margin: '0 auto',
        borderRadius: '10px',
    },
};

export default {
    modalPickerStyles,
    DataGraphStrongAndWeak,
    DomainGraphStrongAndWeak,
    allTabs,
    allCards,
    allDates,
    modalDrawerStyles,
    allCategories,
    topTenRaking,
    DataGraphSpecifics,
    stylesAxis,
    allLabel,
    optionsOrderTime,
};
