import React from 'react';

// js
import {
    CheckBoxSubject,
    CheckBox,
} from '../popUpStyles';
import {
    ContainerCategory,
    NameCategory,
    AdjustInfo,
    ContainerQtd,
    IconDropDawnCategory,
    ContainerSubCategory,
    SingleContainerCategory,
} from '../../../pages/Statistics/statisticsStyles';
import colors from '../../../styles/colors';
import { arrowDropDawnGray } from '../../../assets';

// interfaces
interface Props {
    allData: any,
    selectDropdawn: any,
    setSelectDropdawn: (v: any) => void,
    selectFilter: Array<number>,
    allSubjectsSelect: Array<any>,
    setSelectFilter: (v: Array<number>) => void,
    setAllSubjectsSelect: (v: Array<any>) => void,
    typeQuestionsSelected: Array<string>,
    setTypeQuestionsSelected: (v: Array<string>) => void,
}

const FiltersAndSubjectsComponent: React.FC<Props> = ({
    allData,
    selectDropdawn,
    setSelectDropdawn,
    selectFilter,
    allSubjectsSelect,
    setSelectFilter,
    setAllSubjectsSelect,
    typeQuestionsSelected,
    setTypeQuestionsSelected,
}) => (
    <>
        {allData.map((v: any) => {
            // eslint-disable-next-line max-len
            const qtd = !v?.idGlobal
                ? allSubjectsSelect?.filter((subject) => subject?.id_filter === v.id)?.length
                || v?.subjects?.filter((subject: any) => allSubjectsSelect.includes(subject?.id))
                    ?.length : null;

            /* eslint-disable no-nested-ternary */
            /* eslint-disable max-len */
            const allIds = !v?.idGlobal ? (allSubjectsSelect[0]?.id ? allSubjectsSelect.map((s) => s?.id) : allSubjectsSelect) : null;
            /* eslint-enable no-nested-ternary */
            /* eslint-enable max-len */

            const OpenDropDawnSubject = () => {
                if (v?.idGlobal) {
                    if (typeQuestionsSelected.includes(v.id)) {
                        const RemoveIdFilter = typeQuestionsSelected.filter((filter) => filter
                        !== v.id);
                        setTypeQuestionsSelected(RemoveIdFilter);
                    } else {
                        setTypeQuestionsSelected([...typeQuestionsSelected, v.id]);
                    }
                } else if (!v?.idGlobal) {
                    if (selectFilter?.includes(v.id)) {
                        const RemoveIdFilter = selectFilter.filter((filter) => filter !== v.id);
                        setSelectFilter(RemoveIdFilter);
                        const RemoveIdSubject = allSubjectsSelect[0]?.id
                            ? allSubjectsSelect.filter((subject) => subject?.id_filter !== v.id)
                            /* eslint-disable max-len */
                            : allSubjectsSelect.filter((subject) => !v.subjects.map((e: any) => e.id).includes(subject));
                            /* eslint-disable max-len */
                        setAllSubjectsSelect(RemoveIdSubject);
                    } else {
                        setSelectFilter([...selectFilter, v.id]);
                        setAllSubjectsSelect([
                            ...allSubjectsSelect,
                            ...(v?.subjects?.map((s: any) => s.id) || []),
                        ]);
                    }
                }
            };
            console.log(v);
            return (
                <React.Fragment key={v.id}>
                    {v?.subjects?.length && !v?.idGlobal ? (
                        <>
                            <ContainerCategory
                                isQuestions={v.isQuestion}
                                disabled={!v.isQuestion && !v.table}
                                onClick={() => {
                                    if (selectDropdawn?.includes(v.id)) {
                                        const RemoveIdFilter = selectDropdawn.filter(
                                            (filter: any) => filter !== v.id,
                                        );
                                        setSelectDropdawn(RemoveIdFilter);
                                    } else {
                                        setSelectDropdawn([...selectDropdawn, v.id]);
                                    }
                                }}
                            >
                                <NameCategory>{v.name}</NameCategory>
                                <AdjustInfo>
                                    <ContainerQtd>
                                        <NameCategory
                                            color={colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse}
                                            fontWeight="bold"
                                        >
                                            {qtd}
                                        </NameCategory>
                                    </ContainerQtd>
                                    <IconDropDawnCategory
                                        src={arrowDropDawnGray}
                                        style={{
                                            transform: selectDropdawn?.includes(v.id)
                                                ? 'rotate(180deg)'
                                                : 'rotate(0deg)',
                                        }}
                                    />
                                    <CheckBoxSubject
                                        style={{ marginLeft: 10 }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            OpenDropDawnSubject();
                                        }}
                                    >
                                        {selectFilter.includes(v.id) ? <CheckBox /> : null}
                                    </CheckBoxSubject>
                                </AdjustInfo>
                            </ContainerCategory>
                        </>
                    ) : (
                        <>
                            <SingleContainerCategory
                                isQuestions={v.isQuestion}
                                disabled={!v.isQuestion && !v.idGlobal}
                                onClick={() => {
                                    OpenDropDawnSubject();
                                }}
                            >
                                <NameCategory>
                                    {v.name}
                                </NameCategory>
                                <CheckBoxSubject
                                    style={{ marginLeft: 10 }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        OpenDropDawnSubject();
                                    }}
                                >
                                    {selectFilter.includes(v.id) || typeQuestionsSelected.includes(v.id) ? <CheckBox /> : null}
                                </CheckBoxSubject>
                            </SingleContainerCategory>
                        </>
                    )}
                    {selectDropdawn.includes(v.id) && !v?.idGlobal && allIds ? (
                        v?.subjects?.map((value: any) => {
                            const AddSubject = () => {
                                if (allIds?.includes(value.id)) {
                                    const RemoveId = allSubjectsSelect[0]?.id
                                        ? allSubjectsSelect.filter(
                                            (subject: any) => subject?.id !== value.id,
                                        )
                                        : allSubjectsSelect?.filter((e) => e !== value.id);
                                    setAllSubjectsSelect(RemoveId);
                                    if (!RemoveId.length) {
                                        const RemoveIdFilter = selectFilter.filter(
                                            (filter) => filter !== v.id,
                                        );
                                        setSelectFilter(RemoveIdFilter);
                                    }
                                } else {
                                    setAllSubjectsSelect(
                                        allSubjectsSelect[0]?.id
                                            ? [...allSubjectsSelect, value]
                                            : [...allSubjectsSelect, value?.id],
                                    );
                                    const verifyExistFilter = selectFilter.filter(
                                        (f: any) => f === v.id,
                                    );
                                    if (!verifyExistFilter.length) {
                                        setSelectFilter([...selectFilter, v.id]);
                                    }
                                }
                            };
                            return (
                                <ContainerSubCategory key={value.id} onClick={AddSubject}>
                                    <NameCategory>{value.name}</NameCategory>
                                    <AdjustInfo>
                                        <CheckBoxSubject onClick={(event) => {
                                            event.stopPropagation();
                                            AddSubject();
                                        }}
                                        >
                                            {allIds.includes(value.id) ? <CheckBox /> : null}
                                        </CheckBoxSubject>
                                    </AdjustInfo>
                                </ContainerSubCategory>
                            );
                        })
                    ) : null}
                </React.Fragment>
            );
        })}
    </>
);

export default FiltersAndSubjectsComponent;
