// libraries
import { useEffect, useState } from 'react';

// interfaces
interface Props {
    valueStart: number,
    valueEnd: number,
    children: any,
}

const ProgressProvider = ({ valueStart, valueEnd, children }: Props): any => {
    // states
    const [value, setValue] = useState(valueStart);

    // useEffect
    useEffect(() => {
        setValue(valueEnd);
    }, [valueEnd]);

    return children(value);
};

export default ProgressProvider;
