/* eslint-disable no-alert */
/* eslint-disable max-len */
// libraries
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// js
import auth from '../../services/auth';
import colors from '../../styles/colors';
import {
    Container,
    Home,
    ContainerBox,
    LogoHeader,
    ContainerBody,
    Title,
    Subtitle,
    LogoMain,
    ImgBackgroundLinearGradient,
} from './deleteAccountStyles';
import {
    logoFullSizeColor,
    backgroundHeaderAuthFullSize,
    backgroundLinearGradientFullSize,
    logoZero1App,
    logoFullSizeColorDark,
} from '../../assets';

// components
import ButtonComponent from '../../components/Button/ButtonComponent';
import InputComponent from '../../components/Input/InputComponent';

const DeleteAccountPage: React.FC = () => {
    // states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationSecureText, setVerificationSecureText] = useState(true);
    const opacityBox = 1;

    // methods
    const Signin = async () => {
        const ActiveSignin = await auth.Login(email, password);
        if (ActiveSignin?.status === 200) {
            const removeAccount: any = await auth.DeleteUsers(
                ActiveSignin?.result?.user?.id,
                ActiveSignin?.result?.token,
            );
            if (removeAccount?.status === 200) {
                setEmail('');
                setPassword('');
                alert(removeAccount?.message || 'Conta excluída!');
            } else {
                alert(removeAccount?.message || 'Ocorreu um erro ao excluir a conta!');
            }
        }
    };

    // main
    return (
        <Container>
            <Home>
                <ContainerBody zoomHeight={opacityBox !== 1} height={isMobile ? '20vh' : '50vh'} background={backgroundHeaderAuthFullSize}>
                    <ImgBackgroundLinearGradient zoomHeight={opacityBox !== 1} background={backgroundLinearGradientFullSize}>
                        {opacityBox !== 1 ? (
                            <LogoMain src={logoZero1App} />
                        ) : null}
                    </ImgBackgroundLinearGradient>
                </ContainerBody>
                <ContainerBox opacity={opacityBox}>
                    <LogoHeader src={window.localStorage.getItem('theme') === 'light' ? logoFullSizeColor : logoFullSizeColorDark} />
                    <Title>Exclusão de conta</Title>
                    <Subtitle>Preencha as informações abaixo para excluir permanentemente sua conta.</Subtitle>
                    <InputComponent placeholder="Seu e-mail" type="email" value={email} setValue={setEmail} />
                    <InputComponent placeholder="Sua senha" type={verificationSecureText ? 'password' : 'email'} setVerificationSecureText={setVerificationSecureText} verificationSecureText={verificationSecureText} value={password} setValue={setPassword} />
                    <ButtonComponent colorButton={colors.basic(window.localStorage.getItem('theme') || 'light').red} label="EXCLUIR CONTA" onClick={Signin} />
                </ContainerBox>
            </Home>
        </Container>
    );
};

export default DeleteAccountPage;
