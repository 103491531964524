/* eslint-disable no-useless-escape */
/* eslint-disable no-alert */
/* eslint-disable max-len */
// libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';

// js
import {
    Container,
    Home,
    ContainerBody,
    ContainerFilters,
    // ContainerCalendar,
    // TitleCalendar,
    ContainerOptionsDrawer,
    ContainerAdjust,
    ContainerHeader,
    ContainerTopHeader,
    Close,
    ImgClose,
    ContainerAdjustOptions,
    ContainerIndex,
    // ContainerReportProblem,
    // ImgAlert,
    // ImgOption,
    ContainerTimeOut,
    TitleQuestion,
    StyleArchive,
    ButtonResolution,
    IndexCircle,
    VideoResolution,
    ImageReport,
    TitleReport,
    InputReport,
    ContainerBodyTwo,
    ContainerButtons,
    ButtonNextQuestion,
    ImgNext,
    GoBackStep,
    StyleRenderPDF,
    OpenOption,
    ImgUpperOrLower,
    AdjustFlex,
    AdjustInfoTitle,
    AdjustInfoTime,
    TitleTime,
    ContainerClose,
    ContainerAswerFullSize,
    StyleArchiveFullSize,
    TimeText,
    ContainerButtonsResolution,
} from './flowQuestionsStyles';
import { ContainerLoader, IconRefresh } from '../../components/PopUp/popUpStyles';
import constants from '../../utils/consts';
import { RootState } from '../../store';
import { updateUser } from '../../store/modules/user/actions';
import {
    // alertRed,
    alertTriangule,
    // arrowGoBackPurple,
    arrowLeft,
    arrowLeftBlack,
    arrowRight,
    closeGray,
    // arrowRightWhite,
    // closeWhite,
    deslikeCommentContent,
    deslikeCommentOutline,
    iconRefreshPageWhite,
    likeOutlineBlue,
    likePurple,
    messageSquare,
    // send,
    // telegram,
    warning,
} from '../../assets';
import flowQuestionsFunctions from './flowQuestionsFunctions';
import questionsApi from '../../services/questionsApi';
import tests from '../../services/tests';
import simulate from '../../services/simulate';
import colors from '../../styles/colors';
import questions from '../../functions/questions';
import ToNavigation from '../../routes/navigation';

// components
import HeaderComponent from '../../components/Header/HeaderComponent';
import DrawerComponent from '../../components/Drawer/DrawerComponent';
// import OneDateSimulateComponent from '../Timeline/components/OneDateSimulateComponent';
import PopUpResultQuestion from './components/PopUpResultQuestion';
import reportsApi from '../../services/reportsApi';
import tournament from '../../services/tournament';
import ModalComments from './components/ModalComments/ModalComments';
import evaluations from '../../services/evaluations';
import statistics from '../../services/statistics';

const FlowQuestionsPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const questionsCompleted = infoUser?.allInfoQuestion?.allQuestionsCompleted || [];
    const modalPopUp = {
        overlay: {
            backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
            zIndex: 10,
        },
        content: {
            alignItems: 'center',
            border: 'none',
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            flex: 1,
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
        },
    };

    // states
    // const [search, setSearch] = useState('');
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const [show, setShow] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [idScreen, setIdScreen] = useState(1);
    const [timeOutActual, setTimeOutActual] = useState(-1);
    const [itemActual, setItemActual] = useState<any>(null);
    const [chosenOption, setChosenOption] = useState('');
    const [timeActual, setTimeActual] = useState(new Date());
    const [indexActual, setIndexActual] = useState(0);
    const [openResultQuestion, setOpenResultQuestion] = useState(false);
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [descriptionReport, setDescriptionReport] = useState('');
    const [visibleComment, setVisibleComment] = useState(false);
    const [isEvaluation, setIsEvaluation] = useState('');
    const [statisticsUser, setStatisticsUser] = useState<any>(null);
    const [showPdfFeedback, setShowPdfFeedback] = useState(false);
    const [modalDocument, setModalDocument] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (itemActual) {
            questions.mountScreen();
        }
    }, [itemActual, idScreen]);

    // methods
    const getStatisticsUser = async () => {
        const response = await statistics.GetTimeQuestion(itemActual?.id, infoUser?.id, infoUser?.token);
        if (response?.status === 200) {
            setStatisticsUser(response?.result);
        }
    };

    useEffect(() => {
        if (itemActual?.id) {
            getStatisticsUser();
        }
    }, [itemActual]);

    const getEvaluations = async () => {
        const response = await evaluations.GetEvaluations(itemActual?.id, infoUser?.id, infoUser?.token);
        if (response?.status === 200) {
            const isLike = response?.result?.is_like;
            // eslint-disable-next-line no-nested-ternary
            const validationEvaluation = isLike ? 'like' : (isLike === false ? 'deslike' : '');
            setIsEvaluation(validationEvaluation);
        }
    };

    useEffect(() => {
        if (itemActual?.id) {
            getEvaluations();
        }
    }, [itemActual]);

    function ValidationMarginTimeline() {
        if (show) {
            return '310px';
        }
        if (!show) {
            return '160px';
        }
    }

    function ValidationMarginTimelineLowLarge() {
        if (show) {
            return '215px';
        }
        if (!show) {
            return '105px';
        }
    }

    const onChangeDescriptionReports = (txt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = txt.target;
        setDescriptionReport(value);
    };

    const onDrawer = () => {
        setIsOpen(true);
    };

    const OpenReport = () => {
        setIdScreen(3);
    };

    // const GoToTelegram = () => {
    //     window.open('https://t.me/joinchat/LhoxCYvu9AFjMDlh');
    // };

    const CloseScreen = () => {
        if ([2, 3].includes(idScreen)) {
            setIdScreen(1);
        } else {
            dispatch(updateUser({
                ...infoUser,
                allInfoQuestion: {
                    allSubjectsSelect: infoUser?.allInfoQuestion?.allSubjectsSelect,
                    selectFilter: infoUser?.allInfoQuestion?.selectFilter,
                    idPopUp: infoUser?.allInfoQuestion?.idPopUp,
                    typeSimulate: infoUser?.allInfoQuestion?.typeSimulate,
                    goBack: true,
                },
            }));
            setPathRoute(infoUser?.allInfoQuestion?.backScreen);
            setRedirect(true);
        }
    };

    const NextQuestion = async () => {
        setLoading(true);
        if (indexActual + 1 === infoUser?.allInfoQuestion?.allQuestions?.length) {
            if (infoUser?.allInfoQuestion?.idType === 'Provas' && infoUser?.allInfoQuestion?.idSelected) {
                await tests.CheckOneTests(infoUser?.allInfoQuestion?.idSelected, infoUser.token);
            }
            if (infoUser?.allInfoQuestion?.idType === 'Simulados' && infoUser?.allInfoQuestion?.idSelected) {
                await simulate.CheckOneSimulates(infoUser?.allInfoQuestion?.idSelected, infoUser.id, infoUser.token);
            }
            if (infoUser?.allInfoQuestion?.idType === 'Desafios' && infoUser?.allInfoQuestion?.idSelected) {
                await tournament.CheckOneTournament(infoUser?.allInfoQuestion?.idSelected, infoUser.id, infoUser.token);
            }
            dispatch(updateUser({
                ...infoUser,
                allInfoQuestion: null,
            }));
            setPathRoute(infoUser?.allInfoQuestion?.backScreen);
            setRedirect(true);
        } else {
            const additional = indexActual + 1;
            setIndexActual(additional);
            setTimeActual(new Date());
            setChosenOption('');
            if ([2, 3].includes(idScreen)) {
                CloseScreen();
            }
        }
        setLoading(false);
    };

    const ActionHitQuestion = async () => {
        setLoading(true);
        const result = flowQuestionsFunctions.ValidationTimeOutQuestion(timeActual);
        await questionsApi.CheckedQuestion(
            [itemActual?.id],
            [],
            result,
            infoUser?.id,
            infoUser?.token,
            infoUser?.allInfoQuestion?.idType === 'Desafios' ? infoUser?.allInfoQuestion?.idSelected : null,
            infoUser?.allInfoQuestion?.idType === 'Cadernos' ? infoUser?.allInfoQuestion?.idSelected : null,
        );
        if (!infoUser?.hit_questions?.includes(itemActual?.id)) {
            const newInfoUser = {
                ...infoUser,
                hit_questions: infoUser?.hit_questions?.length ? [...infoUser?.hit_questions, itemActual?.id] : [itemActual?.id],
            };
            dispatch(updateUser(newInfoUser));
        }
        if (!questionsCompleted.includes(itemActual?.id)) {
            await questionsCompleted.push(itemActual?.id);
        }
        if (infoUser?.allInfoQuestion?.idType === 'Cadernos' && !questionsCompleted.includes(itemActual?.id)) {
            await questionsApi.EditQuestionsCompleted(
                [...questionsCompleted, itemActual?.id],
                infoUser?.allInfoQuestion?.idSelected,
                infoUser?.allInfoQuestion?.suggested || false,
                infoUser.id,
                infoUser.token,
            );
        }
        setLoading(false);
        if (itemActual?.options?.length > 0 || (itemActual?.option_right_or_wrong === 0 || itemActual?.option_right_or_wrong === 1)) {
            setOpenResultQuestion(true);
        } else {
            NextQuestion();
        }
    };

    const ActionMissQuestion = async () => {
        setLoading(true);
        const result = flowQuestionsFunctions.ValidationTimeOutQuestion(timeActual);
        await questionsApi.CheckedQuestion(
            [],
            [itemActual?.id],
            result,
            infoUser?.id,
            infoUser?.token,
            infoUser?.allInfoQuestion?.idType === 'Desafios' ? infoUser?.allInfoQuestion?.idSelected : null,
            infoUser?.allInfoQuestion?.idType === 'Cadernos' ? infoUser?.allInfoQuestion?.idSelected : null,
        );
        if (!infoUser?.miss_questions?.includes(itemActual?.id)) {
            const newInfoUser = {
                ...infoUser,
                miss_questions: infoUser?.miss_questions?.length ? [...infoUser?.miss_questions, itemActual?.id] : [itemActual?.id],
            };
            dispatch(updateUser(newInfoUser));
        }
        if (!questionsCompleted.includes(itemActual?.id)) {
            await questionsCompleted.push(itemActual?.id);
        }
        if (infoUser?.allInfoQuestion?.idType === 'Cadernos' && !questionsCompleted.includes(itemActual?.id)) {
            await questionsApi.EditQuestionsCompleted(
                [...questionsCompleted, itemActual?.id],
                infoUser?.allInfoQuestion?.idSelected,
                infoUser?.allInfoQuestion?.suggested || false,
                infoUser.id,
                infoUser.token,
            );
        }
        setLoading(false);
        if (itemActual?.options?.length > 0 || (itemActual?.option_right_or_wrong === 0 || itemActual?.option_right_or_wrong === 1)) {
            setOpenResultQuestion(true);
        } else {
            NextQuestion();
        }
    };

    const GoToResolution = () => {
        setIdScreen(2);
    };

    const ActionHitQuestionClose = () => {
        setLoading(true);
        if (chosenOption !== '' && chosenOption === itemActual?.feedback) {
            ActionHitQuestion();
        } else if (chosenOption !== '' && chosenOption !== itemActual?.feedback) {
            ActionMissQuestion();
        } else {
            // eslint-disable-next-line no-alert
            alert('Selecione uma opção');
            setLoading(false);
        }
    };

    const GetArchiveQuestion = async () => {
        setLoading(true);
        const GetArchivesForQuestion = await questionsApi.GetOnlyArchiveQuestion(infoUser?.allInfoQuestion?.allQuestions[indexActual]?.id, infoUser?.token);
        if (GetArchivesForQuestion?.status === 200) {
            const newItemActual = {
                ...GetArchivesForQuestion?.result,
                statement: GetArchivesForQuestion?.result?.statement,
                answer: GetArchivesForQuestion?.result?.answer,
                options: GetArchivesForQuestion?.result?.options,
            };
            setItemActual(newItemActual);
        }
        setLoading(false);
    };

    const BackQuestion = () => {
        setIndexActual(indexActual - 1);
        setTimeActual(new Date());
        setChosenOption('');
    };

    const renderAlertExpired = () => {
        if (infoUser?.allInfoQuestion?.idType === 'Provas') {
            return 'da prova.';
        }
        if (infoUser?.allInfoQuestion?.idType === 'Desafios Diários') {
            return 'do desafio.';
        }
        return 'do simulado.';
    };

    // useEffect
    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        dispatch(updateUser({
            ...infoUser,
            allInfoQuestion: {
                ...infoUser?.allInfoQuestion,
                focusFlowQuestions: true,
            },
        }));
    }, []);

    useEffect(() => {
        if (timeOutActual >= 1) {
            setTimeout(() => {
                setTimeOutActual(timeOutActual - 1);
            }, 1000);
        } else if (timeOutActual === 0) {
            const descriptionExpired = renderAlertExpired();
            // eslint-disable-next-line no-alert
            alert(`Tempo expirado ${descriptionExpired}`);
            flowQuestionsFunctions.handleTimerFinish(CloseScreen, infoUser?.allInfoQuestion?.idSelected, infoUser, infoUser?.allInfoQuestion?.idType);
        } else if (infoUser?.allInfoQuestion?.timeOutSimulateOrTest && timeOutActual === -1) {
            setTimeOutActual(infoUser?.allInfoQuestion?.timeOutSimulateOrTest);
        }
    }, [timeOutActual]);

    useEffect(() => {
        if (infoUser?.allInfoQuestion?.allQuestions?.length > 0) {
            GetArchiveQuestion();
        }
    }, [indexActual, infoUser?.allInfoQuestion?.allQuestions]);

    // renders
    // const RenderAllDates = () => (
    //     constants.allDates.map((v) => (
    //         <OneDateSimulateComponent item={v} />
    //     ))
    // );

    const RenderIndex = () => (
        infoUser?.allInfoQuestion?.allQuestions?.map((v: any, i: any) => {
            const HitQuestionActual = infoUser?.hit_questions?.length > 0 ? infoUser?.hit_questions.includes(v?.id) : false;
            const MissQuestionActual = infoUser?.miss_questions?.length > 0 ? infoUser?.miss_questions.includes(v?.id) : false;
            const validationHit = HitQuestionActual ? colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong : null;
            const validationMiss = MissQuestionActual ? colors.theme(window.localStorage.getItem('theme') || 'light').pointRadialWeak : null;
            const TradeIndex = () => setIndexActual(i);
            return (
                <IndexCircle
                    onClick={TradeIndex}
                    focus={indexActual === i}
                    key={String(v.id)}
                    newColor={validationHit || validationMiss || colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault}
                >
                    {i + 1}
                </IndexCircle>
            );
        })
    );

    const SendReport = async () => {
        const ActionSendReports = await reportsApi.CreateNewReports(
            descriptionReport,
            itemActual?.id,
            infoUser?.id,
            infoUser?.token,
        );
        if (ActionSendReports?.status === 201) {
            setDescriptionReport('');
            // eslint-disable-next-line no-alert
            alert(ActionSendReports?.message);
        }
    };

    const CloseModal = () => {
        setIsOpen(false);
    };

    const TradeVisibleComment = () => {
        setVisibleComment(!visibleComment);
    };

    const sendDeslike = async () => {
        const response = await evaluations.SendEvaluation(itemActual.id, infoUser.id, isEvaluation !== 'deslike' ? 'deslike' : 'remove', infoUser.token);
        if (response?.status === 200) {
            alert(response?.message);
        } else {
            alert(response?.message || 'Ocorreu um problema ao avaliar essa resolução!');
        }
        getEvaluations();
    };

    const sendLike = async () => {
        const response = await evaluations.SendEvaluation(itemActual.id, infoUser.id, isEvaluation !== 'like' ? 'like' : 'remove', infoUser.token);
        if (response?.status === 200) {
            alert(response?.message);
        } else {
            alert(response?.message || 'Ocorreu um problema ao avaliar essa resolução!');
        }
        getEvaluations();
    };

    const renderTime = (timeSeconds: number) => {
        const convertNumber = Number(timeSeconds);
        if (convertNumber >= 60) {
            const getMinutes = convertNumber / 60;
            const getMinutesMinimum = Math.floor(getMinutes);
            const getSeconds = convertNumber - (getMinutesMinimum * 60);
            return `${getMinutesMinimum}min${getSeconds ? `${getSeconds}s` : ''}`;
        }
        return `${convertNumber}s`;
    };

    const OpenAswer = () => {
        setShowPdfFeedback(!showPdfFeedback);
    };

    const OpenDocument = () => {
        setModalDocument(!modalDocument);
    };

    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <HeaderComponent
                    // search={search}
                    // setSearch={setSearch}
                    // labelSearch="Concursos"
                    idScreen="FlowQuestions"
                    widthActual={widthActual}
                    onDrawer={onDrawer}
                />
                <ContainerBody>
                    {widthActual >= 800 ? (
                        <DrawerComponent
                            show={show}
                            setShow={setShow}
                        />
                    ) : null}
                    <ContainerFilters
                        existStatistics
                        marginLeft={ValidationMarginTimeline()}
                        marginLeftLowLarge={ValidationMarginTimelineLowLarge()}
                    >
                        <ContainerAdjust>
                            {idScreen !== 3 && (
                                <ContainerHeader>
                                    <ContainerTopHeader justifyContent={idScreen === 2 ? 'flex-start' : 'flex-end'}>
                                        {idScreen === 1 && infoUser?.allInfoQuestion?.pdf_feedback ? (
                                            <ButtonResolution
                                                onClick={OpenAswer}
                                                backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault}
                                                color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                                style={{ marginRight: 40 }}
                                            >
                                                {showPdfFeedback ? 'Ver simulado' : 'Ver gabarito'}
                                            </ButtonResolution>
                                        ) : null}
                                        {idScreen === 2 ? (
                                            <>
                                                <Close onClick={CloseScreen}>
                                                    <GoBackStep src={arrowLeftBlack} />
                                                </Close>
                                                <ButtonResolution
                                                    onClick={OpenDocument}
                                                    backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault}
                                                    color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                                    style={{ marginLeft: 40 }}
                                                >
                                                    Expandir resolução
                                                </ButtonResolution>
                                            </>
                                        ) : null}
                                    </ContainerTopHeader>
                                    {idScreen === 1 && !infoUser?.allInfoQuestion?.pdf ? (
                                        <ContainerAdjustOptions>
                                            <ContainerIndex width="auto" overflowX="auto">
                                                {/* <Close onClick={CloseScreen}>
                                                    <GoBackStep src={arrowLeftBlack} />
                                                </Close> */}
                                                {RenderIndex()}
                                            </ContainerIndex>
                                        </ContainerAdjustOptions>
                                    ) : null}
                                </ContainerHeader>
                            )}
                            {!itemActual || loading ? (
                                <ContainerLoader>
                                    <Loader
                                        visible={!itemActual || loading}
                                        type="TailSpin"
                                        color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                        height={100}
                                        width={100}
                                    />
                                </ContainerLoader>
                            ) : (
                                <ContainerBodyTwo>
                                    {idScreen === 1 ? (
                                        <ContainerAdjust>
                                            {infoUser?.allInfoQuestion?.pdf ? (
                                                <StyleRenderPDF src={`${showPdfFeedback ? infoUser?.allInfoQuestion?.pdf_feedback : infoUser?.allInfoQuestion?.pdf}#toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" />
                                            ) : (
                                                <>
                                                    {timeOutActual !== -1 ? (
                                                        <ContainerTimeOut>
                                                            {flowQuestionsFunctions.RenderTimer(timeOutActual)}
                                                        </ContainerTimeOut>
                                                    ) : null}
                                                    <AdjustInfoTitle>
                                                        <TitleQuestion width="60%">{`${itemActual?.id ? `${itemActual.id} -` : ''} Enunciado ${flowQuestionsFunctions.RenderLevel(itemActual?.level)}`}</TitleQuestion>
                                                        <AdjustInfoTime>
                                                            <TitleTime>
                                                                Seu tempo médio:
                                                                {' '}
                                                                <TimeText>
                                                                    {renderTime(statisticsUser?.average_time || 0)}
                                                                </TimeText>
                                                            </TitleTime>
                                                            <TitleTime>
                                                                Tempo médio dos outros alunos:
                                                                {' '}
                                                                <TimeText>
                                                                    {renderTime(statisticsUser?.total_average_time || 0)}
                                                                </TimeText>
                                                            </TitleTime>
                                                        </AdjustInfoTime>
                                                    </AdjustInfoTitle>
                                                    <TitleQuestion>{itemActual?.title}</TitleQuestion>
                                                    <StyleArchive dangerouslySetInnerHTML={{ __html: itemActual?.statement }} />
                                                    {itemActual?.options?.length > 0 && itemActual?.type !== 'discursive' ? (
                                                        <>
                                                            <ContainerIndex width="100%" justifyContent="space-evenly" margin="10px 0px 10px 0px" flexWrap="wrap">
                                                                {flowQuestionsFunctions.RenderAlternatives(
                                                                    itemActual?.options,
                                                                    itemActual?.feedback,
                                                                    chosenOption,
                                                                    setChosenOption,
                                                                    infoUser,
                                                                    itemActual,
                                                                )}
                                                            </ContainerIndex>
                                                            <ButtonResolution onClick={ActionHitQuestionClose} style={{ margin: '10px auto 0 auto', background: 'rgba(67, 202, 67, 0.8)' }}>
                                                                {loading ? <IconRefresh animation src={iconRefreshPageWhite} /> : 'Confirmar'}
                                                            </ButtonResolution>
                                                        </>
                                                    ) : null}
                                                    {itemActual?.option_right_or_wrong === 0 || itemActual?.option_right_or_wrong === 1 ? (
                                                        <ContainerIndex width="100%" justifyContent="space-evenly" margin="10px 0px 0px 0px">
                                                            <ButtonResolution
                                                                style={{
                                                                    background: 'rgba(234, 34, 34, 0.8)',
                                                                }}
                                                                onClick={() => {
                                                                    setChosenOption('0');
                                                                    if (itemActual?.option_right_or_wrong === 0) {
                                                                        ActionHitQuestion();
                                                                    } else {
                                                                        ActionMissQuestion();
                                                                    }
                                                                }}
                                                            >
                                                                Errado
                                                            </ButtonResolution>
                                                            <ButtonResolution
                                                                style={{
                                                                    background: 'rgba(67, 202, 67, 0.8)',
                                                                }}
                                                                onClick={() => {
                                                                    setChosenOption('1');
                                                                    if (itemActual?.option_right_or_wrong === 1) {
                                                                        ActionHitQuestion();
                                                                    } else {
                                                                        ActionMissQuestion();
                                                                    }
                                                                }}
                                                            >
                                                                Certo
                                                            </ButtonResolution>
                                                        </ContainerIndex>
                                                    ) : null}
                                                </>
                                            )}
                                        </ContainerAdjust>
                                    ) : null}
                                    {idScreen === 2 ? (
                                        <ContainerAdjust>
                                            <AdjustFlex>
                                                <ImgUpperOrLower src={isEvaluation !== 'deslike' ? deslikeCommentOutline : deslikeCommentContent} onClick={sendDeslike} />
                                                <ImgUpperOrLower src={isEvaluation !== 'like' ? likeOutlineBlue : likePurple} onClick={sendLike} />
                                            </AdjustFlex>
                                            <StyleArchive dangerouslySetInnerHTML={{ __html: itemActual?.answer }} />
                                            {itemActual?.video ? (
                                                <VideoResolution src={itemActual?.video} width="100%" height="250" controls />
                                            ) : null}
                                            {!itemActual?.options?.length && itemActual?.option_right_or_wrong !== 0 && itemActual?.option_right_or_wrong !== 1 ? (
                                                <ContainerIndex width="100%" justifyContent="space-evenly" margin="10px 0px 0px 0px">
                                                    <ButtonResolution
                                                        onClick={ActionMissQuestion}
                                                    >
                                                        Errei
                                                    </ButtonResolution>
                                                    <ButtonResolution
                                                        onClick={ActionHitQuestion}
                                                    >
                                                        Acertei
                                                    </ButtonResolution>
                                                </ContainerIndex>
                                            ) : null}
                                        </ContainerAdjust>
                                    ) : null}
                                    {idScreen === 3 ? (
                                        <ContainerIndex
                                            flexDirection="column"
                                            width="100%"
                                        >
                                            <ImageReport src={warning} />
                                            <TitleReport>Reporte um problema</TitleReport>
                                            <InputReport placeholder="Digite a sua mensagem" value={descriptionReport} onChange={onChangeDescriptionReports} />
                                            <ContainerButtons style={{ justifyContent: 'space-evenly' }}>
                                                <ButtonResolution onClick={SendReport}>Enviar</ButtonResolution>
                                                <ButtonResolution backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').pointRadialWeak} onClick={() => setIdScreen(1)}>Voltar</ButtonResolution>
                                            </ContainerButtons>
                                        </ContainerIndex>
                                    ) : null}
                                </ContainerBodyTwo>
                            )}
                        </ContainerAdjust>
                        {idScreen === 1 && !infoUser?.allInfoQuestion?.pdf ? (
                            <>
                                <ContainerButtons>
                                    <ContainerButtonsResolution>
                                        {(infoUser?.hit_questions || []).includes(itemActual?.id) || (infoUser?.miss_questions || []).includes(itemActual?.id) || itemActual?.type === 'discursive' ? (
                                            <ButtonResolution onClick={GoToResolution}>Ver Resolução</ButtonResolution>
                                        ) : null}
                                        <ButtonResolution
                                            onClick={OpenDocument}
                                            style={{
                                                marginTop: '',
                                            }}
                                        >
                                            Expandir enunciado
                                        </ButtonResolution>
                                    </ContainerButtonsResolution>
                                    <ContainerIndex width="40%" justifyContent={indexActual ? 'center' : 'center'}>
                                        {indexActual ? (
                                            <ButtonNextQuestion
                                                onClick={BackQuestion}
                                                backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').white}
                                                border={`1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}`}
                                                color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                                fontWeight="500"
                                            >
                                                <ImgNext src={arrowLeft} margin="0px 12px 0px 0px" />
                                                Exercício anterior
                                            </ButtonNextQuestion>
                                        ) : null}
                                        <ButtonNextQuestion onClick={NextQuestion}>
                                            Próximo exercício
                                            <ImgNext src={arrowRight} margin="0px 0px 0px 12px" />
                                        </ButtonNextQuestion>
                                    </ContainerIndex>
                                    <ContainerIndex width="auto" justifyContent={indexActual ? 'space-between' : 'flex-end'}>
                                        <OpenOption onClick={TradeVisibleComment}>
                                            <ImgClose src={messageSquare} />
                                        </OpenOption>
                                        {/* <OpenOption>
                                            <ImgClose src={send} onClick={GoToTelegram} />
                                        </OpenOption> */}
                                        <OpenOption onClick={OpenReport}>
                                            <ImgClose src={alertTriangule} />
                                        </OpenOption>
                                    </ContainerIndex>
                                </ContainerButtons>
                            </>
                        ) : null}
                    </ContainerFilters>
                    {/* <ContainerCalendar>
                        <TitleCalendar>Lista de Simulados</TitleCalendar>
                        {RenderAllDates()}
                    </ContainerCalendar> */}
                </ContainerBody>
                <Modal
                    isOpen={isOpen}
                    style={constants.modalDrawerStyles}
                    onRequestClose={CloseModal}
                >
                    <ContainerOptionsDrawer>
                        <DrawerComponent
                            isModal
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            show
                        />
                    </ContainerOptionsDrawer>
                </Modal>
                <PopUpResultQuestion
                    popUp={openResultQuestion}
                    setPopUp={setOpenResultQuestion}
                    NextQuestion={NextQuestion}
                    setIdScreen={setIdScreen}
                    isCorrect={itemActual?.option_right_or_wrong === 0 || itemActual?.option_right_or_wrong === 1 ? Number(chosenOption) === itemActual?.option_right_or_wrong : chosenOption === itemActual?.feedback}
                    setChosenOption={setChosenOption}
                />
                {!itemActual ? null : (
                    <ModalComments
                        visibleComment={visibleComment}
                        TradeVisibleComment={TradeVisibleComment}
                        itemActual={itemActual}
                    />
                )}
                <Modal isOpen={modalDocument} style={modalPopUp}>
                    <ContainerAswerFullSize>
                        <ContainerClose onClick={OpenDocument}>
                            <ImgClose src={closeGray} />
                        </ContainerClose>
                        <StyleArchiveFullSize dangerouslySetInnerHTML={{ __html: idScreen === 1 ? itemActual?.statement : itemActual?.answer }} />
                    </ContainerAswerFullSize>
                </Modal>
            </Home>
        </Container>
    );
};

export default FlowQuestionsPage;
