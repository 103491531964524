/* eslint-disable max-len */
// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// js
import auth from '../../services/auth';
import colors from '../../styles/colors';
import {
    Container,
    Home,
    LogoHeader,
    ContainerBox,
    Title,
    TitleInput,
    Select,
    Option,
    ContainerSelect,
    ContainerBody,
    ImgBackgroundLinearGradient,
    Subtitle,
    ContainerOr,
    Dashed,
    LogoMain,
} from './registerStyles';
import {
    backgroundHeaderAuthFullSize,
    backgroundLinearGradientFullSize,
    logo,
    logoFullSizeColor,
    logoFullSizeColorDark,
} from '../../assets';
import { updateUser } from '../../store/modules/user/actions';

// components
import ButtonComponent from '../../components/Button/ButtonComponent';
import InputComponent from '../../components/Input/InputComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';
import functions from '../../utils/functions';

// interfaces
interface getParamsForgotPasswordInterfaces {
    social?: string,
}

const RegisterPage: React.FC = () => {
    // constants
    const getParams: getParamsForgotPasswordInterfaces = useParams();
    const Decript = functions.Decrypt(getParams?.social || '');
    const formatObject = Decript?.length ? JSON.parse(Decript) : null;
    const verification = formatObject?.id?.length;

    // dispatch
    const dispatch = useDispatch();

    // states
    const [email, setEmail] = useState(formatObject?.email || '');
    const [name, setName] = useState(formatObject?.name || '');
    // const [telephone, setTelephone] = useState('');
    // const [identifier, setIdentifier] = useState('');
    // const [birthDate, setBirthDate] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [idEngineering, setIdEngineering] = useState('');
    const [idExamination, setIdExamination] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [verificationSecureText, setVerificationSecureText] = useState(true);
    const [verificationSecureTextTwo, setVerificationSecureTextTwo] = useState(true);
    const [opacityBox, setOpacityBox] = useState(1);
    const [dataAllExamination, setDataAllExamination] = useState<Array<{name: string, id: number}>>([]);
    const [dataAllEngineering, setDataAllEngineering] = useState<Array<{name: string, id: number}>>([]);

    // methods
    const GoToRecoverPassword = () => {
        setPathRoute('/recoverPassword');
        setRedirect(true);
    };

    const GoToLogin = () => {
        setPathRoute('/');
        setRedirect(true);
    };

    const GetDataExamination = async () => {
        const dataExamination = await auth.GetExamination();
        const dataEngineering = await auth.GetEngineering();
        setDataAllExamination(dataExamination?.examination);
        setDataAllEngineering(dataEngineering?.engineering);
        setIdExamination(dataExamination?.examination[0]?.id);
        setIdEngineering(dataEngineering?.engineering[0]?.id);
    };

    const Signup = async () => {
        if (password === confirmPassword) {
            const ActionRegister = await auth.Register(
                name,
                email,
                password,
                // telephone,
                // birthDate,
                // identifier,
                idEngineering,
                idExamination,
                verification ? formatObject?.id : '',
            );
            if (ActionRegister?.status === 200) {
                setOpacityBox(0.0000000001);
                setTimeout(() => {
                    const addObject = { ...ActionRegister.result.user, token: ActionRegister.result.token, tokenRefresh: ActionRegister.result.tokenRefresh };
                    dispatch(updateUser(addObject));
                    setPathRoute('/timeline');
                    setRedirect(true);
                }, 1000);
            }
        } else {
            // eslint-disable-next-line no-alert
            alert('As senhas precisam ser iguais.');
        }
    };

    const onChangeEngineering = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setIdEngineering(txt.target.value);
    };

    const onChangeExamination = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setIdExamination(txt.target.value);
    };

    // useEffect
    useEffect(() => {
        GetDataExamination();
    }, []);

    // renders
    const RenderAllEngineering = () => {
        if (dataAllEngineering?.length) {
            return dataAllEngineering.map((v) => (
                <Option value={v.id}>{v?.name}</Option>
            ));
        }
        return null;
    };

    const RenderAllExamination = () => {
        if (dataAllExamination?.length) {
            return dataAllExamination.map((v) => (
                <Option value={v.id}>{v?.name}</Option>
            ));
        }
        return null;
    };

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerBody zoomHeight={opacityBox !== 1} height={isMobile ? '20vh' : '50vh'} background={backgroundHeaderAuthFullSize}>
                    <ImgBackgroundLinearGradient zoomHeight={opacityBox !== 1} background={backgroundLinearGradientFullSize}>
                        {opacityBox !== 1 ? (
                            <LogoMain src={logo} />
                        ) : null}
                    </ImgBackgroundLinearGradient>
                </ContainerBody>
                <ContainerBox opacity={opacityBox}>
                    <LogoHeader src={window.localStorage.getItem('theme') === 'light' ? logoFullSizeColor : logoFullSizeColorDark} />
                    <Title>Olá</Title>
                    <Subtitle>Preencha o formulário abaixo para cadastrar-se.</Subtitle>
                    <InputComponent placeholder="Seu e-mail" type="email" value={email} setValue={setEmail} />
                    <InputComponent placeholder="Seu nome completo" type="email" value={name} setValue={setName} />
                    {/* <InputComponent placeholder="Seu telefone" type="email" value={telephone} setValue={setTelephone} />
                    <InputComponent placeholder="Seu CPF" type="email" value={identifier} setValue={setIdentifier} />
                    <InputComponent placeholder="Sua data de nascimento" type="email" value={birthDate} setValue={setBirthDate} /> */}
                    <ContainerSelect>
                        <Select value={idEngineering} onChange={onChangeEngineering}>
                            {RenderAllEngineering()}
                        </Select>
                    </ContainerSelect>
                    <ContainerSelect>
                        <Select value={idExamination} onChange={onChangeExamination}>
                            {RenderAllExamination()}
                        </Select>
                    </ContainerSelect>
                    <InputComponent placeholder="Sua senha" type={verificationSecureText ? 'password' : 'email'} setVerificationSecureText={setVerificationSecureText} verificationSecureText={verificationSecureText} value={password} setValue={setPassword} />
                    <InputComponent placeholder="Confirmar senha" type={verificationSecureTextTwo ? 'password' : 'email'} setVerificationSecureText={setVerificationSecureTextTwo} verificationSecureText={verificationSecureTextTwo} value={confirmPassword} setValue={setConfirmPassword} />
                    <ButtonComponent label="CADASTRAR-SE" onClick={Signup} />
                    <TitleInput width="auto" fontWeight="normal">
                        Esqueceu sua senha?
                        <TitleInput width="auto" activeCursor="pointer" onClick={GoToRecoverPassword} textDecoration="underline"> Clique aqui!</TitleInput>
                    </TitleInput>
                    <ContainerOr>
                        <Dashed />
                        <TitleInput width="auto" fontWeight="normal" color={colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight}> OU </TitleInput>
                        <Dashed />
                    </ContainerOr>
                    <TitleInput width="auto" fontWeight="normal">
                        Já tem uma conta?
                        <TitleInput width="auto" activeCursor="pointer" onClick={GoToLogin}> Acesse</TitleInput>
                    </TitleInput>
                </ContainerBox>
            </Home>
        </Container>
    );
};

export default RegisterPage;
