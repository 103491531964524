// libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

// js
import {
    Container,
    Home,
    ContainerBody,
    ContainerOptions,
    // ContainerCalendar,
    // TitleCalendar,
    ContainerOptionsDrawer,
} from './questionsStyles';
import constants from '../../utils/consts';
import categories from '../../services/categories';
import { RootState } from '../../store/index';

// components
import HeaderComponent from '../../components/Header/HeaderComponent';
import DrawerComponent from '../../components/Drawer/DrawerComponent';
import BoxOptionsComponent from './components/BoxOptionsComponent';
// import OneDateSimulateComponent from '../Timeline/components/OneDateSimulateComponent';
import PopUpComponent from '../../components/PopUp/PopUpComponent';

// components (navigation)
import questionsConstants from './questionsConstants';
import simulate from '../../services/simulate';
import tests from '../../services/tests';
import auth from '../../services/auth';
import PopUpQuestions from '../../components/PopUpQuestions/PopUpQuestions';
import { updateUser } from '../../store/modules/user/actions';
import tournament from '../../services/tournament';
import essay from '../../services/essay';

// main
const QuestionsPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // useState
    // const [search, setSearch] = useState('');
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(true);
    const [popUp, setPopUp] = useState(false);
    const [flowQuestions, setFlowQuestions] = useState(false);
    const [allQuestions, setAllQuestions] = useState([]);
    const [idPopUp, setIdPopUp] = useState(-1);
    const [allCategories, setAllCategories] = useState([]);
    const [allSubjectsSelect, setAllSubjectsSelect] = useState<Array<any>>([]);
    const [selectFilter, setSelectFilter] = useState<Array<number>>([]);
    const [selectDropdawn, setSelectDropdawn] = useState<Array<number>>([]);
    const [allTests, setAllTests] = useState([]);
    const [allTournament, setAllTournament] = useState([]);
    const [allInfoEssay, setAllInfoEssay] = useState([]);
    const [allTextSupport, setAllTextSupport] = useState([]);
    const [allSimulate, setAllSimulate] = useState([]);
    const [allSimulateTwo, setAllSimulateTwo] = useState([]);
    const [allSolicitation, setAllSolicitation] = useState<Array<any>>([]);
    const [allExamination, setAllExamination] = useState([]);
    const [allEngineering, setAllEngineering] = useState([]);
    const [infoSelectSimulateOrTest] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    // methods
    const onDrawer = () => {
        setIsOpen(true);
    };

    const ActionInitialFilters = async () => {
        setIsLoading(true);
        try {
            const GetAllCategories = await categories.GetAllCategories(
                infoUser?.id || 0,
                infoUser?.token || '',
            );
            setAllCategories(GetAllCategories?.result);
            const dataExamination = await auth.GetExamination();
            setAllExamination(dataExamination?.examination);
            const dataEngineering = await auth.GetEngineering();
            setAllEngineering(dataEngineering?.engineering);
            const GetAllTests = await tests.GetAllTests(
                0,
                0,
                '',
                infoUser?.token || '',
            );
            setAllTests(GetAllTests?.result);
            const GetAllTournament = await tournament.GetAllTournament(
                infoUser.id,
                '',
                'TODAY',
                infoUser.token || '',
            );
            setAllTournament(GetAllTournament?.result || []);
            const GetEssay = await essay.GetAllEssay(
                '',
                infoUser?.token || '',
            );
            setAllInfoEssay(GetEssay?.result || []);
            const GetAllSimulate = await simulate.GetAllSimulate(
                '1',
                infoUser.id_engineering,
                infoUser.id_examination,
                '',
                infoUser?.token || '',
            );
            const GetAllSimulateTwo = await simulate.GetAllSimulate(
                '2',
                infoUser.id_engineering,
                infoUser.id_examination,
                '',
                infoUser?.token || '',
            );
            const GetAllSolicitation = await simulate.GetAllSolicitation(
                infoUser?.id,
                infoUser?.token || '',
            );
            setAllSimulate(GetAllSimulate?.result);
            setAllSimulateTwo(GetAllSimulateTwo?.result);
            setAllSolicitation(GetAllSolicitation?.result);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    function ValidationMarginOptions() {
        if (show) {
            return '310px';
        }
        if (!show) {
            return '160px';
        }
    }

    function ValidationMarginOptionsLowLarge() {
        if (show) {
            return '215px';
        }
        if (!show) {
            return '105px';
        }
    }

    // useEffect
    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        const initializeFilters = async () => {
            setIsLoading(true);
            try {
                await ActionInitialFilters(); // Use await aqui
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        };
        initializeFilters(); // Chama a função assíncrona
    }, []);

    useEffect(() => {
        if (idPopUp !== -1) {
            setPopUp(true);
        }
    }, [idPopUp]);

    useEffect(() => {
        if (popUp === false) {
            setIdPopUp(-1);
            // Atualizar os dados da tela;
            ActionInitialFilters();
        }
    }, [popUp]);

    useEffect(() => {
        if (
            idPopUp
            && infoUser?.allInfoQuestion?.goBack
        ) {
            dispatch(updateUser({
                ...infoUser,
                allInfoQuestion: undefined,
            }));
        }
    }, [idPopUp]);

    useEffect(() => {
        if (flowQuestions === false) {
            if (infoUser?.allInfoQuestion?.goBack) {
                setAllSubjectsSelect(infoUser?.allInfoQuestion?.allSubjectsSelect || []);
                setSelectFilter(infoUser?.allInfoQuestion?.selectFilter || []);
                setIdPopUp(infoUser?.allInfoQuestion?.idPopUp || -1);
            } else {
                setPopUp(false);
                setAllSubjectsSelect([]);
                setSelectFilter([]);
            }
        }
    }, [flowQuestions]);

    useEffect(() => {
        if (allQuestions?.length) {
            setFlowQuestions(true);
        }
    }, [allQuestions]);

    // renders
    const RenderAllOptions = () => (
        questionsConstants.allOptions.map((v) => (
            <BoxOptionsComponent
                item={v}
                widthActual={widthActual}
                setIdPopUp={setIdPopUp}
            />
        ))
    );

    // const RenderAllDates = () => (
    //     constants.allDates.map((v) => (
    //         <OneDateSimulateComponent item={v} />
    //     ))
    // );

    const RenderTitle = () => {
        if (idPopUp === 1) {
            return 'Selecione um filtro';
        }
        if (idPopUp === 2) {
            return 'Provas';
        }
        if (idPopUp === 3) {
            return 'Simulados';
        }
        if (idPopUp === 4) {
            return 'Gerar Simulado';
        }
        if (idPopUp === 5) {
            return 'Filtros';
        }
        if (idPopUp === 6) {
            return 'Desafios Diários';
        }
        if (idPopUp === 7) {
            return 'Redação';
        }
        return 'Filtrar por Concursos/Formação';
    };

    const CloseModal = () => {
        setIsOpen(false);
    };

    return (
        <Container>
            <Home>
                <HeaderComponent
                    // search={search}
                    // setSearch={setSearch}
                    // labelSearch="Concursos"
                    widthActual={widthActual}
                    onDrawer={onDrawer}
                />
                <ContainerBody>
                    {widthActual >= 800 ? (
                        <DrawerComponent
                            show={show}
                            setShow={setShow}
                        />
                    ) : null}
                    <ContainerOptions
                        existOptions
                        marginLeft={ValidationMarginOptions()}
                        marginLeftLowLarge={ValidationMarginOptionsLowLarge()}
                    >
                        {RenderAllOptions()}
                    </ContainerOptions>
                    {/* <ContainerCalendar>
                        <TitleCalendar>Lista de Simulados</TitleCalendar>
                        {RenderAllDates()}
                    </ContainerCalendar> */}
                </ContainerBody>
                <Modal
                    isOpen={isOpen}
                    style={constants.modalDrawerStyles}
                    onRequestClose={CloseModal}
                >
                    <ContainerOptionsDrawer>
                        <DrawerComponent
                            isModal
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            show
                        />
                    </ContainerOptionsDrawer>
                </Modal>
                <PopUpComponent
                    popUp={popUp}
                    setPopUp={setPopUp}
                    title={RenderTitle()}
                    setIdPopUp={setIdPopUp}
                    idScreen="Questions"
                    allCategories={allCategories}
                    selectDropdawn={selectDropdawn}
                    setSelectDropdawn={setSelectDropdawn}
                    selectFilter={selectFilter}
                    setSelectFilter={setSelectFilter}
                    allSubjectsSelect={allSubjectsSelect}
                    setAllSubjectsSelect={setAllSubjectsSelect}
                    allTests={allTests}
                    setAllTests={setAllTests}
                    allTournament={allTournament}
                    setAllTournament={setAllTournament}
                    allInfoEssay={allInfoEssay}
                    setAllInfoEssay={setAllInfoEssay}
                    allTextSupport={allTextSupport}
                    setAllTextSupport={setAllTextSupport}
                    allSimulate={allSimulate}
                    setAllSimulate={setAllSimulate}
                    allSimulateTwo={allSimulateTwo}
                    setAllSimulateTwo={setAllSimulateTwo}
                    allSolicitation={allSolicitation}
                    setAllSolicitation={setAllSolicitation}
                    allExamination={allExamination}
                    setAllExamination={setAllExamination}
                    allEngineering={allEngineering}
                    setAllEngineering={setAllEngineering}
                    setAllQuestions={setAllQuestions}
                    idPopUp={idPopUp}
                    isLoading={isLoading}
                />
                <PopUpQuestions
                    popUp={flowQuestions}
                    setPopUp={setFlowQuestions}
                    allQuestions={allQuestions}
                    setAllQuestions={setAllQuestions}
                    idType={(idPopUp === 3 ? 'Simulados' : '') || (idPopUp === 2 ? 'Provas' : '') || (idPopUp === 6 ? 'Desafios Diários' : '') || ''}
                    idSelected={infoSelectSimulateOrTest?.id}
                    // eslint-disable-next-line max-len
                    timeOutSimulateOrTest={[2, 3, 6].includes(idPopUp) ? (infoSelectSimulateOrTest?.timer || infoSelectSimulateOrTest?.time || -1) : -1}
                />
            </Home>
        </Container>
    );
};

export default QuestionsPage;
