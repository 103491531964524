// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllTournament(
    idUser: number,
    titleForSearching: string,
    period: 'TODAY' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS' | 'LAST_3_MONTHS' | 'LAST_6_MONTHS' | 'YEAR' | 'ALL' | 'CUSTOM',
    token: string,
    start?: string,
    end?: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        let getDataURL = `${baseUrl}/dailyChallenges?titleForSearching=${titleForSearching || ''}&period=${period}&idUser=${idUser}`;
        if (period === 'CUSTOM') {
            if (start?.length === 10) {
                getDataURL = `${getDataURL}&start=${start}`;
            }
            if (end?.length === 10) {
                getDataURL = `${getDataURL}&end=${end}`;
            }
        }
        const response = await axios.get(getDataURL, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response.data?.message || 'Ocorreu um erro no servidor');
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function CheckOneTournament(
    idDailyChallenges: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.patch(`${baseUrl}/dailyChallenges/finish?idUser=${idUser}&idDailyChallenges=${idDailyChallenges}`, null, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response.data?.message || 'Ocorreu um erro no servidor');
    }
}

export default {
    GetAllTournament,
    CheckOneTournament,
};
