// js
import { premium, premiumInfinite, premiumPlus } from '../../assets/index';

const allSignatures = [
    {
        id: 1,
        title: 'Premium Anual',
        type: 'premium',
        priceOld: 220,
        priceNow: 132,
        priceInstallments: 13.20,
        icon: premium,
        descriptionDefault: '',
        descriptions: [
            'A mais completa plataforma de questões do mercado',
            'Saiba o que mais cai na sua prova com o nosso Raio X',
            'Preparatório Base para Concursos em PDF 2.0',
            'Acesso a mais de 8.000 horas de videoaulas',
            'Direcionamento do estudo com nosso Guia de Estudos',
        ],
    },
    {
        id: 2,
        title: 'Premium Plus Anual',
        type: 'plus',
        priceOld: 388.80,
        priceNow: 213.84,
        priceInstallments: 19.80,
        icon: premiumPlus,
        descriptionDefault: 'Tudo do Premium',
        descriptions: [
            'A gente mostra tudo o que você precisa estudar na prática',
            'Vamos gerenciar toda a sua rotina de estudos',
            'Seremos o seu coach digital automatizado',
            'Organize seus resumos e cadernos em um só lugar',
            'Faça simuladões, desafie-se e teste seus conhecimentos',
        ],
    },
    {
        id: 3,
        type: 'unlimited',
        title: 'Ilimitada Anual',
        priceOld: 1198.80,
        priceNow: 1018.98,
        priceInstallments: 84.92,
        icon: premiumInfinite,
        descriptionDefault: 'Tudo do Premium Plus',
        descriptions: [
            '+ de 400 cursos para concursos públicos do Direção Concursos',
            'Teoria e prática juntas na sua preparação para concursos',
            'Aplique a teoria resolvendo questões escolhidas pelos melhores professores do mercado',
            'Faca downloads e estude offline',
            'A única do mercado com PDF 2.0',
            'Além de tudo isso, ganhe meses de bônus e economize na sua assinatura',
        ],
    },
];

export default {
    allSignatures,
};
