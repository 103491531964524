// libraries
import React from 'react';
import functions from '../../../utils/functions';

// js
import {
    ContainerPeriod,
    ButtonPeriod,
    TextPeriod,
    InputCustom,
} from '../statisticsStyles';

// interfaces
interface Props {
    allData: Array<any>,
    start: string,
    setStart: (v: string) => void,
    end: string,
    setEnd: (v: string) => void,
    typeOrderId: number,
    setTypeOrderId: (v: number) => void,
    setOpenModalPeriod: (v: boolean) => void,
}

const ModalPickerComponent: React.FC<Props> = ({
    allData,
    start,
    setStart,
    end,
    setEnd,
    typeOrderId,
    setTypeOrderId,
    setOpenModalPeriod,
}) => (
    <ContainerPeriod>
        {allData.map((v: any) => {
            const TradePeriod = () => {
                const index = allData.indexOf(v);
                setTypeOrderId(index);
                if (v.label !== 'Customizado') {
                    setOpenModalPeriod(false);
                    setStart('');
                    setEnd('');
                }
            };
            const onChangeStart = (txt: React.ChangeEvent<HTMLInputElement>) => {
                setStart(functions.dateMask(start, txt.target.value));
                if (end?.length === 10 && txt.target.value?.length === 10) {
                    const index = allData.indexOf(v);
                    setTypeOrderId(index);
                    setOpenModalPeriod(false);
                }
            };
            const onChangeEnd = (txt: React.ChangeEvent<HTMLInputElement>) => {
                setEnd(functions.dateMask(end, txt.target.value));
                if (start?.length === 10 && txt.target.value?.length === 10) {
                    const index = allData.indexOf(v);
                    setTypeOrderId(index);
                    setOpenModalPeriod(false);
                }
            };
            return (
                <>
                    <ButtonPeriod
                        key={v.id}
                        onClick={TradePeriod}
                        enabledFocus={v.id === allData[typeOrderId].id}
                    >
                        <TextPeriod enabledFocus={v.id === allData[typeOrderId].id}>
                            {v.label}
                        </TextPeriod>
                    </ButtonPeriod>
                    {allData[typeOrderId]?.id === 'CUSTOM' && v.id === 'CUSTOM' ? (
                        <>
                            <InputCustom placeholder="Início: DD/MM/YYYY" value={start} onChange={onChangeStart} />
                            <InputCustom placeholder="Fim: DD/MM/YYYY" value={end} onChange={onChangeEnd} />
                        </>
                    ) : null}
                </>
            );
        })}
    </ContainerPeriod>
);

export default ModalPickerComponent;
