// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllBooks(
    titleForSearching: string,
    typeOrderBy: string,
    flowOrderBy: string,
    suggested: boolean,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const validationPath = suggested ? `${baseUrl}/books?idUser=${idUser}&titleForSearching=${titleForSearching}&suggested=${suggested}&typeOrderBy=${typeOrderBy || 'id'}&flowOrderBy=${flowOrderBy || 'asc'}` : `${baseUrl}/books?idUser=${idUser}&titleForSearching=${titleForSearching}&typeOrderBy=${typeOrderBy || 'id'}&flowOrderBy=${flowOrderBy || 'asc'}`;
        const response = await axios.get(validationPath, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateOneBook(
    title: string,
    idExamination: string,
    idFiltersSaved: Array<number>,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            title,
            idExamination,
            idFiltersSaved,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/books?idUser=${idUser}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function EditOneBook(
    title: string,
    idFiltersSaved: Array<number>,
    idBook: number,
    token: string,
): Promise<any> {
    try {
        let body: { title: string, idFiltersSaved?: Array<number> } = {
            title,
        };
        if (idFiltersSaved?.length) {
            body = {
                ...body,
                idFiltersSaved,
            };
        }
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.put(`${baseUrl}/books?idBook=${idBook}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DeleteOneBook(
    idBook: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.delete(`${baseUrl}/books?idBook=${idBook}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllBooks,
    CreateOneBook,
    EditOneBook,
    DeleteOneBook,
};
