// libraries
import styled from 'styled-components';
import colors from '../../styles/colors';

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const Home = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    padding: 20px;
`;

export const Title = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
    text-align: center;
`;

export const SubTitle = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
`;

export const Description = styled.text`
    margin-top: 20px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-size: 16px;
    font-weight: 600;
`;

export const SubDescription = styled.text`
    margin-top: 5px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-size: 14px;
    font-weight: 500;
    padding-left: 20px;
`;
