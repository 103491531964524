// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface TitleDefaultInterfaces {
    fontSize?: string,
    fontWeight?: string,
    color?: string,
    textDecoration?: string,
    marginLeft?: string,
    marginBottom?: string,
    marginRight?: string,
}

interface LabelInterface {
    size?: number,
    textAlign?: string,
}

interface InputInterfaces {
    width?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    /* justify-content: center; */
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
`;

export const ContainerBody = styled.div`
    display: flex;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
    flex-wrap: wrap;
    width: 60%;
`;

export const Title = styled.text<TitleDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').graySingle)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    text-decoration: ${(props) => (props.textDecoration || 'none')};
    margin-left: ${(props) => (props.marginLeft || '0px')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
`;

export const ContainerFrame = styled.div<InputInterfaces>`
    flex-direction: column;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-top: 20px;
    width: ${(props) => (props.width || '100%')};
`;

export const TitleLabel = styled.text<LabelInterface>`
    flex: 1;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').graySingle};
    font-weight: 500;
    font-size: ${(props) => `${((props?.size || 1) * 12)}px`};
    text-transform: uppercase;
    text-align: ${(props) => (props?.textAlign || 'start')};
    margin-bottom: 10px;
    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const Input = styled.input<InputInterfaces>`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').graySingle};
    font-weight: 500;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundInputsPayment};
    border: none;
    border-radius: 10px;
    min-height: 35px;
    padding-left: 15px;
`;

export const ContainerAdjustInputs = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ContainerWarnning = styled.div`
    align-items: center;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundWarnningPayment};
    display: flex;
    padding: 10px 5px 10px 5px;
    border-radius: 3px;
    margin-top: 20px;
    width: 100%;
`;

export const CheckBox = styled.input`
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    height: 20px;
    width: 20px;
`;

export const ButtonAction = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').blue};
    padding-top: 10px;
    margin-top: 20px;
    border-radius: 3px;
    padding-bottom: 10px;
    width: 100%;
`;

export const GoBackScreen = styled.img`
    object-fit: contain;
    cursor: pointer;
    height: 20px;
    width: 10.5px;
`;
