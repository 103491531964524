/* eslint-disable max-len */
// libraries
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { RiDeleteBin6Line } from 'react-icons/ri';

// js
import {
    ButtonDelete,
    Close,
    ContainerAdjustBody,
    ContainerAdjustResult,
    ContainerComment,
    ContainerInfo,
    ContainerInput,
    ContainerMainComment,
    ContainerMore,
    ContainerPopUp,
    ContainerTitle,
    ContainerUpOrLower,
    DateComment,
    DescriptionComment,
    IconDrowOrUp,
    ImageChildrenComment,
    ImageComment,
    ImageUser,
    ImageVerified,
    ImgClose,
    ImgSend,
    ImgUpperOrLower,
    Input,
    Title,
    TitleComment,
} from './modalCommentsStyles';
import comments from '../../../../services/comments';
import colors from '../../../../styles/colors';
import { RootState } from '../../../../store';
import {
    arrowDropDawnPurple,
    arrowDropDawnPurpleTop,
    checkCircle,
    closeGray,
    iconPhotoProfileDefault,
    send,
    likeOutlineBlue,
    likePurple,
    deslikeCommentOutline,
    deslikeCommentContent,
} from '../../../../assets';

// interfaces
interface Props {
    visibleComment: boolean,
    TradeVisibleComment: any,
    itemActual: any,
}

function ModalComments({
    visibleComment,
    TradeVisibleComment,
    itemActual,
}: Props): any {
    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);
    const [widthActual, setWidthActual] = useState(window.innerWidth);

    // constants
    const modalPopUp = {
        overlay: {
            backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
        },
        content: {
            alignItems: 'center',
            border: 'none',
            backgroundColor: 'transparent',
            display: 'flex',
            width: widthActual > 600 ? '60vw' : '75vw',
            maxHeight: '55vh',
            top: '20vh',
            margin: '0 auto',
            justifyContent: 'center',
            overflow: 'hidden',
        },
    };

    // states
    const [listComments, setListComments] = useState([]);
    const [comment, setComment] = useState('');
    const [allIdsCommentsActiveChildren, setAllIdsCommentsActiveChildren] = useState<number>(-1);
    const [replyComment, setReplyComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    // methods
    const GetComments = async () => {
        const allComments = await comments.GetAllComments(itemActual.id, infoUser.token);
        if (allComments.status === 200) {
            setListComments(allComments.result?.comments || []);
        }
    };

    const CloseScreen = () => {
        TradeVisibleComment();
    };

    const activeNewComment = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            await comments.CreateOneComment(comment, itemActual.id, infoUser.id, infoUser.token);
            setComment('');
            GetComments();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const activeChildrenComment = async () => {
        setIsLoading(true);
        try {
            if (replyComment) {
                const createComments = await comments.CreateOneChildrenComment(replyComment, allIdsCommentsActiveChildren, infoUser.id, infoUser.token);
                if (createComments.status === 201) {
                    setComment('');
                    GetComments();
                    setReplyComment('');
                } else {
                    // eslint-disable-next-line no-alert
                    alert(createComments?.message || 'Ocorreu um erro ao responder o comentário.');
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onKeyPressNewComment = async (event: any) => {
        if (event.keyCode === 13 || event.which === 13) {
            activeNewComment();
        }
    };

    const newChildrenComment = async (event: any) => {
        if (event.keyCode === 13 || event.which === 13) {
            activeChildrenComment();
        }
    };

    // useEffect
    useEffect(() => {
        GetComments();
    }, [itemActual]);

    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    const onChangeComment = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = txt.target;
        setComment(value);
    };

    const onChangeChildrenComment = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = txt.target;
        setReplyComment(value);
    };

    // renders
    const renderComments = () => (
        listComments.map((item: any) => {
            const conditionImageLike = (item.count_upper || []).includes(infoUser.id);
            const conditionImageDeslike = (item.count_down || []).includes(infoUser.id);
            const openModalDeleteComment = async (id?: number) => {
                const confirmDelete = window.confirm('Você tem certeza de que deseja excluir este comentário?');
                if (confirmDelete) {
                    try {
                        setIsLoading(true);
                        if (item.id_user === infoUser.id && !id) {
                            await comments.DelOneComment(item.id, infoUser.token);
                            GetComments();
                        } else if (item.id_user === infoUser.id && id) {
                            await comments.DelChildrenComment(id, infoUser.token);
                            GetComments();
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsLoading(false);
                    }
                }
            };

            const DelChildrenComment = async (id: any) => {
                const confirmDelete = window.confirm('Você tem certeza de que deseja excluir este comentário?');
                if (confirmDelete) {
                    try {
                        setIsLoading(true);
                        if (item.id_user === infoUser.id) {
                            await comments.DelChildrenComment(id, infoUser.token);
                            GetComments();
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsLoading(false);
                    }
                }
            };

            const addUpComment = async () => {
                const addUp = await comments.CreateUpperComment(item.id, infoUser.id, infoUser.token);
                if (addUp.status === 200) {
                    GetComments();
                }
            };

            const addLowerComment = async () => {
                const addUp = await comments.CreateLowerComment(item.id, infoUser.id, infoUser.token);
                if (addUp.status === 200) {
                    GetComments();
                }
            };

            const activeChildrenComments = () => {
                setAllIdsCommentsActiveChildren(item.id);
                setReplyComment('');
            };

            const removeChildrenComments = () => {
                setAllIdsCommentsActiveChildren(-1);
                setReplyComment('');
            };

            return (
                <ContainerComment key={item.id} opacity={isLoading && item.id_user === infoUser.id}>
                    <ContainerMainComment
                        onDoubleClick={() => openModalDeleteComment()}
                        cursor={item.id_user === infoUser.id ? 'default' : 'default'}
                    >
                        <ImageComment src={item.photoUser} />
                        <ContainerInfo>
                            <ContainerTitle>
                                <TitleComment>
                                    {item.nameUser}
                                </TitleComment>
                                <DateComment>
                                    {item.created_at}
                                </DateComment>
                                {!item.verified ? null : (
                                    <ImageVerified src={checkCircle} />
                                )}
                            </ContainerTitle>
                            <DescriptionComment>
                                {item.comment}
                                {item.id_user === infoUser.id ? (
                                    <ButtonDelete type="submit">
                                        <RiDeleteBin6Line onClick={() => openModalDeleteComment()} color="rgba(205, 0, 0)" width={40} height={40} style={{ fontSize: '20px' }}>deletar</RiDeleteBin6Line>
                                    </ButtonDelete>
                                ) : null }
                            </DescriptionComment>
                        </ContainerInfo>
                    </ContainerMainComment>
                    <ContainerAdjustBody>
                        {allIdsCommentsActiveChildren !== item.id ? (
                            <ContainerMore onClick={activeChildrenComments}>
                                Ver resposta
                                <IconDrowOrUp src={arrowDropDawnPurple} />
                            </ContainerMore>
                        ) : null}
                        {allIdsCommentsActiveChildren === item.id ? (
                            <ContainerMore onClick={removeChildrenComments}>
                                Ocultar resposta
                                <IconDrowOrUp src={arrowDropDawnPurpleTop} />
                            </ContainerMore>
                        ) : null}
                        <ContainerUpOrLower>
                            {(item.count_upper || [])?.length}
                            <ImgUpperOrLower src={conditionImageLike ? likePurple : likeOutlineBlue} onClick={addUpComment} />
                            {(item.count_down || [])?.length}
                            <ImgUpperOrLower src={conditionImageDeslike ? deslikeCommentContent : deslikeCommentOutline} onClick={addLowerComment} />
                        </ContainerUpOrLower>
                    </ContainerAdjustBody>
                    {allIdsCommentsActiveChildren !== item.id ? null : (
                        <>
                            {item.childrenComments.map((v: any) => (
                                <ContainerMainComment width="95%" alignSelf="flex-end" key={v.id} cursor="default" style={{ paddingLeft: '19px', paddingRight: '19px' }}>
                                    <ImageComment src={v.photoUser || v.photoManager} />
                                    <ContainerInfo>
                                        <ContainerTitle>
                                            <TitleComment manager={Number(v.id_manager || 0) > 0}>
                                                {v.nameUser || v.nameManager}
                                            </TitleComment>
                                            <DateComment>
                                                {v.created_at}
                                            </DateComment>
                                            {!v.verified ? null : (
                                                <ImageVerified src={checkCircle} />
                                            )}
                                        </ContainerTitle>
                                        <DescriptionComment>
                                            {v.comment}
                                            {item.id_user === infoUser.id ? (
                                                <ButtonDelete type="submit">
                                                    <RiDeleteBin6Line onClick={() => openModalDeleteComment(v.id)} color="rgba(205, 0, 0)" width={40} height={40} style={{ fontSize: '20px' }}>deletar</RiDeleteBin6Line>
                                                </ButtonDelete>
                                            ) : null }
                                        </DescriptionComment>
                                        {v.image ? (
                                            <ImageChildrenComment src={v.image} />
                                        ) : null}
                                    </ContainerInfo>
                                </ContainerMainComment>
                            ))}
                            <ContainerInput
                                width="95%"
                                alignSelf="flex-end"
                                marginTop="1px"
                                marginBottom="1px"
                            >
                                <ImageUser src={infoUser?.photo || iconPhotoProfileDefault} height="27px" width="27px" />
                                <Input
                                    placeholder="Adicionar comentário"
                                    value={replyComment}
                                    onChange={onChangeChildrenComment}
                                    onKeyPress={newChildrenComment}
                                />
                                <Close onClick={activeChildrenComment} disabled={isLoading}>
                                    <ImgSend src={send} isLoading={isLoading} />
                                </Close>
                            </ContainerInput>
                        </>
                    )}
                </ContainerComment>
            );
        })
    );

    return (
        <Modal isOpen={visibleComment} style={modalPopUp} onRequestClose={CloseScreen}>
            <ContainerPopUp>
                <ContainerAdjustResult>
                    <Title>Comentários</Title>
                    <Close onClick={CloseScreen}>
                        <ImgClose src={closeGray} />
                    </Close>
                </ContainerAdjustResult>
                <ContainerInput>
                    <ImageUser src={infoUser?.photo || iconPhotoProfileDefault} />
                    <Input
                        placeholder="Adicionar comentário"
                        value={comment}
                        onChange={onChangeComment}
                        onKeyPress={onKeyPressNewComment}
                    />
                    <Close onClick={activeNewComment}>
                        <ImgSend isLoading={isLoading} src={send} />
                    </Close>
                </ContainerInput>
                {renderComments()}
            </ContainerPopUp>
        </Modal>
    );
}

export default ModalComments;
