// libraries
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import OneSignal from 'react-onesignal';

// js
import GlobalStyle from './styles/global';
import Routes from './routes/index';
import { persistor, store } from './store/index';
import constants from './configs/constants';

const App: React.FC = () => {
    useEffect(() => {
        const appId = constants.getIDNotification();
        if (appId) {
            OneSignal.init({
                appId,
            }).then(() => {
                OneSignal.showSlidedownPrompt();
            });
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
                <GlobalStyle />
            </PersistGate>
        </Provider>
    );
};

export default App;
