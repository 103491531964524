/* eslint-disable max-len */
// libraries
import React, { useState } from 'react';
import {
    VictoryChart,
    VictoryTheme,
    VictoryGroup,
    VictoryArea,
    VictoryScatter,
    VictoryBar,
    VictoryAxis,
    VictoryPolarAxis,
    VictoryLabel,
} from 'victory';

// js
import Loader from 'react-loader-spinner';
import colors from '../../styles/colors';
import statisticsConstants from './statisticsConstants';
import statisticsFunctions from './statisticsFunctions';
import {
    ContainerImgStatistics,
    Card,
    ImgStatistics,
    InfoCard,
    ContainerInfoSpecifics,
    InfoValueCard,
    IconDropDawnCategory,
    ContainerCategory,
    NameCategory,
    ContainerQtd,
    AdjustInfo,
    AdjustGraph,
    CheckBoxRadius,
    Checked,
    ContainerBar,
    DivNull,
    ProgressBar,
    ContainerAdjustCard,
} from './statisticsStyles';
import {
    arrowDropDawnLight,
    chartHorizontalLight,
    chartVerticalLight,
    editSquareLight,
    favoriteLight,
} from '../../assets';

// components
import RankingComponent from './components/HeaderRanking/HeaderRankingComponent';
import { ContainerLoader } from '../../components/PopUp/popUpStyles';

// interfaces
interface AllCardsSpecificsInterfaces {
    resizeAxis: any,
    widthActual: number,
    typeGraphSpecifics: 'numberResolution' | 'performanceRelative',
    setTypeGraphSpecifics: (v: 'numberResolution' | 'performanceRelative') => void,
    allStatisticsSpecifics: any,
    allCategories: any,
    setPopUp: (v: boolean) => void,
    setAllFilters: (v: any) => void,
    infoUser: any,
    selectFilter: Array<number>
}

interface AllCardsRanking {
    infoUser: any,
    widthActual: number,
    allStatisticsRanking: Array<any>
}

interface AllCardsGeneral {
    allStatisticsGeneral: any,
}

interface AllCardsStrongAndWeak {
    allStatisticsStrongAndWeak: any,
}

const AllCards: React.FC<AllCardsGeneral> = ({
    allStatisticsGeneral,
}) => {
    const RenderCard = (v: any, i: number) => {
        const {
            id,
            title,
            icon,
        } = v;
        function RenderValue() {
            if (id === 1) {
                return `${allStatisticsGeneral?.performance || 0}%`;
            }
            if (id === 2) {
                return allStatisticsGeneral?.amountQuestions || 0;
            }
            if (id === 3) {
                return allStatisticsGeneral?.amountCorrect || 0;
            }
            if (id === 4) {
                return allStatisticsGeneral?.amountError || 0;
            }
            if (id === 5) {
                return allStatisticsGeneral?.averageTime || 0;
            }
        }
        return (
            <Card key={id} isPair={String(i / 2).includes('.')}>
                <ContainerImgStatistics
                    backgroundColor={statisticsFunctions.ValidationBackgroundStatisticsGeneral(
                        title,
                    )}
                >
                    <ImgStatistics src={icon} />
                </ContainerImgStatistics>
                <ContainerImgStatistics alignItems="flex-start" flexDirection="column" width="65%" backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').white}>
                    <InfoCard>—</InfoCard>
                    <InfoCard>{title}</InfoCard>
                    <InfoValueCard>{RenderValue()}</InfoValueCard>
                </ContainerImgStatistics>
            </Card>
        );
    };
    return (
        <>
            <ContainerAdjustCard>
                {statisticsConstants.allCards.slice(0, 2).map((v, i) => RenderCard(v, i))}
            </ContainerAdjustCard>
            <ContainerAdjustCard>
                {statisticsConstants.allCards.slice(2, 4).map((v, i) => RenderCard(v, i))}
            </ContainerAdjustCard>
            <ContainerAdjustCard>
                {statisticsConstants.allCards.slice(-1).map((v, i) => RenderCard(v, i))}
                <Card isPair backgroundColor="transparent" />
            </ContainerAdjustCard>
        </>
    );
};

const AllCardsSpecifics: React.FC<AllCardsSpecificsInterfaces> = ({
    resizeAxis,
    widthActual,
    typeGraphSpecifics,
    setTypeGraphSpecifics,
    allStatisticsSpecifics,
    allCategories,
    setPopUp,
    setAllFilters,
    infoUser,
    selectFilter,
}) => {
    // constants
    const styleErrorArea = {
        data: {
            fill: 'transparent',
            stroke: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber,
            strokeWidth: 3,
        },
    };
    const styleCorrectArea = {
        data: {
            fill: 'transparent',
            stroke: colors.theme(window.localStorage.getItem('theme') || 'light').lineGraphSpecifics,
            strokeWidth: 3,
        },
    };
    const styleRelativeArea = {
        data: {
            fill: 'transparent',
            stroke: colors.theme(window.localStorage.getItem('theme') || 'light').lineRelativeGraphSpecifics,
            strokeWidth: 3,
        },
    };
    const styleRelativePointer = {
        data: {
            fill: colors.theme(window.localStorage.getItem('theme') || 'light').lineRelativeGraphSpecifics,
            stroke: colors.theme(window.localStorage.getItem('theme') || 'light').lineRelativeGraphSpecifics,
            strokeWidth: 3,
        },
    };
    const styleErrorPointer = {
        data: {
            fill: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber,
            stroke: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber,
            strokeWidth: 3,
        },
    };
    const styleCorrectPointer = {
        data: {
            fill: colors.theme(window.localStorage.getItem('theme') || 'light').lineGraphSpecifics,
            stroke: colors.theme(window.localStorage.getItem('theme') || 'light').lineGraphSpecifics,
            strokeWidth: 3,
        },
    };

    const [isLoading, setIsLoading] = useState(false);

    // methods
    const TradeFilterGraphSpecifics = () => {
        if (typeGraphSpecifics === 'numberResolution') {
            setTypeGraphSpecifics('performanceRelative');
        } else {
            setTypeGraphSpecifics('numberResolution');
        }
    };

    // main
    const OpenModalFilter = async (category: any) => {
        setIsLoading(true);
        try {
            await statisticsFunctions.GetAllFilters(
                category.id,
                setAllFilters,
                infoUser,
            );
            setPopUp(true);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <ContainerInfoSpecifics>
                <ImgStatistics src={chartHorizontalLight} />
                <InfoCard>—</InfoCard>
                <InfoCard>RELAÇÃO</InfoCard>
                {!allCategories.length ? (
                    <ContainerLoader>
                        <Loader
                            visible={!allCategories.length}
                            type="TailSpin"
                            color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                            height={100}
                            width={100}
                        />
                    </ContainerLoader>
                ) : (
                    allCategories.map((v: any) => {
                        const qtd = selectFilter.filter((filter) => v.allFilters.includes(filter))?.length;
                        return (
                            <ContainerCategory key={v.id} onClick={() => OpenModalFilter(v)}>
                                <NameCategory>{v.name}</NameCategory>
                                <AdjustInfo>
                                    <ContainerQtd>
                                        <NameCategory color={colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault} fontWeight="bold">
                                            {qtd}
                                        </NameCategory>
                                    </ContainerQtd>
                                    <IconDropDawnCategory src={arrowDropDawnLight} />
                                </AdjustInfo>
                            </ContainerCategory>
                        );
                    })
                )}
            </ContainerInfoSpecifics>
            <ContainerInfoSpecifics marginLeft="15px">
                <ImgStatistics src={chartVerticalLight} />
                <InfoCard>—</InfoCard>
                <InfoCard>GRÁFICO</InfoCard>
                <ContainerCategory
                    width={widthActual < 950 ? `${widthActual / 1.3}px` : `${widthActual / 3.2}px`}
                >
                    <AdjustInfo width="48%" onClick={TradeFilterGraphSpecifics}>
                        <CheckBoxRadius isFocus={typeGraphSpecifics === 'numberResolution'}>
                            {typeGraphSpecifics === 'numberResolution' ? <Checked isFocus={typeGraphSpecifics === 'numberResolution'} /> : null}
                        </CheckBoxRadius>
                        <NameCategory>Número de Resoluções</NameCategory>
                    </AdjustInfo>
                    <AdjustInfo width="48%" onClick={TradeFilterGraphSpecifics}>
                        <CheckBoxRadius isFocus={typeGraphSpecifics === 'performanceRelative'}>
                            {typeGraphSpecifics === 'performanceRelative' ? <Checked isFocus={typeGraphSpecifics === 'performanceRelative'} /> : null}
                        </CheckBoxRadius>
                        <NameCategory>Desempenho Relativo (%)</NameCategory>
                    </AdjustInfo>
                </ContainerCategory>
                <AdjustGraph width={widthActual < 950 ? `${widthActual / 1.3}px` : `${widthActual / 3.2}px`}>
                    <VictoryChart
                        theme={VictoryTheme.material}
                        width={widthActual < 950 ? widthActual / 1.5 : widthActual / 3.2}
                        height={300}
                    >
                        <VictoryAxis dependentAxis style={resizeAxis} />
                        <VictoryAxis style={resizeAxis} />
                        {typeGraphSpecifics === 'numberResolution' ? (
                            <VictoryGroup>
                                {allStatisticsSpecifics?.dataError?.filter((v: any) => v.y !== 0)?.length ? (
                                    <VictoryGroup>
                                        <VictoryArea
                                            data={allStatisticsSpecifics?.dataError}
                                            style={styleErrorArea}
                                        />
                                        <VictoryScatter
                                            style={styleErrorPointer}
                                            size={6}
                                            data={allStatisticsSpecifics?.dataError}
                                        />
                                    </VictoryGroup>
                                ) : null}
                                <VictoryArea
                                    data={allStatisticsSpecifics?.dataCorrect}
                                    style={styleCorrectArea}
                                />
                                <VictoryScatter
                                    style={styleCorrectPointer}
                                    size={5}
                                    data={allStatisticsSpecifics?.dataCorrect}
                                />
                                {!allStatisticsSpecifics?.dataError?.filter((v: any) => v.y !== 0)?.length && !allStatisticsSpecifics?.dataCorrect?.filter((v: any) => v.y !== 0)?.length ? (
                                    <VictoryBar data={[{ x: 0, y: 1 }]} />
                                ) : null}
                            </VictoryGroup>
                        ) : (
                            <VictoryGroup>
                                <VictoryArea data={allStatisticsSpecifics?.dataRelative} style={styleRelativeArea} />
                                <VictoryScatter
                                    style={styleRelativePointer}
                                    size={5}
                                    data={allStatisticsSpecifics?.dataRelative}
                                />
                                {!allStatisticsSpecifics?.dataRelative?.filter((v: any) => v.y !== 0)?.length ? (
                                    <VictoryBar data={[{ x: 0, y: 1 }]} />
                                ) : null}
                            </VictoryGroup>
                        )}
                    </VictoryChart>
                </AdjustGraph>
            </ContainerInfoSpecifics>
        </>
    );
};

const AllCardsStrongAndWeak: React.FC<AllCardsStrongAndWeak> = ({
    allStatisticsStrongAndWeak,
}) => {
    // constants
    const paddingChart = {
        left: 80,
        right: 80,
    };
    const stylesVictoryGroup = (type: string) => ({ data: { fillOpacity: 0.2, strokeWidth: 2, stroke: type === 'strong' ? colors.theme(window.localStorage.getItem('theme') || 'light').lineGraphSpecifics : colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber } });
    const stylesPolarAxis = {
        axis: { stroke: colors.basic(window.localStorage.getItem('theme') || 'light').grayLight, opacity: 0.3 },
        grid: { stroke: colors.basic(window.localStorage.getItem('theme') || 'light').grayLight, opacity: 0.3 },
    };
    const stylesLabel = { fill: colors.basic(window.localStorage.getItem('theme') || 'light').gray, fontSize: 10 };

    // main
    if (
        allStatisticsStrongAndWeak?.strongCharacterData[0]?.length <= 2
        && allStatisticsStrongAndWeak?.weakCharacterData[0]?.length <= 2
        && !allStatisticsStrongAndWeak?.strongPerformances?.length
        && !allStatisticsStrongAndWeak?.weakPerformances?.length
    ) {
        return (
            <ContainerInfoSpecifics>
                <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').grayLight}>Selecione um caderno com progresso para exibir suas estatísticas.</InfoCard>
            </ContainerInfoSpecifics>
        );
    }
    return (
        <>
            <ContainerInfoSpecifics>
                {allStatisticsStrongAndWeak?.strongCharacterData[0]?.length > 2 || allStatisticsStrongAndWeak?.strongPerformances?.length ? (
                    <>
                        <ImgStatistics src={editSquareLight} />
                        <InfoCard color={colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong}>—</InfoCard>
                        <InfoCard color={colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong}>PONTOS FORTES</InfoCard>
                    </>
                ) : null}
                {allStatisticsStrongAndWeak?.strongCharacterData[0]?.length > 2 ? (
                    <VictoryChart
                        polar
                        theme={VictoryTheme.material}
                        domain={{ y: [0, 100] }}
                        padding={paddingChart}
                        width={350}
                    >
                        <VictoryGroup style={stylesVictoryGroup('strong')} color={colors.theme(window.localStorage.getItem('theme') || 'light').lineGraphSpecifics}>
                            {allStatisticsStrongAndWeak?.strongCharacterData?.map((data: any, i: number) => {
                                const formatDataTitle = data.map((v: any) => {
                                    if (v?.x?.length > 10) {
                                        const formatX = statisticsFunctions.formatX(v?.x);
                                        return {
                                            x: formatX,
                                            y: v.y,
                                        };
                                    }
                                    return v;
                                });
                                return <VictoryArea key={Number(i)} data={formatDataTitle} />;
                            })}
                        </VictoryGroup>
                        <VictoryPolarAxis
                            labelPlacement="perpendicular"
                            style={stylesPolarAxis}
                            tickLabelComponent={<VictoryLabel labelPlacement="vertical" style={stylesLabel} />}
                        />
                    </VictoryChart>
                ) : null}
                {allStatisticsStrongAndWeak?.strongPerformances?.length ? (
                    <>
                        {allStatisticsStrongAndWeak?.strongPerformances?.map((v: any) => (
                            <>
                                <InfoCard marginTop="0px">{v?.category || ''}</InfoCard>
                                <InfoCard fontWeight="normal">{`${Number.isInteger(v?.performance) ? `${v?.performance}%` : `${(v?.performance).toFixed(2)}%`} de aproveitamento`}</InfoCard>
                                <ContainerBar>
                                    <ProgressBar backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').progressBarStrong} width={`${v?.performance}%`} />
                                </ContainerBar>
                            </>
                        ))}
                    </>
                ) : null}
            </ContainerInfoSpecifics>
            <ContainerInfoSpecifics>
                {allStatisticsStrongAndWeak?.weakCharacterData[0]?.length > 2 || allStatisticsStrongAndWeak?.weakPerformances?.length ? (
                    <>
                        <DivNull />
                        <InfoCard color={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber}>—</InfoCard>
                        <InfoCard color={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber}>PONTOS FRACOS</InfoCard>
                    </>
                ) : null}
                {allStatisticsStrongAndWeak?.weakCharacterData[0]?.length > 2 ? (
                    <VictoryChart
                        polar
                        theme={VictoryTheme.material}
                        domain={{ y: [0, 100] }}
                        padding={paddingChart}
                        width={350}
                        height={350}
                    >
                        <VictoryGroup style={stylesVictoryGroup('')} color={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber}>
                            {allStatisticsStrongAndWeak?.weakCharacterData?.map((data: any, i: number) => {
                                const formatDataTitle = data.map((v: any) => {
                                    if (v?.x?.length > 10) {
                                        const formatX = statisticsFunctions.formatX(v?.x);
                                        return {
                                            x: formatX,
                                            y: v.y,
                                        };
                                    }
                                    return v;
                                });
                                return <VictoryArea key={Number(i)} data={formatDataTitle} />;
                            })}
                        </VictoryGroup>
                        <VictoryPolarAxis
                            labelPlacement="perpendicular"
                            style={stylesPolarAxis}
                            tickLabelComponent={<VictoryLabel labelPlacement="vertical" style={stylesLabel} />}
                        />
                    </VictoryChart>
                ) : null}
                {allStatisticsStrongAndWeak?.weakPerformances?.length ? (
                    <>
                        {allStatisticsStrongAndWeak?.weakPerformances?.map((v: any) => (
                            <>
                                <InfoCard marginTop="0px">{v?.category || ''}</InfoCard>
                                <InfoCard fontWeight="normal">{`${Number.isInteger(v?.performance) ? `${v?.performance}%` : `${(v?.performance).toFixed(2)}%`} de aproveitamento`}</InfoCard>
                                <ContainerBar>
                                    <ProgressBar backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber} width={`${v?.performance}%`} />
                                </ContainerBar>
                            </>
                        ))}
                    </>
                ) : null}
            </ContainerInfoSpecifics>
        </>
    );
};

const AllCardsRanking: React.FC<AllCardsRanking> = ({
    infoUser,
    widthActual,
    allStatisticsRanking,
}) => (
    <>
        {allStatisticsRanking?.length ? (
            <ContainerInfoSpecifics padding={widthActual > 950 ? '10px' : '10px 10px 0px 10px'}>
                <ImgStatistics src={favoriteLight} />
                <InfoCard>—</InfoCard>
                <InfoCard>RANKING</InfoCard>
                <RankingComponent infoUser={infoUser} type="fristFive" widthActual={widthActual} allStatisticsRanking={allStatisticsRanking} />
            </ContainerInfoSpecifics>
        ) : (
            <ContainerInfoSpecifics padding={widthActual > 950 ? '10px' : '10px 10px 0px 10px'}>
                <InfoCard>Não há nenhum usuário no ranking com os filtros atuais.</InfoCard>
            </ContainerInfoSpecifics>
        )}
        {allStatisticsRanking?.length > 5 ? (
            <ContainerInfoSpecifics marginTop={widthActual > 950 ? '20px' : '0px'} padding={widthActual > 950 ? '10px' : '0px 10px 10px 10px'}>
                {widthActual > 950 ? (
                    <>
                        <DivNull />
                        <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').white}>—</InfoCard>
                        <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').white}>RANKING</InfoCard>
                    </>
                ) : null}
                <RankingComponent infoUser={infoUser} type="lastFive" widthActual={widthActual} allStatisticsRanking={allStatisticsRanking} />
            </ContainerInfoSpecifics>
        ) : null}
    </>
);

export default {
    AllCards,
    AllCardsSpecifics,
    AllCardsStrongAndWeak,
    AllCardsRanking,
};
