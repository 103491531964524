/* eslint-disable max-len */
// libraries
import React, { useState } from 'react';
// import { Checkbox } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { isMobile } from 'react-device-detect';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

// js
import auth from '../../services/auth';
import colors from '../../styles/colors';
import {
    Container,
    Home,
    ContainerBox,
    LogoHeader,
    TitleInput,
    // ContainerOptions,
    // ContainerCheckBox,
    ButtonOption,
    IconOption,
    TextOption,
    ContainerBody,
    Title,
    Subtitle,
    ContainerOr,
    Dashed,
    LogoMain,
    ImgBackgroundLinearGradient,
} from './loginStyles';
import {
    iconGoogle,
    iconFacebook,
    logoFullSizeColor,
    backgroundHeaderAuthFullSize,
    backgroundLinearGradientFullSize,
    logoZero1App,
    logoFullSizeColorDark,
} from '../../assets';
import { updateUser } from '../../store/modules/user/actions';

// components
import ButtonComponent from '../../components/Button/ButtonComponent';
import InputComponent from '../../components/Input/InputComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';
import functions from '../../utils/functions';

// interfaces
interface SocialRenderProps {
    onClick: () => void,
    disabled?: boolean | undefined,
}

const LoginPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // constants
    // const colorCheckBox = { color: colors.theme(window.localStorage.getItem('theme') || 'light').blue };
    const stylesButtonFacebook: React.CSSProperties = {
        backgroundColor: 'transparent',
        color: colors.theme(window.localStorage.getItem('theme') || 'light').blue,
        borderRadius: 30,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        minWidth: '30%',
        fontWeight: 'bold',
        marginBottom: 20,
    };

    // states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);
    // const [rememberMe, setRememberMe] = useState(!!localStorage.getItem('rememberMe'));
    const [verificationSecureText, setVerificationSecureText] = useState(true);
    const [opacityBox, setOpacityBox] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    // methods
    // const onChangeRememberMe = () => {
    //     setRememberMe(!rememberMe);
    // };

    const GoToRecoverPassword = () => {
        setPathRoute('/recoverPassword');
        setRedirect(true);
    };

    const GoToRegister = () => {
        setPathRoute('/register');
        setRedirect(true);
    };

    const responseGoogle = async (response: any) => {
        if (response.profileObj) {
            const result = {
                email: response.profileObj.email || '',
                name: response.profileObj.name || '',
                id: response.profileObj.googleId,
            };
            const validationIdSocial = await auth.GetSocial(result.id, result.email);
            if (validationIdSocial?.result) {
                setOpacityBox(0.0000000001);
                setTimeout(() => {
                    const addObject = { ...validationIdSocial.result.user, token: validationIdSocial.result.token, tokenRefresh: validationIdSocial.result.tokenRefresh };
                    dispatch(updateUser(addObject));
                    setRedirect(true);
                    setPathRoute('/timeline');
                }, 1000);
            } else if (!validationIdSocial?.result) {
                const actionEncrypt = functions.Encrypt(JSON.stringify(result));
                setPathRoute(`/register/${actionEncrypt}`);
                setRedirect(true);
            }
        }
    };

    const responseFacebook = async (response: any) => {
        if (response?.email || response?.name || response?.id) {
            const result = {
                email: response.email || '',
                name: response.name || '',
                id: response.id,
            };
            const validationIdSocial = await auth.GetSocial(result.id, result.email);
            if (validationIdSocial?.result) {
                setOpacityBox(0.0000000001);
                setTimeout(() => {
                    const addObject = { ...validationIdSocial.result.user, token: validationIdSocial.result.token, tokenRefresh: validationIdSocial.result.tokenRefresh };
                    dispatch(updateUser(addObject));
                    setRedirect(true);
                    setPathRoute('/timeline');
                }, 1000);
            } else if (!validationIdSocial?.result) {
                const actionEncrypt = functions.Encrypt(JSON.stringify(result));
                setPathRoute(`/register/${actionEncrypt}`);
                setRedirect(true);
            }
        }
    };

    const Signin = async () => {
        setIsLoading(true);
        try {
            const ActiveSignin = await auth.Login(email, password);
            if (ActiveSignin?.status === 200) {
                setOpacityBox(0.0000000001);
                setTimeout(() => {
                    const addObject = { ...ActiveSignin.result.user, token: ActiveSignin.result.token, tokenRefresh: ActiveSignin.result.tokenRefresh };
                    dispatch(updateUser(addObject));
                    setRedirect(true);
                    setPathRoute('/timeline');
                    // if (rememberMe) {
                    //     localStorage.setItem('rememberMe', email);
                    // } else {
                    //     localStorage.removeItem('rememberMe');
                    // }
                }, 1000);
            } else {
                setOpacityBox(1);
            }
        } finally {
            setIsLoading(false);
        }
    };

    // renders
    const RenderButtonGoogle = (renderProps: SocialRenderProps) => {
        const {
            onClick,
        } = renderProps;
        return (
            <ButtonOption border={`1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').borderButtonGoogle}`} backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').white} onClick={onClick}>
                <IconOption src={iconGoogle} />
                <TextOption color={colors.theme(window.localStorage.getItem('theme') || 'light').borderButtonGoogle}>Entre com o Google</TextOption>
            </ButtonOption>
        );
    };

    const RenderButtonFacebook = (renderProps: SocialRenderProps) => {
        const {
            onClick,
        } = renderProps;
        return (
            <ButtonOption border={`1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').borderButtonFacebook}`} backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').white} onClick={onClick}>
                <IconOption src={iconFacebook} />
                <TextOption color={colors.theme(window.localStorage.getItem('theme') || 'light').borderButtonFacebook}>Entre com o Facebook</TextOption>
            </ButtonOption>
        );
    };

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerBody zoomHeight={opacityBox !== 1} height={isMobile ? '20vh' : '50vh'} background={backgroundHeaderAuthFullSize}>
                    <ImgBackgroundLinearGradient zoomHeight={opacityBox !== 1} background={backgroundLinearGradientFullSize}>
                        {opacityBox !== 1 ? (
                            <LogoMain src={logoZero1App} />
                        ) : null}
                    </ImgBackgroundLinearGradient>
                </ContainerBody>
                <ContainerBox opacity={opacityBox}>
                    <LogoHeader src={window.localStorage.getItem('theme') === 'dark' ? logoFullSizeColorDark : logoFullSizeColor} />
                    <Title>Olá</Title>
                    <Subtitle>Escolha a forma para realizar seu login.</Subtitle>
                    <InputComponent placeholder="Seu e-mail" type="email" value={email} setValue={setEmail} />
                    <InputComponent placeholder="Sua senha" type={verificationSecureText ? 'password' : 'email'} setVerificationSecureText={setVerificationSecureText} verificationSecureText={verificationSecureText} value={password} setValue={setPassword} />
                    <ButtonComponent disabled={isLoading} label={isLoading ? 'Carregando...' : 'ENTRAR'} onClick={Signin} />
                    <TitleInput width="auto" fontWeight="normal">
                        Esqueceu sua senha?
                        <TitleInput width="auto" activeCursor="pointer" onClick={GoToRecoverPassword} textDecoration="underline"> Clique aqui!</TitleInput>
                    </TitleInput>
                    {/* <ContainerOptions>
                        <ContainerCheckBox>
                            <Checkbox
                                checked={rememberMe}
                                onChange={onChangeRememberMe}
                                style={colorCheckBox}
                            />
                            <TitleInput width="auto">Lembrar-me</TitleInput>
                        </ContainerCheckBox>
                        <TitleInput width="auto" activeCursor="pointer" onClick={GoToRecoverPassword}>Esqueci minha senha</TitleInput>
                    </ContainerOptions> */}
                    <ContainerOr>
                        <Dashed />
                        <TitleInput width="auto" fontWeight="normal" color={colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight}> OU </TitleInput>
                        <Dashed />
                    </ContainerOr>
                    <ContainerOr>
                        <GoogleLogin
                            clientId="716307291676-mvanf1hmq7s42bcpdq415c86v6i0u39p.apps.googleusercontent.com"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy="single_host_origin"
                            render={RenderButtonGoogle}
                        />
                        <FacebookLogin
                            appId="155401636493020"
                            fields="name,email,picture"
                            callback={responseFacebook}
                            buttonStyle={stylesButtonFacebook}
                            render={RenderButtonFacebook}
                        />
                    </ContainerOr>
                    <TitleInput width="auto" fontWeight="normal">
                        Ainda não é cadastrado?
                        <TitleInput width="auto" activeCursor="pointer" onClick={GoToRegister} activeColor={colors.theme(window.localStorage.getItem('theme') || 'light').blueLight} textDecoration="underline"> Clique aqui e cadastre-se agora.</TitleInput>
                    </TitleInput>
                </ContainerBox>
            </Home>
        </Container>
    );
};

export default LoginPage;
