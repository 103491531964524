// libraries
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

// js
import colors from '../../styles/colors';

// interfaces
interface TitleInputInterfaces {
    activeColor?: string,
    activeCursor?: string,
    fontWeight?: string,
    width?: string,
    marginTop?: string,
}

interface HomeInterfaces {
    background: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    margin-bottom: 250px;
    min-height: 100vh;
    min-width: 100vw;
`;

export const LogoHeader = styled.img`
    object-fit: contain;
    height: 200px;
    width: 200px;
    margin-top: 10px;
    @media (max-width: 900px) {
        height: 120px;
        width: 120px;
    }
`;

export const ContainerBox = styled.div`
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 10px;
    position: absolute;
    top: 100px;
    overflow-y: auto;
    height: 80vh;
    width: 40%;
    @media (max-width: 900px) {
        width: 90%;
    }
`;

export const Title = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    margin-top: 40px;
    font-weight: bold;
    font-size: 26px;
`;

export const TitleInput = styled.text<TitleInputInterfaces>`
    color: ${(props) => (props.activeColor || colors.theme(window.localStorage.getItem('theme') || 'light').blue)};
    cursor: ${(props) => (props.activeCursor || 'default')};
    font-size: 16px;
    margin-top: ${(props) => (props.marginTop || '0px')};
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    width: ${(props) => (props.width || '80%')};
    @media (max-width: 900px) {
        font-size: 14px;
    }
    @media (max-width: 600px) {
        font-size: 12px;
    }
`;

export const Subtitle = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    margin-bottom: 40px;
    font-size: 20px;
    width: 80%;
    text-align: center;
`;

export const ImgBackgroundLinearGradient = styled.div<HomeInterfaces>`
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 150%;
    height: 100%;
    width: 100%;
    opacity: 0.7;
`;

export const ContainerBody = styled.div<HomeInterfaces>`
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 150%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: ${isMobile ? '20vh' : '50vh'};
    min-width: 100vw;
`;
