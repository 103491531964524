/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
// libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

// js
import colors from '../../styles/colors';
import {
    ContainerPopUp,
    ContainerAdjust,
    ContainerHeader,
    ContainerBody,
    ContainerTopHeader,
    Close,
    ImgClose,
    ContainerButtons,
    ButtonResolution,
    ButtonNextQuestion,
    ImgNext,
    ContainerIndex,
    IndexCircle,
    ContainerAdjustOptions,
    ImgOption,
    ContainerReportProblem,
    ImgAlert,
    TitleQuestion,
    ContainerTimeOut,
    ImageReport,
    InputReport,
    TitleReport,
    StyleArchive,
    RenderArchive,
    VideoResolution,
} from './popUpQuestionsStyles';
import {
    arrowRightWhite,
    closeWhite,
    alertRed,
    telegram,
    arrowGoBackPurple,
    warning,
    iconRefreshPageWhite,
} from '../../assets';
import popUpFunctions from './popUpFunctions';
import questionsApi from '../../services/questionsApi';
import { RootState } from '../../store';
import { updateUser } from '../../store/modules/user/actions';
import tests from '../../services/tests';
import simulate from '../../services/simulate';
import { IconRefresh } from '../PopUp/popUpStyles';

interface Props {
    popUp: boolean,
    setPopUp: (v: boolean) => void,
    allQuestions: Array<any>,
    setAllQuestions: (v: any) => void,
    suggested?: boolean,
    allQuestionsCompleted?: any,
    timeOutSimulateOrTest?: number,
    idType: string,
    idSelected: number,
}

const PopUpQuestions: React.FC<Props> = (props) => {
    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // dispatch
    const dispatch = useDispatch();

    // constants
    const modalPopUp = {
        overlay: {
            backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            border: 'none',
            backgroundColor: 'transparent',
            padding: '0px',
            height: '80vh',
            maxWidth: '52vw',
            top: '10vh',
            left: '24vw',
        },
    };
    const {
        popUp,
        setPopUp,
        allQuestions,
        setAllQuestions,
        allQuestionsCompleted,
        idType,
        idSelected,
        suggested,
        timeOutSimulateOrTest,
    } = props;
    const questionsCompleted = allQuestionsCompleted || [];

    // states
    const [indexActual, setIndexActual] = useState(0);
    const [idScreen, setIdScreen] = useState(1);
    const [chosenOption, setChosenOption] = useState('');
    const [timeActual, setTimeActual] = useState(new Date());
    const [timeOutActual, setTimeOutActual] = useState(-1);
    const [itemActual, setItemActual] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    // methods
    const ResetModal = () => {
        setPopUp(false);
        setAllQuestions([]);
        setIndexActual(0);
        setIdScreen(1);
        setChosenOption('');
        setTimeActual(new Date());
        setTimeOutActual(-1);
    };

    const CloseModal = () => {
        if ([2, 3].includes(idScreen)) {
            setIdScreen(1);
        } else {
            ResetModal();
        }
    };

    const GoToTelegram = () => {
        window.open('https://t.me/joinchat/LhoxCYvu9AFjMDlh');
    };

    const NextQuestion = async () => {
        if (indexActual + 1 === allQuestions?.length) {
            if (idType === 'Provas' && idSelected) {
                await tests.CheckOneTests(idSelected, infoUser.token);
            }
            if (idType === 'Simulados' && idSelected) {
                await simulate.CheckOneSimulates(idSelected, infoUser.id, infoUser.token);
            }
            ResetModal();
        } else {
            const additional = indexActual + 1;
            setIndexActual(additional);
            setTimeActual(new Date());
            setChosenOption('');
            if ([2, 3].includes(idScreen)) {
                CloseModal();
            }
        }
        setLoading(false);
    };

    const BackQuestion = () => {
        setIndexActual(indexActual - 1);
        setTimeActual(new Date());
        setChosenOption('');
    };

    const GoToResolution = () => {
        setIdScreen(2);
    };

    const ActionHitQuestion = async () => {
        const result = popUpFunctions.ValidationTimeOutQuestion(timeActual);
        await questionsApi.CheckedQuestion(
            [itemActual?.id],
            [],
            result,
            infoUser?.id,
            infoUser?.token,
            infoUser?.allInfoQuestion?.idType === 'Desafios' ? infoUser?.allInfoQuestion?.idSelected : null,
        );
        if (!infoUser?.hit_questions?.includes(itemActual?.id)) {
            const newInfoUser = {
                ...infoUser,
                hit_questions: infoUser?.hit_questions?.length ? [...infoUser?.hit_questions, itemActual?.id] : [itemActual?.id],
            };
            dispatch(updateUser(newInfoUser));
        }
        if (!questionsCompleted.includes(itemActual?.id)) {
            await questionsCompleted.push(itemActual?.id);
        }
        if (idType === 'Cadernos') {
            await questionsApi.EditQuestionsCompleted(
                [...questionsCompleted, itemActual?.id],
                idSelected,
                suggested || false,
                infoUser.id,
                infoUser.token,
            );
        }
        NextQuestion();
    };

    const ActionMissQuestion = async () => {
        const result = popUpFunctions.ValidationTimeOutQuestion(timeActual);
        await questionsApi.CheckedQuestion(
            [],
            [itemActual?.id],
            result,
            infoUser?.id,
            infoUser?.token,
            infoUser?.allInfoQuestion?.idType === 'Desafios' ? infoUser?.allInfoQuestion?.idSelected : null,
        );
        if (!infoUser?.miss_questions?.includes(itemActual?.id)) {
            const newInfoUser = {
                ...infoUser,
                miss_questions: infoUser?.miss_questions?.length ? [...infoUser?.miss_questions, itemActual?.id] : [itemActual?.id],
            };
            dispatch(updateUser(newInfoUser));
        }
        if (!questionsCompleted.includes(itemActual?.id)) {
            await questionsCompleted.push(itemActual?.id);
        }
        if (idType === 'Cadernos') {
            await questionsApi.EditQuestionsCompleted(
                [...questionsCompleted, itemActual?.id],
                idSelected,
                suggested || false,
                infoUser.id,
                infoUser.token,
            );
        }
        NextQuestion();
    };

    const ActionHitQuestionClose = () => {
        setLoading(true);
        if (chosenOption !== '' && chosenOption === itemActual?.feedback) {
            ActionHitQuestion();
        } else if (chosenOption !== '' && chosenOption !== itemActual?.feedback) {
            ActionMissQuestion();
        } else {
            // eslint-disable-next-line no-alert
            alert('Selecione uma opção');
            setLoading(false);
        }
    };

    const OpenReport = () => {
        setIdScreen(3);
    };

    const GetArchiveQuestion = async () => {
        const GetArchivesForQuestion = await questionsApi.GetOnlyArchiveQuestion(allQuestions[indexActual]?.id, infoUser?.token);
        if (GetArchivesForQuestion?.status === 200) {
            const newItemActual = {
                ...GetArchivesForQuestion?.result,
                statement: GetArchivesForQuestion?.result?.statement,
                answer: GetArchivesForQuestion?.result?.answer,
            };
            setItemActual(newItemActual);
        }
    };

    const renderAlertExpired = () => {
        if (idType === 'Provas') {
            return 'da prova.';
        }
        if (idType === 'Desafios Diários') {
            return 'do desafio.';
        }
        return 'do simulado.';
    };

    // useEffect
    useEffect(() => {
        if (timeOutActual >= 1) {
            setTimeout(() => {
                setTimeOutActual(timeOutActual - 1);
            }, 1000);
        } else if (timeOutActual === 0) {
            const descriptionExpired = renderAlertExpired();
            alert(`Tempo expirado ${descriptionExpired}`);
            popUpFunctions.handleTimerFinish(CloseModal, idSelected, infoUser, idType);
        } else if (timeOutSimulateOrTest && timeOutActual === -1) {
            setTimeOutActual(timeOutSimulateOrTest);
        }
    }, [timeOutActual]);

    useEffect(() => {
        if (allQuestions?.length > 0) {
            GetArchiveQuestion();
        }
    }, [indexActual, allQuestions]);

    // renders
    const RenderIndex = () => (
        allQuestions?.map((v, i) => {
            const HitQuestionActual = infoUser?.hit_questions?.length > 0 ? infoUser?.hit_questions.includes(v?.id) : false;
            const MissQuestionActual = infoUser?.miss_questions?.length > 0 ? infoUser?.miss_questions.includes(v?.id) : false;
            const validationHit = HitQuestionActual ? colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong : null;
            const validationMiss = MissQuestionActual ? colors.theme(window.localStorage.getItem('theme') || 'light').pointRadialWeak : null;
            const TradeIndex = () => setIndexActual(i);
            return (
                <IndexCircle
                    onClick={TradeIndex}
                    focus={indexActual === i}
                    key={String(v.id)}
                    newColor={validationHit || validationMiss || colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                >
                    {i + 1}
                </IndexCircle>
            );
        })
    );

    return (
        <Modal isOpen={popUp} style={modalPopUp} onRequestClose={CloseModal}>
            <ContainerPopUp>
                <ContainerAdjust>
                    <ContainerHeader>
                        <ContainerTopHeader>
                            <Close onClick={CloseModal}>
                                <ImgClose src={closeWhite} />
                            </Close>
                        </ContainerTopHeader>
                        {idScreen === 1 ? (
                            <>
                                <ContainerAdjustOptions>
                                    <ContainerIndex width="30vw" overflowX="auto">
                                        {RenderIndex()}
                                    </ContainerIndex>
                                    <ContainerIndex width="18%" justifyContent="flex-end">
                                        <ContainerReportProblem onClick={OpenReport}>
                                            <ImgAlert src={alertRed} />
                                        </ContainerReportProblem>
                                        <ImgOption src={telegram} onClick={GoToTelegram} />
                                    </ContainerIndex>
                                </ContainerAdjustOptions>
                            </>
                        ) : null}
                    </ContainerHeader>
                    <ContainerBody>
                        {idScreen === 1 ? (
                            <ContainerAdjust>
                                {timeOutActual !== -1 ? (
                                    <ContainerTimeOut>
                                        {popUpFunctions.RenderTimer(timeOutActual)}
                                    </ContainerTimeOut>
                                ) : null}
                                <TitleQuestion>{`${itemActual?.id ? `${itemActual.id} -` : ''} Enunciado ${popUpFunctions.RenderLevel(itemActual?.level)}`}</TitleQuestion>
                                <TitleQuestion>{itemActual?.title}</TitleQuestion>
                                <StyleArchive largeSize={itemActual?.options?.length > 0}>
                                    <RenderArchive dangerouslySetInnerHTML={{ __html: itemActual?.statement }} />
                                </StyleArchive>
                                {itemActual?.options?.length > 0 ? (
                                    <>
                                        <ContainerIndex width="100%" justifyContent="space-evenly" margin="10px 0px 10px 0px" flexWrap="wrap">
                                            {popUpFunctions.RenderAlternatives(
                                                itemActual?.options,
                                                itemActual?.feedback,
                                                chosenOption,
                                                setChosenOption,
                                                infoUser,
                                                itemActual,
                                            )}
                                        </ContainerIndex>
                                        <ButtonResolution onClick={ActionHitQuestionClose}>
                                            {loading ? <IconRefresh animation src={iconRefreshPageWhite} /> : 'Confirmar'}
                                        </ButtonResolution>
                                    </>
                                ) : null}
                            </ContainerAdjust>
                        ) : null}
                        {idScreen === 2 ? (
                            <ContainerAdjust>
                                <StyleArchive>
                                    <RenderArchive dangerouslySetInnerHTML={{ __html: itemActual?.answer }} />
                                </StyleArchive>
                                {itemActual?.video ? (
                                    <VideoResolution src={itemActual?.video} width="100%" height="250" controls />
                                ) : null}
                                {!itemActual?.options?.length && itemActual?.option_right_or_wrong !== 0 && itemActual?.option_right_or_wrong !== 1 ? (
                                    <ContainerIndex width="100%" justifyContent="space-evenly" margin="10px 0px 0px 0px">
                                        <ButtonResolution
                                            onClick={ActionMissQuestion}
                                        >
                                            Errei
                                        </ButtonResolution>
                                        <ButtonResolution
                                            onClick={ActionHitQuestion}
                                        >
                                            Acertei
                                        </ButtonResolution>
                                    </ContainerIndex>
                                ) : null}
                            </ContainerAdjust>
                        ) : null}
                        {idScreen === 3 ? (
                            <ContainerIndex
                                flexDirection="column"
                                width="100%"
                            >
                                <ImageReport src={warning} />
                                <TitleReport>Reporte um problema</TitleReport>
                                <InputReport placeholder="Digite a sua mensagem" />
                                <ButtonResolution backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').pointRadialWeak}>Enviar</ButtonResolution>
                            </ContainerIndex>
                        ) : null}
                    </ContainerBody>
                </ContainerAdjust>
                {idScreen === 1 ? (
                    <ContainerButtons>
                        <ButtonResolution onClick={GoToResolution}>Ver Resolução</ButtonResolution>
                        <ContainerIndex width="50%" justifyContent={indexActual ? 'space-between' : 'flex-end'}>
                            {indexActual ? (
                                <ButtonNextQuestion
                                    onClick={BackQuestion}
                                    backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').white}
                                    border={`1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}`}
                                    color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                    fontWeight="500"
                                >
                                    <ImgNext src={arrowGoBackPurple} margin="0px 5px 0px 0px" />
                                    Anterior
                                </ButtonNextQuestion>
                            ) : null}
                            <ButtonNextQuestion onClick={NextQuestion}>
                                Próximo Exercício
                                <ImgNext src={arrowRightWhite} margin="0px 0px 0px 5px" />
                            </ButtonNextQuestion>
                        </ContainerIndex>
                    </ContainerButtons>
                ) : null}
            </ContainerPopUp>
        </Modal>
    );
};

export default PopUpQuestions;
