// libraries
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerAdjustInfoInterfaces {
    flexDirection?: string,
    alignItems?: string,
    marginTop?: string,
    width?: string,
    justifyContent?: string,
}
interface ContainerStatisticsInterfaces {
    existStatistics: boolean,
    marginLeft?: string,
    marginLeftLowLarge?: string,
}
interface InfoCardInterfaces {
    marginTop?: string,
    padding?: string,
    marginLeft?: string,
}
interface AdjustInfoInterfaces {
    padding?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

// constants
const fadeInAnimation = keyframes`${fadeIn}`;

// styles
export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
`;

export const ContainerBody = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-top: 100px;
    width: 100%;
`;

export const ContainerBoxFilters = styled.div`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 30px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 97%;
    @media (max-width: 600px) {
        width: 90%;
    }
`;

export const ContainerAdjustInfo = styled.div<ContainerAdjustInfoInterfaces>`
    display: flex;
    align-items: ${(props) => (props.alignItems || 'flex-start')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    flex-direction: ${(props) => (props.flexDirection || 'column')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: ${(props) => (props.width || '95%')};
`;

export const InfoCard = styled.text<InfoCardInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    display: flex;
    margin-top: ${(props) => (props.marginTop || '5px')};
    flex-direction: column;
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    font-size: 20px;
    width: ${(props) => (props.width || 'auto')};
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;

export const ContainerFilters = styled.div<ContainerStatisticsInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.existStatistics ? 'center' : 'flex-start')};
    flex-direction: column;
    display: flex;
    width: 100%;
    margin-left: ${(props) => (props.marginLeft || '0px')};
    /* margin-right: 310px; */
    transition-duration: 400ms;
    overflow: hidden;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        /* margin-right: 215px; */
        margin-left: ${(props) => (props.marginLeftLowLarge || '0px')};
    }
    @media (max-width: 1200px) {
        /* margin-right: 5px; */
    }
    @media (max-width: 799px) {
        margin-left: 0px;
    }
`;

export const ContainerSearch = styled.div`
    align-items: center;
    height: 40px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    border-radius: 20px;
    display: flex;
    padding: 0px 15px 0px 15px;
    margin-right: 15px;
    width: 100%;
`;

export const ImgSearch = styled.img`
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-right: 10px;
    @media (max-width: 1700px) {
        height: 20px;
        width: 20px;
    }
    @media (max-width: 1400px) {
        height: 18px;
        width: 18px;
    }
`;

export const InputSearch = styled.input`
    background-color: transparent;
    border: none;
    height: 35px;
    font-size: 16px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    font-weight: bold;
    width: 90%;
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
        font-weight: normal;
    }
`;

export const ImgFilter = styled.img`
    height: 36px;
    margin-right: 10px;
    cursor: pointer;
    width: 36px;
    @media (max-width: 1700px) {
        height: 30px;
        width: 30px;
    }
    @media (max-width: 1400px) {
        height: 24px;
        width: 24px;
    }
`;

export const ContainerAllFilters = styled.div<ContainerAdjustInfoInterfaces>`
    margin-top: 20px;
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'space-between')};
    width: 95%;
    flex-wrap: wrap;
    @media (max-width: 600px) {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const ContainerCalendar = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    min-height: 80vh;
    width: 300px;
    position: absolute;
    right: 15px;
    margin-top: 10px;
    @media (max-width: 1800px) {
        margin-top: 0px;
    }
    @media (max-width: 1600px) {
        width: 200px;
    }
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const TitleCalendar = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    margin-bottom: 20px;
`;

export const ButtonAddFilter = styled.div`
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: 100px;
    position: absolute;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blueSea};
    font-size: 30px;
    font-weight: bold;
    bottom: 20px;
    right: 350px;
    width: 100px;
    @media (max-width: 1600px) {
        right: 250px;
        height: 60px;
        width: 60px;
    }
    @media (max-width: 1200px) {
        right: 30px;
        height: 50px;
        width: 50px;
    }
    :hover {
        opacity: .8;
    }
`;

export const AddFilter = styled.img`
    object-fit: contain;
    height: 45px;
    width: 45px;
    @media (max-width: 1600px) {
        height: 35px;
        width: 35px;
    }
    @media (max-width: 1200px) {
        height: 30px;
        width: 30px;
    }
`;

export const ContainerOptionsDrawer = styled.div`
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px;
    width: auto;
`;

export const ContainerExamination = styled.div<AdjustInfoInterfaces>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: 1s ${fadeInAnimation};
    padding: ${(props) => (props.padding || '0px')};
    cursor: pointer;
    width: 100%;
    background-color: rgba(117, 80, 241, .8);
    padding: 10px 10px;
    border-radius: 16px;
    :hover {
        background-color: rgba(117, 80, 241, 0.6);
    }
    
`;

export const NameExamination = styled.text<ContainerAdjustInfoInterfaces>`
    color: ${(props) => (colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    font-size: 20px;
    margin: 0 auto;
        

    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;

export const AdjustInfo = styled.div`
    align-items: center;
    display: flex;
    width: auto;
`;

export const ContainerOneExamination = styled.div`
    width: 45%;
    max-width: 45%;
    margin-top: 10px;
    @media (max-width: 600px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const IconDropDawnExamination = styled.img`
    object-fit: contain;
    height: 6px;
    width: 28px;
    @media (max-width: 1700px) {
        height: 5px;
        width: 22px;
    }
    @media (max-width: 1400px) {
        height: 4px;
        width: 18px;
    }
`;
