// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface IndexCircleInterfaces {
    focus?: boolean,
    newColor?: string | null,
}

interface ContainerIndexInterfaces {
    width?: string,
    justifyContent?: string,
    margin?: string,
    flexWrap?: string,
    flexDirection?: string,
    overflowX?: string,
}

interface ButtonResolutionInterfaces {
    backgroundColor?: string,
}

interface StyleArchiveInterfaces {
    largeSize?: boolean,
    height?: string,
}

interface ButtonNextQuestionInterfaces {
    backgroundColor?: string,
    border?: string,
    color?: string,
    fontWeight?: string,
}

interface ButtonAlternativeInterfaces {
    select: boolean,
}

interface ImgNextInterfaces {
    margin?: string,
}

// styles
export const ContainerPopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    justify-content: space-between;
    margin-left: 10px;
    transition-duration: 400ms;
    min-width: 50vw;
    min-height: 60vh;
    max-height: 80vh;
    overflow: hidden;
`;

export const ContainerAdjust = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerHeader = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-height: 15vh;
    max-height: 15vh;
    padding: 0px 10px 0px 10px;
    width: 100%;
`;

export const ContainerBody = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 10px 10px 10px;
    margin-bottom: 0px;
    max-height: 50vh;
    min-height: 50vh;
    width: 100%;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const ContainerTopHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    width: 100%;
`;

export const Close = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    /* background-color: pink; */
    display: flex;
    @media (max-width: 600px) {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

export const ImgClose = styled.img`
    height: 16px;
    width: 16px;
    @media (max-width: 600px) {
        height: 12px;
        width: 12px;
    }
    @media (max-width: 500px) {
        height: 8px;
        width: 8px;
    }
`;

export const ContainerSearch = styled.div`
    align-items: center;
    height: 40px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 20px;
    display: flex;
    padding: 0px 15px 0px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
`;

export const ImgSearch = styled.img`
    height: 24px;
    margin-right: 10px;
    width: 24px;
`;

export const InputSearch = styled.input`
    background-color: transparent;
    border: none;
    height: 35px;
    font-size: 16px;
    font-weight: bold;
    width: 90%;
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        font-weight: normal;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 90%;
`;

export const ButtonResolution = styled.div<ButtonResolutionInterfaces>`
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark)};
    font-weight: bold;
    border: none;
    border-radius: 30px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    cursor: pointer;
    min-width: 30%;
    max-width: 50%;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    @media (max-height: 600px) {
        min-height: 45px;
    }
    @media (max-height: 500px) {
        min-height: 35px;
    }
    @media (max-width: 600px) {
        min-height: 45px;
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 10px;
        min-height: 35px;
    }
    @media (max-width: 400px) {
        min-height: 25px;
    }
`;

export const ButtonNextQuestion = styled.div<ButtonNextQuestionInterfaces>`
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics)};
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    border: ${(props) => (props.border || 'none')};
    border-radius: 30px;
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    min-width: 48%;
    @media (max-height: 600px) {
        min-height: 45px;
    }
    @media (max-height: 500px) {
        min-height: 35px;
    }
    @media (max-width: 600px) {
        min-height: 45px;
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 10px;
        min-height: 35px;
    }
    @media (max-width: 400px) {
        min-height: 25px;
    }
`;

export const ImgNext = styled.img<ImgNextInterfaces>`
    height: 14px;
    margin: ${(props) => (props.margin || '0px')};
    width: 7.4px;
`;

export const ContainerIndex = styled.div<ContainerIndexInterfaces>`
    display: flex;
    align-items: center;
    flex-direction: ${(props) => (props.flexDirection || 'flex')};
    overflow-x: ${(props) => (props.overflowX || 'visible')};
    margin: ${(props) => (props.margin || '0px')};
    flex-wrap: ${(props) => (props.flexWrap || 'nowrap')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    min-width: ${(props) => (props.width || '80%')};
    max-width: ${(props) => (props.width || '80%')};
    padding-bottom: 5px;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const IndexCircle = styled.div<IndexCircleInterfaces>`
    min-height: 50px;
    border-radius: 25px;
    display: flex;
    min-width: 50px;
    align-items: center;
    margin-right: 10px;
    border: ${(props) => (`1px solid ${props.newColor}`)};
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    color: ${(props) => (props.focus ? colors.basic(window.localStorage.getItem('theme') || 'light').white : colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark)};
    background-color: ${(props) => (props.focus ? colors.theme(window.localStorage.getItem('theme') || 'light').backgroundIndexQuestion : colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    @media (max-width: 600px) {
        min-width: 40px;
        min-height: 40px;
    }
    @media (max-width: 500px) {
        min-width: 35px;
        min-height: 35px;
    }
    @media (max-width: 400px) {
        min-width: 30px;
        min-height: 30px;
    }
`;

export const ContainerAdjustOptions = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 90%;
`;

export const ContainerReportProblem = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber};
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
    @media (max-width: 600px) {
        min-width: 35px;
        min-height: 35px;
        max-width: 35px;
        max-height: 35px;
    }
    @media (max-width: 500px) {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }
    @media (max-width: 400px) {
        min-width: 25px;
        min-height: 25px;
        max-width: 25px;
        max-height: 25px;
    }
`;

export const ImgOption = styled.img`
    height: 45px;
    margin-left: 10px;
    cursor: pointer;
    object-fit: contain;
    width: 45px;
    @media (max-width: 600px) {
        width: 40px;
        height: 40px;
    }
    @media (max-width: 500px) {
        width: 35px;
        height: 35px;
    }
    @media (max-width: 400px) {
        width: 30px;
        height: 30px;
    }
`;

export const ImgAlert = styled.img`
    height: 41px;
    width: 7.5px;
    @media (max-width: 400px) {
        height: 20.5px;
        width: 3.75px;
    }
`;

export const TitleQuestion = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    font-size: 14px;
`;

export const ButtonAlternative = styled.div<ButtonAlternativeInterfaces>`
    align-items: center;
    display: flex;
    justify-content: center;
    color: ${(props) => (props.select ? colors.basic(window.localStorage.getItem('theme') || 'light').white : colors.theme(window.localStorage.getItem('theme') || 'light').blue)};
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
    background-color: ${(props) => (props.select ? colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong : colors.basic(window.localStorage.getItem('theme') || 'light').white)};
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: ${(props) => (props.select ? 'none' : `1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').blue}`)};
`;

export const ContainerTimeOut = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blue};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    font-size: 14px;
    font-weight: bold;
    height: 25px;
    width: 100px;
    border-radius: 5px;
    margin-bottom: 10px;
    @media (max-width: 600px) {
        font-size: 13px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

export const ImageReport = styled.img`
    object-fit: contain;
    height: 54px;
    width: 54px;
`;

export const TitleReport = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
    font-weight: 500;
    margin-top: 10px;
    font-size: 16px;
`;

export const InputReport = styled.textarea`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 10px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
    padding: 10px;
    margin: 20px 0px 20px 0px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
    height: 100px;
    width: 70%;
    font-weight: normal;
    vertical-align: top;
    resize: none;
    font-family: inherit;
`;

export const StyleArchive = styled.div<StyleArchiveInterfaces>`
    max-height: ${(props) => (props.largeSize ? (props.height || '25vh') : (props.height || '40vh'))};
    overflow-y: auto;
    width: 50vw;
    p {
        text-align: justify;
        font-size: 14px;
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').black};
        font-family: 'Nunito';
    }
    img {
        width: 100%;
    }
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const RenderArchive = styled.p``;

export const AdjustAlternative = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const PercentageAlternative = styled.text`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blue};
    font-size: 12px;
    margin-top: 5px;
    font-weight: bold;
`;

export const VideoResolution = styled.video`
    margin: 20px 0px 10px 0px;
`;
