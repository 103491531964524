// js
import {
    optionGenerateSimulate,
    optionQuestion,
    optionSimulate,
    optionTest,
} from '../../assets/index';

const isProd = window.location.hostname.includes('app.engequest');

const allOptions = isProd ? [
    {
        id: 1,
        title: 'QUESTÕES',
        description: 'Treine com questões por disciplina',
        file: optionQuestion,
    },
    {
        id: 3,
        title: 'SIMULADOS',
        description: 'Teste seus conhecimentos',
        file: optionSimulate,
    },
    {
        id: 6,
        title: 'DESAFIOS DIÁRIOS',
        description: 'Treine com desafios',
        file: optionQuestion,
    },
] : [
    {
        id: 1,
        title: 'QUESTÕES',
        description: 'Treine com questões por disciplina',
        file: optionQuestion,
    },
    // {
    //     id: 2,
    //     title: 'PROVAS',
    //     description: 'Treine com provas passadas',
    //     file: optionTest,
    // },
    {
        id: 3,
        title: 'SIMULADOS',
        description: 'Teste seus conhecimentos',
        file: optionSimulate,
    },
    {
        id: 6,
        title: 'DESAFIOS DIÁRIOS',
        description: 'Treine com desafios',
        file: optionQuestion,
    },
    // {
    //     id: 7,
    //     title: 'REDAÇÃO',
    //     description: 'Treine com dicas de redação',
    //     file: optionGenerateSimulate,
    // },
];

export default {
    allOptions,
};
