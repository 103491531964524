// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface IconInterfaces {
    isMobile: boolean,
}

// styles
export const ContainerInput = styled.div`
    align-items: center;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight};
    display: flex;
    justify-content: space-between;
    border-radius: 30px;
    margin: 10px 0px 20px 0px;
    padding: 0px 10px 0px 30px;
    min-height: 60px;
    width: 80%;
    @media (max-width: 900px), (max-height: 900px) {
        min-height: 50px;
        margin: 5px 0px 10px 0px;
    }
    @media (max-width: 600px), (max-height: 650px) {
        min-height: 40px;
    }
`;

export const Input = styled.input`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    font-size: 16px;
    border: none;
    min-height: 100%;
    width: 90%;
    @media (max-width: 900px) {
        font-size: 15px;
    }
    @media (max-width: 650px), (max-height: 650px) {
        font-size: 14px;
    }
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
    ::-webkit-calendar-picker-indicator {
        background: none;
    }
`;

export const Icon = styled.img<IconInterfaces>`
    object-fit: contain;
    height: ${(props) => (props.isMobile ? '20px' : '25px')};
    width: ${(props) => (props.isMobile ? '20px' : '25px')};
`;
