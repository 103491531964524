// js
import colors from '../../styles/colors';
import statistics from '../../services/statistics';
import { DataUserInterface } from '../../interfaces';
import filters from '../../services/filters';
import categories from '../../services/categories';
import books from '../../services/books';

const ValidationBackgroundStatisticsGeneral = (type: string): string => {
    if (type === 'Geral') {
        return colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen;
    }
    return colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen;
};

const GetRanking = async (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    allData: any,
    typeOrderId: number,
    setAllStatisticsRanking: (v: any) => void,
    idExamination: number,
    idEngineering: number,
    infoUser: DataUserInterface,
): Promise<any> => {
    const GetInfoStatisticsRanking = await statistics.GetStatisticsRanking(
        idExamination,
        idEngineering,
        allData?.length === 9 ? allData[typeOrderId]?.id || 'ALL' : 'ALL',
        '',
        '',
        infoUser?.id || 0,
        infoUser?.token || '',
    );
    if (GetInfoStatisticsRanking?.status === 200) {
        setAllStatisticsRanking(GetInfoStatisticsRanking?.result);
    }
};

const GetSpecifics = async (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    allData: any,
    typeOrderId: number,
    setAllStatisticsSpecifics: (v: any) => void,
    idFilters: Array<number>,
    selectedSubjects: Array<any>,
    infoUser: DataUserInterface,
): Promise<any> => {
    const GetInfoStatisticsSpecific = await statistics.GetStatisticsSpecific(
        allData?.length === 6 ? allData[typeOrderId]?.id || 'LAST_6_MONTHS' : 'LAST_6_MONTHS',
        idFilters,
        selectedSubjects.map((v) => v?.id || v),
        infoUser?.id || 0,
        infoUser?.token || '',
    );
    if (GetInfoStatisticsSpecific?.status === 200) {
        setAllStatisticsSpecifics(GetInfoStatisticsSpecific?.result);
    }
};

const GetGeneral = async (
    setAllStatisticsGeneral: (v: any) => void,
    infoUser: DataUserInterface,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    allData: any,
    typeOrderId: number,
    start: string,
    end: string,
): Promise<any> => {
    const RequestGeneral = async () => {
        const GetInfoStatisticsGeneral = await statistics.GetStatisticsGeneral(
            allData?.length === 9 ? allData[typeOrderId]?.id || 'ALL' : 'ALL',
            start,
            end,
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        if (GetInfoStatisticsGeneral?.status === 200) {
            setAllStatisticsGeneral(GetInfoStatisticsGeneral?.result || null);
        }
    };
    if (allData[typeOrderId]?.id === 'CUSTOM' && start?.length === 10 && end?.length === 10) {
        RequestGeneral();
    }
    if (allData[typeOrderId]?.id !== 'CUSTOM') {
        RequestGeneral();
    }
};

const GetStrongAndWeak = async (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    allData: any,
    idBook: number,
    typeOrderId: number,
    start: string,
    end: string,
    setAllStatisticsStrongAndWeak: (v: any) => void,
    infoUser: DataUserInterface,
): Promise<any> => {
    const RequestStrongAndWeak = async () => {
        const GetInfoStatisticsStrongAndWeak = await statistics.GetStatisticsStrongAndWeak(
            idBook,
            allData?.length === 9 ? allData[typeOrderId]?.id || 'ALL' : 'ALL',
            '',
            '',
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        if (GetInfoStatisticsStrongAndWeak?.status === 200) {
            setAllStatisticsStrongAndWeak(GetInfoStatisticsStrongAndWeak?.result || null);
        }
    };
    if (allData[typeOrderId]?.id === 'CUSTOM' && start?.length === 10 && end?.length === 10) {
        RequestStrongAndWeak();
    }
    if (allData[typeOrderId]?.id !== 'CUSTOM') {
        RequestStrongAndWeak();
    }
};

const ResizeAxisGraph = (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    resizeAxis: any,
    setResizeAxis: (v: any) => void,
): any => {
    if (window.innerWidth < 1700) {
        setResizeAxis({
            ...resizeAxis,
            tickLabels: {
                ...resizeAxis.tickLabels,
                fontSize: 18,
            },
        });
    }
    if (window.innerWidth < 1400) {
        setResizeAxis({
            ...resizeAxis,
            tickLabels: {
                ...resizeAxis.tickLabels,
                fontSize: 14,
            },
        });
    }
};

const GetAllFilters = async (
    idCategory: number,
    setAllFilters: (v: any) => void,
    infoUser: DataUserInterface,
): Promise<any> => {
    const GetFilters = await filters.GetAllFilters(
        idCategory,
        '',
        infoUser?.token || '',
    );
    setAllFilters(GetFilters?.result);
};

const GetAllCategories = async (
    setAllCategories: (v: any) => void,
    infoUser: DataUserInterface,
): Promise<any> => {
    const GetCategories = await categories.GetAllCategories(
        infoUser?.id || 0,
        infoUser?.token || '',
    );
    setAllCategories(GetCategories?.result);
};

const GetAllBooks = async (
    setAllBooks: (v: any) => void,
    infoUser: DataUserInterface,
): Promise<any> => {
    const GetAllResultBooks = await books.GetAllBooks(
        '',
        'id',
        'asc',
        false,
        infoUser?.id || 0,
        infoUser?.token || '',
    );
    const GetOnlyBooks = GetAllResultBooks?.result?.map((v: any) => v?.result);
    const newArray: any = [];
    GetOnlyBooks?.map((value: any) => newArray.push(...value));
    setAllBooks(newArray);
};

const formatX = (v: string): string => {
    if (v?.length >= 30 && v?.length <= 45) {
        return `${v.substring(0, 15)}\n${v.substring(15, 30)}\n${v.substring(30, 45)}`;
    }
    if (v?.length > 45 && v?.length <= 90) {
        const valueInitial = `${v.substring(0, 15)}\n${v.substring(15, 30)}\n${v.substring(30, 45)}`;
        return `${valueInitial}\n${v.substring(45, 60)}\n${v.substring(60, 75)}\n${v.substring(75, 90)}`;
    }
    return `${v.substring(0, 15)}\n${v.substring(15, 30)}`;
};

export default {
    ValidationBackgroundStatisticsGeneral,
    GetSpecifics,
    GetRanking,
    GetGeneral,
    GetStrongAndWeak,
    ResizeAxisGraph,
    GetAllFilters,
    GetAllCategories,
    GetAllBooks,
    formatX,
};
