/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
// libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { addHours, differenceInSeconds, lightFormat } from 'date-fns';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// js
import Loader from 'react-loader-spinner';
import colors from '../../styles/colors';
import {
    ContainerPopUp,
    ContainerHeader,
    Title,
    Close,
    ImgClose,
    ContainerBody,
    ContainerButtons,
    ButtonAction,
    CheckBoxSubject,
    CheckBox,
    ContainerTopHeader,
    ContainerSearch,
    ImgSearch,
    InputSearch,
    ContainerAdjust,
    ContainerOptionBook,
    NameOption,
    ImgOption,
    ImgDropDawn,
    InputRenameBook,
    ContainerBodyCreateBook,
    ContainerSelect,
    Select,
    Option,
    InputNameBook,
    ButtonActionBook,
    ContainerButtonsBooks,
    ButtonSaveFilter,
    ContainerTest,
    TitleTest,
    ContainerAdjustTags,
    ContainerTags,
    TxtTags,
    ContainerBodyInfo,
    IconEvent,
    TotalQuestions,
    ContainerInfo,
    ContainerSeparatorSearch,
    ImgFilter,
    ContainerBodyTestAndSimulate,
    ContainerBodyFilter,
    AdjustEmpty,
    ButtonOptionSimulate,
    TextButtonOptionSimulate,
    InfoCard,
    ContainerAdjustFilter,
    ContainerSubjects,
    AdjustButtonsFilterOrder,
    ButtonFilterOrder,
    TextFilterOrder,
    CardSimulate,
    TitleCardSimulate,
    ResultCardSimulate,
    ContainerDeleteCardSimulate,
    ContainerAllCardSimulate,
    InputQtdQuestion,
    DivNull,
    ContainerAdjustHeader,
    ContainerSelectedTypeBook,
    ButtonTypeBook,
    IconRefresh,
    DescriptionExamination,
    ImgTarget,
    AdjustTitle,
    ImageDropDawn,
    ContainerAdjustAbout,
    Welcome,
    AnimationDescription,
    ArrowForDropDawn,
    Line,
    TextFilterConfirm,
    ContainerLoader,
    ContainerDownload,
} from './popUpStyles';
import {
    ContainerCategory,
    NameCategory,
    IconDropDawnCategory,
    AdjustInfo,
    ContainerQtd,
    ContainerPeriod,
    SelectPeriod,
    ContainerCategorySolo,
} from '../../pages/Statistics/statisticsStyles';
import {
    activityWhite,
    arrowDropDawn,
    arrowDropDawnLight,
    arrowDropDawnPurple,
    arrowDropDawnPurpleTop,
    closeWhite,
    // edit,
    editWhite,
    eventBlue,
    // eventsBlue,
    filterWhite,
    iconRefreshPage,
    // iconRefreshPage,
    iconRefreshPageWhite,
    // peopleBlue,
    searchWhite,
    tickCorrect,
    tickError,
} from '../../assets';
// import functions from '../../utils/functions';
import FiltersAndSubjectsComponent from './components/FiltersAndSubjectsComponent';
import ButtonTestAndSimulate from './components/ButtonTestAndSimulate';
import books from '../../services/books';
import { RootState } from '../../store';
import auth from '../../services/auth';
import filters from '../../services/filters';
import popUpConstants from './popUpConstants';
import { PopUpInterfaces } from './popUpInterfaces';
import tests from '../../services/tests';
import simulate from '../../services/simulate';
import questionsApi from '../../services/questionsApi';
import { updateUser } from '../../store/modules/user/actions';
import tournament from '../../services/tournament';
// import PickerComponent from '../../pages/Statistics/components/Picker/PickerComponent';
import ModalPickerComponent from '../../pages/Statistics/components/ModalPickerComponent';
import statisticsConstants from '../../pages/Statistics/statisticsConstants';
import essay from '../../services/essay';

const PopUpComponent: React.FC<PopUpInterfaces> = ({
    popUp,
    setPopUp,
    title,
    allData,
    selectDropdawn,
    setSelectDropdawn,
    selectFilter,
    setSelectFilter,
    allSubjectsSelect,
    setAllSubjectsSelect,
    ApplyFilter,
    allBooks,
    idBook,
    setIdBook,
    nameBook,
    setNameBook,
    setEngineering,
    setExamination,
    allExamination,
    allEngineering,
    examination,
    engineering,
    setAllBooks,
    setAllExamination,
    setAllEngineering,
    setAllFilters,
    idOrder,
    setIdOrder,
    typeBook,
    bookSelected,
    RefreshInitial,
    setIdPopUp,
    filtersSavedUser,
    selectFilterSaved,
    idScreen,
    allCategories,
    nameCreateFilter,
    setNameCreateFilter,
    allTests,
    setAllTests,
    allTournament,
    setAllTournament,
    allInfoEssay,
    setAllInfoEssay,
    allTextSupport,
    setAllTextSupport,
    allSimulate,
    setAllSimulate,
    allSimulateTwo,
    setAllSimulateTwo,
    allSolicitation,
    // setAllSolicitation,
    setAllQuestions,
    idPopUp,
    flowOrderBy,
    setFlowOrderBy,
    ActionApplyOrder,
    CleanFilters,
    isLoading,
}) => {
    // dispatch
    const dispatch = useDispatch();

    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const modalPopUpDefault = {
        overlay: {
            backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            border: 'none',
            backgroundColor: 'transparent',
            padding: '0px',
            height: '77vh',
            minWidth: '300px',
            margin: 'auto auto',
            maxWidth: '55vw',
            '@media (minWidth: 700px)': {
                minWidth: '500px',
            },
        },
    };

    // states
    const [search, setSearch] = useState('');
    const [idExamination, setIdExamination] = useState('1');
    const [idEngineering, setIdEngineering] = useState('1');
    const [nameCreateBook, setNameCreateBook] = useState('');
    const [enabledRename, setEnabledRename] = useState(false);
    const [allFiltersSelected, setAllFiltersSelected] = useState<Array<number>>([]);
    const [allDataDefault, setAllDataDefault] = useState([]);
    const [idPopUpOld, setIdPopUpOld] = useState(-1);
    const [typeSimulate, setTypeSimulate] = useState('');
    const [typeQuestionsSelected, setTypeQuestionsSelected] = useState<Array<string>>([]);
    const [allCards, setAllCards] = useState<Array<any>>([]);
    const [allDataDefaultTemporary, setAllDataDefaultTemporary] = useState<any>([]);
    const [modalPopUp, setModalPopUp] = useState<any>(modalPopUpDefault);
    const [typeListSimulate, setTypeListSimulate] = useState('simulates');
    const [typeOrderId, setTypeOrderId] = useState(0);
    const [openModalPeriod, setOpenModalPeriod] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [allDataOrder, setAllDataOrder] = useState<Array<any>>([]);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    // const [animationRefresh, setAnimationRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allSelectedAccordion, setAllSelectedAccordion] = useState<Array<number>>([]);
    const [isVerified, setIsVerified] = useState(false);
    const [openModalConfirmDeleteFilter, setOpenModalConfirmDeleteFilter] = useState(false);

    // methods
    const onChangeSearch = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(txt.target.value);
    };

    const onChangeNameCreateBook = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setNameCreateBook(txt.target.value);
    };

    const onChangeNewNameBook = (txt: React.ChangeEvent<HTMLInputElement>) => {
        if (setNameBook && idScreen !== 'Filters') {
            setNameBook(txt.target.value);
        }
        if (idScreen === 'Filters' && setNameCreateFilter) {
            setNameCreateFilter(txt.target.value);
        }
    };

    const onChangeExamination = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setIdExamination(txt.target.value);
    };

    const onChangeEngineering = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setIdEngineering(txt.target.value);
    };

    const onChangeNameCreateFilter = (txt: React.ChangeEvent<HTMLInputElement>) => {
        if (setNameCreateFilter) {
            setNameCreateFilter(txt.target.value);
        }
    };

    const ApplyFilterTwo = async (type: 'clean' | 'apply') => {
        setLoading(true);
        try {
            if (title === 'Filtrar por Concursos/Formação' && setIdPopUp && setAllTests) {
                setIdPopUp(idPopUpOld);
                if (idPopUpOld === 2) {
                    const GetAllTests = await tests.GetAllTests(
                        type === 'apply' ? Number(idEngineering) : 0,
                        type === 'apply' ? Number(idExamination) : 0,
                        '',
                        infoUser?.token || '',
                    );
                    setAllTests(GetAllTests?.result);
                }
                if (idPopUpOld === 3) {
                    const GetAllSimulate = await simulate.GetAllSimulate(
                        typeListSimulate === 'simulates' ? '1' : '2',
                        type === 'apply' ? Number(idEngineering) : 0,
                        type === 'apply' ? Number(idExamination) : 0,
                        '',
                        infoUser?.token || '',
                    );
                    if (typeListSimulate === 'simulates' && setAllSimulate) {
                        setAllSimulate(GetAllSimulate?.result);
                    } else if (setAllSimulateTwo) {
                        setAllSimulateTwo(GetAllSimulate?.result);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const onKeyPressNewNameBook = async (event: any) => {
        if ((event.which === 13 || event.keyCode === 13)) {
            if (idScreen === 'Filters' && selectFilter && allSubjectsSelect && nameCreateFilter && setNameCreateFilter) {
                await filters.EditOneFilters(
                    nameCreateFilter,
                    selectFilterSaved?.id,
                    infoUser?.id,
                    infoUser?.token,
                    selectFilter,
                    allSubjectsSelect,
                );
                setPopUp(false);
                setEnabledRename(false);
                setNameCreateFilter('');
                RefreshInitial();
            } else if (bookSelected && setNameBook && nameBook) {
                await books.EditOneBook(nameBook, [], bookSelected?.id, infoUser?.token);
                setPopUp(false);
                setEnabledRename(false);
                setNameBook('');
                RefreshInitial();
            }
        }
    };

    const ActionSearch = async (newSearch: string, idOrderSelect: string) => {
        setLoading(true);
        try {
            if (title === 'Cadernos' && flowOrderBy && idOrder) {
                const GetAllResultBooks = await books.GetAllBooks(
                    newSearch,
                    idOrderSelect || idOrder,
                    flowOrderBy,
                    false,
                    infoUser?.id || 0,
                    infoUser?.token || '',
                );
                const [GetOnlyBooks] = GetAllResultBooks?.result?.map((v: any) => v?.result);
                setAllBooks(GetOnlyBooks);
            }
            if (title === 'Bancas') {
                const dataExamination = await auth.GetExamination(newSearch);
                setAllExamination(dataExamination?.examination);
                console.log(allExamination);
            }
            if (title === 'Formações') {
                const dataEngineering = await auth.GetEngineering(newSearch);
                setAllEngineering(dataEngineering?.engineering);
            }
            if (title === 'Filtros' && setAllFilters) {
                const GetFilters = await filters.GetAllFilters(
                    allData[0]?.id_category,
                    newSearch,
                    infoUser?.token || '',
                );
                setAllFilters(GetFilters?.result);
            }
            if (title === 'Provas' && setAllTests) {
                const GetFilters = await tests.GetAllTests(
                    0,
                    0,
                    newSearch,
                    infoUser?.token || '',
                );
                setAllTests(GetFilters?.result);
            }
            if (title === 'Desafios Diários' && setAllTournament) {
                const GetAllTournament = await tournament.GetAllTournament(
                    infoUser.id,
                    newSearch,
                    allDataOrder[typeOrderId]?.id || 'TODAY',
                    infoUser.token || '',
                    start,
                    end,
                );
                setAllTournament(GetAllTournament?.result || []);
            }
            if (title === 'Redação' && setAllInfoEssay) {
                const GetEssay = await essay.GetAllEssay(
                    newSearch || '',
                    infoUser?.token || '',
                );
                setAllInfoEssay(GetEssay?.result || []);
            }
            if (title === 'Simulados') {
                if (!newSearch?.length) {
                    const GetFilters = await simulate.GetAllSimulate(
                        '1',
                        infoUser.id_engineering,
                        infoUser.id_examination,
                        newSearch,
                        infoUser?.token || '',
                    );
                    if (setAllSimulate) {
                        setAllSimulate(GetFilters?.result);
                    }
                    const GetFiltersTwo = await simulate.GetAllSimulate(
                        '2',
                        infoUser.id_engineering,
                        infoUser.id_examination,
                        newSearch,
                        infoUser?.token || '',
                    );
                    if (setAllSimulateTwo) {
                        setAllSimulateTwo(GetFiltersTwo?.result);
                    }
                } else if (newSearch?.length) {
                    const GetFilters = await simulate.GetAllSimulate(
                        typeListSimulate === 'simulates' ? '1' : '2',
                        infoUser.id_engineering,
                        infoUser.id_examination,
                        newSearch,
                        infoUser?.token || '',
                    );
                    if (typeListSimulate === 'simulates' && setAllSimulate) {
                        setAllSimulate(GetFilters?.result);
                    }
                    if (typeListSimulate === 'simulatesTwo' && setAllSimulateTwo) {
                        setAllSimulateTwo(GetFilters?.result);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const CloseModal = async () => {
        setPopUp(false);
        setAllFiltersSelected([]);
        setAllDataDefault([]);
        setIdExamination('1');
        setIdEngineering('1');
        setNameCreateBook('');
        setTypeSimulate('');
        setIdPopUpOld(-1);
        setTypeOrderId(0);
        if (setNameCreateFilter) {
            setNameCreateFilter('');
        }
        if (setAllSubjectsSelect && setSelectFilter) {
            setAllSubjectsSelect([]);
            setSelectFilter([]);
        }
        await ActionSearch('', '');
    };

    const CreateOrEditBook = async () => {
        if (!allFiltersSelected.length || (!nameBook && !nameCreateBook)) {
            return;
        }
        setLoading(true);
        if (title === 'Criar caderno' && setNameBook) {
            setLoading(true);
            const Create = await books.CreateOneBook(nameCreateBook, idExamination, allFiltersSelected, infoUser?.id, infoUser?.token);
            setLoading(false);
            if (Create?.status === 200) {
                setPopUp(false);
                setAllFiltersSelected([]);
                setIdExamination('1');
                setNameCreateBook('');
                setEnabledRename(false);
                setNameBook('');
                RefreshInitial();
            }
        }
        if (title === 'Editar caderno' && setNameBook) {
            setLoading(true);
            const Edit = await books.EditOneBook(nameCreateBook, allFiltersSelected, bookSelected?.id, infoUser?.token);
            setLoading(false);
            if (Edit?.status === 200) {
                setPopUp(false);
                setAllFiltersSelected([]);
                setIdExamination('1');
                setNameCreateBook('');
                setEnabledRename(false);
                setNameBook('');
                RefreshInitial();
            }
        }
        setLoading(false);
    };

    // useEffect
    useEffect(() => {
        if (popUp) {
            const timeOutId = setTimeout(() => {
                ActionSearch(search, idOrder || 'created_at');
            }, 1000);
            return () => clearTimeout(timeOutId);
        }
        return () => null;
    }, [search, typeOrderId, start, end]);

    useEffect(() => {
        setLoading(true);
        try {
            if (((infoUser?.allInfoQuestion?.allQuestions?.length > 0 || infoUser?.allInfoQuestion?.pdf) && infoUser?.allInfoQuestion?.backScreen?.length > 0 && !infoUser?.allInfoQuestion?.focusFlowQuestions)) {
                window.location.replace('/flowQuestions');
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [infoUser]);

    useEffect(() => {
        setTypeSimulate(infoUser?.allInfoQuestion?.typeSimulate || '');
    }, []);

    const ValidationTypeCategory = (GetIdCategory: Array<any>) => {
        if (GetIdCategory.includes(1)) {
            // Disciplinas
            return 'Disciplinas';
        }
        if (GetIdCategory.includes(2)) {
            // Bancas
            return 'Bancas';
        }
        if (GetIdCategory.includes(3)) {
            // Dificuldades
            return 'Dificuldades';
        }
        return '';
    };

    const AddNewCardSimulate = () => {
        if (selectFilter && selectFilter?.length > 0 && setSelectFilter && setAllSubjectsSelect) {
            const GetIdCategory = allDataDefaultTemporary.map((value: any) => value.id_category);
            const GetTypeCategory = ValidationTypeCategory(GetIdCategory);
            const qtdFilter = selectFilter?.length;
            setAllCards([...allCards, {
                idFilters: selectFilter,
                idSubjects: allSubjectsSelect,
                qntBank: GetTypeCategory === 'Bancas' ? qtdFilter : 0,
                qntDificuldade: GetTypeCategory === 'Dificuldades' ? qtdFilter : 0,
                qntDisciplinas: GetTypeCategory === 'Disciplinas' ? qtdFilter : 0,
                qtd: 1,
            }]);
            setSelectFilter([]);
            setAllSubjectsSelect([]);
            setAllDataDefaultTemporary([]);
            setModalPopUp({
                ...modalPopUp,
                content: {
                    ...modalPopUp.content,
                    maxWidth: '70vw',
                },
            });
        } else {
            // eslint-disable-next-line no-alert
            alert('Adicione algum filtro antes de criar um card.');
        }
    };

    const applyDeleteFilter = async () => {
        setIsVerified(false);
        await filters.DeleteOneFilters(
            selectFilterSaved?.id,
            infoUser?.token,
        );
        setOpenModalConfirmDeleteFilter(false);
        setPopUp(false);
        RefreshInitial();
    };

    // renders
    const RenderBody = () => {
        if ((title === 'Filtros' || title === 'Selecione um filtro') && (allDataDefault?.length || allData?.length) && selectFilter && setSelectFilter && setAllSubjectsSelect && allSubjectsSelect) {
            const BackModal = () => {
                if (idScreen === 'Timeline' || idScreen === 'statistics') {
                    setPopUp(false);
                    ApplyFilter();
                } else if (setIdPopUp) {
                    setIdPopUp(idPopUpOld);
                    setAllDataDefaultTemporary(allDataDefault);
                    setAllDataDefault([]);
                }
            };
            return (
                <ContainerAdjustFilter>
                    <ContainerSubjects>
                        <FiltersAndSubjectsComponent
                            allData={allData || allDataDefault}
                            selectDropdawn={selectDropdawn}
                            setSelectDropdawn={setSelectDropdawn}
                            selectFilter={selectFilter}
                            allSubjectsSelect={allSubjectsSelect}
                            setSelectFilter={setSelectFilter}
                            setAllSubjectsSelect={setAllSubjectsSelect}
                            typeQuestionsSelected={typeQuestionsSelected}
                            setTypeQuestionsSelected={setTypeQuestionsSelected}
                        />
                    </ContainerSubjects>
                    <ContainerButtonsBooks>
                        <ButtonActionBook details="voltar" onClick={BackModal}>Voltar</ButtonActionBook>
                        <ButtonActionBook
                            backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').graySmall}
                            onClick={BackModal}
                            fontWeight="bold"
                            details="salvar"
                            enabled={selectFilter?.length > 0 || allSubjectsSelect?.length > 0 || typeQuestionsSelected?.length > 0 || idScreen === 'Timeline'}
                        >
                            Salvar
                        </ButtonActionBook>
                    </ContainerButtonsBooks>
                </ContainerAdjustFilter>
            );
        }
        if (title === 'Cadernos' && allBooks?.length && idBook !== undefined && setIdBook && setNameBook) {
            return allBooks?.map((v) => {
                const ActionSelectedBook = () => {
                    if (v.id === idBook) {
                        setIdBook(0);
                        setNameBook('');
                    } else {
                        setIdBook(v.id);
                        setNameBook(v.title);
                        CloseModal();
                    }
                };
                return (
                    <ContainerCategorySolo
                        key={v.id}
                        onClick={ActionSelectedBook}
                    >
                        <NameCategory>{v.title}</NameCategory>
                        <AdjustInfo>
                            <CheckBoxSubject>
                                {idBook === v.id ? (
                                    <CheckBox />
                                ) : null}
                            </CheckBoxSubject>
                        </AdjustInfo>
                    </ContainerCategorySolo>
                );
            });
        }
        if (['Bancas', 'Formações'].includes(title) && (setEngineering || setExamination) && examination && engineering) {
            return (title === 'Bancas' ? allExamination : allEngineering)?.map((v) => {
                const ActionSelectedBook = () => {
                    if (title === 'Bancas' && setExamination) {
                        if (v.id === examination.id) {
                            setExamination({ id: 0, name: 'Selecione um concurso' });
                        } else {
                            setExamination({ id: v.id, name: v.name });
                            CloseModal();
                        }
                    }
                    if (title === 'Formações' && setEngineering) {
                        if (v.id === engineering.id) {
                            setEngineering({ id: 0, name: 'Selecione uma Formação' });
                        } else {
                            setEngineering({ id: v.id, name: v.name });
                            CloseModal();
                        }
                    }
                };
                return (
                    <ContainerCategorySolo
                        key={v.id}
                        onClick={ActionSelectedBook}
                    >
                        <NameCategory>{v.name}</NameCategory>
                        <AdjustInfo>
                            <CheckBoxSubject>
                                {(title === 'Bancas' ? examination.id === v.id : engineering.id === v.id) ? (
                                    <CheckBox />
                                ) : null}
                            </CheckBoxSubject>
                        </AdjustInfo>
                    </ContainerCategorySolo>
                );
            });
        }
        if (title === 'Ordenação' || title === 'Opções') {
            const RenderAllData = () => {
                if (title === 'Ordenação') {
                    if (idScreen === 'Filters') {
                        return popUpConstants.allOrder.slice(0, 2);
                    }
                    return popUpConstants.allOrder;
                }
                if (typeBook === 'book') {
                    return popUpConstants.allOptions;
                }
                if (idScreen === 'Filters') {
                    return popUpConstants.allOptions.filter((v) => v.title !== 'Download');
                }
                return popUpConstants.allOptions.slice(0, 1);
            };
            return RenderAllData().map((v) => {
                const SelectedOrder = async () => {
                    setLoading(true);
                    if (setIdOrder && title === 'Ordenação') {
                        if (idOrder === v.type && setFlowOrderBy) {
                            setFlowOrderBy(flowOrderBy === 'asc' ? 'desc' : 'asc');
                        }
                        if (v.type === 'title' && idOrder !== v.type) {
                            setIdOrder('title');
                        }
                        if (v.type === 'created_at' && idOrder !== v.type) {
                            setIdOrder('created_at');
                        }
                        if (v.type === 'progress' && idOrder !== v.type) {
                            setIdOrder('progress');
                        }
                    }
                    if (title === 'Opções' && setIdPopUp) {
                        if (v.id === 1) {
                            /*
                                const GetQuestions = await questionsApi.GetAllQuestions(
                                    [],
                                    [],
                                    typeQuestionsSelected,
                                    infoUser?.id,
                                    infoUser?.token,
                                    bookSelected?.id || selectFilterSaved?.id,
                                );
                            */
                            const GetQuestions = await questionsApi.GetAllQuestions(
                                selectFilterSaved?.id_filters || [],
                                selectFilterSaved?.id_subjects || [],
                                typeQuestionsSelected,
                                infoUser?.id,
                                infoUser?.token,
                                bookSelected?.id,
                            );
                            if (GetQuestions?.questions?.length > 0 && setAllQuestions) {
                                // setAllQuestions(GetQuestions?.questions);
                                // eslint-disable-next-line no-alert
                                // alert('Questoes criadas!');
                                dispatch(updateUser({
                                    ...infoUser,
                                    allInfoQuestion: {
                                        allQuestionsCompleted: [],
                                        allQuestions: GetQuestions?.questions,
                                        backScreen: window.location.pathname,
                                        focusFlowQuestions: false,
                                        idType: bookSelected?.id ? 'Cadernos' : 'Filtros',
                                        idSelected: bookSelected?.id || selectFilterSaved?.id,
                                        suggested: bookSelected?.id ? bookSelected?.suggested : undefined,
                                        timeOutSimulateOrTest: undefined,
                                    },
                                }));
                            } else {
                                // eslint-disable-next-line no-alert
                                alert('Não existe questões para esses filtros.');
                            }
                        }
                        if (v.id === 2) {
                            // Download do caderno
                        }
                        if (v.id === 3) {
                            setEnabledRename(true);
                        }
                        if (v.id === 4) {
                            if (idScreen === 'Filters') {
                                setIdPopUp(3);
                            } else {
                                setIdPopUp(4);
                                setAllFiltersSelected(bookSelected?.id_filters_saved);
                                setIdExamination(bookSelected?.id_examination);
                                setNameCreateBook(bookSelected?.title);
                            }
                        }
                        if (v.id === 5) {
                            if (idScreen === 'Filters') {
                                if (selectFilterSaved?.isBook && !isVerified) {
                                    setIsVerified(true);
                                    setOpenModalConfirmDeleteFilter(true);
                                } else {
                                    applyDeleteFilter();
                                }
                            } else {
                                await books.DeleteOneBook(
                                    bookSelected?.id,
                                    infoUser?.token,
                                );
                                setPopUp(false);
                                RefreshInitial();
                            }
                        }
                    }
                    setLoading(false);
                };
                const RenderIcon = () => {
                    if (title === 'Ordenação' && (idOrder === v.type || (v.type === 'created_at' && !idOrder))) {
                        return <ImgDropDawn src={arrowDropDawnPurpleTop} flowOrderBy={flowOrderBy} />;
                    }
                    return null;
                };
                const RenderRenameBook = () => {
                    if (enabledRename && v.id === 3) {
                        return (
                            <InputRenameBook
                                value={idScreen === 'Filters' ? nameCreateFilter : nameBook}
                                onChange={onChangeNewNameBook}
                                onKeyPress={onKeyPressNewNameBook}
                                placeholder="Novo título"
                            />
                        );
                    }

                    return <NameOption>{v.title}</NameOption>;
                };
                return (
                    <ContainerOptionBook onClick={SelectedOrder} key={v.id}>
                        <ImgOption src={v.img} />
                        {loading && (v.id === 1 && v.title === 'Abrir') ? <IconRefresh animation src={iconRefreshPage} /> : RenderRenameBook()}
                        {RenderIcon()}
                    </ContainerOptionBook>
                );
            });
        }
        if ((title === 'Criar caderno' || title === 'Editar caderno') && allExamination) {
            // const RenderTitleBook = () => {
            //     if (title === 'Editar caderno') {
            //         return 'Salvar';
            //     }
            //     return 'Cadastrar caderno';
            // };
            return (
                <ContainerBodyCreateBook>
                    <NameOption>Crie um nome para o caderno</NameOption>
                    <InputNameBook value={nameCreateBook} onChange={onChangeNameCreateBook} placeholder="Crie um nome para o caderno" />
                    {title === 'Criar caderno' ? (
                        <>
                            <NameOption>Selecionar o Concursos</NameOption>
                            <ContainerSelect>
                                <Select value={idExamination} onChange={onChangeExamination}>
                                    {allExamination.map((v) => (
                                        <Option value={v.id}>{v?.name}</Option>
                                    ))}
                                </Select>
                            </ContainerSelect>
                        </>
                    ) : null}
                    <NameOption>Selecione os filtros salvos</NameOption>
                    {filtersSavedUser?.map((v: any) => {
                        const ActionSelectedBook = () => {
                            if (allFiltersSelected?.includes(v.id)) {
                                const removeId = allFiltersSelected?.filter((e) => e !== v.id);
                                setAllFiltersSelected(removeId);
                            } else {
                                setAllFiltersSelected([...allFiltersSelected || [], v.id]);
                            }
                        };
                        return (
                            <ContainerCategory
                                key={v.id}
                                onClick={ActionSelectedBook}
                                width="95%"
                                margin="10px 0px 10px 15px"
                            >
                                <NameCategory>{v.title}</NameCategory>
                                <AdjustInfo>
                                    <CheckBoxSubject>
                                        {allFiltersSelected?.includes(v.id) ? <CheckBox /> : null}
                                    </CheckBoxSubject>
                                </AdjustInfo>
                            </ContainerCategory>
                        );
                    })}
                    <ContainerButtonsBooks>
                        <ButtonActionBook onClick={CloseModal}>Cancelar</ButtonActionBook>
                        <ButtonActionBook
                            backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').graySmall}
                            onClick={CreateOrEditBook}
                            fontWeight="bold"
                            enabled={!loading && allFiltersSelected?.length > 0 && idExamination !== '' && nameCreateBook !== ''}
                        >
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {loading ? (
                                <AiOutlineLoading3Quarters
                                    color={colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight}
                                    size={30}
                                    style={{ animation: 'spin 0.5s linear infinite' }}
                                />
                            ) : (title === 'Editar caderno' ? 'Salvar' : 'Cadastrar caderno')}
                        </ButtonActionBook>
                    </ContainerButtonsBooks>
                </ContainerBodyCreateBook>
            );
        }
        if ((title === 'Editar filtro salvo' || title === 'Criar filtro salvo' || (title === 'Filtros' && idScreen === 'Questions')) && setIdPopUp && allCategories?.length > 0) {
            const CreateQuestions = async () => {
                if ((selectFilter && selectFilter?.length > 0) || (allSubjectsSelect && allSubjectsSelect?.length > 0)) {
                    const GetQuestions = await questionsApi.GetAllQuestions(
                        selectFilter || [],
                        allSubjectsSelect || [],
                        typeQuestionsSelected,
                        infoUser?.id,
                        infoUser?.token,
                        '',
                    );
                    if (GetQuestions?.questions?.length > 0 && setAllQuestions) {
                        // FIXME setar as questoes no redux e consumir na tela de flowQuestions, colocar
                        // validacao no index da pasta routes pra qnd o user acessar qlq tela sem ser o flowQuestions
                        // remover as questoes do redux
                        // setAllQuestions(GetQuestions?.questions);
                        dispatch(updateUser({
                            ...infoUser,
                            allInfoQuestion: {
                                allQuestionsCompleted: [],
                                allQuestions: GetQuestions?.questions,
                                backScreen: window.location.pathname,
                                focusFlowQuestions: false,
                                idType: '',
                                idSelected: undefined,
                                suggested: undefined,
                                timeOutSimulateOrTest: undefined,
                                selectFilter,
                                allSubjectsSelect,
                                idPopUp,
                                typeSimulate,
                            },
                        }));
                        // eslint-disable-next-line no-alert
                        // alert('Questoes criadas!');
                        // setAllQuestions([...GetQuestions?.questions, ...GetQuestions?.questions, ...GetQuestions?.questions]);
                    } else {
                        // eslint-disable-next-line no-alert
                        alert('Não existe questões para esses filtros.');
                    }
                } else {
                    // eslint-disable-next-line no-alert
                    alert('Selecione algum filtro e/ou assunto');
                }
                setLoading(false);
            };
            const SavedFilter = async () => {
                if (!selectFilter?.length) {
                    // eslint-disable-next-line no-alert
                    alert('Selecione algum filtro antes de prosseguir.');
                    return;
                }
                if (title === 'Editar filtro salvo') {
                    if (!nameCreateFilter) {
                        // eslint-disable-next-line no-alert
                        alert('Digite o nome antes de prosseguir.');
                        return;
                    }
                    setLoading(true);
                    const isSubject = allSubjectsSelect && Array.isArray(allSubjectsSelect) && allSubjectsSelect[0]?.id;
                    const EditFilterSaved = await filters.EditOneFilters(
                        nameCreateFilter,
                        selectFilterSaved?.id,
                        infoUser?.id,
                        infoUser?.token,
                        selectFilter,
                        isSubject ? allSubjectsSelect?.map((v) => v?.id) : allSubjectsSelect,
                    );
                    if (EditFilterSaved?.status === 200 && setNameCreateFilter && setAllSubjectsSelect && setSelectFilter) {
                        setPopUp(false);
                        setNameCreateFilter('');
                        setAllSubjectsSelect([]);
                        setSelectFilter([]);
                        RefreshInitial();
                    }
                    setLoading(false);
                }
                if (title === 'Criar filtro salvo') {
                    if (!nameCreateFilter) {
                        // eslint-disable-next-line no-alert
                        alert('Digite o nome antes de prosseguir.');
                        return;
                    }
                    setLoading(true);
                    const isSubject = allSubjectsSelect && Array.isArray(allSubjectsSelect) && allSubjectsSelect[0]?.id;
                    const CreateFilterSaved = await filters.CreateOneFilters(
                        nameCreateFilter,
                        infoUser?.id,
                        infoUser?.token,
                        selectFilter,
                        isSubject ? allSubjectsSelect?.map((v) => v?.id) : allSubjectsSelect,
                    );
                    if (CreateFilterSaved?.status === 200 && setNameCreateFilter && setAllSubjectsSelect && setSelectFilter) {
                        setPopUp(false);
                        setNameCreateFilter('');
                        setAllSubjectsSelect([]);
                        setSelectFilter([]);
                        RefreshInitial();
                    }
                    setLoading(false);
                }
                if (title === 'Filtros') {
                    setLoading(true);
                    if (!typeSimulate || typeSimulate === 'Fast') {
                        CreateQuestions();
                    } else if (['Personalizado'].includes(typeSimulate)) {
                        if (allCards?.length > 0) {
                            let queries: any = [];
                            let AllIdFilters: any = [];
                            let AllIdSubjects: any = [];
                            allCards.map((v: any) => {
                                queries = [
                                    ...queries,
                                    {
                                        idFilters: v.idFilters,
                                        idSubjects: v.idSubjects,
                                        qtd: v.qtd,
                                    },
                                ];
                                v.idFilters.map((value: any) => {
                                    if (!AllIdFilters.includes(value)) {
                                        AllIdFilters = [
                                            ...AllIdFilters,
                                            value,
                                        ];
                                    }
                                    return null;
                                });
                                v.idSubjects.map((value: any) => {
                                    if (!AllIdSubjects.includes(value)) {
                                        AllIdSubjects = [
                                            ...AllIdSubjects,
                                            value,
                                        ];
                                    }
                                    return null;
                                });
                                return null;
                            });
                            const allQtd = queries?.map((v: any) => Number(v?.qtd)).reduce((somas: any, i: any) => somas + i);
                            if (allQtd <= 200) {
                                setLoading(true);
                                const GenerateSimulatePerso = await simulate.GenerateSimulates(
                                    typeQuestionsSelected,
                                    queries,
                                    infoUser.id,
                                    infoUser?.token,
                                );
                                if (GenerateSimulatePerso?.status === 200) {
                                    setAllCards([]);
                                    // eslint-disable-next-line no-alert
                                    alert('Seu simulado está sendo processado. Você pode verificar suas solicitações na tab de Solicitações de Simulados.');
                                    // dispatch(updateUser({
                                    //     ...infoUser,
                                    //     allInfoQuestion: {
                                    //         allQuestionsCompleted: [],
                                    //         allQuestions: GenerateSimulatePerso?.questions,
                                    //         backScreen: window.location.pathname,
                                    //         focusFlowQuestions: false,
                                    //         idType: '',
                                    //         idSelected: undefined,
                                    //         suggested: undefined,
                                    //         timeOutSimulateOrTest: undefined,
                                    //         selectFilter,
                                    //         allSubjectsSelect,
                                    //         idPopUp,
                                    //         typeSimulate,
                                    //     },
                                    // }));
                                } else {
                                    // eslint-disable-next-line no-alert
                                    alert(GenerateSimulatePerso?.message || 'Ocorreu um erro ao criar o simulado');
                                }
                            } else {
                                // eslint-disable-next-line no-alert
                                alert('Limite máximo de 200 questões por simulado.');
                            }
                        } else {
                            // eslint-disable-next-line no-alert
                            alert('Adicione pelo menos um card antes de gerar um simulado personalizado.');
                        }
                        setLoading(false);
                    }
                }
            };
            const activeBackgroundColor = () => {
                if (!['Criar filtro salvo', 'Editar filtro salvo'].includes(title)) {
                    return null;
                }
                if ((selectFilter || []).length && nameCreateFilter) {
                    return colors.theme(window.localStorage.getItem('theme') || 'light').backgroundButtonSaved;
                }
                return colors.basic(window.localStorage.getItem('theme') || 'light').grayDefault;
            };
            const RenderLabelButtonFinal = () => {
                if (title === 'Criar filtro salvo') {
                    return 'Criar Filtro';
                }
                if (title === 'Editar filtro salvo') {
                    return 'Salvar Filtro';
                }
                if (title === 'Filtros' && typeSimulate) {
                    return `Gerar Simulado ${typeSimulate}`;
                }
                return 'Visualizar Questões';
            };
            const NewCategories = typeSimulate === 'Personalizado' || !typeSimulate ? [...allCategories, popUpConstants.TypeQuestion] : allCategories;
            return (
                <ContainerBodyCreateBook>
                    <ButtonSaveFilter
                        backgroundColor={activeBackgroundColor()}
                        onClick={SavedFilter}
                        enabled={selectFilter?.length === 0 && typeQuestionsSelected?.length === 0}
                    >
                        {loading ? <IconRefresh animation src={iconRefreshPageWhite} /> : RenderLabelButtonFinal()}
                    </ButtonSaveFilter>
                    <ContainerCategory>
                        {typeSimulate !== 'Personalizado' ? (
                            <NameCategory fontWeight="bold">{idScreen !== 'Questions' ? 'QUESTÕES' : ''}</NameCategory>
                        ) : null}
                        {typeSimulate === 'Personalizado' ? (
                            <ButtonSaveFilter onClick={AddNewCardSimulate}>Gerar Card</ButtonSaveFilter>
                        ) : null}
                    </ContainerCategory>
                    {idScreen !== 'Questions' ? (
                        <>
                            <NameOption>Crie um nome para o filtro salvo</NameOption>
                            <InputNameBook value={nameCreateFilter} onChange={onChangeNameCreateFilter} placeholder="Crie um nome para o filtro salvo" />
                        </>
                    ) : null}
                    {NewCategories.map((v: any) => {
                        const OpenModalFilter = async () => {
                            setLoading(true);
                            const GetNowFilters = v.id !== 'typeQuestion' ? await filters.GetAllFilters(
                                v.id,
                                '',
                                infoUser?.token,
                            ) : null;
                            const ExistData = GetNowFilters?.result?.length > 0 || v?.allTypeQuestions?.length > 0;
                            if (ExistData) {
                                if (idScreen !== 'Questions') {
                                    setIdPopUpOld(title === 'Editar filtro salvo' ? 3 : 2);
                                    setIdPopUp(5);
                                }
                                if (idScreen === 'Questions') {
                                    setIdPopUpOld(5);
                                    setIdPopUp(1);
                                }
                                setAllDataDefault(GetNowFilters?.result || v?.allTypeQuestions);
                            } else {
                                // eslint-disable-next-line no-alert
                                alert('Não existem filtros para essa categoria.');
                            }
                            setLoading(false);
                        };
                        const qtd = v?.allFilters?.filter((idFilter: number) => selectFilter?.includes(idFilter))?.length;
                        return (
                            <ContainerCategory onClick={OpenModalFilter}>
                                <NameCategory>{v.name}</NameCategory>
                                <AdjustInfo>
                                    <ContainerQtd>
                                        <NameCategory color={colors.basic(window.localStorage.getItem('theme') || 'light').white} fontWeight="bold">{v?.id !== 'typeQuestion' ? qtd : typeQuestionsSelected?.length}</NameCategory>
                                    </ContainerQtd>
                                    <IconDropDawnCategory src={arrowDropDawnLight} />
                                </AdjustInfo>
                            </ContainerCategory>
                        );
                    })}
                </ContainerBodyCreateBook>
            );
        }

        if (title === 'Provas' || title === 'Simulados' || title === 'Desafios Diários') {
            const validationAllData = () => {
                if (title === 'Provas') {
                    return allTests;
                }
                if (title === 'Simulados') {
                    if (typeListSimulate === 'simulates') {
                        return allSimulate;
                    }
                    if (typeListSimulate === 'simulatesTwo') {
                        return allSimulateTwo;
                    }
                    return allSolicitation;
                }
                return allTournament;
            };
            const allDatas = validationAllData();
            // const TradeTypeForSolicitation = async () => {
            //     if (typeListSimulate === 'solicitation' && setAllSolicitation) {
            //         setAnimationRefresh(true);
            //         const GetAllSolicitation = await simulate.GetAllSolicitation(
            //             infoUser?.id,
            //             infoUser?.token || '',
            //         );
            //         setAllSolicitation(GetAllSolicitation?.result);
            //         setAnimationRefresh(false);
            //     } else {
            //         setTypeListSimulate('solicitation');
            //     }
            // };
            const TradeTypeForSimulates = () => {
                setSearch('');
                if (typeListSimulate === 'simulates') {
                    setTypeListSimulate('simulatesTwo');
                } else {
                    setTypeListSimulate('simulates');
                }
            };
            const renderListEmpty = () => {
                if (title === 'Provas') {
                    return 'NENHUMA PROVA ENCONTRADA';
                }
                if (title === 'Desafios Diários') {
                    return 'NENHUM DESAFIO ENCONTRADO';
                }
                return 'NENHUM SIMULADO ENCONTRADO';
            };
            const labelListEmpty = renderListEmpty();
            return (
                <>
                    {title === 'Simulados' ? (
                        <ContainerSelectedTypeBook>
                            <ButtonTypeBook isFocus={typeListSimulate === 'simulates'} onClick={TradeTypeForSimulates}>Simulados 1ª Fase</ButtonTypeBook>
                            <ButtonTypeBook isFocus={typeListSimulate === 'simulatesTwo'} onClick={TradeTypeForSimulates}>Simulados 2ª Fase</ButtonTypeBook>
                            {/* <ButtonTypeBook isFocus={typeListSimulate === 'solicitation'} onClick={TradeTypeForSolicitation}>
                                Solicitações de Simulados
                                <IconRefresh animation={animationRefresh} src={typeListSimulate === 'solicitation' ? iconRefreshPageWhite : iconRefreshPage} />
                            </ButtonTypeBook> */}
                        </ContainerSelectedTypeBook>
                    ) : null}
                    {/* <PickerComponent
                        label="Período:"
                        value=""
                        idFilter={1}
                        typeOrderId={typeOrderId}
                        setOpenModalPeriod={setOpenModalPeriod}
                        setAllData={setAllDataOrder}
                    /> */}
                    <ContainerBodyTestAndSimulate>
                        {/* eslint-disable */}
                        {allDatas?.length > 1 ? (
                            allDatas.map((e: any) => {
                                const RenderStatus = () => {
                                    if (e?.status === 'finished') {
                                        return 'Finalizado';
                                    }
                                    if (e?.status === 'pending') {
                                        return 'Na fila';
                                    }
                                    if (e?.status === 'progress') {
                                        return 'Processando';
                                    }
                                    return '';
                                };
                                const renderDates = () => {
                                    if (typeListSimulate === 'solicitation') {
                                        return `${lightFormat(new Date(e?.created_at), 'dd/MM/yyyy HH:mm')}`;
                                    }
                                    if (title === 'Desafios Diários') {
                                        return `${lightFormat(addHours(new Date(e?.date_start), 3), 'dd/MM/yyyy HH:mm')} até ${e?.date_end ? lightFormat(addHours(new Date(e?.date_end), 3), 'dd/MM/yyyy HH:mm') : 'Tempo indeterminado'}`;
                                    }
                                    return ''; // `${lightFormat(addHours(Number(e.date_initial), 3), 'dd/MM/yyyy HH:mm')} até ${lightFormat(addHours(Number(e.date_final), 3), 'dd/MM/yyyy HH:mm')}`;
                                };
                                const DownloadSimulate = () => {
                                    fetch(e?.pdf).then((response) => {
                                        response.blob().then((blob) => {
                                            // Creating new object of PDF file
                                            const fileURL = window.URL.createObjectURL(blob);
                                            // Setting various property values
                                            const alink = document.createElement('a');
                                            alink.href = fileURL;
                                            alink.download = e?.pdf?.split('-')[1];
                                            alink.click();
                                        });
                                    });
                                };
                                return (
                                    <ContainerTest key={e?.id}>
                                        <AdjustTitle>
                                            <TitleTest>{typeListSimulate === 'solicitation' ? `#${e?.id}` : e?.title}</TitleTest>
                                            {e?.pdf ? (
                                                <>
                                                    <ContainerDownload>
                                                        <ImageDropDawn src={arrowDropDawn} onClick={DownloadSimulate} /> 
                                                        <TitleTest style={{ fontSize: '10px', textAlign: 'center' }}>Download do simulado</TitleTest>
                                                    </ContainerDownload>
                                                </>
                                            ) : null}
                                        </AdjustTitle>
                                        {typeListSimulate === 'solicitation' ? (
                                            <TotalQuestions margin="0px 0px 5px 0px" color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}>{`Status: ${RenderStatus()}`}</TotalQuestions>
                                        ) : null}
                                        {e?.tags ? (
                                            <ContainerAdjustTags>
                                                {e.tags.map((value: string, index: number) => {
                                                    const indexActual = index;
                                                    return (
                                                        <ContainerTags key={indexActual}>
                                                            <TxtTags>{value}</TxtTags>
                                                        </ContainerTags>
                                                    );
                                                })}
                                            </ContainerAdjustTags>
                                        ) : (
                                            <>
                                                {title !== 'Desafios Diários' ? null : (
                                                    <ContainerBodyInfo>
                                                        <IconEvent src={eventBlue} />
                                                        <TotalQuestions>
                                                            {renderDates()}
                                                        </TotalQuestions>
                                                    </ContainerBodyInfo>
                                                )}
                                            </>
                                        )}
                                        {title === 'Desafios Diários' ? null : (
                                            <ContainerInfo>
                                                <ContainerBodyInfo width="50%" />
                                                <ContainerBodyInfo width="50%" justifyContent="flex-end">
                                                    {typeListSimulate === 'solicitation' ? (
                                                        <TotalQuestions width="auto">{e?.queue ? `Posição na fila: ${e?.queue}` : ''}</TotalQuestions>
                                                    ) : (
                                                        <>
                                                            {/* <TotalQuestions width="auto" margin="0px 5px 0px 0px">{`${e.users_resolved?.length || 0} ${e?.tags ? 'vezes' : ''}`}</TotalQuestions>
                                                            <IconEvent src={e?.tags ? eventsBlue : peopleBlue} dimensions={e?.tags ? '19px' : '20px'} /> */}
                                                        </>
                                                    )}
                                                </ContainerBodyInfo>
                                            </ContainerInfo>
                                        )}
                                        {title !== 'Desafios Diários' ? null : (
                                            <DescriptionExamination>
                                                <ImgTarget src={tickCorrect} marginRight="3px" />
                                                {e.hit_questions}
                                                <ImgTarget src={tickError} marginLeft="10px" marginRight="3px" />
                                                {e.miss_questions}
                                            </DescriptionExamination>
                                        )}
                                    </ContainerTest>
                                );
                            })
                        ) : (
                            isLoading || loading ? (
                                <ContainerLoader>
                                    <Loader
                                        visible={isLoading || loading}
                                        type="TailSpin"
                                        color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                        height={100}
                                        width={100}
                                />
                            </ContainerLoader>
                            ) : <AdjustEmpty>
                            <NameCategory>{labelListEmpty}</NameCategory>
                            </AdjustEmpty>
                        )}
                    </ContainerBodyTestAndSimulate>
                </>
            );
        }
        if (title === 'Filtrar por Concursos/Formação') {
            return (
                <ContainerBodyCreateBook>
                    <ContainerBodyFilter>
                        <ButtonSaveFilter onClick={() => ApplyFilterTwo('clean')} width="135px" backgroundColor={colors.basic(window.localStorage.getItem('theme') || 'light').grayDefault}>LIMPAR Filtros</ButtonSaveFilter>
                        <ButtonSaveFilter onClick={() => ApplyFilterTwo('apply')} width="135px">Aplicar Filtros</ButtonSaveFilter>
                    </ContainerBodyFilter>
                    <NameOption>Selecionar o Concursos</NameOption>
                    <ContainerSelect>
                        <Select value={idExamination} onChange={onChangeExamination}>
                            {allExamination?.map((v) => (
                                <Option value={v.id}>{v?.name}</Option>
                            ))}
                        </Select>
                    </ContainerSelect>
                    <NameOption>Selecionar a Formação</NameOption>
                    <ContainerSelect>
                        <Select value={idEngineering} onChange={onChangeEngineering}>
                            {allEngineering?.map((v) => (
                                <Option value={v.id}>{v?.name}</Option>
                            ))}
                        </Select>
                    </ContainerSelect>
                </ContainerBodyCreateBook>
            );
        }

        if (title === 'Gerar Simulado') {
            const GoToSelectedFilterPerso = () => {
                if (setIdPopUp) {
                    setTypeSimulate('Personalizado');
                    setIdPopUp(5);
                }
            };
            const GoToSelectedFilterFast = () => {
                if (setIdPopUp) {
                    setTypeSimulate('Fast');
                    setIdPopUp(5);
                }
            };
            return (
                <AdjustEmpty>
                    <InfoCard marginTop="20px" fontWeight="600">Qual tipo de simulado você deseja criar?</InfoCard>
                    <ButtonOptionSimulate onClick={GoToSelectedFilterPerso}>
                        <IconEvent src={editWhite} />
                        <TextButtonOptionSimulate>PERSONALIZADO</TextButtonOptionSimulate>
                    </ButtonOptionSimulate>
                    <ButtonOptionSimulate onClick={GoToSelectedFilterFast}>
                        <IconEvent src={activityWhite} />
                        <TextButtonOptionSimulate>FAST</TextButtonOptionSimulate>
                    </ButtonOptionSimulate>
                </AdjustEmpty>
            );
        }

        if (title === 'Redação') {
            const spaceDescription = { paddingTop: 10 };
            const onChangeAccordion = async (txt: Array<number>) => {
                setLoading(true);
                setAllSelectedAccordion(txt);
                if (setAllTextSupport && txt?.length) {
                    const getAllTextSupport = await essay.GetAllTextSupport(txt[0], infoUser?.token || '');
                    setAllTextSupport(getAllTextSupport?.result || []);
                } else if (setAllTextSupport) {
                    setAllTextSupport([]);
                }
            };
            return (
                <ContainerAdjustAbout>
                    <Accordion
                        allowZeroExpanded
                        onChange={onChangeAccordion}
                    >
                        {allInfoEssay.map((v: any) => (
                            <AccordionItem
                                uuid={v.id}
                                style={popUpConstants.stylesAccordionItem(allSelectedAccordion.includes(v.id))}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton style={popUpConstants.stylesAccordionItemButton}>
                                        {v.description}
                                        <ArrowForDropDawn src={arrowDropDawnPurple} />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {!allSelectedAccordion.includes(v.id) ? (
                                    <Line margin="0px 0px 0px 0px" />
                                ) : null}
                                {allSelectedAccordion.includes(v.id) ? (
                                    <AccordionItemPanel style={spaceDescription}>
                                        {allTextSupport?.map((e: any) => (
                                            <AnimationDescription>
                                                <Welcome>
                                                    {e?.description}
                                                </Welcome>
                                            </AnimationDescription>
                                        ))}
                                        {allSelectedAccordion.includes(v.id) ? (
                                            <Line margin="10px 0px 20px 0px" />
                                        ) : null}
                                    </AccordionItemPanel>
                                ) : null}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </ContainerAdjustAbout>
            );
        }

        return null;
    };

    const RenderHeightFix = (typeStyles: string) => {
        if (title === 'Ordenação' && typeStyles === 'container') {
            return idScreen === 'Filters' ? '470px' : '310px';
        }
        if (title === 'Ordenação' && typeStyles === 'header') {
            return idScreen === 'Filters' ? '100px' : '100px';
        }
        if (title === 'Ordenação' && typeStyles === 'body') {
            return idScreen === 'Filters' ? '470px' : '210px';
        }

        return '';
    };

    const GoToFilter = () => {
        if (setIdPopUp) {
            setIdPopUpOld(title === 'Provas' ? 2 : 3);
            setIdPopUp(6);
        }
    };

    return (
        <Modal
            isOpen={popUp}
            style={modalPopUp}
            onRequestClose={CloseModal}
        >
            <ContainerPopUp heightFix={RenderHeightFix('container')} loading={loading}>
                <ContainerAdjust>
                    <ContainerHeader heightFix={RenderHeightFix('header')} LargeSize={['Ordenação', 'Opções', 'Criar caderno', 'Editar caderno', 'Criar filtro salvo', 'Editar filtro salvo', 'Selecione um filtro', 'Filtros', 'Filtrar por Concursos/Formação', 'Gerar Simulado', 'Simulados', 'Provas', 'Desafios Diários', 'Redação'].includes(title)}>
                        <ContainerTopHeader>
                            <Title>
                                <Title>—</Title>
                                {title.toUpperCase()}
                            </Title>
                            <Close onClick={CloseModal}>
                                Fechar
                                <ImgClose src={closeWhite} />
                            </Close>
                        </ContainerTopHeader>
                        {!['Ordenação', 'Opções', 'Criar caderno', 'Editar caderno', 'Criar filtro salvo', 'Editar filtro salvo', 'Selecione um filtro', 'Filtros', 'Provas', 'Simulados', 'Filtrar por Concursos/Formação', 'Gerar Simulado'].includes(title) ? (
                            <ContainerSearch>
                                <ImgSearch src={searchWhite} />
                                <InputSearch placeholder="Buscar" onChange={onChangeSearch} />
                            </ContainerSearch>
                        ) : null}
                        {['Provas', 'Simulados'].includes(title) && typeListSimulate !== 'solicitation' ? (
                            <ContainerSeparatorSearch>
                                <ContainerSearch>
                                    <ImgSearch src={searchWhite} />
                                    <InputSearch value={search} placeholder="Buscar" onChange={onChangeSearch} />
                                </ContainerSearch>
                                <ImgFilter src={filterWhite} onClick={GoToFilter} />
                            </ContainerSeparatorSearch>
                        ) : null}
                    </ContainerHeader>
                    <ContainerBody heightFix={RenderHeightFix('body')} LargeSize={['Cadernos', 'Bancas', 'Formações', 'Ordenação', 'Selecione um filtro', 'Opções'].includes(title)}>
                        {allExamination?.length === 0 || allEngineering?.length === 0 || loading ? (
                            <ContainerLoader>
                                <Loader
                                    visible={allExamination?.length === 0 || !allEngineering || loading}
                                    type="TailSpin"
                                    color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                    height={100}
                                    width={100}
                                />
                            </ContainerLoader>
                        ) : RenderBody()}
                        {title === 'Ordenação' && flowOrderBy ? (
                            <AdjustButtonsFilterOrder onClick={ActionApplyOrder}>
                                <ButtonFilterOrder>
                                    <TextFilterOrder>Aplicar Filtros</TextFilterOrder>
                                </ButtonFilterOrder>
                                {idOrder !== 'id' ? (
                                    <ButtonFilterOrder style={{ marginLeft: '10px', background: 'rgba(255, 63, 63, 0.97)' }} onClick={CleanFilters}>
                                        <TextFilterOrder>Limpar Filtros</TextFilterOrder>
                                    </ButtonFilterOrder>
                                ) : null}
                            </AdjustButtonsFilterOrder>
                        ) : null}
                    </ContainerBody>
                </ContainerAdjust>
                {!['Cadernos', 'Bancas', 'Formações', 'Ordenação', 'Opções', 'Criar caderno', 'Editar caderno', 'Criar filtro salvo', 'Editar filtro salvo', 'Selecione um filtro', 'Filtros', 'Provas', 'Simulados', 'Filtrar por Concursos/Formação', 'Gerar Simulado', 'Desafios Diários'].includes(title) ? (
                    <ContainerButtons>
                        <ButtonAction onClick={CloseModal}>Cancelar</ButtonAction>
                        <ButtonAction
                            backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').pointStrong}
                            onClick={ApplyFilter}
                        >
                            Concluir
                        </ButtonAction>
                    </ContainerButtons>
                ) : null}
            </ContainerPopUp>
            {typeSimulate === 'Personalizado' && allCards?.length > 0 ? (
                <ContainerAllCardSimulate heightFix={RenderHeightFix('container')}>
                    {allCards.map((v: any, i: number) => {
                        const DeleteOneCard = () => {
                            const removeCard = allCards.filter((value: any, index: number) => i !== index);
                            setAllCards(removeCard);
                            setModalPopUp({
                                ...modalPopUp,
                                content: {
                                    ...modalPopUp.content,
                                    maxWidth: '52vw',
                                },
                            });
                        };
                        const onChangeQtdQuestion = (txt: React.ChangeEvent<HTMLInputElement>) => {
                            const { value } = txt.target;
                            const formatOneElement = allCards.map((item: any, index) => {
                                if (index === i) {
                                    return {
                                        ...item,
                                        qtd: value,
                                    };
                                }
                                return item;
                            });
                            setAllCards(formatOneElement);
                        };
                        return (
                            <CardSimulate>
                                <ContainerAdjustHeader>
                                    <DivNull />
                                    <ContainerDeleteCardSimulate onClick={DeleteOneCard}>-</ContainerDeleteCardSimulate>
                                </ContainerAdjustHeader>
                                <TitleCardSimulate>Disciplinas</TitleCardSimulate>
                                <ResultCardSimulate>{v?.qntDisciplinas}</ResultCardSimulate>
                                <TitleCardSimulate>Bancas</TitleCardSimulate>
                                <ResultCardSimulate>{v?.qntBank}</ResultCardSimulate>
                                <TitleCardSimulate>Dificuldades</TitleCardSimulate>
                                <ResultCardSimulate>{v?.qntDificuldade}</ResultCardSimulate>
                                <TitleCardSimulate>Quantidade de questões</TitleCardSimulate>
                                <InputQtdQuestion value={v?.qtd} onChange={onChangeQtdQuestion} />
                            </CardSimulate>
                        );
                    })}
                </ContainerAllCardSimulate>
            ) : null}
            <Modal
                isOpen={openModalPeriod}
                style={statisticsConstants.modalPickerStyles}
                onRequestClose={() => setOpenModalPeriod(false)}
            >
                <ModalPickerComponent
                    allData={allDataOrder}
                    typeOrderId={typeOrderId}
                    setTypeOrderId={setTypeOrderId}
                    setOpenModalPeriod={setOpenModalPeriod}
                    start={start}
                    end={end}
                    setStart={setStart}
                    setEnd={setEnd}
                />
            </Modal>
            <Modal
                isOpen={openModalConfirmDeleteFilter}
                style={statisticsConstants.modalPickerStyles}
                onRequestClose={() => setOpenModalConfirmDeleteFilter(false)}
            >
                <ContainerPeriod style={{ padding: 20 }}>
                    <TextFilterConfirm style={{ marginBottom: 20 }}>Este filtro salvo está vinculado a um caderno. Tem certeza que deseja excluí-lo?</TextFilterConfirm>
                    <ContainerButtons>
                        <ButtonAction onClick={() => {
                            setOpenModalConfirmDeleteFilter(false);
                            setIsVerified(false);
                        }}
                        >
                            Cancelar
                        </ButtonAction>
                        <ButtonAction
                            backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').pointRadialWeak}
                            onClick={applyDeleteFilter}
                        >
                            DELETAR
                        </ButtonAction>
                    </ContainerButtons>
                </ContainerPeriod>
            </Modal>
        </Modal>
    );
};

export default PopUpComponent;
