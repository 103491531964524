// libraries
import styled from 'styled-components';

// js
import colors from '../../../../styles/colors';

export const ContainerPopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    /* justify-content: space-between; */
    margin-left: 10px;
    transition-duration: 400ms;
    padding: 15px;
    min-width: 60vw;
    max-width: 60vw;
    min-height: 55vh;
    max-height: 55vh;
    overflow-y: auto;
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
    @media (max-width: 600px) {
    min-width: 75vw;
    max-width: 75vw;
    min-height: 55vh;
    max-height: 55vh;
    }
`;

export const ContainerAdjustResult = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Title = styled.div`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    font-size: 18px;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

export const Close = styled.button`
    border: none;
    background-color: transparent;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    cursor: pointer;
    padding: 10px;
    display: flex;
`;

export const ImgClose = styled.img`
    height: 12px;
    width: 12px;
`;

export const ImgSend = styled.img<ImgInterface>`
    opacity: ${(props) => (props.isLoading ? '0.3' : '')};
    height: 16px;
    width: 16px;
    object-fit: contain;
    transform: rotate(45deg);
`;

export const ContainerInput = styled.div<ContainerMainCommentInterfaces>`
    border-bottom: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    padding-bottom: 5px;
    display: flex;
    margin-top: ${(props) => props.marginTop || '20px'};
    margin-bottom: ${(props) => props.marginBottom || '20px'};
    align-items: center;
    align-self: ${(props) => props.alignSelf || 'flex-start'};
    width: ${(props) => props.width || '100%'};
`;

interface ImageUserInterfaces {
    height?: string,
    width?: string,
}

interface ImgInterface {
    isLoading: boolean
}

export const ImageUser = styled.img<ImageUserInterfaces>`
    display: flex;
    border-radius: 50%;
    height: ${(props) => props.height || '40px'};
    width: ${(props) => props.width || '40px'};
`;

export const ImageComment = styled.img`
    display: flex;
    border-radius: 50%;
    height: 27px;
    width: 27px;
`;

export const Input = styled.input<ContainerMainCommentInterfaces>`
    height: 40px;
    margin-left: 5px;
    border: none;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    align-self: ${(props) => props.alignSelf || 'flex-start'};
    width: ${(props) => props.width || '100%'};
    font-size: 14px;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

export const ContainerComment = styled.div<ContainerCommentInterface>`
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    margin-bottom: 10px;
    width: 100%;
    opacity: ${(props) => (props.opacity ? '.3' : '1')};
`;

export const ContainerInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    width: 100%;
`;

export const DescriptionComment = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse};
    font-size: 13px;
    width: 100%;
    margin-left: 3px;
    display: flex;
    flex: 1;
`;

export const ButtonDelete = styled.button`
    margin-right: 10px;
    margin-left: auto;
    text-decoration: none;
    border: none;
    outline: none;
    background: none;
    padding: 10;
    cursor: pointer;
`;

export const ContainerTitle = styled.div`
    display: flex;
    align-items: center;
`;

interface TitleCommentInterfaces {
    manager?: boolean;
}

interface ContainerCommentInterface {
    opacity: boolean;
}

export const TitleComment = styled.text<TitleCommentInterfaces>`
    display: flex;
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    padding: ${(props) => (!props.manager ? '0px' : '2px 5px 2px 5px')};
    border-radius: ${(props) => (!props.manager ? '0px' : '9px')};
    background-color: ${(props) => (!props.manager ? 'transparent' : colors.theme(window.localStorage.getItem('theme') || 'light').purpleDarkMedium)};
    font-size: 14px;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

export const DateComment = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayMedium};
    font-size: 8px;
    margin-left: 5px;
    text-align: center;
`;

export const ImageVerified = styled.img`
    margin-left: 5px;
`;

interface ContainerMainCommentInterfaces {
    width?: string;
    cursor?: string;
    alignSelf?: string;
    marginTop?: string;
    marginBottom?: string;
}

export const ContainerMainComment = styled.div<ContainerMainCommentInterfaces>`
    width: ${(props) => props.width || '100%'};
    min-width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: ${(props) => props.alignSelf || 'flex-start'};
    display: flex;
    cursor: ${(props) => props.cursor || 'default'};
    position: relative;
    z-index: 1;
    min-height: 40px;
`;

export const ContainerMore = styled.div`
    display: flex;
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    margin-left: 5%;
    cursor: pointer;
    align-items: center;
    min-width: 90px;
    max-width: 90px;
    font-size: 13px;
    @media (max-width: 600px) {
        font-size: 13px;
    }
`;

export const ContainerAdjustBody = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const IconDrowOrUp = styled.img`
    margin-left: 5px;
    width: 6px;
    height: 3px;
    object-fit: contain;
`;

export const ContainerUpOrLower = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 20px;
    margin-left: 35px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
`;

export const ImgUpperOrLower = styled.img`
    cursor: pointer;
    object-fit: contain;
    margin-left: 5px;
    margin-right: 10px;
    height: 16px;
    width: 16px;
`;

export const ImageChildrenComment = styled.img`
    min-height: 150px;
    max-height: 150px;
    min-width: 200px;
    max-width: 200px;
    object-fit: contain;
    margin-top: 10px;
`;
