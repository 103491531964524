// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function GetAllFilters(
    idCategory: number,
    titleForSearching: string,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/filters?idCategory=${idCategory}&searchTxt=${titleForSearching}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetAllFiltersSaved(
    titleForSearching: string,
    typeOrderBy: string,
    flowOrderBy: string,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/filtersSaved?idUser=${idUser}&typeOrderBy=${typeOrderBy || 'id'}&titleForSearching=${titleForSearching}&flowOrderBy=${flowOrderBy || 'asc'}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateOneFilters(
    title: string,
    idUser: number,
    token: string,
    idFilters: Array<number>,
    idSubjects?: Array<number>,
): Promise<any> {
    try {
        const body = {
            idFilters,
            idSubjects,
            title,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/filtersSaved?idUser=${idUser}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function EditOneFilters(
    title: string,
    idFilterSaved: number,
    idUser: number,
    token: string,
    idFilters: Array<number>,
    idSubjects?: Array<number>,
): Promise<any> {
    try {
        const body = {
            idFilters,
            idSubjects,
            idFilterSaved,
            title,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.put(`${baseUrl}/filtersSaved?idUser=${idUser}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DeleteOneFilters(
    idFilterSaved: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.delete(`${baseUrl}/filtersSaved?idFilterSaved=${idFilterSaved}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetAllFilters,
    GetAllFiltersSaved,
    CreateOneFilters,
    EditOneFilters,
    DeleteOneFilters,
};
