// libraries
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';

// js
import { UPDATE_DARKMODE } from './constants';

const initialState = {
    darkMode: false,
};

const handlers = {
    [UPDATE_DARKMODE]: (state = initialState, action: AnyAction) => {
        const darkMode = action.payload;
        return { ...state, darkMode };
    },
};

export default handleActions(handlers, initialState);
