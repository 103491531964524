// libraries
// import { getTime } from 'date-fns';
import axios from 'axios';
import OneSignal from 'react-onesignal';
import { v4 as uuidv4 } from 'uuid';

// js
import constants from '../configs/constants';
import { logoutAuth } from '../store/modules/user/actions';
// import functions from '../utils/functions';

// constants
const { baseUrl } = constants;
const headerApplicationJson = {
    'Content-Type': 'application/json',
};

// api
async function Login(
    email: string,
    password: string,
): Promise<any> {
    try {
        const GeneratorId = uuidv4();
        const body: any = {
            email,
            password,
            session: GeneratorId,
        };
        if (constants.getIDNotification()) {
            body.idDevice = await OneSignal.getUserId();
        }
        const stringBody = JSON.stringify(body);
        const headers = headerApplicationJson;
        const response = await axios.post(`${baseUrl}/users/token`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function LogoutUser(
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.patch(`${baseUrl}/users/logout?idUser=${idUser}`, null, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function RefreshToken(
    session: string,
    setPathRoute: (v: string) => void,
    setRedirect: (v: boolean) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dispatch: any,
    token: string,
): Promise<any> {
    try {
        const body = {
            session,
            refresh: token,
        };
        const stringBody = JSON.stringify(body);
        const headers = headerApplicationJson;
        const response = await axios.post(`${baseUrl}/users/refreshToken`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
        if (response.status === 401) {
            dispatch(logoutAuth());
            setPathRoute('/');
            setRedirect(true);
        }
    } catch (error) {
        const { response }: any = error;
        return response?.data;
    }
}

async function RecoverPassword(email: string): Promise<any> {
    try {
        const body = {
            email,
        };
        const stringBody = JSON.stringify(body);
        const headers = headerApplicationJson;
        const response = await axios.post(`${baseUrl}/users/recoverPassword`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function Register(
    name: string,
    email: string,
    password: string,
    // telephone: string,
    // birthDate: string,
    // identifier: string,
    idEngineering: string,
    idExamination: string,
    idSocial: string,
): Promise<any> {
    try {
        // const [day, month, year] = birthDate.split('/');
        // const timeBirthDate = getTime(new Date(Number(year), Number(month) - 1, Number(day)));
        const GeneratorId = uuidv4();
        const body: any = {
            name,
            email,
            password,
            // telephone: functions.OnlyNumbersMask(telephone),
            // birthDate: timeBirthDate,
            // cpf: functions.OnlyNumbersMask(identifier),
            idEngineering: Number(idEngineering),
            idExamination: Number(idExamination),
            idSocial,
            session: GeneratorId,
        };
        if (constants.getIDNotification()) {
            body.idDevice = await OneSignal.getUserId();
        }
        const stringBody = JSON.stringify(body);
        const headers = headerApplicationJson;
        const response = await axios.post(`${baseUrl}/users`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetSocial(idSocial: string, email = ''): Promise<any> {
    try {
        const GeneratorId = uuidv4();
        const response = await axios.get(`${baseUrl}/users/social?idSocial=${idSocial}&email=${email}&session=${GeneratorId}`);
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        if (response?.data?.message) {
            // eslint-disable-next-line no-alert
            alert(response?.data?.message);
        }
    }
}

async function GetExamination(
    searchTxt?: string,
): Promise<any> {
    try {
        const RenderPath = searchTxt ? `${baseUrl}/examination?searchTxt=${searchTxt}` : `${baseUrl}/examination`;
        const response = await axios.get(RenderPath);
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetEngineering(
    searchTxt?: string,
): Promise<any> {
    try {
        const RenderPath = searchTxt ? `${baseUrl}/engineering?searchTxt=${searchTxt}` : `${baseUrl}/engineering`;
        const response = await axios.get(RenderPath);
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DeleteUsers(idUser: number, token: string): Promise<any> {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await axios.delete(`${baseUrl}/users?idUser=${idUser}`, configs);
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error: any) {
        // const { response }: any = error;
        // // eslint-disable-next-line no-alert
        // alert(response?.data?.message);
    }
}

export default {
    Login,
    RecoverPassword,
    Register,
    RefreshToken,
    GetSocial,
    GetExamination,
    GetEngineering,
    LogoutUser,
    DeleteUsers,
};
