// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

// api
async function GetTimeQuestion(
    idQuestion: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/statistics/question?idQuestion=${idQuestion}&idUser=${idUser}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetStatisticsGeneral(
    period: string,
    start: string,
    end: string,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const RenderURL = () => {
            if (period === 'CUSTOM') {
                return `${baseUrl}/statistics/general?idUser=${idUser}&period=${period}&start=${start}&end=${end}`;
            }

            return `${baseUrl}/statistics/general?idUser=${idUser}&period=${period}`;
        };
        const response = await axios.get(RenderURL(), { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetStatisticsSpecific(
    period: string,
    idFilters: Array<number>,
    selectedSubjects: Array<number>,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        let newUrl = `${baseUrl}/statistics/specific?idUser=${idUser}&period=${period}`;
        if (idFilters?.length > 0) {
            newUrl = `${newUrl}&idFilters=${idFilters}`;
        }
        if (selectedSubjects?.length > 0) {
            newUrl = `${newUrl}&idSubjects=${selectedSubjects}`;
        }
        const response = await axios.get(newUrl, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetStatisticsStrongAndWeak(
    idBook: number,
    period: string,
    start: string,
    end: string,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const RenderURL = () => {
            if (period === 'CUSTOM') {
                return `${baseUrl}/statistics/strongweak?idUser=${idUser}&idBook=${idBook}&period=${period}&start=${start}&end=${end}`;
            }

            return `${baseUrl}/statistics/strongweak?idUser=${idUser}&idBook=${idBook}&period=${period}`;
        };
        const response = await axios.get(RenderURL(), { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function GetStatisticsRanking(
    idExamination: number,
    idEngineering: number,
    period: string,
    start: string,
    end: string,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const RenderURL = () => {
            const RenderIdExamination = idExamination ? `&idExamination=${idExamination}` : '';
            const RenderIdEngineering = idEngineering ? `&idEngineering=${idEngineering}` : '';
            if (period === 'CUSTOM') {
                return `${baseUrl}/statistics/ranking?idUser=${idUser}${RenderIdExamination}&period=${period}&start=${start}&end=${end}${RenderIdEngineering}`;
            }
            return `${baseUrl}/statistics/ranking?idUser=${idUser}${RenderIdExamination}&period=${period}${RenderIdEngineering}`;
        };
        const response = await axios.get(RenderURL(), { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    GetStatisticsGeneral,
    GetStatisticsSpecific,
    GetStatisticsStrongAndWeak,
    GetStatisticsRanking,
    GetTimeQuestion,
};
