/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

// js
import colors from '../../styles/colors';
import {
    Container,
    Home,
    ContainerBody,
    Title,
    ContainerFrame,
    TitleLabel,
    Input,
    ContainerAdjustInputs,
    ContainerWarnning,
    CheckBox,
    ButtonAction,
    GoBackScreen,
} from './paymentStyles';
import paymentConstants from './paymentConstants';
import functions from '../../utils/functions';
import paymentApi from '../../services/payment';
import { arrowLeftBlack } from '../../assets';
import { RootState } from '../../store';

// interfaces
interface getParamsForgotPasswordInterfaces {
    type?: string,
    idUser?: string,
    token?: string,
}

const PaymentPage: React.FC = () => {
    // constants
    const getParams: getParamsForgotPasswordInterfaces = useParams();

    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // states
    const [inputs, setInputs] = useState(paymentConstants.initialInputs);
    const [isCheckedWarnning, setIsCheckedWarnning] = useState(false);

    // methods
    const onChangeIsCheckedWarnning = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const validationBoolean = txt.target.value.toLowerCase() === 'true';
        setIsCheckedWarnning(validationBoolean);
    };

    const ActionGoBack = () => {
        window.location.replace(`/signatures/typePlan=${infoUser?.type_plan}/idUser=${infoUser?.id}/token=${infoUser?.token}`);
    };

    const onPayment = async () => {
        if (
            isCheckedWarnning
            && inputs.brandCard
            && inputs.codCard?.length >= 3
            && inputs.dateCard?.length === 5
            && inputs.nameClient?.length
            && inputs.numberCard?.length
            && inputs.cpfClient?.length === 14
        ) {
            const GetInfoPayment = await paymentApi.ActivePayment(
                getParams.type || '',
                inputs.cpfClient.replace(/\D/g, ''),
                inputs.nameClient,
                inputs.numberCard.replace(/\D/g, ''),
                inputs.brandCard,
                inputs.codCard,
                inputs.dateCard.substring(0, 2),
                `20${inputs.dateCard.slice(-2)}`,
                getParams.idUser || '',
                getParams.token || '',
            );
            if (GetInfoPayment?.status === 200) {
                window.location.replace(`/payment/typePlan=${getParams.type}/idUser=${getParams.idUser}/token=${getParams.token}`);
            } else {
                // eslint-disable-next-line no-alert
                alert(GetInfoPayment?.message || 'Ocorreu um erro ao processar o pagamento!');
            }
        } else {
            // eslint-disable-next-line no-alert
            alert('Preencha todos os campos.');
        }
    };

    useEffect(() => {
        const validation = paymentConstants.validationCards.find((item) => item.regex.test(inputs.numberCard.replace(/\s/g, '')));
        if (validation) {
            const { type, mask } = validation;
            setInputs({ ...inputs, brandCard: type, maskNumberCard: mask });
        }
    }, [inputs.numberCard]);

    // renders
    function RenderInput(
        label: string,
        type: 'numberCard' | 'nameClient' | 'dateCard' | 'codCard' | 'cpfClient',
        placeholder: string,
        maxLength?: number,
    ) {
        // methods
        const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = txt.target;
            if (type === 'numberCard') {
                return setInputs({ ...inputs, [type]: value.replace(/[^0-9\\.]+/g, '') });
            }
            if (type === 'cpfClient') {
                return setInputs({ ...inputs, [type]: functions.MaskIdentifier(value) });
            }
            if (type === 'nameClient') {
                return setInputs({ ...inputs, [type]: value.replace(/[0-9]/g, '').toUpperCase() });
            }
            if (type === 'codCard') {
                return setInputs({ ...inputs, [type]: value.replace(/[^0-9\\.]+/g, '') });
            }
            if (type === 'dateCard') {
                const ValidationDateCard = () => {
                    if (inputs.dateCard?.length === 1 && value?.length === 2) {
                        if (value.includes('/')) {
                            return value.replace(/[^0-9\\.]+/g, '');
                        }
                        return `${value.replace(/[^0-9\\.]+/g, '')}/`;
                    }
                    return value;
                };
                return setInputs({ ...inputs, [type]: ValidationDateCard() });
            }
            return setInputs({ ...inputs, [type]: value });
        };

        // main
        return (
            <ContainerFrame width={type === 'dateCard' || type === 'codCard' ? '48%' : '100%'}>
                <TitleLabel>{label}</TitleLabel>
                {type === 'numberCard' ? (
                    <InputMask
                        mask={inputs.maskNumberCard}
                        value={inputs.numberCard.replace(/\D/g, '')}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={paymentConstants.stylesInputNumberCard}
                        maskPlaceholder={null}
                    />
                ) : (
                    <Input
                        value={inputs[type]}
                        onChange={onChange}
                        placeholder={placeholder}
                        maxLength={maxLength}
                    />
                )}
            </ContainerFrame>
        );
    }

    return (
        <Container>
            <Home>
                <ContainerBody>
                    {infoUser?.token ? (
                        <GoBackScreen src={arrowLeftBlack} onClick={ActionGoBack} />
                    ) : null}
                    {RenderInput('NÚMERO DO CARTÃO *', 'numberCard', '0000 0000 0000 0000')}
                    {RenderInput('NOME IMPRESSO NO CARTÃO *', 'nameClient', '', 64)}
                    <ContainerAdjustInputs>
                        {RenderInput('MÊS E ANO DE VENCIMENTO *', 'dateCard', 'MM/AA', 5)}
                        {RenderInput('CÓDIGO DE SEGURANÇA (CVV) *', 'codCard', '000', 3)}
                    </ContainerAdjustInputs>
                    {RenderInput('CPF DO TÍTULAR DO CARTÃO', 'cpfClient', '000.000.000-00')}
                    <ContainerWarnning>
                        <CheckBox type="checkbox" checked={isCheckedWarnning} onChange={onChangeIsCheckedWarnning} value={JSON.stringify(!isCheckedWarnning)} />
                        <Title fontWeight="700">
                            Importante:
                            <Title marginLeft="5px">Estou de acordo com os</Title>
                            <Title marginLeft="5px" textDecoration="underline">termos de adesão</Title>
                            <Title>.</Title>
                        </Title>
                    </ContainerWarnning>
                    <ButtonAction onClick={onPayment}>
                        <Title fontWeight="bold" color={colors.basic(window.localStorage.getItem('theme') || 'light').white}>Realizar Pagamento</Title>
                    </ButtonAction>
                </ContainerBody>
            </Home>
        </Container>
    );
};

export default PaymentPage;
