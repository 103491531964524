// libraries
import styled from 'styled-components';

// js
import colors from '../../../../styles/colors';

// styles
export const ContainerHeader = styled.div`
    display: flex;
    padding: 0px 20px 0px 20px;
    margin-top: 20px;
    justify-content: space-between;
`;

export const TextInfoUser = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayLight};
    font-size: 14px;
    width: 25%;
    @media (max-width: 1700px) {
        font-size: 12px;
    }
    @media (max-width: 1400px) {
        font-size: 11px;
    }
`;

export const YouIdentifier = styled.text`
    font-weight: normal;
    font-size: 20px;
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;
