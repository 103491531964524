// libraries
import React from 'react';
import { lightFormat } from 'date-fns';

// js
import {
    Cover,
    ContainerBox,
    ContainerInfo,
    Title,
    Hours,
    Description,
    ButtonMore,
    AdjustInfoTitle,
} from '../timelineStyles';

// interface
interface Props {
    item: any,
    widthActual: number,
    setOpenDescriptionTimeline: (v: any) => void,
}

const BoxTimelineComponent: React.FC<Props> = (props: Props) => {
    // constants
    const { item, widthActual, setOpenDescriptionTimeline } = props;

    const formatDate = lightFormat(new Date(item.created_at), 'dd/MM/yyyy');
    const formatHour = lightFormat(new Date(item.created_at), 'HH');
    const formatMinutes = lightFormat(new Date(item.created_at), 'mm');

    // methods
    const ValidationQtdLine = () => {
        if (widthActual < 2000) {
            return 3;
        }

        return 2;
    };

    const TradeMore = () => {
        setOpenDescriptionTimeline({
            idTimeline: item.id,
            description: item.description,
        });
    };

    // main
    return (
        <ContainerBox key={item.id}>
            {item?.file ? (
                <Cover src={item.file} />
            ) : null}
            <ContainerInfo fileExist={item?.file?.length > 0}>
                <AdjustInfoTitle>
                    <Title>{item.title}</Title>
                    <Hours>{`${formatDate} ${formatHour}h${formatMinutes}`}</Hours>
                </AdjustInfoTitle>
                <Description qtdLine={ValidationQtdLine()}>{item.description}</Description>
                <ButtonMore onClick={TradeMore}>
                    <Hours>Ler matéria completa</Hours>
                </ButtonMore>
            </ContainerInfo>
        </ContainerBox>
    );
};

export default BoxTimelineComponent;
