// libraries
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';

// js
import colors from '../../styles/colors';

// constants
const fadeInAnimation = keyframes`${fadeIn}`;

// interfaces
interface TitleInputInterfaces {
    activeColor?: string,
    activeCursor?: string,
    fontWeight?: string,
    width?: string,
}

interface HomeInterfaces {
    background: string,
    zoomHeight?: boolean,
    height?: string,
}

interface ContainerBoxInterfaces {
    opacity?: number,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundScreen};
    min-height: 100vh;
    min-width: 100vw;
`;

export const LogoHeader = styled.img`
    object-fit: contain;
    height: 80px;
    width: 200px;
`;

export const ContainerBox = styled.div<ContainerBoxInterfaces>`
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 10px;
    position: absolute;
    top: 100px;
    overflow-y: auto;
    opacity: ${(props) => (props.opacity || 1)};
    height: 80vh;
    width: 40%;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
    @media (max-width: 900px) {
        width: 90%;
    }
`;

export const ContainerBody = styled.div<HomeInterfaces>`
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: ${(props) => (props.zoomHeight ? '100% 100%' : '100% 150%')};
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* height: ${isMobile ? '20vh' : '50vh'}; */
    height: ${(props) => (props.zoomHeight ? '100vh' : props.height)};
    transition-duration: 1000ms;
    min-width: 100vw;
`;

export const Title = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    font-size: 26px;
`;

export const TitleInput = styled.text<TitleInputInterfaces>`
    color: ${(props) => (props.activeColor || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    cursor: ${(props) => (props.activeCursor || 'default')};
    font-size: 16px;
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    width: ${(props) => (props.width || '80%')};
    @media (max-width: 900px) {
        font-size: 14px;
    }
    @media (max-width: 600px) {
        font-size: 12px;
    }
`;

export const ContainerSelect = styled.div`
    align-items: center;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight};
    display: flex;
    justify-content: space-between;
    border-radius: 30px;
    margin: 10px 0px 20px 0px;
    padding: 0px 10px 0px 30px;
    min-height: 60px;
    width: 80%;
    @media (max-width: 900px), (max-height: 900px) {
        margin: 5px 0px 10px 0px;
        min-height: 50px;
    }
    @media (max-width: 600px), (max-height: 650px) {
        min-height: 40px;
    }
`;

export const LogoMain = styled.img`
    object-fit: contain;
    height: 185px;
    transition-duration: 400ms;
    animation: 1s ${fadeInAnimation};
    width: 185px;
`;

export const Select = styled.select`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border: none;
    min-height: 100%;
    width: 95%;
`;

export const Option = styled.option``;

export const ImgBackgroundLinearGradient = styled.div<HomeInterfaces>`
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: ${(props) => (props.zoomHeight ? '100% 100%' : '100% 150%')};
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    opacity: 0.7;
`;

export const Subtitle = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    margin-bottom: 40px;
    font-size: 20px;
    width: 80%;
    text-align: center;
`;

export const ContainerOr = styled.div`
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
`;

export const Dashed = styled.div`
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight};
    height: 1px;
    width: 45%;
`;
