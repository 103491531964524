/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Switch,
    Route,
    useHistory,
    // Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

// pages
import LoginPage from '../pages/Login/LoginPage';
import RegisterPage from '../pages/Register/RegisterPage';
import RecoverPasswordPage from '../pages/RecoverPassword/RecoverPasswordPage';
import TimelinePage from '../pages/Timeline/TimelinePage';
import StatisticsPage from '../pages/Statistics/StatisticsPage';
import BooksPage from '../pages/Books/BooksPage';
import QuestionsPage from '../pages/Questions/QuestionsPage';
import FiltersPage from '../pages/Filters/FiltersPage';
import FlowQuestionsPage from '../pages/FlowQuestions/FlowQuestionsPage';
import SignaturesPage from '../pages/signatures/SignaturesPage';
import PaymentPage from '../pages/payment/PaymentPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicy/PrivacyPolicyPage';
import DeleteAccountPage from '../pages/DeleteAccount/DeleteAccountPage';

// js
import { RootState } from '../store';

const Routes: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const history = useHistory();

    // conditions
    if (!window.location.pathname.includes('/recoverPassword') && !window.location.pathname.includes('/register') && !window.location.pathname.includes('/politica-de-privacidade') && !window.location.pathname.includes('/zero1app/excluir-conta') && !infoUser && !isMobile) {
        history.push('/');
    }
    if (infoUser && window.location.pathname === '/' && !isMobile) {
        history.push('/timeline');
    }

    // rota para redirecionar o usuário quando digitar uma rota não encontrada.
    // const pathNotFound = infoUser ? '/timeline' : '/';
    // const RouteNotFound = infoUser ? TimelinePage : LoginPage;

    return (
        <Switch>
            <Route path="/" exact component={LoginPage} />
            <Route path={['/register/:social', '/register']} exact component={RegisterPage} />
            <Route path="/recoverPassword" exact component={RecoverPasswordPage} />
            <Route path="/timeline" exact component={TimelinePage} />
            <Route path="/statistics" exact component={StatisticsPage} />
            <Route path="/books" exact component={BooksPage} />
            <Route path="/questions" exact component={QuestionsPage} />
            <Route path="/filters" exact component={FiltersPage} />
            <Route path="/flowQuestions" exact component={FlowQuestionsPage} />
            <Route path="/signatures/typePlan=:type/idUser=:idUser/token=:token" component={SignaturesPage} />
            <Route path="/payment/typePlan=:type/idUser=:idUser/token=:token" component={PaymentPage} />
            <Route path="/politica-de-privacidade" exact component={PrivacyPolicyPage} />
            <Route path="/zero1app/excluir-conta" exact component={DeleteAccountPage} />
            {/* <Route path={pathNotFound} component={RouteNotFound} />
            <Redirect to={pathNotFound} /> */}
        </Switch>
    );
};

export default Routes;
