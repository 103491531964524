/* eslint-disable no-console */
// libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import {
    // useDispatch,
    useSelector,
} from 'react-redux';

// js
import colors from '../../styles/colors';
import {
    Container,
    Home,
    // ContainerCalendar,
    ContainerBooks,
    // TitleCalendar,
    ContainerOptionsDrawer,
    ContainerBody,
    ContainerSelectedTypeBook,
    ButtonTypeBook,
    ContainerBoxBooks,
    InfoCard,
    ContainerSearch,
    ImgSearch,
    ContainerAdjustInfo,
    InputSearch,
    ImgFilter,
    ContainerAllBooks,
    ButtonAddBook,
    AddBook,
} from './booksStyles';
import { searchGray, filterGray, addBook } from '../../assets';
import { RootState } from '../../store';
import books from '../../services/books';
import auth from '../../services/auth';
import constants from '../../utils/consts';

// components
import DrawerComponent from '../../components/Drawer/DrawerComponent';
import HeaderComponent from '../../components/Header/HeaderComponent';
// import OneDateSimulateComponent from '../Timeline/components/OneDateSimulateComponent';
import BookComponent from './components/BookComponent';
import PopUpComponent from '../../components/PopUp/PopUpComponent';
import filtersSaved from '../../services/filtersSaved';
import PopUpQuestions from '../../components/PopUpQuestions/PopUpQuestions';

// main
const BooksPage: React.FC = () => {
    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // dispatch
    // const dispatch = useDispatch();

    // states
    // const [search, setSearch] = useState('');
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(true);
    const [type, setType] = useState('book');
    const [searchBook, setSearchBook] = useState('');
    const [searchBookSuggested, setSearchBookSuggested] = useState('');
    const [allBooks, setAllBooks] = useState([]);
    const [allBooksSuggested, setAllBooksSuggested] = useState([]);
    const [selectExamination, setSelectExamination] = useState(0);
    const [existBooks, setExistBooks] = useState<boolean | null>(null);
    const [existBooksSuggested, setExistBooksSuggested] = useState<boolean | null>(null);
    const [loading, setLoading] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [flowQuestions, setFlowQuestions] = useState(false);
    const [idPopUp, setIdPopUp] = useState(-1);
    const [idOrder, setIdOrder] = useState('id');
    const [flowOrderBy, setFlowOrderBy] = useState('asc');
    const [bookSelected, setBookSelected] = useState<any>(null);
    const [newNameBook, setNewNameBook] = useState('');
    const [allExamination, setAllExamination] = useState([]);
    const [filtersSavedUser, setFiltersSavedUser] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [selectDropdawn, setSelectDropdawn] = useState<Array<number>>([]);

    // methods
    const onDrawer = () => {
        setIsOpen(true);
    };

    const onChangeSearch = async (txt: React.ChangeEvent<HTMLInputElement>) => {
        if (type === 'book') {
            setSearchBook(txt.target.value);
        }
        if (type === 'bookSuggested') {
            setSearchBookSuggested(txt.target.value);
        }
    };

    function ValidationMarginTimeline() {
        if (show) {
            return '310px';
        }
        if (!show) {
            return '160px';
        }
    }

    function ValidationMarginTimelineLowLarge() {
        if (show) {
            return '215px';
        }
        if (!show) {
            return '105px';
        }
    }

    const TradeTypeBook = () => {
        if (type === 'book') {
            setType('bookSuggested');
        } else {
            setType('book');
        }
        setIdPopUp(-1);
        setIdOrder('id');
        setFlowOrderBy('asc');
    };

    const ActionInitialBook = async () => {
        const GetAllResultBooks = await books.GetAllBooks(
            searchBook,
            idOrder,
            flowOrderBy,
            false,
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        setAllBooks(GetAllResultBooks?.result);
        setExistBooks(GetAllResultBooks?.result?.length > 0);
        setLoading(false);
        const dataExamination = await auth.GetExamination();
        setAllExamination(dataExamination?.examination);
        const GetFilters = await filtersSaved.GetAllFiltersSaved(infoUser?.id, infoUser?.token);
        setFiltersSavedUser(GetFilters?.filterSaved);
    };

    const ActionInitialBookSuggested = async () => {
        const GetAllResultBooksSuggested = await books.GetAllBooks(
            searchBookSuggested,
            idOrder,
            flowOrderBy,
            true,
            infoUser?.id || 0,
            infoUser?.token || '',
        );
        setAllBooksSuggested(GetAllResultBooksSuggested?.result);
        setExistBooksSuggested(GetAllResultBooksSuggested?.result?.length > 0);
        setLoading(false);
    };

    const OpenOrderList = () => {
        setIdPopUp(1);
    };

    const OpenOptionsBook = (selectedBook: any) => {
        setBookSelected(selectedBook);
        setNewNameBook(selectedBook.title);
        setIdPopUp(2);
    };

    const OpenCreateBook = () => {
        setIdPopUp(3);
    };

    const RefreshInitial = () => {
        ActionInitialBook();
        ActionInitialBookSuggested();
    };

    // useEffect
    useEffect(() => {
        if (idPopUp !== -1) {
            setPopUp(true);
        }
    }, [idPopUp]);

    useEffect(() => {
        if (popUp === false) {
            setIdPopUp(-1);
        }
    }, [popUp]);

    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    useEffect(() => {
        setLoading(true);
        const timeOutId = setTimeout(() => {
            ActionInitialBook();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchBook]);

    useEffect(() => {
        setLoading(true);
        const timeOutId = setTimeout(() => {
            ActionInitialBookSuggested();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchBookSuggested]);

    useEffect(() => {
        if (allQuestions?.length) {
            setFlowQuestions(true);
        }
    }, [allQuestions]);

    // renders
    // const RenderAllDates = () => (
    //     constants.allDates.map((v) => (
    //         <OneDateSimulateComponent item={v} />
    //     ))
    // );

    const ActionApplyOrder = async () => {
        setPopUp(false);
        if (type === 'book') {
            const GetAllResultBooks = await books.GetAllBooks(
                searchBook,
                idOrder,
                flowOrderBy,
                false,
                infoUser?.id || 0,
                infoUser?.token || '',
            );
            setAllBooks(GetAllResultBooks?.result);
            setExistBooks(GetAllResultBooks?.result?.length > 0);
        } else {
            const GetAllResultBooksSuggested = await books.GetAllBooks(
                searchBookSuggested,
                idOrder,
                flowOrderBy,
                true,
                infoUser?.id || 0,
                infoUser?.token || '',
            );
            setAllBooksSuggested(GetAllResultBooksSuggested?.result);
            setExistBooksSuggested(GetAllResultBooksSuggested?.result?.length > 0);
        }
    };

    const CleanFilters = async () => {
        setIdOrder('id');
        setFlowOrderBy('asc');
        setPopUp(false);
        if (type === 'book') {
            const GetAllResultBooks = await books.GetAllBooks(
                searchBook,
                'id',
                'asc',
                false,
                infoUser?.id || 0,
                infoUser?.token || '',
            );
            setAllBooks(GetAllResultBooks?.result);
            setExistBooks(GetAllResultBooks?.result?.length > 0);
        } else {
            const GetAllResultBooksSuggested = await books.GetAllBooks(
                searchBookSuggested,
                'id',
                'asc',
                true,
                infoUser?.id || 0,
                infoUser?.token || '',
            );
            setAllBooksSuggested(GetAllResultBooksSuggested?.result);
            setExistBooksSuggested(GetAllResultBooksSuggested?.result?.length > 0);
        }
    };

    function AllBooks() {
        if (type === 'book') {
            if (existBooks === false) {
                return (
                    <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>
                        SEM CADERNOS SALVOS PARA SEREM EXIBIDOS
                    </InfoCard>
                );
            }
            return (
                allBooks.map((v) => (
                    <BookComponent
                        item={v}
                        selectExamination={selectExamination}
                        setSelectExamination={setSelectExamination}
                        OpenOptionsBook={OpenOptionsBook}
                        // infoUser={infoUser}
                        // dispatch={dispatch}
                    />
                ))
            );
        }
        if (type === 'bookSuggested') {
            if (existBooksSuggested === false) {
                return (
                    <ContainerAdjustInfo alignItems="center">
                        <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>
                            SEM CADERNOS SUGERIDOS PARA SEREM EXIBIDOS
                        </InfoCard>
                    </ContainerAdjustInfo>
                );
            }
            return (
                allBooksSuggested.map((v) => (
                    <BookComponent
                        item={v}
                        selectExamination={selectExamination}
                        setSelectExamination={setSelectExamination}
                        OpenOptionsBook={OpenOptionsBook}
                        // infoUser={infoUser}
                        // dispatch={dispatch}
                    />
                ))
            );
        }
    }

    const RenderTitle = () => {
        if (idPopUp === 1) {
            return 'Ordenação';
        }
        if (idPopUp === 2) {
            return 'Opções';
        }
        if (idPopUp === 3) {
            return 'Criar caderno';
        }
        return 'Editar caderno';
    };

    const CloseModal = () => {
        setIsOpen(false);
    };

    // main
    return (
        <Container>
            <Home>
                <HeaderComponent
                    // search={search}
                    // setSearch={setSearch}
                    // labelSearch="Concursos"
                    widthActual={widthActual}
                    onDrawer={onDrawer}
                />
                <ContainerBody>
                    {widthActual >= 800 ? (
                        <DrawerComponent
                            show={show}
                            setShow={setShow}
                        />
                    ) : null}
                    <ContainerBooks
                        existStatistics
                        marginLeft={ValidationMarginTimeline()}
                        marginLeftLowLarge={ValidationMarginTimelineLowLarge()}
                    >
                        <ContainerBoxBooks>
                            <ContainerSelectedTypeBook>
                                <ButtonTypeBook isFocus={type === 'book'} onClick={TradeTypeBook}>Meus Cadernos</ButtonTypeBook>
                                <ButtonTypeBook isFocus={type === 'bookSuggested'} onClick={TradeTypeBook}>Cadernos Sugeridos</ButtonTypeBook>
                            </ContainerSelectedTypeBook>
                            <ContainerAdjustInfo>
                                <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>—</InfoCard>
                                <InfoCard color={colors.basic(window.localStorage.getItem('theme') || 'light').gray}>
                                    {type === 'book' ? 'CONFIRA SEUS CADERNOS SALVOS' : 'CONFIRA SEUS CADERNOS SUGERIDOS'}
                                </InfoCard>
                            </ContainerAdjustInfo>
                            <ContainerAdjustInfo flexDirection="row" alignItems="center" marginTop="40px">
                                <ContainerSearch>
                                    <ImgSearch src={searchGray} />
                                    <InputSearch value={type === 'book' ? searchBook : searchBookSuggested} placeholder="Buscar" onChange={onChangeSearch} />
                                </ContainerSearch>
                                <ImgFilter src={filterGray} onClick={OpenOrderList} />
                            </ContainerAdjustInfo>
                            <ContainerAllBooks justifyContent={existBooksSuggested === null || existBooks === null ? 'center' : 'space-between'}>
                                <Loader
                                    visible={loading}
                                    type="TailSpin"
                                    color={colors.theme(window.localStorage.getItem('theme')
                                    || 'light').purpleDark}
                                    height={80}
                                    width={80}
                                />
                                {existBooksSuggested === null || existBooks === null
                                    ? null : AllBooks()}
                            </ContainerAllBooks>
                        </ContainerBoxBooks>
                    </ContainerBooks>
                    {/* <ContainerCalendar>
                        <TitleCalendar>Lista de Simulados</TitleCalendar>
                        {RenderAllDates()}
                    </ContainerCalendar> */}
                    {type === 'book' ? (
                        <ButtonAddBook onClick={OpenCreateBook}>
                            <AddBook src={addBook} />
                        </ButtonAddBook>
                    ) : null}
                </ContainerBody>
                <Modal
                    isOpen={isOpen}
                    style={constants.modalDrawerStyles}
                    onRequestClose={CloseModal}
                >
                    <ContainerOptionsDrawer>
                        <DrawerComponent
                            isModal
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            show
                        />
                    </ContainerOptionsDrawer>
                </Modal>
                <PopUpComponent
                    popUp={popUp}
                    setPopUp={setPopUp}
                    title={RenderTitle()}
                    idOrder={idOrder}
                    selectDropdawn={selectDropdawn}
                    setSelectDropdawn={setSelectDropdawn}
                    setIdOrder={setIdOrder}
                    flowOrderBy={flowOrderBy}
                    setFlowOrderBy={setFlowOrderBy}
                    setAllBooks={setAllBooks}
                    typeBook={type}
                    nameBook={newNameBook}
                    setNameBook={setNewNameBook}
                    bookSelected={bookSelected}
                    RefreshInitial={RefreshInitial}
                    setIdPopUp={setIdPopUp}
                    allExamination={allExamination}
                    filtersSavedUser={filtersSavedUser}
                    setAllQuestions={setAllQuestions}
                    ActionApplyOrder={ActionApplyOrder}
                    CleanFilters={CleanFilters}
                />
                <PopUpQuestions
                    popUp={flowQuestions}
                    setPopUp={setFlowQuestions}
                    allQuestions={allQuestions}
                    setAllQuestions={setAllQuestions}
                    idType="Cadernos"
                    idSelected={bookSelected?.id}
                    suggested={bookSelected?.suggested}
                    allQuestionsCompleted={bookSelected?.completed_questions}
                />
            </Home>
        </Container>
    );
};

export default BooksPage;
