function mountScreen(): any {
    const scriptUrlMathML = document.createElement('script');
    scriptUrlMathML.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js?config=MML_HTMLorMML';
    scriptUrlMathML.async = true;
    scriptUrlMathML.type = 'text/javascript';
    document.body.appendChild(scriptUrlMathML);

    const scriptmml3 = document.createElement('script');
    scriptmml3.type = 'text/javascript';
    scriptmml3.async = true;
    scriptmml3.innerHTML = 'window.MathJax = { MathML: { extensions: ["mml3.js", "content-mathml.js"]}};';
    document.body.appendChild(scriptmml3);

    const scriptmathjaxconfig = document.createElement('script');
    scriptmathjaxconfig.type = 'text/x-mathjax-config';
    scriptmathjaxconfig.innerHTML = `
        MathJax.Hub.Config({
            tex2jax: { inlineMath: [['$','$']] },
            "HTML-CSS": { linebreaks: { automatic: true } },
            CommonHTML: { linebreaks: { automatic: true } },
            SVG: { linebreaks: { automatic: true } }
        });
    `;
    document.body.appendChild(scriptmathjaxconfig);

    return () => {
        document.body.removeChild(scriptUrlMathML);
        document.body.removeChild(scriptmml3);
        document.body.removeChild(scriptmathjaxconfig);
    };
}

export default {
    mountScreen,
};
