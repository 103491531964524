// js
import user from './user';
import config from './config';

export const actions = {
    user: user.actions,
    config: config.actions,
};

export const reducers = {
    user: user.reducer,
    config: config.reducer,
};
