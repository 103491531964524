// libraries
import { flipInX } from 'react-animations';
import styled, { keyframes } from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ButtonActionInterfaces {
    backgroundColor?: string,
    enabled?: boolean,
    fontWeight?: string,
    details?: string,
}

interface ContainerBodyInterfaces {
    LargeSize?: boolean,
    heightFix?: string,
}

interface ImgTargetInterfaces {
    marginLeft?: string,
    marginRight?: string,
}

interface ContainerPopUpInterfaces {
    heightFix?: string,
    loading?: boolean,
}

interface IconEventInterfaces {
    dimensions?: string
}

interface ContainerBodyInfoInterfaces {
    width?: string,
    justifyContent?: string,
    backgroundColor?: string,
    enabled?: boolean,
}

interface InfoCardInterfaces {
    marginTop?: string,
    padding?: string,
    marginLeft?: string,
}
interface ButtonTypeBookInterfaces {
    isFocus: boolean,
}

interface TotalQuestionsInterfaces {
    margin?: string,
    width?: string,
}

const animationItemAccordion = keyframes`${flipInX}`;

// styles
export const ContainerPopUp = styled.div<ContainerPopUpInterfaces>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    border-radius: 35px;
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
    justify-content: space-between;
    transition-duration: 400ms;
    min-width: 55vw;
    min-height: ${(props) => (props.heightFix || '75vh')};
    max-height: ${(props) => (props.heightFix || '75vh')};
    overflow: hidden;
    margin: 0 auto;
`;

export const ContainerAdjustHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
`;

export const DivNull = styled.div``;

export const ContainerAllCardSimulate = styled.div<ContainerPopUpInterfaces>`
    align-items: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    min-height: ${(props) => (props.heightFix || '80vh')};
    max-height: ${(props) => (props.heightFix || '80vh')};
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 20vw;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const ContainerHeader = styled.div<ContainerBodyInterfaces>`
    align-items: center;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    display: flex;
    min-height: ${(props) => (props.heightFix || (props.LargeSize ? '15vh' : '15vh'))};
    padding: 10px 20px 10px 20px;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-size: 30px;
    font-weight: bold;
    @media (max-width: 1200px) {
        font-size: 26px;
    }
    @media (max-width: 800px) {
        font-size: 24px;
    }
    @media (max-width: 600px) {
        font-size: 18px;
    }
    @media (max-height: 600px) {
        font-size: 18px;
    }
    @media (max-width: 400px) {
        font-size: 14px;
    }
`;

export const Close = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-size: 16px;
    cursor: pointer;
    padding-top: 20px;
    height: 100%;
    display: flex;
    :hover{
        opacity: .7;
    }
`;

export const ImgClose = styled.img`
    height: 12px;
    margin-left: 7px;
    margin-top: 4px;
    width: 12px;
`;

export const ContainerAdjustFilter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
`;

export const ContainerSubjects = styled.div``;

export const ContainerBody = styled.div<ContainerBodyInterfaces>`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 10px 10px 10px;
    margin-bottom: ${(props) => (props.LargeSize ? '0px' : '20px')};
    max-height: ${(props) => (props.heightFix || (props.LargeSize ? '60vh' : '60vh'))};
    min-height: ${(props) => (props.heightFix || (props.LargeSize ? '60vh' : '60vh'))};
    width: 100%;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    }
`;

export const ContainerLoader = styled.div`
    height: 48vh;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    flex-wrap: wrap;
    @media (max-width: 600px) {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const ContainerAdjust = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 -1px 10px -1px rgba(0, 0, 0, 0.4);

`;

export const CheckBoxSubject = styled.div`
    align-items: center;
    border-radius: 10px;
    border: 2px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    display: flex;
    justify-content: center;
    height: 20px;
    width: 20px;
`;

export const CheckBox = styled.div`
    border-radius: 9px;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    height: 18px;
    width: 18px;
`;

export const ButtonAction = styled.div<ButtonActionInterfaces>`
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    font-weight: bold;
    border: ${(props) => (props.backgroundColor ? `1px solid ${props.backgroundColor}` : `1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight}`)};
    border-radius: 20px;
    color: ${(props) => (props.backgroundColor ? colors.basic(window.localStorage.getItem('theme') || 'light').white : colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight)};
    cursor: pointer;
    width: 45%;
    @media (max-height: 600px) {
        min-height: 45px;
    }
    @media (max-height: 500px) {
        min-height: 35px;
    }
`;

export const ButtonActionBook = styled.div<ButtonActionInterfaces>`
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.enabled ? colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark : (props.backgroundColor || 'transparent'))};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    border: ${(props) => (props.backgroundColor ? `1px solid ${props.backgroundColor}` : `1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight}`)};
    border-radius: 35px;
    color: ${(props) => (props.backgroundColor ? colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault : colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse)};
    cursor: ${(props) => (props.enabled || !props.backgroundColor ? 'pointer' : 'default')};
    font-size: 16px;
    width: 45%;

    :hover {
        background-color: ${(props) => (props.enabled || props.details === 'voltar' ? colors.basic(window.localStorage.getItem('theme') || 'light').grayMedium : '')};
        color: ${(props) => (props.details === 'voltar' ? 'black' : '')};
        cursor: ${(props) => (!props.enabled && props.details === 'salvar' ? 'not-allowed' : '')};
        background-color: ${(props) => (props.enabled ? 'rgba(117, 80, 241, 0.5)' : '')}
    }

    @media (max-height: 600px) {
        min-height: 35px;
        font-size: 15px;
    }
    @media (max-height: 500px) {
        min-height: 30px;
        font-size: 14px;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 90%;
`;

export const ContainerButtonsBooks = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 10px;
    width: 100%;
`;

export const ContainerTopHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    min-width: 300px;
`;

export const ContainerSearch = styled.div`
    align-items: center;
    height: 40px;
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    border-radius: 20px;
    display: flex;
    padding: 0px 15px 0px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
`;

export const ImgSearch = styled.img`
    height: 24px;
    margin-right: 10px;
    width: 24px;
`;

export const InputSearch = styled.input`
    background-color: transparent;
    border: none;
    height: 35px;
    font-size: 16px;
    font-weight: bold;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    width: 90%;
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
        font-weight: normal;
    }
`;

export const ContainerOptionBook = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    width: 150px;
    border-radius: 12px;
    padding: 10px;
    :hover {
        opacity: .7;
    }

`;

export const NameOption = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
`;

export const ImgOption = styled.img`
    height: 24px;
    margin-left: 10px;
    width: 24px;
`;

interface ImgDropDawnInterfaces {
    flowOrderBy?: string,
}

export const ImgDropDawn = styled.img<ImgDropDawnInterfaces>`
    height: 10.5px;
    margin-left: 10px;
    transform: ${(props) => (props.flowOrderBy === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)')};
    width: 21px;
`;

export const InputRenameBook = styled.input`
    border: 1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    border-radius: 10px;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    padding-left: 5px;
    margin-left: 10px;
    height: 35px;
    width: 200px;
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').graySmall};
    }
`;

export const ContainerBodyCreateBook = styled.div`
    margin-top: 20px;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerBodyTestAndSimulate = styled.div`
    margin-top: 20px;
    padding: 0px 10px 0px 10px;
    display: flex;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    flex-direction: column;
    margin-bottom: 50px;
    width: 100%;
`;

export const ContainerSelect = styled.div`
    align-items: center;
    border: 1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    display: flex;
    justify-content: space-between;
    border-radius: 30px;
    margin: 10px 0px 20px 15px;
    padding: 0px 15px 0px 15px;
    min-height: 45px;
    width: 95%;
    @media (max-width: 900px) {
        margin: 5px 0px 10px 0px;
        min-height: 40px;
    }
    @media (max-width: 600px) {
        min-height: 35px;
    }
`;

export const Select = styled.select`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    border: none;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    min-height: 100%;
    width: 100%;
`;

export const Option = styled.option``;

export const InputNameBook = styled.input`
    border: 1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight};
    border-radius: 30px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    padding: 0px 15px 0px 15px;
    min-height: 45px;
    margin: 10px 0px 20px 15px;
    width: 95%;
    @media (max-width: 900px) {
        margin: 5px 0px 10px 0px;
        min-height: 40px;
    }
    @media (max-width: 600px) {
        min-height: 35px;
    }
    ::placeholder {
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').graySmall};
    }
`;

export const ButtonSaveFilter = styled.div<ContainerBodyInfoInterfaces>`
    cursor: ${(props) => (!props.enabled ? 'pointer' : 'not-allowed')};
    background-color: ${(props) => (props.enabled ? colors.basic(window.localStorage.getItem('theme') || 'light').graySmall : colors.theme(window.localStorage.getItem('theme') || 'light').backgroundButtonSaved)};
    min-width: ${(props) => (props.width || '150px')};
    min-height: 60px;
    border-radius: 35px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px 5px 20px;
    font-weight: bold;
    margin-right: 5px;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-size: 20px;
    text-align: center;
    @media (max-width: 1400px) {
        font-size: 16px;
        min-height: 45px;
        min-width: 125px;
    }
    @media (max-width: 900px) {
        font-size: 14px;
        min-height: 45px;
        min-width: 115px;
    }
    :hover {
        opacity: .8;
    }
`;

export const ContainerTest = styled.div`
    background-color: white;
    border-radius: 10px;
    border: 1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundQtdCategory};
    margin-bottom: 20px;
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const TitleTest = styled.text`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').blueSea};
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
    width: 100%;
`;

export const ContainerAdjustTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px 0px 0px;
    width: 100%;
`;

export const ContainerTags = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundQtdCategory};
    border-radius: 8px;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 3px 10px 3px 10px;
`;

export const TxtTags = styled.text`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundQtdCategory};
    font-weight: bold;
    font-size: 13px;
`;

export const ContainerBodyInfo = styled.div<ContainerBodyInfoInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    display: flex;
    margin: 5px 0px 0px 0px;
    width: ${(props) => (props.width || '100%')};
`;

export const IconEvent = styled.img<IconEventInterfaces>`
    object-fit: contain;
    height: ${(props) => (props.dimensions || '22px')};
    width: ${(props) => (props.dimensions || '22px')};
`;

export const TotalQuestions = styled.text<TotalQuestionsInterfaces>`
    color: ${(props) => (props.color || colors.theme(window.localStorage.getItem('theme') || 'light').backgroundQtdCategory)};
    width: ${(props) => (props.width || '100%')};
    font-weight: bold;
    margin: ${(props) => (props.margin || '0px 0px 0px 5px')};
    font-size: 13px;
`;

export const ContainerInfo = styled.div`
    align-self: center;
    display: flex;
    justify-content: space-between;
    margin: 5px 0px 5px 0px;
    width: 100%;
`;

export const ButtonFinalTestAndSimulate = styled.button<ButtonActionInterfaces>`
    background-color: ${(props) => (props.backgroundColor || colors.theme(window.localStorage.getItem('theme') || 'light').blue)};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 40px;
    border-radius: 30px;
    margin-top: 20px;
    width: 60%;
    padding: 25px;
    transition: 200ms;

    @media (max-width: 740px) {
        width: 100%;
    }
    :hover {
        background-color: rgba(117, 80, 241, 0.5);
    }
`;

export const TxtButton = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-weight: bold;
    font-size: 16px;
`;

export const ImgFilter = styled.img`
    height: 36px;
    cursor: pointer;
    width: 36px;
    @media (max-width: 1700px) {
        height: 32px;
        width: 32px;
    }
    @media (max-width: 1400px) {
        height: 27px;
        width: 27px;
    }
`;

export const ContainerSeparatorSearch = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ContainerBodyFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const AdjustEmpty = styled.div`
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ButtonOptionSimulate = styled.div`
    display: flex;
    min-height: 70px;
    align-items: center;
    border-radius: 20px;
    padding: 0px 10px 0px 10px;
    margin-top: 20px;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    width: 90%;
    cursor: pointer;
`;

export const TextButtonOptionSimulate = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
`;

export const InfoCard = styled.text<InfoCardInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    display: flex;
    margin-top: ${(props) => (props.marginTop || '5px')};
    flex-direction: column;
    font-weight: ${(props) => (props.fontWeight || 'bold')};
    font-size: 20px;
    width: ${(props) => (props.width || 'auto')};
    @media (max-width: 1700px) {
        font-size: 18px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`;

export const AdjustButtonsFilterOrder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    width: 100%;
`;

export const ButtonFilterOrder = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    width: 80%;
    min-height: 45px;
    :hover {
        opacity: .8;
    }
`;

export const TextFilterOrder = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-weight: bold;
`;

export const TextFilterConfirm = styled.text`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').grayDefault};
`;

export const CardSimulate = styled.div`
    min-height: 250px;
    max-height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    min-width: 18vw;
    max-width: 18vw;
`;

export const TitleCardSimulate = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-size: 12px;
    margin-bottom: 5px;
`;

export const ResultCardSimulate = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    font-size: 10px;
    min-height: 20px;
    margin-bottom: 5px;
    width: 90%;
    font-weight: bold;
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
`;

export const ContainerDeleteCardSimulate = styled.div`
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').red};
`;

export const InputQtdQuestion = styled.input`
    color: ${colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark};
    background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault};
    font-weight: bold;
    border: none;
    width: 90%;
    padding-left: 5px;
    border-radius: 15px;
    font-size: 10px;
    min-height: 20px;
    margin-bottom: 5px;
`;

export const ContainerSelectedTypeBook = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    justify-content: space-between;
    width: 100%;
`;

export const ButtonTypeBook = styled.div<ButtonTypeBookInterfaces>`
    background-color: ${(props) => (props.isFocus ? colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics : 'transparent')};
    border: ${(props) => (props.isFocus ? 'none' : `1px solid ${colors.basic(window.localStorage.getItem('theme') || 'light').graySmall}`)};
    min-height: 70px;
    color: ${(props) => (props.isFocus ? colors.basic(window.localStorage.getItem('theme') || 'light').whiteDefault : colors.basic(window.localStorage.getItem('theme') || 'light').grayLight)};
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    border-radius: 35px;
    font-size: 20px;
    transition-duration: 400ms;
    font-weight: ${(props) => (props.isFocus ? 'bold' : 'normal')};
    width: 49%;
    @media (max-width: 1700px) {
        font-size: 18px;
        min-height: 60px;
    }
    @media (max-width: 1400px) {
        font-size: 14px;
        min-height: 40px;
    }
`;

interface IconRefreshInterfaces {
    animation: boolean,
}

export const IconRefresh = styled.img<IconRefreshInterfaces>`
    height: 30px;
    width: 30px;
    animation: ${(props) => (props.animation ? 'spin 0.5s linear infinite' : 'none')};
    @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
    }
`;

export const AdjustTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ContainerDownload = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover{
        opacity: .7;
    }
`;

export const AnimationDescription = styled.div`
    animation: 0.3s ${animationItemAccordion};
`;

interface WelcomeInterfaces {
    margin?: string,
}

export const Welcome = styled.text<WelcomeInterfaces>`
    color: ${(props) => (props.color || colors.basic(window.localStorage.getItem('theme') || 'light').gray)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '16px')};
    margin: ${(props) => (props.margin || '0px')};
    @media (max-width: 1000px) {
        font-size: 13px;
    }
`;

interface LineInterfaces {
    margin?: string,
    justifyContent?: string,
}

export const Line = styled.div<LineInterfaces>`
    background-color: ${colors.theme(window.localStorage.getItem('theme') || 'light').backgroundLineTransparent};
    margin: ${(props) => (props.margin || '20px 0px 20px 0px')};
    min-height: 1px;
    width: 100%;
`;

export const ArrowForDropDawn = styled.img`
    height: 10.5px;
    width: 21px;
`;

export const ContainerAdjustAbout = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ImageDropDawn = styled.img`
    height: 30px;
    cursor: pointer;
    width: 30px;
`;

export const DescriptionExamination = styled.div`
    color: ${colors.basic(window.localStorage.getItem('theme') || 'light').gray};
    display: flex;
    align-items: center;
    font-size: 18px;
    width: 100%;
    margin-top: 10px;
    @media (max-width: 1700px) {
        font-size: 16px;
    }
    @media (max-width: 1400px) {
        font-size: 12px;
    }
`;

export const ImgTarget = styled.img<ImgTargetInterfaces>`
    height: 12px;
    object-fit: contain;
    margin-left: ${(props) => (props.marginLeft || '0px')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    width: 12px;
`;
