/* eslint-disable max-len */
// libraries
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// js
import {
    ContainerExamination,
    NameExamination,
    AdjustInfo,
    ContainerQtd,
    ContainerOneExamination,
    IconDropDawnExamination,
    ContainerAdjustInfo,
    DescriptionExamination,
    ContainerRadius,
    ContainerBook,
    ImgTarget,
    ImgOption,
    ContainerImgOption,
} from '../booksStyles';
import {
    arrowDropDawnBlueTop,
    arrowDropDawnLight,
    optionBook,
    tickCorrect,
    tickError,
} from '../../../assets';
import colors from '../../../styles/colors';
import ProgressProvider from './ProgressProviderComponent';
import BooksConstants from '../BooksConstants';
// import questionsApi from '../../../services/questionsApi';
// import { updateUser } from '../../../store/modules/user/actions';

// interfaces
interface Props {
    item: any,
    selectExamination: number,
    setSelectExamination: (v: number) => void,
    OpenOptionsBook: any,
    // infoUser: any
    // dispatch: any,
}

const BookComponent: React.FC<Props> = ({
    item,
    selectExamination,
    setSelectExamination,
    OpenOptionsBook,
    // infoUser,
    // dispatch,
}) => {
    // constants
    const {
        id,
        title,
        result,
    } = item;

    // methods
    const OpenDropDawnSubject = () => {
        if (result?.length > 0) {
            if (selectExamination === id) {
                setSelectExamination(0);
            } else {
                setSelectExamination(id);
            }
        }
    };

    // renders
    const RenderBooks = () => {
        if (selectExamination === id) {
            return result.map((value: any) => {
                const [year, month, day] = value.created_at.substring(0, 10).split('-');
                const accessAtFormat = `${day}/${month}/${year}`;
                const ActionOpenOptionsBooks = () => {
                    OpenOptionsBook(value);
                };
                // const OpenBook = async () => {
                //     const GetQuestions = await questionsApi.GetAllQuestions(
                //         [],
                //         [],
                //         [],
                //         infoUser?.id,
                //         infoUser?.token,
                //         value?.id,
                //     );
                //     if (GetQuestions?.questions?.length > 0) {
                //         dispatch(updateUser({
                //             ...infoUser,
                //             allInfoQuestion: {
                //                 allQuestionsCompleted: [],
                //                 allQuestions: GetQuestions?.questions,
                //                 backScreen: window.location.pathname,
                //                 focusFlowQuestions: false,
                //                 idType: value?.id ? 'Cadernos' : 'Filtros',
                //                 idSelected: value?.id,
                //                 suggested: value?.suggested,
                //                 timeOutSimulateOrTest: undefined,
                //             },
                //         }));
                //     } else {
                //         // eslint-disable-next-line no-alert
                //         alert('Não existem questões para os filtros desse caderno.');
                //     }
                // };
                return (
                    <ContainerBook
                        key={value.id}
                        onClick={ActionOpenOptionsBooks}
                    >
                        <ContainerAdjustInfo width="70%">
                            <NameExamination color={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics}>
                                {value.title}
                            </NameExamination>
                            <DescriptionExamination>
                                {`${value.completed_questions?.length}/${value.total_questions} questões - último acesso ${accessAtFormat}`}
                            </DescriptionExamination>
                            <DescriptionExamination>
                                <ImgTarget src={tickCorrect} marginRight="3px" />
                                {value.hit_questions}
                                <ImgTarget src={tickError} marginLeft="10px" marginRight="3px" />
                                {value.miss_questions}
                            </DescriptionExamination>
                        </ContainerAdjustInfo>
                        <ContainerAdjustInfo width="28%" flexDirection="row" alignItems="center" justifyContent="flex-end">
                            <ContainerRadius>
                                <ProgressProvider valueStart={0} valueEnd={value.progress}>
                                    {(progress: number) => (
                                        <CircularProgressbar
                                            value={progress}
                                            text={`${progress}%`}
                                            styles={BooksConstants.stylesCircularProgress}
                                        />
                                    )}
                                </ProgressProvider>
                            </ContainerRadius>
                            <ContainerImgOption onClick={ActionOpenOptionsBooks}>
                                <ImgOption src={optionBook} />
                            </ContainerImgOption>
                        </ContainerAdjustInfo>
                    </ContainerBook>
                );
            });
        }

        return null;
    };

    // main
    return (
        <ContainerOneExamination
            key={id}
        >
            <ContainerExamination
                padding="10px"
                onClick={OpenDropDawnSubject}
                backgroundColor={selectExamination === id ? colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatisticsLow : 'transparent'}
            >
                <NameExamination color={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics} fontWeight="bold">
                    {title}
                </NameExamination>
                <AdjustInfo>
                    <ContainerQtd>
                        <NameExamination color={colors.basic(window.localStorage.getItem('theme') || 'light').white} fontWeight="bold">{result?.length}</NameExamination>
                    </ContainerQtd>
                    <IconDropDawnExamination src={selectExamination === id ? arrowDropDawnBlueTop : arrowDropDawnLight} />
                </AdjustInfo>
            </ContainerExamination>
            {RenderBooks()}
        </ContainerOneExamination>
    );
};

export default BookComponent;
