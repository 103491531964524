/* eslint-disable max-len */
// libraries
import React, { useState } from 'react';

// js
import auth from '../../services/auth';
import colors from '../../styles/colors';
import {
    Container,
    Home,
    LogoHeader,
    ContainerBox,
    Title,
    TitleInput,
    ContainerBody,
    ImgBackgroundLinearGradient,
    Subtitle,
} from './recoverPasswordStyles';
import {
    backgroundHeaderAuthFullSize,
    backgroundLinearGradientFullSize,
    logoFullSizeColor,
    logoFullSizeColorDark,
} from '../../assets';

// components
import ButtonComponent from '../../components/Button/ButtonComponent';
import InputComponent from '../../components/Input/InputComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';

const RecoverPasswordPage: React.FC = () => {
    // states
    const [email, setEmail] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const GoToLogin = () => {
        setPathRoute('/');
        setRedirect(true);
    };

    const RecoverPassword = async () => {
        const GetNewPassword = await auth.RecoverPassword(email);
        // eslint-disable-next-line no-alert
        alert(GetNewPassword?.message);
        setEmail('');
    };

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerBody background={backgroundHeaderAuthFullSize}>
                    <ImgBackgroundLinearGradient background={backgroundLinearGradientFullSize} />
                </ContainerBody>
                <ContainerBox>
                    <LogoHeader src={window.localStorage.getItem('theme') === 'light' ? logoFullSizeColor : logoFullSizeColorDark} />
                    <Title>Olá</Title>
                    <Subtitle>Digite seu email abaixo para receber seu link de recuperação.</Subtitle>
                    <InputComponent placeholder="Seu e-mail" type="email" value={email} setValue={setEmail} />
                    <ButtonComponent label="ME ENVIE O LINK DE RECUPERAÇÃO DE SENHA" onClick={RecoverPassword} />
                    <TitleInput width="auto" activeCursor="pointer" activeColor={colors.theme(window.localStorage.getItem('theme') || 'light').blueLight} onClick={GoToLogin} marginTop="10px">Ou entre agora</TitleInput>
                </ContainerBox>
            </Home>
        </Container>
    );
};

export default RecoverPasswordPage;
