// libraries
import axios from 'axios';

// js
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

async function CreateNewReports(
    description: string,
    idQuestion: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            idSender: idUser,
            description,
            idQuestion,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/reports`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 201) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    CreateNewReports,
};
