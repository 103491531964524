/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Container,
    Home,
    Title,
    Description,
    SubDescription,
    SubTitle,
} from './privacyPolicyStyles';

// main
const PrivacyPolicyPage: React.FC = () => (
    <Container>
        <Home>
            <Title style={{ marginTop: 0 }}>POLÍTICA DE PRIVACIDADE</Title>
            <Description>Última atualização: 3 de janeiro de 2024</Description>
            <Description>
                Esta Política de Privacidade descreve Nossas políticas e procedimentos sobre a coleta, uso e divulgação de Suas informações quando Você usa o Serviço e informa sobre Seus direitos de privacidade e como a lei protege Você.
            </Description>
            <Description>
                Usamos Seus dados pessoais para fornecer e melhorar o Serviço. Ao utilizar o Serviço, você concorda com a coleta e uso de informações de acordo com esta Política de Privacidade. Esta Política de Privacidade foi criada com a ajuda do Gerador de Política de Privacidade .
            </Description>
            <Title>Interpretação e Definições</Title>
            <SubTitle>Interpretação</SubTitle>
            <Description>
                As palavras com a letra inicial maiúscula têm significados definidos nas seguintes condições. As seguintes definições terão o mesmo significado, independentemente de aparecerem no singular ou no plural.
            </Description>
            <SubTitle>Definições</SubTitle>
            <Description>
                Para efeitos desta Política de Privacidade:
            </Description>
            <SubDescription>
                . CONTA: significa uma conta exclusiva criada para Você acessar nosso Serviço ou partes de nosso Serviço.
            </SubDescription>
            <SubDescription>
                . AFILIADA: significa uma entidade que controla, é controlada ou está sob controle comum com uma parte, onde "controle" significa propriedade de 50% ou mais das ações, participações acionárias ou outros títulos com direito a voto para eleição de diretores ou outra autoridade administrativa.
            </SubDescription>
            <SubDescription>
                . A APLICAÇÃO: refere-se ao Zero1App, o programa de software fornecido pela Empresa.
            </SubDescription>
            <SubDescription>
                . EMPRESA: (referida como "a Empresa", "Nós", "Nós" ou "Nosso" neste Contrato) refere-se ao Concurseiro Zero1, R PATRICIO NOVELETTO N 684 BARRA DO TROMBUDO.
            </SubDescription>
            <SubDescription>
                . PAÍS: refere-se a: Brasil.
            </SubDescription>
            <SubDescription>
                . DISPOSITIVO: significa qualquer dispositivo que possa acessar o Serviço, como um computador, um celular ou um tablet digital.
            </SubDescription>
            <SubDescription>. DADOS PESSOAIS: são quaisquer informações relacionadas a um indivíduo identificado ou identificável.</SubDescription>
            <SubDescription>
                . SERVIÇO: refere-se ao Aplicativo.
            </SubDescription>
            <SubDescription>
                . PROVEDOR DE SERVIÇOS: significa qualquer pessoa física ou jurídica que processe os dados em nome da Empresa. Refere-se a empresas terceirizadas ou indivíduos contratados pela Empresa para facilitar o Serviço, para fornecer o Serviço em nome da Empresa, para executar serviços relacionados ao Serviço ou para auxiliar a Empresa na análise de como o Serviço é usado.
            </SubDescription>
            <Description>
                . DADOS DE USO: referem-se a dados coletados automaticamente, gerados pelo uso do Serviço ou pela própria infraestrutura do Serviço (por exemplo, a duração de uma visita à página).
            </Description>
            <Description>
                . VOCÊ: significa o indivíduo que acessa ou usa o Serviço, ou a empresa ou outra entidade legal em nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.
            </Description>
            <Title>Coletando e usando seus dados pessoais</Title>
            <SubTitle>Tipos de dados coletados</SubTitle>
            <SubTitle style={{ fontSize: 16 }}>Dados pessoais</SubTitle>
            <Description>
                Ao usar Nosso Serviço, podemos solicitar que Você nos forneça certas informações de identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo. As informações de identificação pessoal podem incluir, mas não estão limitadas a:
            </Description>
            <Description>
                . Endereço de email
            </Description>
            <Description>
                . Dados de uso
            </Description>
            <SubTitle style={{ fontSize: 16 }}>Dados de uso</SubTitle>
            <Description>
                Os Dados de Uso são coletados automaticamente ao usar o Serviço.
            </Description>
            <Description>
                Os Dados de Uso podem incluir informações como o endereço de protocolo da Internet do seu dispositivo (por exemplo, endereço IP), tipo de navegador, versão do navegador, as páginas do nosso Serviço que você visita, a hora e a data da sua visita, o tempo gasto nessas páginas, dispositivo exclusivo identificadores e outros dados de diagnóstico.
            </Description>
            <Description>Quando você acessa o Serviço por ou através de um dispositivo móvel, podemos coletar determinadas informações automaticamente, incluindo, entre outras, o tipo de dispositivo móvel que você usa, o ID exclusivo do seu dispositivo móvel, o endereço IP do seu dispositivo móvel, o seu sistema operacional, o tipo de navegador de Internet móvel que você usa, identificadores exclusivos de dispositivo e outros dados de diagnóstico.</Description>
            <Description>
                Também poderemos coletar informações que Seu navegador envia sempre que Você visita nosso Serviço ou quando acessa o Serviço por meio de um dispositivo móvel.
            </Description>
            <SubTitle>Uso dos seus dados pessoais</SubTitle>
            <Description>A Empresa poderá usar Dados Pessoais para os seguintes fins:</Description>
            <Description>. Para fornecer e manter nosso Serviço , inclusive para monitorar o uso de nosso Serviço.</Description>
            <Description>. Para gerenciar sua conta: para gerenciar seu registro como usuário do Serviço. Os Dados Pessoais que você fornece podem lhe dar acesso a diferentes funcionalidades do Serviço que estão disponíveis para você como usuário registrado.</Description>
            <Description>. Para a execução de um contrato: o desenvolvimento, cumprimento e execução do contrato de compra dos produtos, itens ou serviços que você adquiriu ou de qualquer outro contrato conosco através do Serviço.</Description>
            <Description>. Para contatá-lo: Para contatá-lo por e-mail, telefonemas, SMS ou outras formas equivalentes de comunicação eletrônica, como notificações push de um aplicativo móvel sobre atualizações ou comunicações informativas relacionadas às funcionalidades, produtos ou serviços contratados, incluindo as atualizações de segurança, quando necessário ou razoável para sua implementação.</Description>
            <Description>. Para lhe fornecer notícias, ofertas especiais e informações gerais sobre outros bens, serviços e eventos que oferecemos e que sejam semelhantes aos que você já comprou ou consultou, a menos que Você tenha optado por não receber tais informações.</Description>
            <Description>. Para gerenciar suas solicitações: Para atender e gerenciar suas solicitações para nós.</Description>
            <Description>
                . Para transferências de negócios: Poderemos usar Suas informações para avaliar ou conduzir uma fusão, alienação, reestruturação, reorganização, dissolução ou outra venda ou transferência de alguns ou de todos os Nossos ativos, seja como uma empresa em continuidade ou como parte de falência, liquidação, ou processo semelhante, em que os Dados Pessoais mantidos por Nós sobre os usuários de nossos Serviços estejam entre os ativos transferidos.
            </Description>
            <Description>. Para outros fins : Poderemos usar Suas informações para outros fins, como análise de dados, identificação de tendências de uso, determinação da eficácia de nossas campanhas promocionais e para avaliar e melhorar nosso Serviço, produtos, serviços, marketing e sua experiência.</Description>
            <Description>Poderemos compartilhar Suas informações pessoais nas seguintes situações:</Description>
            <Description>. Com Provedores de Serviços: Poderemos compartilhar Suas informações pessoais com Provedores de Serviços para monitorar e analisar o uso de nosso Serviço, para entrar em contato com Você.</Description>
            <Description>. Para transferências de negócios: Poderemos compartilhar ou transferir Suas informações pessoais em conexão com, ou durante negociações de, qualquer fusão, venda de ativos da Empresa, financiamento ou aquisição de todo ou parte de Nossos negócios para outra empresa.</Description>
            <Description>. Com Afiliados: Poderemos compartilhar Suas informações com Nossos afiliados e, nesse caso, exigiremos que esses afiliados honrem esta Política de Privacidade. Afiliadas incluem Nossa controladora e quaisquer outras subsidiárias, parceiros de joint venture ou outras empresas que Nós controlamos ou que estejam sob controle comum Conosco.</Description>
            <Description>. Com parceiros de negócios: Poderemos compartilhar Suas informações com Nossos parceiros de negócios para lhe oferecer determinados produtos, serviços ou promoções.</Description>
            <Description>. Com outros usuários: quando Você compartilha informações pessoais ou de outra forma interage em áreas públicas com outros usuários, tais informações podem ser visualizadas por todos os usuários e podem ser distribuídas publicamente para fora.</Description>
            <Description>. Com o seu consentimento : Podemos divulgar suas informações pessoais para qualquer outra finalidade com o seu consentimento.</Description>
            <SubTitle>Retenção dos seus dados pessoais</SubTitle>
            <Description>A Empresa reterá Seus Dados Pessoais apenas pelo tempo necessário para os fins estabelecidos nesta Política de Privacidade. Reteremos e utilizaremos os Seus Dados Pessoais na medida necessária para cumprir as nossas obrigações legais (por exemplo, se formos obrigados a reter os seus dados para cumprir as leis aplicáveis), resolver disputas e fazer cumprir os nossos acordos e políticas legais.</Description>
            <Description>A Empresa também reterá Dados de Uso para fins de análise interna. Os Dados de Utilização são geralmente retidos por um período de tempo mais curto, exceto quando estes dados são utilizados para reforçar a segurança ou para melhorar a funcionalidade do Nosso Serviço, ou quando somos legalmente obrigados a reter estes dados por períodos de tempo mais longos.</Description>
            <SubTitle>Transferência dos seus dados pessoais</SubTitle>
            <Description>Suas informações, incluindo Dados Pessoais, são processadas nos escritórios operacionais da Empresa e em quaisquer outros locais onde as partes envolvidas no processamento estejam localizadas. Isso significa que essas informações podem ser transferidas para — e mantidas em — computadores localizados fora do Seu estado, província, país ou outra jurisdição governamental onde as leis de proteção de dados possam ser diferentes daquelas da Sua jurisdição.</Description>
            <Description>Seu consentimento com esta Política de Privacidade seguido pelo envio de tais informações representa Sua concordância com essa transferência.</Description>
            <Description>
                A Empresa tomará todas as medidas razoavelmente necessárias para garantir que Seus dados sejam tratados com segurança e de acordo com esta Política de Privacidade e nenhuma transferência de Seus Dados Pessoais ocorrerá para uma organização ou país, a menos que haja controles adequados em vigor, incluindo a segurança de Seus dados e outras informações pessoais.
            </Description>
            <SubTitle>Exclua seus dados pessoais</SubTitle>
            <Description>Você tem o direito de excluir ou solicitar que ajudemos a excluir os Dados Pessoais que coletamos sobre Você.</Description>
            <Description>Nosso Serviço pode dar a Você a capacidade de excluir certas informações sobre Você de dentro do Serviço.</Description>
            <Description>Você pode atualizar, alterar ou excluir Suas informações a qualquer momento, acessando Sua Conta, se tiver uma, e visitando a seção de configurações da conta que permite gerenciar Suas informações pessoais. Você também pode entrar em contato conosco para solicitar acesso, corrigir ou excluir quaisquer informações pessoais que você nos forneceu.</Description>
            <Description>Observe, no entanto, que poderemos precisar reter determinadas informações quando tivermos uma obrigação legal ou base legal para fazê-lo.</Description>
            <SubTitle>Divulgação dos seus dados pessoais</SubTitle>
            <SubTitle style={{ fontSize: 16, marginTop: 20 }}>Transações comerciais</SubTitle>
            <Description>Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de ativos, seus Dados Pessoais poderão ser transferidos. Forneceremos um aviso antes que seus dados pessoais sejam transferidos e fiquem sujeitos a uma Política de Privacidade diferente.</Description>
            <SubTitle style={{ fontSize: 16, marginTop: 20 }}>
                Aplicação da lei
            </SubTitle>
            <Description>Em determinadas circunstâncias, a Empresa poderá ser obrigada a divulgar os Seus Dados Pessoais se tal for exigido por lei ou em resposta a pedidos válidos de autoridades públicas (por exemplo, um tribunal ou uma agência governamental).</Description>
            <SubTitle style={{ fontSize: 16, marginTop: 20 }}>Outros requisitos legais</SubTitle>
            <Description>
                A Empresa poderá divulgar Seus Dados Pessoais acreditando de boa fé que tal ação é necessária para:
            </Description>
            <Description>
                . Cumprir uma obrigação legal
            </Description>
            <Description>
                . Proteger e defender os direitos ou propriedade da Empresa
            </Description>
            <Description>
                . Prevenir ou investigar possíveis irregularidades relacionadas ao Serviço
            </Description>
            <Description>
                . Proteger a segurança pessoal dos Usuários do Serviço ou do público
            </Description>
            <Description>
                . Proteja-se contra responsabilidade legal
            </Description>
            <SubTitle>Segurança dos seus dados pessoais</SubTitle>
            <Description>
                A segurança dos Seus Dados Pessoais é importante para Nós, mas lembre-se de que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro. Embora nos esforcemos para utilizar meios comercialmente aceitáveis para proteger os Seus Dados Pessoais, não podemos garantir a sua segurança absoluta.
            </Description>
            <Title>Privacidade infantil</Title>
            <Description>Nosso Serviço não se dirige a ninguém com menos de 13 anos. Não coletamos intencionalmente informações de identificação pessoal de ninguém com menos de 13 anos. Se você é pai ou responsável e está ciente de que seu filho nos forneceu dados pessoais, por favor Contate-nos. Se tomarmos conhecimento de que coletamos Dados Pessoais de qualquer pessoa com menos de 13 anos de idade sem verificação do consentimento dos pais, tomaremos medidas para remover essas informações de Nossos servidores.</Description>
            <Description>Se precisarmos confiar no consentimento como base legal para processar Suas informações e Seu país exigir o consentimento de um dos pais, poderemos exigir o consentimento dos Seus pais antes de coletarmos e usarmos essas informações.</Description>
            <Title>Links para outros sites</Title>
            <Description>Nosso Serviço pode conter links para outros sites que não são operados por Nós. Se você clicar em um link de terceiros, será direcionado ao site desse terceiro. Recomendamos fortemente que você revise a Política de Privacidade de cada site que visitar.</Description>
            <Description>Não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou práticas de sites ou serviços de terceiros.</Description>
            <Title>Alterações nesta Política de Privacidade</Title>
            <Description>Poderemos atualizar nossa Política de Privacidade de tempos em tempos. Iremos notificá-lo sobre quaisquer alterações publicando a nova Política de Privacidade nesta página.</Description>
            <Description>
                Avisaremos Você por e-mail e/ou um aviso em destaque em Nosso Serviço, antes que a alteração entre em vigor e atualizaremos a data da “Última atualização” no topo desta Política de Privacidade.
            </Description>
            <Description>
                Aconselhamos que você revise esta Política de Privacidade periodicamente para quaisquer alterações. As alterações a esta Política de Privacidade entram em vigor quando são publicadas nesta página.
            </Description>
            <Title>Contate-nos</Title>
            <Description>Se você tiver alguma dúvida sobre esta Política de Privacidade, você pode entrar em contato conosco:</Description>
            <Description>. Visitando esta página em nosso site: https://app.engequest.com.br/</Description>
            <Description style={{ color: 'transparent' }}>.</Description>
        </Home>
    </Container>
);
export default PrivacyPolicyPage;
