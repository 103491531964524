/* eslint-disable react/require-default-props */
// libraries
import React from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { isMobile } from 'react-device-detect';

// js
import colors from '../../styles/colors';
import { ContainerInput, Input, Icon } from './inputStyles';
import functions from '../../utils/functions';

// interfaces
interface Props {
    icon?: string,
    placeholder: string,
    type: string,
    value: string,
    setValue?: (v: string) => void,
    verificationSecureText?: boolean,
    setVerificationSecureText?: (v: boolean) => void,
    onChange?: any,
}

const InputComponent: React.FC<Props> = (props: Props) => {
    // constants
    const {
        icon,
        placeholder,
        type,
        value,
        setValue,
        verificationSecureText,
        setVerificationSecureText,
        onChange,
    } = props;

    // methods
    const onChangeSecureText = () => {
        if (setVerificationSecureText) {
            setVerificationSecureText(!verificationSecureText);
        }
    };

    const onChangeInput = (txt: React.ChangeEvent<HTMLInputElement>) => {
        if (setValue) {
            if (placeholder === 'Seu telefone') {
                setValue(functions.MaskPhone(txt.target.value));
            } else if (placeholder === 'Seu CPF') {
                setValue(functions.MaskIdentifier(txt.target.value));
            } else if (placeholder === 'Sua data de nascimento') {
                setValue(functions.dateMask(value, txt.target.value));
            } else {
                setValue(txt.target.value);
            }
        }
    };

    // renders
    const RenderIconPassword = (): React.ReactElement => {
        if (verificationSecureText) {
            return (
                <AiFillEyeInvisible
                    color={colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight}
                    size={isMobile ? 20 : 25}
                    onClick={onChangeSecureText}
                    cursor="pointer"
                />
            );
        }

        return (
            <AiFillEye
                color={colors.basic(window.localStorage.getItem('theme') || 'light').grayUltraLight}
                size={isMobile ? 20 : 25}
                onClick={onChangeSecureText}
                cursor="pointer"
            />
        );
    };

    const RenderIcon = () => {
        if (!setVerificationSecureText && icon) {
            return <Icon src={icon} isMobile={isMobile} />;
        }

        if (setVerificationSecureText && !icon) {
            return RenderIconPassword();
        }

        return null;
    };

    return (
        <ContainerInput>
            <Input
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={onChange || onChangeInput}
                maxLength={placeholder === 'Seu CPF' ? 14 : 64}
            />
            {RenderIcon()}
        </ContainerInput>
    );
};

export default InputComponent;
