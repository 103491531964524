const colors = {
    basic: (darkMode: string): any => ({
        black: '#000000',
        graySingle: '#777777',
        gray: '#7F8588',
        grayLight: '#A9B2B4',
        graySmall: darkMode === 'dark' ? '#707070' : '#B8C2C6',
        grayUltraLight: '#CCD5DB',
        grayDefault: '#707070',
        grayMedium: '#A5A5A5',
        yellow: '#FFC40E',
        blue: '#292FA2',
        red: '#cc0000',
        blackSingle: '#333333',
        blackMedium: '#303030',
        white: darkMode === 'dark' ? '#333333' : '#ffffff',
        whiteReverse: darkMode === 'dark' ? '#ffffff' : '#333333',
        blueOrDarkMissOrHitQuestion: darkMode === 'dark' ? '#FFFFFF' : '#363A88',
        whiteDefault: '#FFFFFF',
    }),
    theme: (darkMode: string): any => ({
        backgroundScreen: darkMode === 'dark' ? '#222222' : '#EAF2F5',
        backgroundAuthLinearGradient: 'linear-gradient(to right, rgba(119, 79, 240, 1), rgba(9, 244, 252, 1))',
        blue: '#363A88',
        blueLight: '#0086DD',
        backgroundColorFacebook: '#3C5A9A',
        purple: '#7550F0',
        purpleDark: '#7550F1',
        purpleDarkMedium: 'rgba(117, 80, 241, 0.3)',
        backgroundIndexQuestion: '#5F3ECB',
        placeHolderAuthInputs: '#BCC5E3',
        backgroundNotificationNumber: '#FD4747',
        borderButtonGoogle: '#F85B51',
        borderButtonFacebook: '#3879FC',
        blueSea: '#21C5E8',
        blueTurquoise: '#1FCAD1',
        backgroundModal: 'rgba(0,0,0,0.3)',
        backgroundTabFilterStatistics: '#5099FC',
        backgroundTabFilterStatisticsLow: 'rgba(80,153,252, 0.3)',
        backgroundQtdExamination: '#8DADD8',
        backgroundSelectPeriod: darkMode === 'dark' ? '#707070' : '#F5F8F8',
        colorSelectPeriod: darkMode === 'dark' ? '#F5F8F8' : '#A9B2B4',
        backgroundQtdCategory: '#9DBFCC',
        lineGraphSpecifics: 'rgba(45, 229, 137)',
        lineRelativeGraphSpecifics: '#05B6F7',
        pointStrong: '#21D171',
        backgroundRadialStrong: '#00E742',
        pointRadialStrong: '#04AF30',
        progressBarStrong: '#25E742',
        pointRadialWeak: '#C63535',
        backgroundTopOne: '#FFBB22',
        backgroundTopThree: '#D9935F',
        backgroundTopTen: '#D9DFE2',
        backgroundButtonSaved: '#4D2EB4',
        backgroundLineTransparent: '#C5CDD1',
        version: '#B9C5CB',
        descriptionQuestion: darkMode === 'dark' ? '#FFFFFF' : '#222222',
        colorAlternative: darkMode === 'dark' ? '#FFFFFF' : '#363A88',
        backgroundInputsPayment: '#F2F2F2',
    }),
};

export default colors;
