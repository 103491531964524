const allTabs = [
    {
        id: '/timeline',
        title: 'Timeline',
    },
    {
        id: '/statistics',
        title: 'Estatísticas',
    },
    {
        id: '/questions',
        title: 'Questões',
    },
    {
        id: '/filters',
        title: 'Filtros',
    },
    {
        id: '/books',
        title: 'Cadernos',
    },
];

export default {
    allTabs,
};
