// js
import axios from 'axios';
import constants from '../configs/constants';

// constants
const { baseUrl } = constants;

// api
async function GetAllComments(
    idQuestion: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.get(`${baseUrl}/comments?idQuestion=${idQuestion}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateOneComment(
    comment: string,
    idQuestion: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            comment,
            idQuestion,
            idUser,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/comments`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 201) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateOneChildrenComment(
    comment: string,
    idComment: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            comment,
            idUser,
            idComment,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.post(`${baseUrl}/childrenComments`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 201) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateUpperComment(
    idComment: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            idUser,
            idComment,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.patch(`${baseUrl}/comments/upper?idComment=${idComment}&idUser=${idUser}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function CreateLowerComment(
    idComment: number,
    idUser: number,
    token: string,
): Promise<any> {
    try {
        const body = {
            idUser,
            idComment,
        };
        const stringBody = JSON.stringify(body);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const response = await axios.patch(`${baseUrl}/comments/lower?idComment=${idComment}&idUser=${idUser}`, stringBody, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DelOneComment(
    idComment: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.delete(`${baseUrl}/comments?idComment=${idComment}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

async function DelChildrenComment(
    idComment: number,
    token: string,
): Promise<any> {
    try {
        const headers = {
            'x-access-token': token,
        };
        const response = await axios.delete(`${baseUrl}/childrenComments?idChildrenComment=${idComment}`, { headers });
        const data: any = response?.data;
        if (response.status === 200) {
            return data;
        }
    } catch (error) {
        const { response }: any = error;
        // eslint-disable-next-line no-alert
        alert(response?.data?.message);
    }
}

export default {
    CreateOneComment,
    DelOneComment,
    DelChildrenComment,
    GetAllComments,
    CreateLowerComment,
    CreateUpperComment,
    CreateOneChildrenComment,
};
