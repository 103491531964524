/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
// libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
// import { addHours, lightFormat } from 'date-fns';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// js
import colors from '../../../styles/colors';
import {
    arrowDropDawnPurple,
    arrowLeftBlack,
    checkCircleBlueSea,
    closeWhite,
    iconClockGray,
    // likeBlue,
    // deslikeCommentContent,
    // likeOutlineBlue,
    // deslikeCommentOutline,
    like,
    deslike,
} from '../../../assets';
import {
    Close,
    ContainerAdjust,
    ContainerHeaderPopUp,
    ContainerPopUp,
    ContainerTopHeader,
    ImgClose,
    Title,
    ContainerBody,
    ContainerSelect,
    Select,
    Option,
    ButtonSave,
    ContainerAlarm,
    ContainerAdjustAbout,
    Welcome,
    Line,
    ArrowForDropDawn,
    AnimationDescription,
    TitleAlarm,
    DescriptionAlarm,
    IconAlarm,
    AdjustTimer,
    GoBackStep,
    AdjustContainerAlarm,
    ContainerAdjustInputs,
    AllDays,
    ContainerDay,
    BarSelected,
    UnChecked,
    Checked,
    ContainerInfoAlarm,
    EmptyList,
    ContainerLikeAndDeslike,
    IconLikeAndDeslike,
    AdjustLikes,
    DescriptionFaq,
} from '../headerStyles';
import headerConstants from '../headerConstants';
// import functions from '../../../utils/functions';
import auth from '../../../services/auth';
import profile from '../../../services/profile';
import { RootState } from '../../../store';

// components
import InputComponent from '../../Input/InputComponent';
import { updateUser } from '../../../store/modules/user/actions';
import notesStudy from '../../../services/notesStudy';
import timeline from '../../../services/timeline';
import helpCenter from '../../../services/helpCenter';

// interfaces
interface Props {
    isOpen: boolean,
    setIsOpen: (v: any) => void,
    optionSelected: any,
    descriptionBody?: string,
}

const ModalOptionsProfile: React.FC<Props> = (props) => {
    // dispatch
    const dispatch = useDispatch();

    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const {
        isOpen,
        setIsOpen,
        optionSelected,
        descriptionBody,
    } = props;

    // states
    const [widthActual, setWidthActual] = useState(window.innerWidth);
    const [allAlarms, setAllAlarms] = useState([]);
    const [verificationListEmpty, setVerificationListEmpty] = useState(null);
    const [allInputsMyData, setAllInputsMyData] = useState(headerConstants.initialInputsMyData);
    const [allInputsAlarm, setAllInputsAlarm] = useState(headerConstants.initialInputsAlarm);
    const [dataAllExamination, setDataAllExamination] = useState<Array<{name: string, id: number}>>([]);
    const [dataAllEngineering, setDataAllEngineering] = useState<Array<{name: string, id: number}>>([]);
    const [allSelectedAccordion, setAllSelectedAccordion] = useState<Array<number>>([]);
    const [alarmActual, setAlarmActual] = useState<any>(null);
    const [stepAlarm, setStepAlarm] = useState<number>(1);
    const [allInfoHelpCenter, setAllInfoHelpCenter] = useState<any>([]);

    const modalPopUp = {
        overlay: {
            backgroundColor: colors.theme(window.localStorage.getItem('theme') || 'light').backgroundModal,
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            border: 'none',
            backgroundColor: 'transparent',
            padding: '0px',
            height: widthActual > 0 ? '80vh' : '70vh',
            maxWidth: widthActual > 0 ? '61vw' : '76vw',
            margin: '8vh auto',
        },
    };

    // methods
    const CloseModal = () => {
        if (optionSelected?.idTimeline) {
            setIsOpen(null);
        } else {
            setIsOpen(!isOpen);
        }
    };

    const onChangeEngineering = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setAllInputsMyData({ ...allInputsMyData, engineering: txt.target.value });
    };

    const onChangeExamination = (txt: React.ChangeEvent<HTMLSelectElement>) => {
        setAllInputsMyData({ ...allInputsMyData, examination: txt.target.value });
    };

    const GetDataExaminationAndEngineering = async () => {
        const dataExamination = await auth.GetExamination();
        const dataEngineering = await auth.GetEngineering();
        setDataAllExamination(dataExamination?.examination);
        setDataAllEngineering(dataEngineering?.engineering);
        setAllInputsMyData({
            ...allInputsMyData,
            examination: dataExamination?.examination[0]?.id,
            engineering: dataEngineering?.engineering[0]?.id,
        });
        setAllInputsMyData({
            ...allInputsMyData,
            email: infoUser?.email,
            name: infoUser?.name,
            // telephone: functions.MaskPhone(infoUser?.telephone),
            // birthDate: lightFormat(addHours(Number(infoUser?.birth_date), 9), 'dd/MM/yyyy') || '',
            // identifier: functions.MaskIdentifier(infoUser?.cpf),
            examination: infoUser?.id_examination || dataExamination?.examination[0]?.id,
            engineering: infoUser?.id_engineering || dataEngineering?.engineering[0]?.id,
        });
    };

    const ActionGetAllAlarms = async () => {
        const GetAlarms = await notesStudy.GetAllAlarms(infoUser?.id, infoUser?.token);
        setAllAlarms(GetAlarms?.result);
        setVerificationListEmpty(GetAlarms?.result?.length || 0);
    };

    // renders
    const RenderInput = (value: any, key: string, placeholder: string, type: string) => {
        const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
            if (stepAlarm === 2) {
                setAllInputsAlarm({ ...allInputsAlarm, [key]: txt.target.value });
            } else if (!alarmActual) {
                // if (placeholder === 'Seu telefone') {
                //     setAllInputsMyData({
                //         ...allInputsMyData,
                //         [key]: functions.MaskPhone(txt.target.value),
                //     });
                // } else if (placeholder === 'Seu CPF') {
                //     setAllInputsMyData({
                //         ...allInputsMyData,
                //         [key]: functions.MaskIdentifier(txt.target.value),
                //     });
                // } else if (placeholder === 'Sua data de nascimento') {
                //     setAllInputsMyData({
                //         ...allInputsMyData,
                //         [key]: functions.dateMask(value, txt.target.value),
                //     });
                // } else {
                //     setAllInputsMyData({ ...allInputsMyData, [key]: txt.target.value });
                // }
                setAllInputsMyData({ ...allInputsMyData, [key]: txt.target.value });
            }
        };
        return (
            <InputComponent
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={onChange}
            />
        );
    };

    const UpdateUser = async () => {
        const GetEditProfile = await profile.EditProfile(
            infoUser.id,
            infoUser.token,
            allInputsMyData.name,
            allInputsMyData.email,
            allInputsMyData.password,
            allInputsMyData.telephone,
            allInputsMyData.birthDate,
            allInputsMyData.identifier,
            allInputsMyData.engineering,
            allInputsMyData.examination,
        );
        if (GetEditProfile?.status === 200) {
            const addObject = { ...GetEditProfile.result.user, token: infoUser.token, tokenRefresh: infoUser.tokenRefresh };
            dispatch(updateUser(addObject));
            setAllInputsMyData({ ...allInputsMyData, password: '', confirmPassword: '' });
            // eslint-disable-next-line no-alert
            alert(GetEditProfile?.message);
        } else {
            // eslint-disable-next-line no-alert
            alert(GetEditProfile?.message);
        }
    };

    const GetAllHelpCenter = async () => {
        const GetAllFAQ = await helpCenter.GetAllFAQ(infoUser?.token);
        if (GetAllFAQ?.status === 200) {
            setAllInfoHelpCenter(GetAllFAQ?.result?.faq);
        }
    };

    // useEffect
    useEffect(() => {
        if (optionSelected?.id === 2) {
            GetDataExaminationAndEngineering();
        }
        if (optionSelected?.id === 1) {
            ActionGetAllAlarms();
        }
        if (optionSelected?.id === 5) {
            GetAllHelpCenter();
        }
    }, [optionSelected]);

    const RenderAllEngineering = () => {
        if (dataAllEngineering?.length) {
            return dataAllEngineering.map((v) => (
                <Option value={v.id}>{v?.name}</Option>
            ));
        }
        return null;
    };

    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setWidthActual(window.innerWidth);
        }, true);

        return adjustSize;
    }, []);

    const RenderAllExamination = () => {
        if (dataAllExamination?.length) {
            return dataAllExamination.map((v) => (
                <Option value={v.id}>{v?.name}</Option>
            ));
        }
        return null;
    };

    const RenderBody = () => {
        if (optionSelected?.id === 1) {
            if (verificationListEmpty === 0 && stepAlarm === 1) {
                return <EmptyList>Sem dados para serem exibidos.</EmptyList>;
            }

            const ActionGoBackStep = () => {
                setStepAlarm(1);
                setAlarmActual(null);
                setAllInputsAlarm(headerConstants.initialInputsAlarm);
            };

            const onToggleIsRepeat = () => {
                setAllInputsAlarm({
                    ...allInputsAlarm,
                    daysSelected: allInputsAlarm.isRepeat ? [] : headerConstants.allDays.map((v) => v.id),
                    isRepeat: !allInputsAlarm.isRepeat,
                });
            };

            const RenderAllDays = () => (
                headerConstants.allDays.map((item) => {
                    const ActionSelected = () => {
                        if (allInputsAlarm.daysSelected?.includes(item.id)) {
                            const removeId = allInputsAlarm.daysSelected?.filter((v) => v !== item.id);
                            setAllInputsAlarm({
                                ...allInputsAlarm,
                                daysSelected: removeId,
                            });
                        } else {
                            setAllInputsAlarm({
                                ...allInputsAlarm,
                                daysSelected: [...allInputsAlarm.daysSelected, item.id],
                            });
                        }
                    };
                    return (
                        <ContainerDay onClick={ActionSelected}>
                            <AdjustTimer>
                                <BarSelected backgroundColor={allInputsAlarm.daysSelected?.includes(item.id) ? colors.theme(window.localStorage.getItem('theme') || 'light').blueSea : colors.basic(window.localStorage.getItem('theme') || 'light').whiteReverse} />
                                <DescriptionAlarm>{item?.label}</DescriptionAlarm>
                            </AdjustTimer>
                            {allInputsAlarm.daysSelected?.includes(item.id) ? (
                                <Checked src={checkCircleBlueSea} />
                            ) : (
                                <UnChecked />
                            )}
                        </ContainerDay>
                    );
                })
            );

            return (
                <AdjustContainerAlarm>
                    {stepAlarm !== 1 ? (
                        <GoBackStep src={arrowLeftBlack} onClick={ActionGoBackStep} />
                    ) : null}
                    {stepAlarm === 1 ? (
                        allAlarms.map((v: any) => {
                            const onChangeTheme = async () => {
                                await notesStudy.EditOneAlarm(v?.title, v?.subtitle, v?.from_dates, v?.is_repeat, !v?.enabled, v?.id, infoUser.id, infoUser.token);
                                ActionGetAllAlarms();
                            };
                            const GoToEditAlarm = () => {
                                setAlarmActual(v);
                                setAllInputsAlarm({
                                    title: v?.title,
                                    subtitle: v?.subtitle,
                                    hour: v?.hours,
                                    daysSelected: v?.from_dates?.map((value: string) => Number(value)),
                                    isRepeat: v?.from_dates?.length === 7,
                                });
                                setStepAlarm(2);
                            };
                            return (
                                <ContainerAlarm key={v?.id}>
                                    <ContainerInfoAlarm onClick={GoToEditAlarm}>
                                        <TitleAlarm>{v?.title}</TitleAlarm>
                                        <DescriptionAlarm>{v?.subtitle}</DescriptionAlarm>
                                        <AdjustTimer>
                                            <IconAlarm src={iconClockGray} />
                                            <DescriptionAlarm>{v?.hours}</DescriptionAlarm>
                                        </AdjustTimer>
                                    </ContainerInfoAlarm>
                                    {/* <Switch
                                        onChange={onChangeTheme}
                                        checked={v?.enabled}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onColor={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics}
                                    /> */}
                                </ContainerAlarm>
                            );
                        })
                    ) : (
                        <ContainerAdjustInputs>
                            {RenderInput(allInputsAlarm.title, 'title', 'Título', 'email')}
                            {RenderInput(allInputsAlarm.subtitle, 'subtitle', 'Subtítulo', 'email')}
                            {RenderInput(allInputsAlarm.hour, 'hour', 'Ex.: HH:mm', 'time')}
                            <AdjustTimer width="80%" justifyContent="space-between">
                                <DescriptionAlarm>Repetir para todos os dias da semana</DescriptionAlarm>
                                {/* <Switch
                                    onChange={onToggleIsRepeat}
                                    checked={allInputsAlarm.isRepeat}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    onColor={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundTabFilterStatistics}
                                /> */}
                            </AdjustTimer>
                            <AllDays>
                                {RenderAllDays()}
                            </AllDays>
                        </ContainerAdjustInputs>
                    )}
                </AdjustContainerAlarm>
            );
        }
        if (optionSelected?.id === 2) {
            return (
                <>
                    {RenderInput(allInputsMyData.email, 'email', 'Seu e-mail', 'email')}
                    {RenderInput(allInputsMyData.name, 'name', 'Seu nome completo', 'email')}
                    {/* {RenderInput(allInputsMyData.birthDate, 'birthDate', 'Sua data de nascimento', 'email')}
                    {RenderInput(allInputsMyData.telephone, 'telephone', 'Seu telefone', 'email')}
                    {RenderInput(allInputsMyData.identifier, 'identifier', 'Seu CPF', 'email')} */}
                    <ContainerSelect>
                        <Select value={allInputsMyData.engineering} onChange={onChangeEngineering}>
                            {RenderAllEngineering()}
                        </Select>
                    </ContainerSelect>
                    <ContainerSelect>
                        <Select value={allInputsMyData.examination} onChange={onChangeExamination}>
                            {RenderAllExamination()}
                        </Select>
                    </ContainerSelect>
                    {RenderInput(allInputsMyData.password, 'password', 'Senha | mínimo de 8 caracteres', 'password')}
                    {RenderInput(allInputsMyData.confirmPassword, 'confirmPassword', 'Confirmar senha', 'password')}
                </>
            );
        }
        if (optionSelected?.id === 4) {
            return (
                <ContainerAdjustAbout>
                    <Welcome>{headerConstants.lipsum}</Welcome>
                </ContainerAdjustAbout>
            );
        }
        if (optionSelected?.id === 5) {
            const spaceDescription = { paddingTop: 10 };
            const onChangeAccordion = (txt: Array<number>) => {
                setAllSelectedAccordion(txt);
            };
            return (
                <ContainerAdjustAbout>
                    <Accordion
                        allowZeroExpanded
                        allowMultipleExpanded
                        onChange={onChangeAccordion}
                    >
                        {allInfoHelpCenter.map((v: any) => (
                            <AccordionItem
                                uuid={v.id}
                                style={headerConstants.stylesAccordionItem(allSelectedAccordion.includes(v.id))}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton style={headerConstants.stylesAccordionItemButton}>
                                        {v.title}
                                        <ArrowForDropDawn src={arrowDropDawnPurple} />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {!allSelectedAccordion.includes(v.id) ? (
                                    <Line margin="0px 0px 0px 0px" />
                                ) : null}
                                <AccordionItemPanel style={spaceDescription}>
                                    <AnimationDescription>
                                        <DescriptionFaq>
                                            {v.description}
                                        </DescriptionFaq>
                                        {allSelectedAccordion.includes(v.id) ? (
                                            <Line margin="10px 0px 20px 0px" />
                                        ) : null}
                                    </AnimationDescription>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </ContainerAdjustAbout>
            );
        }
        if (optionSelected?.idTimeline) {
            return (
                <ContainerAdjustAbout>
                    <Welcome>{descriptionBody}</Welcome>
                </ContainerAdjustAbout>
            );
        }
        return null;
    };

    const RefreshAlarm = () => {
        setStepAlarm(1);
        setAlarmActual(null);
        setAllInputsAlarm(headerConstants.initialInputsAlarm);
        ActionGetAllAlarms();
    };

    const ActiveLike = async () => {
        const ActionActiveLike = await timeline.EditTimeLine('like', optionSelected?.idTimeline, infoUser.id, infoUser.token);
        // eslint-disable-next-line no-alert
        alert(ActionActiveLike?.message);
    };

    const ActiveDeslike = async () => {
        const ActionActiveDeslike = await timeline.EditTimeLine('deslike', optionSelected?.idTimeline, infoUser.id, infoUser.token);
        // eslint-disable-next-line no-alert
        alert(ActionActiveDeslike?.message);
    };

    const ActionAddOrEdit = async () => {
        if (alarmActual && stepAlarm === 2) {
            await notesStudy.EditOneAlarm(allInputsAlarm?.title, allInputsAlarm?.subtitle, allInputsAlarm.daysSelected, allInputsAlarm.hour, alarmActual?.enabled, alarmActual?.id, infoUser.id, infoUser.token);
            RefreshAlarm();
        } else if (!alarmActual && stepAlarm === 2) {
            await notesStudy.CreateOneAlarm(allInputsAlarm?.title, allInputsAlarm?.subtitle, allInputsAlarm?.daysSelected, allInputsAlarm?.hour, infoUser.id, infoUser.token);
            RefreshAlarm();
        } else if (!alarmActual && stepAlarm === 1) {
            setStepAlarm(2);
        }
    };

    const DeleteAlarm = async () => {
        await notesStudy.DeleteOneAlarm(alarmActual?.id, infoUser.token);
        RefreshAlarm();
    };

    const RenderLabelAlarm = () => {
        if (alarmActual) {
            return 'Editar alarme';
        }
        if (!alarmActual && stepAlarm === 2) {
            return 'Adicionar alarme';
        }
        return 'Adicionar';
    };

    return (
        <Modal isOpen={isOpen} style={modalPopUp} onRequestClose={CloseModal}>
            <ContainerPopUp>
                <ContainerAdjust>
                    <ContainerHeaderPopUp>
                        <ContainerTopHeader>
                            <Title>
                                <Title>—</Title>
                                {optionSelected?.name?.toUpperCase()}
                            </Title>
                            <Close onClick={CloseModal}>
                                <ImgClose src={closeWhite} />
                            </Close>
                        </ContainerTopHeader>
                    </ContainerHeaderPopUp>
                    <ContainerBody>
                        {RenderBody()}
                        {[2].includes(optionSelected?.id) ? (
                            <ButtonSave onClick={UpdateUser}>Salvar</ButtonSave>
                        ) : null}
                        {[1].includes(optionSelected?.id) ? (
                            <ButtonSave
                                onClick={ActionAddOrEdit}
                                backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                                width="40%"
                            >
                                {RenderLabelAlarm()}
                            </ButtonSave>
                        ) : null}
                        {[1].includes(optionSelected?.id) && alarmActual ? (
                            <ButtonSave
                                onClick={DeleteAlarm}
                                backgroundColor={colors.theme(window.localStorage.getItem('theme') || 'light').backgroundNotificationNumber}
                                width="40%"
                            >
                                Excluir alarme
                            </ButtonSave>
                        ) : null}
                        {optionSelected?.idTimeline ? (
                            <ContainerAdjustAbout>
                                <Welcome fontSize="13px">Esse artigo foi útil?</Welcome>
                                <AdjustLikes>
                                    <ContainerLikeAndDeslike onClick={ActiveLike}>
                                        <IconLikeAndDeslike src={like} />
                                    </ContainerLikeAndDeslike>
                                    <ContainerLikeAndDeslike onClick={ActiveDeslike}>
                                        <IconLikeAndDeslike src={deslike} />
                                    </ContainerLikeAndDeslike>
                                </AdjustLikes>
                                <ButtonSave onClick={CloseModal}>Ocultar matéria</ButtonSave>
                            </ContainerAdjustAbout>
                        ) : null}
                    </ContainerBody>
                </ContainerAdjust>
            </ContainerPopUp>
        </Modal>
    );
};

export default ModalOptionsProfile;
