/* eslint-disable react/require-default-props */
// libraries
import React from 'react';

// js
import {
    SelectPeriod,
    TitleSelect,
    TitlePeriod,
    IconDropDawn,
} from '../../statisticsStyles';
import { arrowDropDawnGray, arrowDropDawnWhite } from '../../../../assets';
import statisticsConstants from '../../statisticsConstants';

// interfaces
interface PickerComponentInterfaces {
    label: string,
    value: string,
    idFilter: number,
    typeOrderId?: number,
    setOpenModalPeriod?: (v: boolean) => void,
    setAllData: (v: Array<any>) => void,
    setPopUp?: (v: boolean) => void,
    setTypeBankOrEngineering?: (v: string) => void,
}

const PickerComponent: React.FC<PickerComponentInterfaces> = ({
    label,
    value,
    idFilter,
    typeOrderId,
    setOpenModalPeriod,
    setAllData,
    setPopUp,
    setTypeBankOrEngineering,
}) => {
    const RenderValue = () => {
        if (typeof typeOrderId === 'number') {
            return statisticsConstants.optionsOrderTime[typeOrderId].label;
        }

        return value;
    };
    const OpenFilter = () => {
        if (setOpenModalPeriod !== undefined) {
            // if (label === 'Período:' && idFilter === 1) {
            //     setAllData(statisticsConstants.allLabel);
            //     setOpenModalPeriod(true);
            // }
            if (label === 'Período:' && idFilter === 2) {
                const removeCustom = statisticsConstants.optionsOrderTime.filter((v) => !['CUSTOM', 'ALL', 'YEAR'].includes(v.id));
                setAllData(removeCustom);
                setOpenModalPeriod(true);
            }
            if (label === 'Período:' && [1, 3, 4].includes(idFilter)) {
                setAllData(statisticsConstants.optionsOrderTime);
                setOpenModalPeriod(true);
            }
        }
        if (label === 'Cadernos:' && idFilter === 3 && setPopUp) {
            setPopUp(true);
        }
        if (label === 'Bancas' && setPopUp && setTypeBankOrEngineering) {
            setPopUp(true);
            setTypeBankOrEngineering('Bancas');
        }
        if (label === 'Formações' && setPopUp && setTypeBankOrEngineering) {
            setPopUp(true);
            setTypeBankOrEngineering('Formações');
        }
    };
    return (
        <SelectPeriod onClick={OpenFilter}>
            <TitleSelect>
                {setTypeBankOrEngineering ? '' : label}
                <TitlePeriod>
                    {RenderValue()}
                    <IconDropDawn src={window.localStorage.getItem('theme') === 'dark' ? arrowDropDawnWhite : arrowDropDawnGray} />
                </TitlePeriod>
            </TitleSelect>
        </SelectPeriod>
    );
};

export default PickerComponent;
