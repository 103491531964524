// js
import { UPDATE_DARKMODE } from './constants';
import { AppDispatch } from '../../index';

// actions
export const updateDarkMode: AppDispatch = (darkMode: boolean) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_DARKMODE,
        payload: darkMode,
    });
};
