/* eslint-disable react/no-unused-prop-types */
// libraries
import React, { useEffect, useState, useRef } from 'react';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { version } from '../../../package.json';

// js
import {
    ContainerHeader,
    // ContainerSearch,
    Logo,
    IconSearch,
    IconFaq,
    // InputSearch,
    ButtonFaq,
    TextButtonFaq,
    // TextFilter,
    IconNotification,
    // NumberNotification,
    IconProfile,
    ContainerColumn,
    Welcome,
    ContainerAdjustIcons,
    // TextTypeFilter,
    // ArrowDropDawn,
    ContainerDropDawn,
    ContainerPopUpProfile,
    ContainerAdjustProfile,
    Line,
    IconTab,
    TitleClose,
    InputNullSelectedArchive,
    ContainerSearchInvisible,
    ContainerMenus,
} from './headerStyles';
import {
    logoFullSizeColor,
    // iconSearch,
    iconFaq,
    // iconNotification,
    // iconPhotoProfileDefault,
    iconCalendar,
    iconDrawer,
    // iconSearchLowDimensions,
    // arrowDropDawnPurple,
    userBlue,
    logoFullSizeColorDark,
} from '../../assets/index';
import { RootState } from '../../store';
import headerConstants from './headerConstants';
import colors from '../../styles/colors';
import profile from '../../services/profile';
import { logoutAuth, updateUser } from '../../store/modules/user/actions';

// components
import ModalOptionsProfile from './components/ModalOptionsProfile';
import uploads from '../../services/uploads';
import { TextClosedModal } from '../Drawer/drawerStyles';

// interfaces
interface Props {
    // labelSearch: string,
    // search: string,
    // setSearch: (v: string) => void,
    widthActual: number,
    onDrawer: any | undefined,
    // eslint-disable-next-line react/require-default-props
    idScreen?: string,
}

const HeaderComponent: React.FC<Props> = (props: Props) => {
    // dispatch
    const dispatch = useDispatch();

    // selector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const {
        // labelSearch,
        // search,
        // setSearch,
        widthActual,
        onDrawer,
        idScreen,
    } = props;
    const localTheme = window.localStorage.getItem('theme');

    // states
    const [photoProfile, setPhotoProfile] = useState(infoUser?.photo || userBlue);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [optionSelected, setOptionSelected] = useState<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [checked, setChecked] = useState(localTheme === 'dark');
    const dropdownRef = useRef<HTMLDivElement>(null);

    // use Effects
    useEffect(() => {
        setPhotoProfile(infoUser?.photo || userBlue);
    }, [infoUser]);

    useEffect(() => {
        const pageClickEvent = (e: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
                setOpenPopUp(false);
            }
        };
        if (openPopUp) {
            window.addEventListener('click', pageClickEvent);
        }
        return () => {
            window.removeEventListener('click', pageClickEvent);
        };
    }, [openPopUp]);

    // methods
    // const onChangeSearch = (txt: React.ChangeEvent<HTMLInputElement>) => {
    //     const { value } = txt.target;
    //     setSearch(value);
    // };

    const greetingMessage = (): string | null => {
        const h = new Date().getHours();
        if (h >= 0 && h <= 5) {
            return 'Boa madrugada';
        } if (h >= 6 && h < 12) {
            return 'Bom dia';
        } if (h >= 12 && h < 18) {
            return 'Boa tarde';
        } if (h >= 18 && h <= 23) {
            return 'Boa noite';
        }
        return null;
    };

    const OpenPopUpProfile = () => {
        setOpenPopUp(!openPopUp);
    };

    const updatePhoto = async (event: any) => {
        const dataPhoto = {
            eventFile: event.target.files[0],
            eventName: event.target.files[0].name,
            file: URL.createObjectURL(event.target.files[0]),
        };
        const resultPhoto = await uploads.UploadFile(dataPhoto.eventFile);
        if (!resultPhoto.result) {
            // eslint-disable-next-line no-alert
            return alert('Ocorreu um erro ao tentar atualizar sua foto de perfil.');
        }
        const result = await profile.EditProfile(
            infoUser.id,
            infoUser.token,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            resultPhoto.result,
        );
        dispatch(updateUser({ ...infoUser, ...result.result.user }));
        OpenPopUpProfile();
        return null;
    };

    const onChangeTheme = () => {
        setChecked(!checked);
        if (localTheme === 'light' || !localTheme) {
            window.localStorage.setItem('theme', 'dark');
        } else {
            window.localStorage.setItem('theme', 'light');
        }
        window.location.reload();
    };

    const openFAQ = () => {
        setOptionSelected(headerConstants.allTabs.find((v) => v.id === 5));
        setOpenPopUp(false);
        setIsOpen(true);
    };

    // main
    return (
        <ContainerHeader idScreen={idScreen}>
            <Logo src={window.localStorage.getItem('theme') === 'dark' ? logoFullSizeColorDark : logoFullSizeColor} />
            {widthActual >= 800 ? (
                <ContainerSearchInvisible />
                // <ContainerSearch>
                //     <ContainerDropDawn width="70%">
                //         <IconSearch src={iconSearch} />
                //         <InputSearch
                //             value={search}
                //             onChange={onChangeSearch}
                //             placeholder={`Buscar por ${labelSearch.toUpperCase()}`}
                //         />
                //     </ContainerDropDawn>
                //     {widthActual >= 800 ? (
                //         <TextFilter>
                //             Filtrar por:
                //             <ContainerDropDawn>
                //                 <TextTypeFilter>Todos</TextTypeFilter>
                //                 <ArrowDropDawn src={arrowDropDawnPurple} />
                //             </ContainerDropDawn>
                //         </TextFilter>
                //     ) : null}
                // </ContainerSearch>
            ) : null}
            <ContainerMenus>
                {widthActual >= 800 ? (
                    <ButtonFaq onClick={openFAQ}>
                        <IconFaq src={iconFaq} />
                        <TextButtonFaq>FAQ</TextButtonFaq>
                    </ButtonFaq>
                ) : null}
                {widthActual <= 800 ? (
                    <ContainerAdjustIcons>
                        {/* {widthActual <= 800 ? (
                            <IconSearch src={iconSearchLowDimensions} />
                        ) : null} */}
                        {widthActual <= 800 ? (
                            <IconSearch src={iconDrawer} cursor="pointer" onClick={onDrawer} />
                        ) : null}
                    </ContainerAdjustIcons>
                ) : null}
                {widthActual >= 200 ? (
                    <>
                        {/* <IconNotification background={iconNotification}>
                            <NumberNotification>3</NumberNotification>
                        </IconNotification> */}
                        <ContainerDropDawn onClick={OpenPopUpProfile}>
                            <IconProfile src={photoProfile} />
                            <ContainerColumn>
                                {widthActual >= 800 ? (
                                    <>
                                        <Welcome>
                                            {greetingMessage()}
                                            ,
                                        </Welcome>
                                        <Welcome fontWeight="bold">{`${infoUser?.name}!` || ''}</Welcome>
                                    </>
                                ) : null}
                            </ContainerColumn>
                        </ContainerDropDawn>
                    </>
                ) : null}
            </ContainerMenus>
            <ContainerPopUpProfile ref={dropdownRef} openPopUp={openPopUp}>
                <TextClosedModal onClick={OpenPopUpProfile} fontWeight="bold">X</TextClosedModal>
                <ContainerAdjustProfile>
                    <ContainerDropDawn>
                        <Button>
                            <IconProfile src={photoProfile} />
                            <InputNullSelectedArchive
                                accept="image/png, image/jpeg, image/jpg"
                                type="file"
                                onChange={updatePhoto}
                            />
                        </Button>
                        <ContainerColumn>
                            <Welcome>
                                {greetingMessage()}
                                ,
                            </Welcome>
                            <Welcome fontWeight="bold">{`${infoUser?.name}!` || ''}</Welcome>
                        </ContainerColumn>
                    </ContainerDropDawn>
                </ContainerAdjustProfile>
                {/*
        <Welcome color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}>
                    Assinatura
                    <Welcome
                    color={colors.theme(window.localStorage.getItem('theme') || 'light').purpleDark}
                        fontWeight="bold"
                        >{' Premium'}</Welcome>
                </Welcome> */}
                <Line />
                <ContainerAdjustProfile justifyContent="space-between">
                    <Welcome style={{ margin: '18px 0' }} fontWeight="bold">Modo Noturno</Welcome>
                    <Switch
                        onChange={onChangeTheme}
                        checked={checked}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={colors.theme(window.localStorage.getItem('theme')
                        || 'light').purpleDark}
                    />
                </ContainerAdjustProfile>
                {headerConstants.allTabs.map((v) => {
                    const OpenTab = () => {
                        if ([1, 2, 5].includes(v?.id)) {
                            setOptionSelected(v);
                            setOpenPopUp(false);
                            setIsOpen(true);
                        }
                        if (v.id === 3) {
                            window.location.replace(`/signatures/typePlan=${infoUser?.type_plan}/idUser=${infoUser?.id}/token=${infoUser?.token}`);
                        }
                        if (v.id === 4) {
                            window.open('https://www.instagram.com/engequest/');
                        }
                        if (v.id === 6) {
                            dispatch(logoutAuth());
                        }
                    };
                    return (
                        <ContainerAdjustProfile key={v.id} margin="0px 0px 40px 0px" onClick={OpenTab}>
                            <IconTab height={v.height} width={v.width} src={v.icon} />
                            <Welcome fontWeight="bold">{v.name}</Welcome>
                        </ContainerAdjustProfile>
                    );
                })}
                <Line margin="60px 0px 20px 0px" />
                <Welcome color={colors.theme(window.localStorage.getItem('theme') || 'light').version}>{`Versão ${version}`}</Welcome>
            </ContainerPopUpProfile>
            <ModalOptionsProfile
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                optionSelected={optionSelected}
            />
        </ContainerHeader>
    );
};

export default HeaderComponent;
