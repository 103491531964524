// libraries
import { createGlobalStyle } from 'styled-components';

// js
import colors from './colors';
import consts from '../utils/consts';

// style
const globalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    body {
        background-color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
        -webkit-font-smoothing: antialiased;
        max-width: 100%;
        overflow-x: hidden;
        transition: all 0.25s linear;
    }
    body, input, button {
        font-size: ${consts.fontSize.default};
        font-family: ${consts.fontFamily};
        font-weight: 400;
        color: ${colors.basic(window.localStorage.getItem('theme') || 'light').white};
    }
    #root {
        margin: 0 auto;
    }
`;

export default globalStyle;
